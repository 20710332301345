import { SELECTED_ITEM } from "Redux/Types"
import { DashBoardItems } from "pages/OT/Pages/Dashboard/DashboardItems";

export const selectedItem = (state = <DashBoardItems />, action) => {

    switch (action.type) {
        case SELECTED_ITEM:
            return {
                ...state,
                item: action.payload,
            };
        default:
            return state;
    }
}
