import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { Grid, makeStyles, Typography, Dialog, DialogContent, Divider, DialogActions, IconButton } from "@material-ui/core";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
//components
import Button from "components/Button";
import TextField from "components/TextField";
//pages
import MedicineTable from "../ConsumableTables/MedicinesTable";
import { Autocomplete } from "@material-ui/lab";
import { MedicineTableDataURL, MedicineMstDataURL, MedicineURL, MedicineUpdateURL, MedicineDeleteURL } from "pages/Services/URLs";
import { toast } from "react-toastify";
import axiosInstance from "pages/Services/AxiosInstance";
import { useSelector } from "react-redux";
import { CloseRounded } from "@material-ui/icons";
import { DeleteDialogBox } from "pages/OT/components/DeleteDailogBox";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        padding: theme.spacing(0),
    },
    grid1: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },
    label: {
        fontsize: '0.875rem',
        margin: 5,
    },
    dialogHead: {
        color: theme.palette.primary.contrastText,
        backgroundColor: theme.palette.primary.main,
        padding: 2,
    },
    buttons: {
        display: 'flex',
        justifyContent: 'flex-end',
        padding: 15,

    }
}));

export const MedicineForm = (props) => {
    const classes = useStyles();
    const bookingId = useSelector((store) => store.OTPatients.bookingId);
    const [open, setOpen] = React.useState(false);
    const [openDeleteBox, setOpenDeleteBox] = React.useState(false);
    const [edit, setEdit] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(true);
    const [item, setItem] = React.useState([]);
    const [medicineTable, setMedicineTable] = React.useState([]);
    const [medicinelist, setMedicinelist] = React.useState([]);

    const defaultValues = {
        bookingId: bookingId,
        medicineId: "",
        quantity: "",
    }

    const schema = yup.object().shape({
        medicineId: yup
            .string()
            .required("Medicine is required"),

        quantity: yup
            .string()
            .required("Quantity is required"),

    });

    const { reset, handleSubmit, control, formState: { errors } } = useForm({
        mode: "onChange",
        defaultValues,
        resolver: yupResolver(schema),

    });

    useEffect(() => {
        fetchMedicineTable();
    }, [])

    const handleClickOpen = () => {
        resetForm();
        setOpen(true);
        fetchMedicineItemList();

    };
    const handleClose = () => {
        setOpen(false);
        setEdit(false);

    };

    const setMedicinesEditdata = (item) => {
        handleClickOpen();
        setEdit(true);
        reset(item);
    }

    const resetForm = () => {
        reset(defaultValues);
    };

    const fetchMedicineItemList = () => {
        axiosInstance
            .get(MedicineMstDataURL)
            .then((res) => {
                setMedicinelist(res.data);
            })
            .catch((e) => console.log("fetchMedicineItemListError:" + e.message));
    }

    const fetchMedicineTable = () => {
        bookingId ?
            axiosInstance
                .get(MedicineTableDataURL + bookingId)
                .then((res) => {
                    setMedicineTable(res.data);
                    setIsLoading(false);
                })
                .catch((e) => {
                    console.log("fetchMedicineTableError:" + e.message)
                    setIsLoading(false);
                }) : setIsLoading(false);
    }

    const executeSubmit = async (data) => {
            await axiosInstance
                .post(MedicineURL, { ...data })
                .then((res) => {
                    if (res.status === 200 || res.status === 201) {
                        // const strsubmit = "Data Successfully Submitted";
                        // toast.success(strsubmit);
                        handleClose();
                        resetForm();
                        fetchMedicineTable();
                    } else if (res.status === 409) {
                        toast.warning(res.response.data);
                    }
                })
                .catch((error) => {
                    if (error.response.status === 409) {
                        toast.warning(error.response.data);
                    }
                    else {
                        toast.error("Something went wrong");
                    }
                })
    }


    const executeUpdate = async (data) => {
        await axiosInstance
            .post(MedicineUpdateURL, { ...data })
            .then((res) => {
                if (res.status === 200 || res.status === 201) {
                    const strupdate = "Record Updated";
                    toast.success(strupdate);
                    handleClose();
                    resetForm();
                    fetchMedicineTable();
                } else if (res.status === 409) {
                    toast.warning(res.response.data);
                }
            })
            .catch((error) => {
                if (error.response.status === 409) {
                    toast.warning(error.response.data);
                }
                else {
                    toast.error("Something went wrong");
                }
            });
    }
    const executeDelete = async (data) => {
        await axiosInstance
            .post(MedicineDeleteURL, { ...data })
            .then((res) => {
                if (res.status === 200 || res.status === 201) {
                    const strsubmit = "Record Deleted";
                    toast.error(strsubmit);
                    setOpenDeleteBox(!openDeleteBox);
                    resetForm();
                    fetchMedicineTable();
                } else if (res.response.status === 409) {
                    toast.warning(res.response.data);
                }
            })
            .catch((error) => {
                toast.error("Something went wrong");
            });
    }

    const onClickOpenDeleteBox = (item) => {
        setOpenDeleteBox(!openDeleteBox)
        setItem(item)
    }

    return (
        <div className={classes.root}>
            <Grid container
                className={classes.grid1}
                alignItems="center" spacing={2} justifyContent="center" >
                <Grid item >
                    <Typography> <span style={{ fontWeight: 500 }}>Medicines  ({medicineTable ? medicineTable?.length : 0})</span>
                    </Typography>
                </Grid>
                <Grid item >
                    <Button
                        onClick={handleClickOpen}
                        type="submit"
                        color="primary"
                        variant="contained"
                    >
                        Add Medicine
                    </Button>
                    <form onSubmit={handleSubmit(executeSubmit)}>
                        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                            <Grid xs={12} container justifyContent="space-between" className={classes.dialogHead} >
                                <Grid item >
                                    <Typography className={classes.label} >Medicines Details</Typography>
                                </Grid>
                                <Grid item  >
                                    <IconButton
                                        size="small"
                                        //style={{ color: "white" }}
                                        onClick={handleClose} >
                                        <CloseRounded />
                                    </IconButton>
                                </Grid>
                            </Grid>
                            <Divider />
                            <DialogContent  >
                                <Grid container spacing={2}>

                                    <Grid item xs={12} lg={12}>
                                        <Typography > Medicine Name<span style={{ color: 'red' }}>*</span> </Typography>
                                        <Controller
                                            name="medicineId"
                                            control={control}
                                            render={({
                                                field: { onChange, value },
                                            }) => (
                                                <Autocomplete
                                                    componentsProps={{ popper: { style: { width: 'fit-content' } } }} l

                                                    options={medicinelist}
                                                    value={medicinelist.find((o) => o.id === value) || ""
                                                    }
                                                    getOptionLabel={(option) =>
                                                        option.name ? option.name : ""
                                                    }
                                                    onChange={(e, v) => {
                                                        onChange((v?.id));
                                                        // console.log(v)
                                                    }}
                                                    disabled={edit ? true : false}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            variant="outlined"
                                                            size="small"
                                                            placeholder="Select Medicine"
                                                            error={!!errors.medicineId}
                                                            helperText={errors.medicineId?.message}
                                                        />
                                                    )}
                                                />
                                            )}
                                        />
                                    </Grid>

                                    <Grid item lg={12} xs={12} >
                                        <Typography> Quantity <span style={{ color: 'red' }}>*</span> </Typography>
                                        <Controller
                                            name="quantity"
                                            control={control}
                                            render={({ field: { onChange, value } }) => (
                                                <TextField
                                                    name="quantity"
                                                    placeholder=" Add Quantity"
                                                    size="small"
                                                    variant="outlined"
                                                    value={value}
                                                    onChange={onChange}
                                                    error={!!errors.quantity}
                                                    helperText={errors.quantity?.message} />
                                            )}
                                        />
                                    </Grid>
                                </Grid>
                            </DialogContent>
                            <DialogActions style={{ justifyContent: "center" }}>
                                {edit ? (
                                    <Button
                                        align="center"
                                        type="submit"
                                        color="primary"
                                        variant="contained"
                                        sx={{ mr: 2 }}
                                        onClick={handleSubmit(executeUpdate)}>
                                        Update
                                    </Button>

                                ) : (

                                    <Button

                                        align="center"
                                        type="submit"
                                        color="primary"
                                        variant="contained"
                                        sx={{ m: 6 }}
                                        onClick={handleSubmit(executeSubmit)}

                                    >
                                        Submit
                                    </Button>

                                )}

                                <Button
                                    align="center"
                                    color="primary"
                                    variant="contained"
                                    className={classes.reset}
                                    onClick={() => {
                                        resetForm();
                                        setEdit(false);
                                    }}>
                                    Reset
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </form>
                </Grid>
                <Grid item xs={12}>
                    <MedicineTable
                        isLoading={isLoading}
                        data={medicineTable}
                        onClickOpenDeleteBox={onClickOpenDeleteBox}
                        setEditdata={setMedicinesEditdata} />
                </Grid>
            </Grid>
            <DeleteDialogBox openDeleteBox={openDeleteBox}
                closeDeleteBox={() => setOpenDeleteBox(!openDeleteBox)}
                onClick={() => executeDelete(item)}
            />
        </div >
    )
}