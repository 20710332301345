import React from "react";
import { Grid, makeStyles, } from "@material-ui/core";
//components
import Button from "components/Button";

//forms
import { PhysicianForm } from "./SurgeryTeamForms/PhysicianForm";
import { SupportingStaffForm } from "./SurgeryTeamForms/SupportingStaffFrom";
import { ChecklistForm } from "./SurgeryTeamForms/ChecklistForm";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        padding: theme.spacing(0),
    },
}));

export const SurgeryTeamTab = (props) => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <PhysicianForm />
            <SupportingStaffForm />
            <ChecklistForm />
            <Grid container spacing={4} justifyContent='center'
                style={{ marginTop: 10 }}>
                {/* <Grid item >
                    <Button
                        variant="contained"
                        color="secondary"
                    >
                        Save
                    </Button>
                </Grid> */}
                <Grid item>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={props.onClickBack}
                    >
                        Back
                    </Button>
                </Grid>
                <Grid item>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={props.onClickNext}
                    >
                        Next
                    </Button>
                </Grid>
            </Grid>
        </div >
    )
}