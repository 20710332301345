import React, { useState } from "react";
import { Grid,  InputAdornment, IconButton } from "@material-ui/core";

import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

import { Alert } from "@material-ui/lab";
import { Snackbar } from "@material-ui/core";
import TextField from "../../components/TextField";
import Button from "../../components/Button";

//redux
import AxiosInstance from "../Services/AxiosInstance";

//URLs and ReloadState
import { changePasswordForEmployee, changePasswordForAdmin} from "../Services/URLs";
import { encryptFn } from "pages/HomePage/SignUp";
import { toast } from "react-toastify";


export const passwordRegex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{8,50}$/;
function ChangePwd({user, onClose, logout}) {
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword1, setShowPassword1] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const [customError, setCustomError] = useState(false);
  const [customMsg, setCustomMsg] = useState("");
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [samePasswordError, setSamePasswordError] = useState("");
  const [passwordMatchError, setPasswordMatchError] = useState("");

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleClickShowPassword1 = () => {
    setShowPassword1(!showPassword1);
  };
  const handleMouseDownPassword1 = (event) => {
    event.preventDefault();
  };

  const handleClickShowPassword2 = () => {
    setShowPassword2(!showPassword2);
  };

  const handleMouseDownPassword2 = (event) => {
    event.preventDefault();
  };




  const handleClickUpdatePassword = () => {
    if (
      oldPassword == null ||
      oldPassword == "" ||
      confirmPassword == null ||
      confirmPassword == "" ||
      newPassword == "" ||
      newPassword == null
    ) {
      setCustomError(true);
      setCustomMsg("Please fill all the fields");
    } else if (confirmPassword !== newPassword) {
      setPasswordMatchError(true);
    } else if (oldPassword === confirmPassword) {
      setSamePasswordError(true);
    } else if (!confirmPassword.match(passwordRegex)) {
      setCustomError(true);
      setCustomMsg("Password must be at least 8 characters long and include an uppercase letter, a lowercase letter, a number, and one special character (@, $, !, %, ?, &, #).");
    } else {
      if (user.roleId === 2) {
      AxiosInstance.post(changePasswordForEmployee, {
        username: encryptFn(user.username),
        currentPassword: encryptFn(oldPassword),
        password: encryptFn(confirmPassword),
      }).then(({data}) => {
          toast.success("Password changed successfully.");
          onClose();
          logout()
        })
        .catch((error) => {
          if(error.response?.data){
            toast.error(error.response.data);
          }else{
            toast.error("Failed to register, please try again");
          }
          console.log(error.message);
        });
    }
    else if(user.roleId === 1 || user.roleId === 3 || user.roleId === 7) {
      AxiosInstance.post(changePasswordForAdmin , {
        username: encryptFn(user.username),
        currentPassword: encryptFn(oldPassword),
        password: encryptFn(confirmPassword),
      }).then(({data}) => {
          toast.success("Password changed successfully.");
          onClose();
          logout()
        })
        .catch((error) => {
          if(error.response?.data){
            toast.error(error.response.data);
          }else{
            toast.error("Failed to register, please try again");
          }
          console.log(error.message);
        });
    }
   }
  };

  const handleClose = () => {
    setSamePasswordError(false);
    setPasswordMatchError(false);
    setCustomError(false);
  };

  return (
    <Grid container spacing={2}>
      {customError && (
        <Snackbar open={customError} autoHideDuration={2000} onClose={handleClose}>
          <Alert
            onClose={handleClose}
            severity="error"
            sx={{ width: "100%" }}
            variant="filled"
            style={{ backgroundColor: "red" }}
          >
            {customMsg}
          </Alert>
        </Snackbar>
      )}
      {samePasswordError && (
        <Snackbar open={samePasswordError} autoHideDuration={2000} onClose={handleClose}>
          <Alert
            onClose={handleClose}
            severity="error"
            sx={{ width: "100%" }}
            variant="filled"
            style={{ backgroundColor: "red" }}
          >
            New Password shouldn't be same as Old Password
          </Alert>
        </Snackbar>
      )}
     
      {passwordMatchError && (
        <Snackbar open={passwordMatchError} autoHideDuration={2000} onClose={handleClose}>
          <Alert
            onClose={handleClose}
            severity="error"
            sx={{ width: "100%" }}
            variant="filled"
            style={{ backgroundColor: "red" }}
          >
            New Password and Confirm Password must match
          </Alert>
        </Snackbar>
      )}
      <Grid item xs={12}>
        <TextField
          labelText="Old Password"
          placeholder="Old Password"
          type={showPassword1 ? "text" : "password"}
          required
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={handleClickShowPassword1} onMouseDown={handleMouseDownPassword1}>
                  {showPassword1 ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          onChange={(e) => setOldPassword(e.target.value)}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          labelText="New Password"
          type={showPassword ? "text" : "password"}
          placeholder="New Password"
          required
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword}>
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          onChange={(e) => setNewPassword(e.target.value)}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          labelText="Confirm Password"
          placeholder="Confirm Password"
          type={showPassword2 ? "text" : "password"}
          required
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={handleClickShowPassword2} onMouseDown={handleMouseDownPassword2}>
                  {showPassword2 ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          onChange={(e) => setConfirmPassword(e.target.value)}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center" marginTop="30px">
        <Button variant="contained" autoFocus color="primary" onClick={handleClickUpdatePassword}>
          Update Password
        </Button>
      </Grid>
    </Grid>
  );
}

export default ChangePwd;
