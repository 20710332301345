import { useCallback, useEffect, useRef, useState } from "react";
import {
  CircularProgress,
  Grid,
  LinearProgress,
  makeStyles,
  Paper,
  Typography,
  FormControl,
  FormHelperText,
  Select,
  MenuItem,
  OutlinedInput,
  TextField as MuiTextField,
  Divider,
  Box,
} from "@material-ui/core";
import configData from "../config.json";
//
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { toast } from "react-toastify";
// import { DoubleArrow } from "@material-ui/icons";
import axiosInstance from "pages/Services/AxiosInstance";
import {
  Camera,
  DoubleArrow,
  InsertPhoto,
  PhotoCamera,
} from "@material-ui/icons";
import { useStylesRenewalForm } from "pages/EmployeeRenewalForm/RenewalForm";
import { useSelector } from "react-redux";
import moment from "moment";
import { restrict } from "restrict";
import { Required } from "./LoginPage";

const useStyles = makeStyles((theme) => ({
  formTittle: {
    backgroundColor: "aliceblue",
    paddingInline: 16,
    paddingBlock: 8,
  },

  inputRoot: {
    padding: theme.spacing(1), // Reduce padding
    fontSize: "0.875rem", // Small font size
    "& input": {
      padding: theme.spacing(0.5, 1), // Additional padding adjustments if needed
    },
    "&.Mui-disabled": {
      backgroundColor: theme.palette.grey[100],
      color: "#464545",
    },
  },
  helperText: {
    fontSize: "0.75rem", // Smaller font size for helper text
    minHeight: "1rem",
  },

  selectRoot: {
    fontSize: "0.875rem", // Smaller font size for the select text
    "&.Mui-disabled": {
      backgroundColor: theme.palette.grey[100],
      color: "#464545",
    },
  },
  button: {
    height: 40,
    marginTop: 16,
    backgroundColor: "#ff9e3d",
    color: "#fff",
    border: "none",
    fontSize: 16,
    paddingInline: 32,
    paddingBlock: 8,
    borderRadius: 4,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    fontWeight: "bold",
    "&:active": {
      transform: "scale(0.99)",
    },
    "& .arrow": {
      color: "white",
      transition: "transform 0.5s ease",
    },
    "&:hover": {
      backgroundColor: "#ff8d1b",
      // color: "#000",
      "& .arrow": {
        // This targets the arrow specifically when the button is hovered
        transform: "translateX(4px)",
        transition: "transform 0.3s ease",
        // color: "black",
      },
    },
    "&:disabled": {
      backgroundColor: "#e0e0e0", // Lighter grey for disabled state
      color: "#a0a0a0", // Dimmed text color
      cursor: "not-allowed", // Shows a 'not-allowed' cursor
      "& .arrow": {
        color: "#a0a0a0", // Arrow color dimmed for disabled state
        transform: "none", // Prevent hover transform in disabled state
      },
    },
  },
  commonDetail: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    marginBottom: "18px",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      gap: "5px",
    },
  },
  labelValue: {
    display: "flex",
    gap: "4px",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      gap: "0px",
      justifyContent: "space-between",
    },
  },
}));

const GENDERS = [
  { label: "Male", value: "M" },
  { label: "Female", value: "F" },
];
export const alpReg = /^[a-zA-Z ]+$/;
export const mobileReg = /^[1-9]\d{9,11}$/;
export const emailRegex =  /^(?:)[^@\s]+@[a-zA-Z0-9]+(?:\.[a-zA-Z0-9]+)*$/;

const schema = yup.object().shape({
  // empMobileNo: yup
  //   .string()
  //   .nullable()
  //   .required("Required")
  //   .matches(mobileReg, "Invalid Mobile No."),
  // email: yup
  //   .string()
  //   .email("Email must be valid")
  //   .matches(emailRegex, "Invalid Email")
  //   .nullable()
  //   .required("Required"),
  emergencyEmail: yup
    .string()
    .email("Email must be valid")
    .matches(emailRegex, "Invalid Email")
    .nullable()
    .required("Required"),
  // spouseEmail: yup
  //   .string()
  //   .email("Email must be valid")
  //   .matches(emailRegex, "Invalid Email")
  //   .nullable()
  //   .required("Required"),
  emergencyContactName: yup
    .string()
    .trim()
    .required("Required")
    .nullable()
    .matches(alpReg, "Only alphabets and spaces are allowed"),
  emergencyRelationType: yup
    .string()
    .trim()
    .required("Required")
    .nullable()
    .matches(alpReg, "Only alphabets and spaces are allowed"),
  emergencyContactMobileNo: yup
    .string()
    .required("Required")
    .nullable()
    .matches(mobileReg, "Invalid Mobile No."),
  // spouseMobileNo: yup
  //   .string()
  //   .required("Required")
  //   .nullable()
  //   .matches(mobileReg, "Invalid Mobile No."),
  // address: yup
  //   .string()
  //   .trim()
  //   .required("Required")
  //   .nullable()
  //   .matches(
  //     /^[a-zA-Z0-9\s,.'&%#$@?/+=_()\[\]{}-]{3,255}$/,
  //     "Address must be between 3 to 100 characters long and can include letters, numbers, spaces, commas, periods, apostrophes, and special characters like &%#$@?/+=-_)([]{}."
  //   ),
  // alternate detail schema
  altEmergencyContactMobileNo: yup
    .string()
    .nullable()
    .when((value) => {
      if (value) {
        return yup.string().matches(mobileReg, "Invalid Mobile No.");
      }
    }),
  altEmergencyContactName: yup
    .string()
    .nullable()
    .when((value) => {
      if (value) {
        return yup
          .string()
          .matches(alpReg, "Only alphabets and spaces are allowed");
      }
    }),
  altEmergencyRelationType: yup
    .string()
    .nullable()
    .when((value) => {
      if (value) {
        return yup
          .string()
          .matches(alpReg, "Only alphabets and spaces are allowed");
      }
    }),
  altEmergencyEmail: yup
    .string()
    .nullable()
    .when((value) => {
      if (value) {
        return yup.string().matches(emailRegex, "Invalid Email");
      }
    }),
  emergencyTelecodeId: yup
    .string()
    .nullable()
    .notRequired(),
  altEmergencyTelecodeId: yup
    .string()
    .nullable()
    .notRequired(),
});

// private String address;
// private String secondAddressLine;
// private String city;
// private String district;
// private String postalCode;
// private String state;
// private String country;
// private String careOf;

const defaultValues = {
  employeeName: "",
  gender: "",
  empdob: "",
  spouseName: "",
  spouseDob: "",
  address: "",
  secondAddressLine: "",
  city: "",
  district: "",
  country: "",
  state: "",
  postalCode: "",
  empMobileNo: "",
  spouseMobileNo: "",
  email: "",
  spouseEmail: "",
  emergencyContactName: "",
  emergencyContactMobileNo: null,
  emergencyRelationType: "",
  emergencyEmail: "",
  altEmergencyContactMobileNo: "",
  altEmergencyContactName: "",
  altEmergencyRelationType: "",
  altEmergencyEmail: "",
  emergencyTelecodeId: "1",
  altEmergencyTelecodeId: "1",
};

export default function LoginPage() {
  const classes = useStyles();
  const loggedInUser = useSelector((store) => store.loggedInUser);

  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [capturedImageEmp, setCapturedImageEmp] = useState(null);
  const [capturedImageSpouse, setCapturedImageSpouse] = useState(null);
  const [countries, setCountries] = useState([]);

  const { handleSubmit, reset, control, getValues, setValue } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
  });

  async function onSubmit(formData) {
    console.log(formData, "=== onSubmit ===");
    setSaving(true);
    try {
      const url = configData.SERVICE_URL + "employee/editEmployee";
      const req = {
        emergencyContactMobileNo: +formData.emergencyContactMobileNo,
        emergencyContactName: formData.emergencyContactName,
        emergencyEmail: formData.emergencyEmail,
        emergencyRelationType: formData.emergencyRelationType,
        renewalId: formData.renewalId,
        employeeId: formData.employeeId,
        employeeDetailsId: formData.employeeDetailsId,
        altEmergencyContactMobileNo: formData.altEmergencyContactMobileNo,
        altEmergencyContactName: formData.altEmergencyContactName,
        altEmergencyRelationType: formData.altEmergencyRelationType,
        altEmergencyEmail: formData.altEmergencyEmail,
        emergencyTelecodeId: formData.emergencyTelecodeId,
        altEmergencyTelecodeId: formData.altEmergencyTelecodeId,
      };
      await axiosInstance.post(url, req);
      toast.success("Successfully Updated");
    } catch (error) {
      console.log(error);
      toast.error("Failed to submit, Please try again");
    } finally {
      setSaving(false);
    }
  }
  useEffect(() => {
    axiosInstance.get("country/getAll").then(({ data }) => {
      setCountries(data);
    });
  }, []);
  useEffect(() => {
    const url = configData.SERVICE_URL + "employee/getSavedEmpDetails";
    // const req = { id: loggedInUser?.empId };
    setLoading(true);
    axiosInstance
      .get(url)
      .then(({ data }) => {
        console.log(moment(data.empdob).format(), "empdob");
        setCapturedImageEmp(data.empPhotoStr);
        setCapturedImageSpouse(data.spousePhotoStr);
        reset({
          ...data,
          empMobileNo: data.empMobileNo
            ? (data.empTelecode || "") + (data.empMobileNo || "")
            : "",
          spouseMobileNo: data.spouseMobileNo
            ? (data.spouseTelecode || "") + (data.spouseMobileNo || "")
            : "",
          employeeId: data.employeeId,
          empdob: data.empdob ? moment(data.empdob).format("YYYY-MM-DD") : "",
          spouseName: data?.spouseName ?? "",
          spouseDob: data.spouseDob
            ? moment(data.spouseDob).format("YYYY-MM-DD")
            : "",
          address: data.address || "",
          secondAddressLine: data.secondAddressLine || "",
          state: data.state || "",
          country: data.country || "",
          city: data.city || "",
          district: data.district || "",
          postalCode: data.postalCode || "",
          emergencyTelecodeId: data?.altEmergencyTelecodeId
            ? data.emergencyTelecodeId
            : "1",
          altEmergencyTelecodeId: data?.altEmergencyTelecodeId
            ? data.altEmergencyTelecodeId
            : "1",
        });
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <div
      style={{
        border: "1px solid aliceblue",
        paddingInline: 8,
        paddingBottom: 32,
      }}
    >
      {loading && <LinearProgress />}
      <Typography
        variant="h5"
        align="center"
        color="secondary"
        style={{ paddingBlock: 16, textDecoration: "underline" }}
      >
        Employee Profile Details
      </Typography>
      <div className={classes.commonDetail}>
        <div className={classes.labelValue}>
          <Typography>Staff Number:</Typography>
          <Typography component={"span"} style={{ fontWeight: "500" }}>
            {getValues("employeeId")}
          </Typography>
        </div>
        <div className={classes.labelValue}>
          <Typography>BERECHS Membership No.: </Typography>
          <Typography component={"span"} style={{ fontWeight: "500" }}>
            {getValues("berechsNo")}
          </Typography>
        </div>
        <div className={classes.labelValue}>
          <Typography>Unit Name: </Typography>
          <Typography component={"span"} style={{ fontWeight: "500" }}>
            {getValues("unitCode")}
          </Typography>
        </div>
        <div className={classes.labelValue}>
          <Typography>Category: </Typography>
          <Typography component={"span"} style={{ fontWeight: "500" }}>
            {getValues("category")}
          </Typography>
        </div>
      </div>

      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={6}>
            <Paper elevation={1} style={{ paddingBottom: 16 }}>
              <Typography variant="h6" className={classes.formTittle}>
                Employee details
              </Typography>
              <Grid container spacing={1} style={{ padding: 16 }}>
                <Grid item xs={12} sm={12} md={6}>
                  <TextField
                    control={control}
                    name="employeeName"
                    placeholder="Enter Employee Name"
                    label="Name"
                    disabled
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={6}>
                  <SelectField
                    control={control}
                    name="gender"
                    label="Gender"
                    options={GENDERS}
                    disabled
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <TextField
                    control={control}
                    name="empdob"
                    placeholder="dd-mm-yyyy"
                    label="DOB"
                    type="date"
                    disabled
                  />
                </Grid>
                {/* <Grid item xs={12} sm={12} md={6}>
                  <SelectField control={control} name="maritalStatus" label="Marital Status" options={MARTIALS} />
                </Grid> */}
                <Grid item xs={12} sm={12} md={6}>
                  <TextField
                    control={control}
                    name="empMobileNo"
                    placeholder="Enter 10 digit Mobile No."
                    label="Mobile No."
                    onInput={restrict.digits}
                    inputProps={{ maxLength: 12 }}
                    disabled
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <TextField
                    control={control}
                    name="email"
                    placeholder="Enter Email"
                    label="Email"
                    inputProps={{ maxLength: 100 }}
                    disabled
                  />
                </Grid>
                <Grid item xs={12}>
                  <div
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      gap: "10px",
                      justifyContent: "center",
                    }}
                  >
                    <div
                      style={{
                        textAlign: "center",
                        border: "1px solid lightgrey",
                        marginTop: "8px",
                        minWidth: "250px",
                      }}
                    >
                      <Typography
                        variant="caption"
                        align="center"
                        color="secondary"
                      >
                        Passport Size Photo
                      </Typography>
                      <WebcamCapture
                        capturedImage={getValues("retirePassportPhotoStr")}
                        setCapturedImage={setCapturedImageEmp}
                        onlyPreview
                      />
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Paper>
          </Grid>

          <Grid item xs={12} sm={12} md={6}>
            <Paper elevation={1} style={{ paddingBottom: 10 }}>
              <Typography variant="h6" className={classes.formTittle}>
                Spouse details
              </Typography>

              <Grid container spacing={1} style={{ padding: 16 }}>
                <Grid item xs={12} sm={12} md={6}>
                  <TextField
                    control={control}
                    name="spouseName"
                    placeholder="Enter Spouse Name"
                    label="Name"
                    disabled
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <TextField
                    control={control}
                    name="berechSpouseId"
                    placeholder="Spouse BERECHS Membership No."
                    label="Spouse BERECHS Membership No."
                    inputProps={{ maxLength: 100 }}
                    disabled
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <TextField
                    control={control}
                    name="spouseDob"
                    placeholder="dd-mm-yyyy"
                    label="DOB"
                    type="date"
                    disabled
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <TextField
                    control={control}
                    name="spouseMobileNo"
                    placeholder="Enter 10 digit Mobile No."
                    label="Mobile No."
                    disabled
                    onInput={restrict.digits}
                    inputProps={{ maxLength: 12 }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <TextField
                    control={control}
                    name="spouseEmail"
                    placeholder="Enter Email"
                    label="Email"
                    disabled
                    inputProps={{ maxLength: 100 }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <div
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      gap: "10px",
                      justifyContent: "center",
                    }}
                  >
                    <div
                      style={{
                        textAlign: "center",
                        border: "1px solid lightgrey",
                        marginTop: "8px",
                        minWidth: "250px",
                      }}
                    >
                      <Typography
                        variant="caption"
                        align="center"
                        color="secondary"
                      >
                        Passport size Photo
                      </Typography>
                      <WebcamCapture
                        capturedImage={getValues("spousePassportPhotoStr")}
                        setCapturedImage={setCapturedImageEmp}
                        onlyPreview
                      />
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Paper>
          </Grid>

          <Grid item xs={12}>
            <Paper elevation={1}>
              <Typography variant="h6" className={classes.formTittle}>
                Common Details (Address)
              </Typography>

              <Grid container spacing={1} style={{ padding: 16 }}>
                <Grid item xs={12}>
                  <TextDivider text="Address as per record" />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    disabled
                    control={control}
                    name="address"
                    placeholder=""
                    label="Address Line 1"
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    disabled
                    control={control}
                    name="secondAddressLine"
                    placeholder=""
                    label="Address Line 2"
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <TextField
                    control={control}
                    name="city"
                    placeholder=""
                    label="City"
                    disabled
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <TextField
                    control={control}
                    name="district"
                    placeholder=""
                    label="District"
                    disabled
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <TextField
                    control={control}
                    name="state"
                    placeholder=""
                    label="State"
                    disabled
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <TextField
                    control={control}
                    name="country"
                    placeholder=""
                    label="Country"
                    disabled
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <TextField
                    control={control}
                    name="postalCode"
                    placeholder=""
                    label="PinCode"
                    disabled
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6}></Grid>
                <Grid item xs={12}>
                  <TextDivider text="Current address" />
                </Grid>

                <Grid item xs={6}>
                  <TextField
                    disabled
                    control={control}
                    name="addressDto.addressLine1"
                    placeholder=""
                    label="Address Line 1"
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    disabled
                    control={control}
                    name="addressDto.addressLine2"
                    placeholder=""
                    label="Address Line 2"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={6}>
                  <TextField
                    control={control}
                    name="addressDto.districtName"
                    placeholder=""
                    label="District"
                    disabled
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <TextField
                    control={control}
                    name="addressDto.stateName"
                    placeholder=""
                    label="State"
                    disabled
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <TextField
                    control={control}
                    name="addressDto.countryName"
                    placeholder=""
                    label="Country"
                    disabled
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <TextField
                    control={control}
                    name="addressDto.pincode"
                    placeholder=""
                    label="PinCode"
                    disabled
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextDivider text="Emergency Details (*Emergency contact person shall not be retiree/spouse)" />
                </Grid>
                <Grid item xs={12}>
                  <fieldset>
                    <legend>Primary Emergency Details</legend>
                    <Grid container spacing={1}>
                      <Grid item xs={12} sm={12} md={6}>
                        <TextField
                          required
                          control={control}
                          name="emergencyRelationType"
                          placeholder="Enter Relation Type"
                          label="Relation Type"
                          inputProps={{ maxLength: 100 }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <TextField
                          required
                          control={control}
                          name="emergencyContactName"
                          placeholder="Enter Name"
                          label="Contact Name"
                          inputProps={{ maxLength: 100 }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <Typography variant="body2">
                          Contact Number <span style={{ color: "red" }}>*</span>
                        </Typography>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "4px",
                          }}
                        >
                          <Controller
                            name={"emergencyTelecodeId"}
                            control={control}
                            render={({ field, fieldState: { error } }) => (
                              <MuiTextField
                                {...field}
                                InputProps={{
                                  classes: {
                                    input: classes.input,
                                  },
                                }}
                                variant="outlined"
                                size="small"
                                control={control}
                                style={{ width: "120px" }}
                                select
                                SelectProps={{ native: true }}
                              >
                                {countries?.length > 0 &&
                                  countries?.map(({ id, teleCode }) => (
                                    <option key={id} value={id}>
                                      {teleCode}
                                    </option>
                                  ))}
                              </MuiTextField>
                            )}
                          />
                          <TextField
                            control={control}
                            name="emergencyContactMobileNo"
                            placeholder="Enter 10 Digit Mobile No."
                            onInput={restrict.digits}
                            inputProps={{ maxLength: 12 }}
                          />
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <TextField
                          required
                          control={control}
                          name="emergencyEmail"
                          placeholder="Enter Email"
                          label="Email"
                          inputProps={{ maxLength: 100 }}
                        />
                      </Grid>
                    </Grid>
                  </fieldset>
                </Grid>
                <Grid item xs={12}>
                  <fieldset>
                    <legend>Alternate Emergency Details</legend>
                    <Grid container spacing={1}>
                      <Grid item xs={12} sm={12} md={6}>
                        <TextField
                          control={control}
                          name="altEmergencyRelationType"
                          placeholder="Enter Relation Type"
                          label="Relation Type"
                          inputProps={{ maxLength: 100 }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <TextField
                          control={control}
                          name="altEmergencyContactName"
                          placeholder="Enter Name"
                          label="Contact Name"
                          inputProps={{ maxLength: 100 }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <Typography variant="body2">Contact Number</Typography>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "4px",
                          }}
                        >
                          <Controller
                            name={"altEmergencyTelecodeId"}
                            control={control}
                            render={({ field, fieldState: { error } }) => (
                              <MuiTextField
                                InputProps={{
                                  classes: {
                                    input: classes.input,
                                  },
                                }}
                                {...field}
                                variant="outlined"
                                size="small"
                                control={control}
                                style={{ width: "120px" }}
                                select
                                SelectProps={{ native: true }}
                              >
                                {countries?.length > 0 &&
                                  countries?.map(({ id, teleCode }) => (
                                    <option key={id} value={id}>
                                      {teleCode}
                                    </option>
                                  ))}
                              </MuiTextField>
                            )}
                          />
                          <TextField
                            control={control}
                            name="altEmergencyContactMobileNo"
                            placeholder="Enter 10 Digit Mobile No."
                            onInput={restrict.digits}
                            inputProps={{ maxLength: 12 }}
                          />
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <TextField
                          control={control}
                          name="altEmergencyEmail"
                          placeholder="Enter Email"
                          label="Email"
                          inputProps={{ maxLength: 100 }}
                        />
                      </Grid>
                    </Grid>
                  </fieldset>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <button
            onClick={handleSubmit(onSubmit)}
            disabled={loading || saving}
            className={classes.button}
          >
            {!saving ? (
              <>
                <div>Submit</div>
                <div className="arrow" style={{ marginTop: 4 }}>
                  <DoubleArrow />
                </div>
              </>
            ) : (
              <CircularProgress size={24} />
            )}
          </button>
        </div>
      </form>

      <div>
        <Typography variant="subtitle1" style={{ fontWeight: 600 }}>
          Note:
        </Typography>
        <Typography variant="body1" color="error">
          For any communication, Spouse details will be considered only if
          retired employee is not alive.
        </Typography>

        <Typography variant="body1" color="error">
          For any other correction in employee profile please contact your HR.
        </Typography>
      </div>
    </div>
  );
}

function TextField({
  name,
  placeholder,
  label,
  type = "text",
  control,
  required,
  ...others
}) {
  const classes = useStyles();
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <FormControl fullWidth error={Boolean(error)}>
          <Typography variant="body2" color="textSecondary">
            {label} {required && <Required />}
          </Typography>
          <OutlinedInput
            classes={{ root: classes.inputRoot }}
            {...field}
            id={name}
            placeholder={placeholder}
            type={type}
            {...others}
          />
          {error && (
            <FormHelperText error>{error ? error?.message : ""}</FormHelperText>
          )}
        </FormControl>
      )}
    />
  );
}

function SelectField({ name, label, options = [], control, disabled }) {
  const classes = useStyles();
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <FormControl size="small" fullWidth error={Boolean(error)}>
          <Typography variant="body2" color="textSecondary">
            {label}
          </Typography>
          <Select
            {...field}
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "left",
              },
            }}
            classes={{ root: classes.selectRoot }}
            fullWidth
            variant="outlined"
            disabled={disabled}
          >
            {options.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText>{error?.message}</FormHelperText>
        </FormControl>
      )}
    />
  );
}

function WebcamCapture({ capturedImage, setCapturedImage, onlyPreview }) {
  const classes = useStylesRenewalForm();
  // const classesLocal = useStyles();
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const fileInputRef = useRef(null);

  const [isWebcamActive, setIsWebcamActive] = useState(false);
  const [validationMessage, setValidationMessage] = useState("");
  const streamRef = useRef(null);

  const startWebcam = async () => {
    setCapturedImage(null);
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      streamRef.current = stream;
      if (videoRef.current) {
        videoRef.current.srcObject = stream;
        setIsWebcamActive(true);
      }
    } catch (err) {
      toast.error("No Web Camera found");
      console.error("Error accessing the webcam:", err);
    }
  };

  const stopWebcam = () => {
    if (streamRef.current) {
      streamRef.current.getTracks().forEach((track) => track.stop());
      setIsWebcamActive(false);
    }
  };

  const capture = useCallback(() => {
    const video = videoRef.current;
    const canvas = canvasRef.current;

    if (video && canvas) {
      const context = canvas.getContext("2d");
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      context.drawImage(video, 0, 0, canvas.width, canvas.height);
      const imageSrc = canvas.toDataURL("image/jpeg");
      setCapturedImage(imageSrc);

      // Stop webcam after capturing the image
      stopWebcam();
    }
  }, []);

  // File validation for image upload
  const handleFileUpload = (event) => {
    event.stopPropagation();
    const file = event.target.files[0];

    // Reset previous validation message
    setValidationMessage("");

    if (file) {
      // Validate file size (Example: limit to 5MB)
      const maxSize = 5 * 1024 * 1024; // 5MB
      if (file.size > maxSize) {
        setValidationMessage(
          "File is too large. Please upload an image smaller than 5MB."
        );
        return;
      }

      // Validate file type (allow only image types)
      if (!file.type.startsWith("image/")) {
        setValidationMessage("Invalid file type. Please upload an image.");
        return;
      }

      // If validation passes, read the file
      const reader = new FileReader();
      reader.onloadend = () => {
        setCapturedImage(reader.result);
        stopWebcam();
        setIsWebcamActive(false);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <div className={classes.root}>
      <Paper elevation={3} className={classes.paper}>
        {capturedImage && !isWebcamActive ? (
          <img src={capturedImage} alt="Captured" height={"100%"} />
        ) : (
          <Typography variant="caption" color="textSecondary">
            Photo not found
          </Typography>
        )}

        <video
          ref={videoRef}
          autoPlay
          height={"100%"}
          hidden={!isWebcamActive}
        />
      </Paper>
      <canvas ref={canvasRef} style={{ display: "none" }} />
      {!onlyPreview && (
        <div className={classes.buttonGroup}>
          <button
            type="button"
            // className={`${classesLocal.button} ${classesLocal.start}`}
            onClick={startWebcam}
            disabled={isWebcamActive}
          >
            <PhotoCamera fontSize="small" style={{ marginBottom: -4 }} /> Start
          </button>
          <button
            type="button"
            //  className={`${classesLocal.button} ${classesLocal.capture}`}
            onClick={capture}
            disabled={!isWebcamActive}
          >
            <Camera fontSize="small" style={{ marginBottom: -4 }} /> Capture
          </button>
          <button
            type="button"
            //  className={`${classesLocal.button} ${classesLocal.browse}`}
            onClick={() => fileInputRef.current.click()}
          >
            <InsertPhoto fontSize="small" style={{ marginBottom: -4 }} /> Browse
          </button>
          <input
            type="file"
            ref={fileInputRef}
            onChange={handleFileUpload}
            accept="image/*"
            style={{ display: "none" }}
          />
          {validationMessage && (
            <p style={{ color: "red" }}>{validationMessage}</p>
          )}
        </div>
      )}
    </div>
  );
}

export function TextDivider({ text }) {
  return (
    <Box display="flex" alignItems="center">
      <Divider style={{ flex: 1, backgroundColor: "skyblue" }} />
      <Typography
        variant="body1"
        color="secondary"
        style={{ padding: "0 16px", whiteSpace: "nowrap" }}
      >
        {text}
      </Typography>
      <Divider style={{ flex: 1, backgroundColor: "skyblue" }} />
    </Box>
  );
}
