import {
  Grid,
  Paper,
  Typography,
  TextField,
  makeStyles,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  Avatar,
  Fab,
  ListItemText,
  Box,
  Tooltip,
  IconButton,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
} from "@material-ui/core";
import {
  AttachFile,
  Close,
  FileCopy,
  Gif,
  PhotoLibrary,
  PictureAsPdf,
  Send,
} from "@material-ui/icons";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
// import SockJS from "sockjs-client";
// import { over } from "stompjs";
import { SERVICE_URL } from "../../config.json";
import Socket from "react-stomp";
import Button from "components/Button";
import moment from "moment";
import { toast } from "react-toastify";
import axiosInstance from "pages/Services/AxiosInstance";
import {
  appontmentDownloadFileUrl,
  appontmentUploadFileUrl,
} from "pages/Services/URLs";
const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.primary.dark,
  },
  chatSection: {
    width: "100%",
    height: "50vh",
  },
  headBG: {
    backgroundColor: theme.palette.background.default,
  },
  borderRight500: {
    borderRight: "1px solid #e0e0e0",
  },
  messageArea: {
    height: "40vh",
    overflowY: "auto",
  },
  textcolor: {
    color: theme.palette.background.light,
  },
  date: {
    color: theme.palette.background.light,
    fontSize: 12,
  },
  receiveMessages: {
    whiteSpace: "pre-wrap",
    position: "relative",
    marginRight: "200px",
    marginBottom: "10px",
    padding: "10px",
    backgroundColor: theme.palette.primary.light,
    // width: "60%",
    //height: "50px",
    textAlign: "left",
    font: "400 .9em 'Open Sans', sans-serif",
    border: `1px solid ${theme.palette.primary.light}`,
    borderRadius: "10px",
    "&:after": {
      content: "''",
      position: "absolute",
      width: "0",
      height: "0",
      borderTop: `15px solid ${theme.palette.primary.light}`,
      borderLeft: "15px solid transparent",
      borderRight: "15px solid transparent",
      top: "0",
      left: "-15px",
    },
    "&:before": {
      content: "''",
      position: "absolute",
      width: "0",
      height: "0",
      borderTop: `17px solid ${theme.palette.primary.light}`,
      borderLeft: "16px solid transparent",
      borderRight: "16px solid transparent",
      top: "-1px",
      left: "-17px",
    },
  },
  sendMessages: {
    whiteSpace: "pre-wrap",
    position: "relative",
    // marginLeft: "200px",
    marginBottom: "10px",
    padding: "10px",
    backgroundColor: theme.palette.primary.main,
    // width: "60%",
    //height: "50px",
    textAlign: "left",
    // font: "400 .9em 'Open Sans', sans-serif",
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: "10px",
    "&:after": {
      content: "''",
      position: "absolute",
      width: "0",
      height: "0",
      borderTop: `15px solid ${theme.palette.primary.main}`,
      borderLeft: "15px solid transparent",
      borderRight: "15px solid transparent",
      top: "0",
      right: "-15px",
    },
    "&:before": {
      content: "''",
      position: "absolute",
      width: "0",
      height: "0",
      borderTop: `17px solid ${theme.palette.primary.main}`,
      borderLeft: "16px solid transparent",
      borderRight: "16px solid transparent",
      top: "-1px",
      right: "-17px",
    },
  },
  hide: {
    display: "none",
  },
  upload: {
    justifyContent: "center",
    display: "grid",
    alignItems: "center",
    height: "10vh",
    // backgroundColor: theme.palette.primary.light,
  },
  messagesRight: {
    justifyContent: "flex-end",
  },
}));

export default function ChatBox() {
  const logineduser =
    useSelector((store) => store.loggedInUser) ||
    JSON.parse(localStorage.getItem("User"));
  const appointmentDetails = useSelector(
    (store) => store.selectedAppDetails.appointmentDetails
  );
  const [inputmessage, setInputMessage] = useState("");
  const [chats, setChats] = useState([]);
  const [senderid, setSenderId] = useState(
    logineduser?.roleId == 4
      ? appointmentDetails?.id + "" + appointmentDetails?.patientId
      : appointmentDetails?.id + "" + appointmentDetails?.doctorId
  );
  const [downloadFiles, setDownladFiles] = useState(null);
  const clientref = useRef();
  const classes = useStyles();
  const messageArearef = useRef(null);
  const sendMessage = () => {
    var messagetrackdataobj = {};
    if (logineduser?.roleId == 4) {
      messagetrackdataobj = {
        senderId: appointmentDetails?.id + "" + appointmentDetails?.patientId,
        receiverId: appointmentDetails?.id + "" + appointmentDetails?.doctorId,
        message: inputmessage,
        date: moment().format(),
        status: "MESSAGE",
      };
    } else {
      messagetrackdataobj = {
        senderId: appointmentDetails?.id + "" + appointmentDetails?.doctorId,
        receiverId: appointmentDetails?.id + "" + appointmentDetails?.patientId,
        message: inputmessage,
        date: moment().format(),
        status: "MESSAGE",
      };
    }
    clientref.current.sendMessage(
      "/app/private-message",
      JSON.stringify(messagetrackdataobj)
    );
    setChats([...chats, messagetrackdataobj]);
    setInputMessage("");
  };

  useEffect(() => {
    messageArearef.current.scroll({
      top: messageArearef.current.scrollHeight,
      behavior: "smooth",
    });
  }, [chats]);
  useEffect(() => {
    fetchFiles();
  }, []);

  //////// for global alert
  const onConnetedAlert = () => {
    var connectMessageobj = {};
    if (logineduser.roleId == 4) {
      connectMessageobj = {
        senderName: logineduser?.eName,
        senderId: appointmentDetails?.doctorId,
        receiverId: appointmentDetails?.patientId,
        message: logineduser?.eName + " Joined",
        date: moment().format(),
        status: "JOIN",
      };
    } else {
      // console.log(appointmentDetails);
      connectMessageobj = {
        senderName: logineduser?.eName,
        senderId: appointmentDetails?.patientId,
        receiverId: appointmentDetails?.doctorId,
        message: logineduser?.eName + " Joined",
        date: moment().format(),
        status: "JOIN",
      };
    }
    clientref.current.sendMessage(
      "/app/private-message",
      JSON.stringify(connectMessageobj)
    );
  };
  const [viewDialog, setViewDialog] = useState(false);
  //file upload
  const [fileUploadDialog, setFileUploadDialog] = useState(false);
  let fileInputRef = useRef(null);
  const [selectedFile, setSelectedFile] = useState([]);
  const handleFileInput = (e) => {
    fileInputRef.click();
    const file = Array.prototype.slice.call(e.target.files);
    const existing = [...selectedFile, ...file];
    setSelectedFile(existing);
  };
  const deleteFile = (id) => {
    const filteredList = selectedFile?.filter((item, index) => index !== id);
    setSelectedFile(filteredList);
  };

  //////fetch files
  const fetchFiles = () => {
    axiosInstance
      .get(appontmentDownloadFileUrl + "/" + appointmentDetails?.id)
      .then(({ data }) => {
        setDownladFiles(data);
      })
      .catch((error) => {
        // toast.error("Something went wrong");
      });
  };

  const FileIcon = ({ extName }) => {
    var extName = extName.toLowerCase();
    if (extName == "jpg" || extName == "png" || extName == "jpeg") {
      return <PhotoLibrary />;
    } else if (extName == "pdf") {
      return <PictureAsPdf />;
    } else {
      return <FileCopy />;
    }
  };

  const toDataURL = async (url) => {
    const response = await axiosInstance.get(url, { responseType: "blob" });
    const imageDataUrl = URL.createObjectURL(response.data);
    return imageDataUrl;
  };

  const handleDownload = async (urlImage) => {
    const a = document.createElement("a");
    a.href = await toDataURL(urlImage);
    var filenameBefore = urlImage.split("/").pop();
    a.download = filenameBefore;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const saveUploadFiles = () => {
    if (selectedFile.length > 0) {
      const formData = new FormData();
      selectedFile.forEach((item) => formData.append("uploadFiles", item));
      formData.append("id", appointmentDetails?.id);
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      axiosInstance
        .post(appontmentUploadFileUrl, formData, config)
        .then(({ data }) => {
          /////////////upload file notify
          setSelectedFile([]);
          var connectMessageobj = {};
          if (logineduser.roleId == 4) {
            // connectMessageobj = {
            //   senderName: logineduser?.eName,
            //   senderId: appointmentDetails?.doctorId,
            //   receiverId: appointmentDetails?.patientId,
            //   message: logineduser?.eName + " Joined",
            //   date: moment().format(),
            //   status: "JOIN",
            // };
          } else {
            // console.log(appointmentDetails);
            connectMessageobj = {
              senderId:
                appointmentDetails?.id + "" + appointmentDetails?.doctorId,
              receiverId:
                appointmentDetails?.id + "" + appointmentDetails?.patientId,
              message: inputmessage,
              date: moment().format(),
              status: "HTTPREQUEST",
              url: appontmentDownloadFileUrl,
            };
          }
          clientref.current.sendMessage(
            "/app/private-message",
            JSON.stringify(connectMessageobj)
          );
          setFileUploadDialog(false);
          fetchFiles();
          toast.success("File Upload successfully .");
          return data;
        })
        .catch((error) => {
          toast.error("Something went wrong");
        });
    } else {
      toast.warning("No Files Selected");
    }
  };

  return (
    <>
      <Socket
        url={`${SERVICE_URL}berech-websocket`}
        topics={[
          "/user/" +
            `${
              logineduser.roleId == 4
                ? appointmentDetails?.id + "" + appointmentDetails?.patientId
                : appointmentDetails?.id + "" + appointmentDetails?.doctorId
            }` +
            "/private",
        ]}
        onConnect={() => onConnetedAlert()}
        onDisconnect={() => console.log("Disconnected")}
        onMessage={(msg) => {
          switch (msg.status) {
            case "JOIN":
              toast.success(msg?.senderName + " joined");
              break;
            case "MESSAGE":
              setChats([...chats, msg]);
              messageArearef.current.scrollIntoView();
              break;
            case "HTTPREQUEST":
              fetchFiles();
              break;
            default:
              break;
          }
        }}
        ref={(ss) => {
          clientref.current = ss;
        }}
      />
      <Box>
        <Grid container component={Paper}>
          <Grid item xs={12} className={classes.headBG}>
            <List className={classes.messageArea} ref={messageArearef}>
              {chats.map((item, index) => (
                <>
                  {item.senderId == senderid ? (
                    <>
                      <ListItem key={index} className={classes.messagesRight}>
                        {/* <Grid container>
                      <Grid item xs={12} className={classes.sendMessages}> */}
                        <div className={classes.sendMessages}>
                          <Typography
                            align="right"
                            className={classes.textcolor}
                          >
                            {item.message}
                          </Typography>
                          <Typography align="right" className={classes.date}>
                            {moment(item.date).format("HH:mm")}
                          </Typography>
                        </div>
                      </ListItem>
                    </>
                  ) : (
                    <>
                      <ListItem key={index}>
                        {/* <Grid container>
                      <Grid item xs={12} className={classes.sendMessages}> */}
                        <div className={classes.receiveMessages}>
                          <Typography
                            align="left"
                            className={classes.textcolor}
                          >
                            {item.message}
                          </Typography>
                          <Typography
                            align="left"
                            variant="body2"
                            className={classes.textcolor}
                          >
                            {moment(item.date).format("HH:mm")}
                          </Typography>
                        </div>

                        {/* </Grid>
                    </Grid> */}
                      </ListItem>
                    </>
                  )}
                </>
              ))}
            </List>
          </Grid>
          <Divider />
          <Grid container xs={12} style={{ padding: "15px" }}>
            <Grid item xs={11}>
              <TextField
                placeholder="Type Something"
                rows={2}
                multiline
                fullWidth
                value={inputmessage}
                onChange={(e) => setInputMessage(e.target.value)}
              />
            </Grid>

            <Grid item xs={1} align="right" className={classes.icon}>
              <Tooltip title="Send" onClick={() => sendMessage()}>
                <Send />
              </Tooltip>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      {logineduser?.roleId == 5 && (
        <Box>
          <Grid container className={classes.upload}>
            <Grid item>
              <Dialog
                open={fileUploadDialog}
                onClose={() => setFileUploadDialog(false)}
                maxWidth={"sm"}
                fullWidth={"sm"}
              >
                <DialogTitle>Upload Files</DialogTitle>
                <Divider />
                <DialogContent>
                  {/* <DialogContentText>dfghfgh</DialogContentText> */}
                  <Grid container spacing={2} align="center">
                    <Grid item xs={12}>
                      <input
                        ref={(refParam) => (fileInputRef = refParam)}
                        type="file"
                        multiple
                        style={{ display: "none" }}
                        // accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                        onChange={handleFileInput}
                      />

                      <Button
                        variant="contained"
                        startIcon={<AttachFile />}
                        color="primary"
                        component="span"
                        onClick={() => {
                          fileInputRef.click();
                        }}
                      >
                        Choose Files
                      </Button>
                    </Grid>
                    <Grid item xs={12}>
                      {selectedFile.length > 0 ? (
                        selectedFile?.map((item, index) => (
                          <>
                            <MenuItem key={index}>
                              <ListItemText
                                disableTypography
                                primary={
                                  <Typography variant="body2">
                                    {item?.name}
                                  </Typography>
                                }
                              />
                              <IconButton
                                size="small"
                                onClick={() => {
                                  deleteFile(index);
                                }}
                              >
                                <Close fontSize="small" color="action" />
                              </IconButton>
                            </MenuItem>
                          </>
                        ))
                      ) : (
                        <Typography
                          component={"span"}
                          variant="caption"
                          color="textSecondary"
                        >
                          No file selected.
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={() => setFileUploadDialog(false)}
                    variant="contained"
                    color="secondary"
                  >
                    Cancel
                  </Button>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => saveUploadFiles()}
                  >
                    Submit
                  </Button>
                </DialogActions>
              </Dialog>
              <Button
                variant="contained"
                startIcon={<CloudUploadIcon />}
                color="primary"
                component="span"
                onClick={() => {
                  setFileUploadDialog(true);
                }}
              >
                Upload
              </Button>
            </Grid>
          </Grid>
        </Box>
      )}
      <Grid item align="center">
        {downloadFiles && (
          <>
            <Typography variant="h4">Uploads Files</Typography>
            {downloadFiles?.map((item, index) => (
              <>
                <MenuItem
                  key={index}
                  onClick={() => {
                    handleDownload(item);
                  }}
                >
                  <ListItemText
                    disableTypography
                    primary={
                      <Typography variant="body2">
                        {" "}
                        {item.split("/").pop()}
                      </Typography>
                    }
                  />
                  <IconButton size="small">
                    <FileIcon
                      extName={item
                        .split("/")
                        .pop()
                        .split(".")
                        .pop()}
                    />
                  </IconButton>
                </MenuItem>
              </>
            ))}
          </>
        )}
      </Grid>
    </>
  );
}
