import { Button, Card, CardActionArea, CardContent, CardMedia, Container, Divider } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
// import MedicineBill from "pages/BillTables/MedicineBills/MedicineBill";
// import Appointment from "pages/Citizen/Appointment";
import RenewalForm from "pages/EmployeeRenewalForm/RenewalForm";
import React from "react";
// import ClaimStatus from "../BillTables/ClaimStatus/ClaimStatusTable";
//
// import ClaimStatusIcon from "../../assets/Images/claimStatus.png";
// import AppointmentIcon from "../../assets/Images/images.png";
// import MedicalBillIcon from "../../assets/Images/MedicalBill.png";
import RenewalFormIcon from "../../assets/Images/RenewalForm.png";
import ProfileIcon from "../../assets/Images/profile.png";
import ProfilePage from "NewLayout/ProfilePage";
import SubmittedRenewalList from "pages/EmployeeRenewalForm/submitted-renewal-list";

export default function TabpanelView() {
  const [selectedCard, setSelectedCard] = React.useState("");
  return (
    <>
      <Container style={{ paddingTop: 16 }}>
        {selectedCard === "" && (
          <div style={{ display: "flex", justifyContent: "center", gap: 32, paddingTop: 180,flexWrap:'wrap' }}>
            {/* <NavigableCard title="Appointment" icon={AppointmentIcon} setSelectedCard={setSelectedCard} /> */}
            {/* <NavigableCard title="Medical Bill" icon={MedicalBillIcon} setSelectedCard={setSelectedCard} /> */}
            {/* <NavigableCard title="Claim Status" icon={ClaimStatusIcon} setSelectedCard={setSelectedCard} /> */}
            <NavigableCard title="Profile" icon={ProfileIcon} setSelectedCard={setSelectedCard} />
            <NavigableCard title="Renewal Form" icon={RenewalFormIcon} setSelectedCard={setSelectedCard} />
          </div>
        )}

        {/* {selectedCard === "Appointment" && (
          <>
            <Button onClick={() => setSelectedCard("")} variant="contained" color="secondary" style={{ marginBottom: 16 }}>
              Back to Home
            </Button>
            <Appointment />
          </>
        )}

        {selectedCard === "Medical Bill" && (
          <>
            <Button onClick={() => setSelectedCard("")} variant="contained" color="secondary" style={{ marginBottom: 16 }}>
              Back to Home
            </Button>
            <MedicineBill />
          </>
        )}

        {selectedCard === "Claim Status" && (
          <>
            <Button onClick={() => setSelectedCard("")} variant="contained" color="secondary" style={{ marginBottom: 16 }}>
              Back to Home
            </Button>
            <ClaimStatus />
          </>
        )} */}

        {selectedCard === "Renewal Form" && (
          <>
            <Button onClick={() => setSelectedCard("")} variant="contained" color="secondary" style={{ marginBottom: 16 }}>
              Back to Home
            </Button>
            <SubmittedRenewalList />
          </>
        )}

        {selectedCard === "Profile" && (
          <>
            <Button onClick={() => setSelectedCard("")} variant="contained" color="secondary" style={{ marginBottom: 16 }}>
              Back to Home
            </Button>
            <ProfilePage />
          </>
        )}
      </Container>
    </>
  );
}

const details = {
  "Claim Status": "Track your claim's progress. View status updates, required documents, and settlement details easily.",
  Appointment: "Schedule medical consultations. Manage upcoming appointments, get reminders, and reschedule with just a click.",
  "Medical Bill": "View and manage your medical expenses. Download bills, track payments, and submit for insurance claims",
  "Renewal Form": "Quickly renew your health insurance. Update personal information, select coverage, and submit forms online.",
  Profile: "Manage your personal details effortlessly. Update contact information, keep your profile up to date for a smooth experience.",
};

const NavigableCard = ({ title, icon, setSelectedCard }) => {
  // Function to handle navigation on card click
  const handleCardClick = () => {
    setSelectedCard(title);
  };

  return (
    <Card onClick={handleCardClick} style={{ maxWidth: 345 }}>
      <CardActionArea>
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", padding: 24, backgroundColor: "aliceblue", }}>
          <CardMedia
            component="img"
            alt={title}
            // height="140"
            style={{ width: "140px" }}
            image={icon}
            title={title}
          />
        </div>
        <Divider />
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2" color="primary" align="center">
            {title}
          </Typography>
          <Typography variant="caption" color="textSecondary" component="p">
            {details[title]}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};
