import React, { useEffect, useRef, useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Grid, TextField, Typography, } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import { toast } from "react-toastify";
import axiosInstance from "pages/Services/AxiosInstance";
//components
import Button from "components/Button";
import { PatientDetailBox } from "../../components/PatientDetailBox";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { OTBookingStatusUpdate, OTNotes } from "pages/Services/URLs";
import { addSelectedItem } from "Redux/Actions/OTActions/SeletedItem";
import { OTBilling } from "../OTBilling/OTBilling";
import { VideoRecorder } from "pages/OT/components/VideoRecorder";

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(0),
    },
    container: {
        background: theme.palette.background.light,
        borderRadius: 6,
        padding: 15,
        height: 580,
        [theme.breakpoints.down("md")]: {
            height: "auto",
        },
    },
    videoContainer: {
        background: theme.palette.background.light,
        borderRadius: 6,
        padding: 15,
    },
    label: {
        fontSize: "0.875rem",
        color: theme.palette.text.primary,
    },
}));


export const NotesActionItem = (props) => {
    const classes = useStyles();
    const theme = useTheme();
    const dispatch = useDispatch();
    const bookingId = useSelector((store) => store.OTPatients.bookingId);
    const [video, setVideo] = useState([]);
    const [recorder, setRecorder] = useState(false);
    const [display, setDisplay] = useState(false);
    const currentDate = new Date();
    const formattedDateTime =
        `${currentDate.toISOString().split('T')[0]} ${currentDate.toTimeString().split(' ')[0].split(':').join('-')}`;
    const mainRef = useRef(null);

    const handleScroll = (ref) => {
        setTimeout(() => {
            window.scrollTo(0, document.body.scrollHeight);
        }, 20);
    }

    const defaultValues = {
        bookingId: bookingId,
        preOtNotes: '',
        postOtNotes: '',
        preAnesthesiaNotes: '',
        postAnesthesiaNotes: '',
        preMedicalNotes: '',
        postMedicalNotes: '',
        finalNotes: '',
    }

    const Schema = yup.object().shape({
        preOtNotes: yup.string().matches(/^(?!\s)/, 'Invalid Input').required("Enter Pre OT Notes"),
        postOtNotes: yup.string().matches(/^(?!\s)/, 'Invalid Input').required("Enter Post OT Notes"),
        preAnesthesiaNotes: yup.string().matches(/^(?!\s)/, 'Invalid Input').required("Enter Pre Anesthesia Notes"),
        postAnesthesiaNotes: yup.string().matches(/^(?!\s)/, 'Invalid Input').required("Enter Post Anesthesia Notes"),
        preMedicalNotes: yup.string().matches(/^(?!\s)/, 'Invalid Input').required("Enter Pre Diagnosis Notes"),
        postMedicalNotes: yup.string().matches(/^(?!\s)/, 'Invalid Input').required("Enter Post Diagnosis Notes"),
        finalNotes: yup.string().matches(/^(?!\s)/, 'Invalid Input').required("Enter Final Notes"),
    });

    const {
        register,
        watch,
        handleSubmit,
        reset,
        control,
        clearErrors,
        formState: { errors },
    } = useForm({
        defaultValues,
        resolver: yupResolver(Schema),
        mode: "onChange",
    });

    const getVideo = (recordedChunks) => {
        setVideo(recordedChunks);
    };

    const handleVideoRecord = () => {
        setDisplay(false);
        setRecorder(!recorder)
        handleScroll();
    };

    const handleVideoDisplay = () => {
        setRecorder(false);
        setDisplay(!display)
        handleScroll();
    };

    const downloadVideo = () => {
        if (video.length) {
            const blob = new Blob(video, {
                type: "video/webm",
            });
            const url = URL.createObjectURL(blob);
            console.log(url);
            const a = document.createElement("a");
            document.body.appendChild(a);
            a.style = "display: none";
            a.href = url;
            console.log(a.href);
            a.download = `${bookingId} ${formattedDateTime}.webm`;
            a.click();
            window.URL.revokeObjectURL(url);
            setVideo([]);
        }
    };

    const UpdateOTStatus = async () => {
        await axiosInstance
            .post(OTBookingStatusUpdate, {
                bookingId: bookingId,
                otStatus: "Billing"
            })
            .then((res) => {
                if (res.status === 200 || res.status === 201) {
                    const strsubmit = "Patient OT Completed Successfully";
                    toast.success(strsubmit);
                    dispatch(addSelectedItem(<OTBilling />));
                } else if (res.response.status === 409) {
                    toast.warning(res.response.data);
                }
            })
            .catch((error) => {
                toast.error("Something went wrong");
            });

    };

    const executeSubmit = async (data) => {
        await axiosInstance
            .post(OTNotes, { ...data })
            .then((res) => {
                if (res.status === 200 || res.status === 201) {
                    UpdateOTStatus();
                } else if (res.response.status === 409) {
                    window.scrollTo(0, 0);
                    toast.warning(res.response.data);
                }
            })
            .catch((error) => {
                if (error.response.status === 409) {
                    toast.warning(error.response.data);
                }
                else {
                    toast.error("Something went wrong");
                }
            });
    };

    const resetForm = () => {
        reset(defaultValues);
    };

    return (
        <form onSubmit={handleSubmit(executeSubmit)}>
            <div className={classes.root} ref={mainRef}>
                <Grid container spacing={2} direction="column">
                    <Grid item>
                        <PatientDetailBox />
                    </Grid>
                    <Grid item>
                        <div className={classes.container} style={{ height: (recorder || display) && "auto" }}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6} lg={4}>
                                    <Typography>Pre OT Notes<span style={{ color: "red" }}>*</span></Typography>
                                    <Controller
                                        name="preOtNotes"
                                        control={control}
                                        render={({ field: { onChange, value } }) => (
                                            <TextField
                                                fullWidth
                                                multiline
                                                rows={6}
                                                placeholder="Pre OT Notes"
                                                size="small"
                                                variant="outlined"
                                                value={value}
                                                onChange={onChange}
                                                error={!!errors.preOtNotes}
                                                helperText={errors.preOtNotes?.message}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} lg={4}>
                                    <Typography>Post OT Notes<span style={{ color: "red" }}>*</span></Typography>
                                    <Controller
                                        name="postOtNotes"
                                        control={control}
                                        render={({ field: { onChange, value } }) => (
                                            <TextField
                                                fullWidth
                                                multiline
                                                rows={6}
                                                placeholder="Post OT Notes"
                                                size="small"
                                                variant="outlined"
                                                value={value}
                                                onChange={onChange}
                                                error={!!errors.postOtNotes}
                                                helperText={errors.postOtNotes?.message}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} lg={4}>
                                    <Typography>Pre Anaesthesia Notes<span style={{ color: "red" }}>*</span></Typography>
                                    <Controller
                                        name="preAnesthesiaNotes"
                                        control={control}

                                        render={({ field: { onChange, value } }) => (
                                            <TextField
                                                fullWidth
                                                multiline
                                                rows={6}
                                                placeholder="Pre Anaesthesia Notes"
                                                size="small"
                                                variant="outlined"
                                                value={value}
                                                onChange={onChange}
                                                error={!!errors.preAnesthesiaNotes}
                                                helperText={errors.preAnesthesiaNotes?.message}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} lg={4}>
                                    <Typography>Post Anaesthesia Notes<span style={{ color: "red" }}>*</span></Typography>
                                    <Controller
                                        name="postAnesthesiaNotes"
                                        control={control}
                                        render={({ field: { onChange, value } }) => (
                                            <TextField
                                                fullWidth
                                                multiline
                                                rows={6}
                                                placeholder="Post Anaesthesia Notes"
                                                size="small"
                                                variant="outlined"
                                                value={value}
                                                onChange={onChange}
                                                error={!!errors.postAnesthesiaNotes}
                                                helperText={errors.postAnesthesiaNotes?.message}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} lg={4}>
                                    <Typography>Pre Diagnosis Notes<span style={{ color: "red" }}>*</span></Typography>
                                    <Controller
                                        name="preMedicalNotes"
                                        control={control}
                                        render={({ field: { onChange, value } }) => (
                                            <TextField
                                                fullWidth
                                                multiline
                                                rows={6}
                                                placeholder="Pre Diagnosis Notes"
                                                size="small"
                                                variant="outlined"
                                                value={value}
                                                onChange={onChange}
                                                error={!!errors.preMedicalNotes}
                                                helperText={errors.preMedicalNotes?.message}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} lg={4}>
                                    <Typography>Post Diagnosis Notes<span style={{ color: "red" }}>*</span></Typography>
                                    <Controller
                                        name="postMedicalNotes"
                                        control={control}
                                        render={({ field: { onChange, value } }) => (
                                            <TextField
                                                fullWidth
                                                multiline
                                                rows={6}
                                                placeholder="Post Diagnosis Notes"
                                                size="small"
                                                variant="outlined"
                                                value={value}
                                                onChange={onChange}
                                                error={!!errors.postMedicalNotes}
                                                helperText={errors.postMedicalNotes?.message}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} lg={4}>
                                    <Typography>Final Notes<span style={{ color: "red" }}>*</span></Typography>
                                    <Controller
                                        name="finalNotes"
                                        control={control}
                                        render={({ field: { onChange, value } }) => (
                                            <TextField
                                                fullWidth
                                                multiline
                                                rows={6}
                                                placeholder="Final Notes"
                                                size="small"
                                                variant="outlined"
                                                value={value}
                                                onChange={onChange}
                                                error={!!errors.finalNotes}
                                                helperText={errors.finalNotes?.message}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item direction="column" style={{ paddingTop: 30, display: 'flex', justifyContent: "center", }}>
                                    {!recorder &&
                                        <>
                                            <Grid item>
                                                <Button
                                                    align="center"
                                                    color="primary"
                                                    variant="contained"
                                                    onClick={handleVideoRecord}
                                                >Video Record</Button>
                                            </Grid>
                                            <Grid item>
                                                <Typography variant="subtitle2" >(Optional)</Typography>
                                            </Grid>
                                        </>}
                                </Grid>
                                {!display &&
                                    <Grid item style={{ display: 'flex', alignItems: "center", }}>
                                        <Button
                                            align="center"
                                            color="primary"
                                            variant="contained"
                                            onClick={handleVideoDisplay}
                                        > Video Display </Button>
                                    </Grid>
                                }
                                {!recorder &&
                                    <Grid item style={{ display: 'flex', alignItems: "center", }}>
                                        <Button
                                            align="center"
                                            type="submit"
                                            color="primary"
                                            variant="contained"
                                            onClick={handleSubmit(executeSubmit)}
                                        > Submit </Button>
                                    </Grid>
                                }
                            </Grid>
                        </div>
                    </Grid>
                    {
                        display &&
                        <Grid item >
                            <div className={classes.videoContainer}>
                                <Grid container spacing={2} >
                                    <Grid item container xs={12} justifyContent="center" spacing={2} direction="column">
                                        <Grid item>
                                            <Typography >
                                                Video Display</Typography>
                                        </Grid>
                                        <Grid item container justifyContent="center">
                                            <VideoRecorder display={display} />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </div>
                        </Grid>
                    }
                    {
                        recorder &&
                        <Grid item >
                            <div className={classes.videoContainer}>
                                <Grid container spacing={2} >
                                    <Grid item container xs={12} justifyContent="center" spacing={2} direction="column">
                                        <Grid item>
                                            <Typography >
                                                Video Recorder</Typography>
                                        </Grid>
                                        <Grid item container justifyContent="center">
                                            <VideoRecorder getVideo={getVideo} recorder={recorder} />
                                        </Grid>
                                    </Grid>
                                    <Grid item container xs={12} sm={12} lg={12} spacing={1} justifyContent="center" alignItems="center">
                                        {/* <Grid item>
                                        <Button
                                            align="center"
                                            type="submit"
                                            color="primary"
                                            variant="contained"
                                            onClick={() => setOpenPreview(!openPreview)}
                                        > Preview </Button>
                                    </Grid> */}
                                        {video.length > 0 ? (
                                            <Grid item>
                                                <Button
                                                    align="center"
                                                    color="primary"
                                                    variant="contained"
                                                    onClick={downloadVideo}
                                                > Download </Button>
                                            </Grid>) : null
                                        }

                                        <Grid item>
                                            <Button
                                                align="center"
                                                type="submit"
                                                color="primary"
                                                variant="contained"
                                                onClick={handleSubmit(executeSubmit)}
                                            > Submit </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </div>
                        </Grid>
                    }
                </Grid >
            </div >
        </form >
    );
}


