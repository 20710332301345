import React, { useEffect } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import PropTypes from 'prop-types';
import { Box, Grid, TextField } from '@material-ui/core';
//tabs
import { BookingTab } from './BookingTab/BookingTab';
import { SurgeryTeamTab } from '../ScheduleTabs/SurgeryTeamTab/SurgeryTeamTab';
import { ConsumablesTab } from './ConsumableTab/ConsumablesTab';
import { BloodRequestTab } from './BloodRequestTab/BloodRequestTab';

const AntTabs = withStyles((theme) => ({
    root: {
        borderBottom: `1px solid 
        ${theme.palette.background.default}`,
    },
    indicator: {
        backgroundColor: theme.palette.primary.main,
    },
}))(Tabs);
const AntTab = withStyles((theme) => ({
    root: {
        textTransform: 'none',
        minWidth: 72,
        fontWeight: theme.typography.fontWeightRegular,
        marginRight: theme.spacing(4),
        color: theme.palette.text.primary,

        '&:hover': {
            color: theme.palette.primary.main,
            opacity: 1,
        },
        '&$selected': {
            color: theme.palette.primary.main,
            fontWeight: theme.typography.fontWeightMedium,
        },
        '&:focus': {
            color: theme.palette.primary.main,
        },
    },
    selected: {},
}))((props) => <Tab disableRipple {...props} />);
function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-prevent-tabpanel-${index}`}
            aria-labelledby={`scrollable-prevent-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={2}>
                    {children}
                </Box>
            )}
        </div>
    );
}
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};
function a11yProps(index) {
    return {
        id: `scrollable-prevent-tab-${index}`,
        'aria-controls': `scrollable-prevent-tabpanel-${index}`,
    };
}
const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    tabs: {
        background: theme.palette.background.light,
        borderTopLeftRadius: 6,
        borderTopRightRadius: 6,
        padding: 0,
    },
    tabsPanel: {
        background: theme.palette.background.light,
        borderBottomLeftRadius: 6,
        borderBottomRightRadius: 6,
        padding: 0,
        overflowY: 'auto',
        overflowX: 'hidden',
        height: 530,
    },
}));

export default function ScheduleTabs() {
    const classes = useStyles();
    const tabCount = 4;
    const [value, setValue] = React.useState(0);
    // const [bookingDetails, setBookingDetails] = React.useState([]);

    // const setBookingEditdata = (data) => {
    //     setBookingDetails(data)
    // }

    return (
        <div className={classes.root}>
            <div className={classes.tabs}>
                <AntTabs value={value}
                    onChange={(e, newValue) => setValue(newValue)}>
                    <AntTab label="Booking"  {...a11yProps(0)} />
                    <AntTab label="Surgery Team"  {...a11yProps(1)} />
                    <AntTab label="Consumables" {...a11yProps(2)} />
                    <AntTab label="Blood Request" {...a11yProps(3)} />
                </AntTabs>
            </div>
            <div className={classes.tabsPanel}>
                <TabPanel value={value} index={0}>
                    <BookingTab onClickNext={() => setValue((value + 1) % tabCount)}
                    //bookingEditdata={setBookingEditdata}
                    />
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <SurgeryTeamTab
                        onClickBack={() => setValue((value + tabCount - 1) % tabCount)}
                        onClickNext={() => setValue((value + 1) % tabCount)} />
                </TabPanel>
                <TabPanel value={value} index={2}>
                    <ConsumablesTab
                      onClickBack={() => setValue((value + tabCount - 1) % tabCount)}
                      onClickNext={() => setValue((value + 1) % tabCount)} />
                </TabPanel>
                <TabPanel value={value} index={3}>
                    <BloodRequestTab
                        onClickBack={() => setValue((value + tabCount - 1) % tabCount)} />
                </TabPanel>
            </div >
        </div >
    );
}
