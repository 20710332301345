export const TREE_ITEM = "TREE_ITEM";
export const LOGIN_TYPE = "LOGIN_TYPE";
export const PRIMARY_COLOR = "PRIMARY_COLOR";
export const SECONDARY_COLOR = "SECONDARY_COLOR";
export const RESET_COLOR = "RESET_COLOR";
export const UPDATE_OBJECT = "UPDATE_OBJECT";
export const SElECTED_HOME_EVENT = "SElECTED_HOME_EVENT";
export const SELECTED_MENU = "SELECTED_MENU";
export const LOGGED_IN_USER = "LOGGED_IN_USER";
export const FETCH_EMPLOYEE_DETAILS = "FETCH_EMPLOYEE_DETAILS";
export const FETCH_EMP_DETAILS_BY_ID = "FETCH_EMP_DETAILS_BY_ID";
export const FETCH_ELG_DETAILS = "FETCH_ELG_DETAILS";
export const FETCH_SUBMITTED_CLAIM = "FETCH_SUBMITTED_CLAIM";
export const FETCH_CLAIM_ID = "FETCH_CLAIM_ID";
export const FETCH_ALL_CLAIM_BY_EMP_ID = "FETCH_ALL_CLAIM_BY_EMP_ID";
export const FETCH_DRAFT_CLAIM_DETAILS = "FETCH_CLAIM_DETAILS";
export const FETCH_FILE = "FETCH_FILE";
export const DELETE_BILL = "DELETE_BILL";
export const SElECTED_APPOINTMENT_DETAILS = "SElECTED_APPOINTMENT_DETAILS";
export const appontmentDownloadFileUrl = "appontmentDownloadFileUrl";
export const ADD_PATIENT_DETAILS = "ADD_PATIENT_DETAILS";
//OT
export const SELECTED_ITEM = "SELECTED_ITEM";
export const SELECTED_ACTION_ITEM = "SELECTED_ACTION_ITEM";
export const FETCH_OT_PENDING_LIST = "FETCH_PENDING_LIST";
export const FETCH_OT_SCHEDULED_LIST = "FETCH_OT_SCHEDULED_LIST";
export const FETCH_OT_BILLING_LIST = "FETCH_OT_BILLING_LIST";
export const FETCH_BILLING_SERVICE_DOCTORS_LIST = "FETCH_BILLING_SERVICE_DOCTORS_LIST";
export const FETCH_BILLING_SERVICE_LIST = "FETCH_BILLING_SERVICE_LIST";
export const ADD_OT_PATIENT_DETAILS = "ADD_OT_PATIENT_DETAILS";
export const ADD_OT_BOOKING_ID = "ADD_OT_BOOKING_ID";
export const UPDATE_OT_BOOKING = "UPDATE_OT_BOOKING";


