import React, { useState, useEffect } from "react";
import moment from "moment";
//material-ui
import { makeStyles, Table, TableBody, Grid, TextField, InputAdornment } from "@material-ui/core";

import { useDispatch, useSelector } from "react-redux";

import LinearProgress from "@material-ui/core/LinearProgress";
import { Search } from "@material-ui/icons";
//components
import useTable, { StyledTableCell, StyledTableRow } from "../../../components/UseTable";
// import TextField from "../../../components/TextField"
import { fetchEmpDetails } from "../../../Redux/Actions/EmployeeAction";
import EmployeeButtons from "./Employee";

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: "100%",
  },

  tableCell: {
    fontSize: "14px",
    border: "0.1px solid #ededed",
    padding: "4px",
    // color: "",
    [theme.breakpoints.down("md")]: {
      fontSize: "12px",
    },
  },
  fontstyle: {
    transform: "lowerCase",
  },
  tableColor: {
    color: "white",
  },
}));

const headCells = [
  //    { id: "id", label: "S.No.", align: "left" },
  { id: "eid", label: "Emp Id", align: "left" },
  { id: "ename", label: "Emp Name", align: "left" },
  { id: "berechsNo", label: "BERECHS No.", align: "left" },
  { id: "email", label: "Email", align: "left" },
  { id: "contactNo", label: "Contact No.", align: "left" },
  { id: "dob", label: "DOB", align: "left" },
  { id: "spouseName", label: "Spouse Name", align: "left" },
  { id: "berechSpouseId", label: "Spouse BERECHS Membership ID", align: "left" },
  { id: "unitCode", label: "Unit Code", align: "left" },
  { id: "spouseStatus", label: "Spouse Status", align: "left" },

  // { id: "actions", label: "Actions", disableSorting: true },
];

export default function PedencyLog() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);

  const [records, setRecords] = useState([]);
  const [inputValue, setInputValue] = useState("");

  const [filterFn, setFilterFn] = useState({ fn: (items) => items });
  const empDetails = useSelector((store) => store.employeeReducer.emp);
  const loggedInUser = useSelector((store) => store.loggedInUser);

  const requestSearch = (e) => {
    let val = inputValue;
    setFilterFn({
      fn: (records) => {
        if (!val) return records;
        else {
          return records.filter(
            (row) =>
              row.id == val ||
              row.ename?.toLowerCase().includes(val.toLowerCase()) ||
              row.contactNo?.toString().includes(val) ||
              row.spouseName?.toLowerCase().includes(val.toLowerCase()) ||
              row.email?.toLowerCase().includes(val.toLowerCase()) ||
              row.berechsNo?.toLowerCase().includes(val.toLowerCase()) ||
              row.bankAccNo?.toLowerCase().includes(val.toLowerCase()) ||
              row.spouseStatus?.toLowerCase().includes(val.toLowerCase()) ||
              row.accountStatus?.toLowerCase().includes(val.toLowerCase()) ||
              moment(row.dob)
                .format("DD.MM.YYYY")
                .includes(val.toLowerCase())
          );
        }
      },
    });
  };

  const fetch = async () => {
    if (loggedInUser && loggedInUser.unitCode) {
      setLoading(true);
      await dispatch(fetchEmpDetails(loggedInUser.unitCode));
      setLoading(false);
    } else {
      console.log("Logged-in user or unitCode is undefined.");
    }
  };
  const capitalizeFirstLetter = (string) => {
    const newString = string.charAt(0) + string.slice(1, string.maxLength)?.toLowerCase();
    return newString;
  };

  useEffect(() => {
    fetch();
  }, [loggedInUser]);

  useEffect(() => {
    console.log("klkll", empDetails);
    if (empDetails.length !== 0) {
      setRecords(empDetails);
    }
  }, [empDetails]);
  const { TblContainer, TblHead, TblPagination, recordsAfterPagingAndSorting, getSlNo } = useTable(records, headCells, filterFn);
  return (
    // <Paper>
    <div>
      <Grid>
        <Grid container>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <EmployeeButtons />
          </Grid>
        </Grid>
        <div style={{ display: "flex", alignItems: "center", justifyContent: "end", marginBottom: "8px" }}>
          <TextField
            placeholder="Search..."
            variant="outlined"
            size="small"
            // className={classes.searchInput}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
            onChange={(e) => setInputValue(e.target.value)}
            onKeyDown={(e) => e.key === "Enter" && requestSearch()}
          />
        </div>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center" style={{ margin: "auto", overflowX: "auto" }}>
          <Grid container>
            <TblContainer style={{ maxHeight: "600px" }}>
              {loading && <LinearProgress />}
              <Table style={{ position: "relative", border: "1px solid lightgrey" }} size="small" aria-label="simple table">
                <TblHead />
                {recordsAfterPagingAndSorting()?.length !== 0 ? (
                  <TableBody>
                    {recordsAfterPagingAndSorting()?.map((item, index) => (
                      <StyledTableRow className={classes.tableColor} key={index}>
                        <StyledTableCell className={classes.tableCell}>{item?.id}</StyledTableCell>
                        <StyledTableCell className={classes.tableCell}>{item?.ename}</StyledTableCell>
                        <StyledTableCell className={classes.tableCell}>{item?.berechsNo}</StyledTableCell>
                        <StyledTableCell className={classes.tableCellEmail}>{item?.email?.toLowerCase()}</StyledTableCell>
                        <StyledTableCell className={classes.tableCell}>{item?.contactNo}</StyledTableCell>
                        <StyledTableCell className={classes.tableCell}>{moment(item.dob).format("DD.MM.YYYY")}</StyledTableCell>
                        <StyledTableCell className={classes.tableCell}>
                          {item?.spouseName ? capitalizeFirstLetter(item?.spouseName) : "--"}
                        </StyledTableCell>
                        <StyledTableCell className={classes.tableCell}>{item?.berechSpouseId}</StyledTableCell>
                        <StyledTableCell className={classes.tableCell}>{item.unitCode}</StyledTableCell>
                        <StyledTableCell className={classes.tableCell}>{item?.spouseStatus}</StyledTableCell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                ) : (
                  <TableBody>
                    <StyledTableRow>
                      <StyledTableCell colSpan={10} align="center">
                        No records found
                      </StyledTableCell>
                    </StyledTableRow>
                  </TableBody>
                )}
              </Table>
              {recordsAfterPagingAndSorting()?.length != 0 && recordsAfterPagingAndSorting()?.length != undefined ? <TblPagination /> : ""}
            </TblContainer>
          </Grid>
        </Grid>
      </Grid>
    </div>
    // </Paper>
  );
}
