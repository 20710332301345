import { Container, Grid } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import Typography from "@material-ui/core/Typography";
import Appbar from "pages/Layout/Appbar";
import axiosInstance from "pages/Services/AxiosInstance";
import PropTypes from "prop-types";
import React from "react";

import SessionTimeout from "../../SessionTimeOut/SessionTimeout";

import { useDispatch, useSelector } from "react-redux";
import { LOGGED_IN_USER } from "Redux/Types";
import { SERVICE_URL } from "../../config.json";
import Appointment from "./Appointment";
import ConsultationHistory from "./ConsultationHistory";
import { useNavigate } from "react-router-dom";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box style={{ padding: "0px 24px" }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.primary,
    display: "flex",
    justifyContent: "spacing-between",
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
    overflow: "unset",
  },
  tab: {
    textTransform: "capitalize",
    [theme.breakpoints.down("md")]: {
      width: "100%",
      maxWidth: "unset",
    },
  },
}));

export default function CitizenTabs() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const [value, setValue] = React.useState(0);
  const theme = useTheme();
  const [isAuthenticated, setAuth] = React.useState(true);

  const user =
    useSelector((store) => store.loggedInUser) ||
    JSON.parse(localStorage.getItem("User"));
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleLogout = () => {
    console.log("user in logout", user);
    axiosInstance
      .post(SERVICE_URL + "user/logout", { userName: user.username })
      .then(() => {
        localStorage.clear();
        dispatch({ type: LOGGED_IN_USER, payload: null });
      })
      .catch((e) => console.log(e.message)).finally(()=>{
        navigate("/");
      });;
  };

  // useEffect(() => {
  //   axiosInstance(URL_GET_ALL_RECORDS)
  //     .then(({ data }) => {
  //       setAllOrgRecords(data);
  //     })
  //     .catch((e) => {
  //       toast.error("Failed to get all Orgs!");
  //       console.log(e);
  //     });
  // }, []);

  return (
    <>
      <Container component={Box} style={{ paddingTop: 16 }} maxWidth="xl">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={2} lg={2}>
            <Tabs
              orientation="vertical"
              variant="scrollable"
              value={value}
              onChange={handleChange}
              aria-label="Vertical tabs example"
              className={classes.tabs}
            >
              <Tab
                label="Appointment"
                {...a11yProps(0)}
                style={{
                  background:
                    value === 0
                      ? `${theme.palette.primary.main}`
                      : `${theme.palette.primary.light}`,
                  color:
                    value === 0
                      ? theme.palette.primary.contrastText
                      : theme.palette.primary.contrastText,
                  opacity: 1,
                  margin: "5px 0px",
                }}
                className={classes.tab}
              />
              <Tab
                label="Consultation History"
                {...a11yProps(1)}
                style={{
                  background:
                    value === 1
                      ? `${theme.palette.primary.main}`
                      : `${theme.palette.primary.light}`,
                  color:
                    value === 1
                      ? theme.palette.primary.contrastText
                      : theme.palette.primary.contrastText,
                  opacity: 1,
                  margin: "5px 0px",
                }}
                className={classes.tab}
              />
            </Tabs>
          </Grid>
          <Grid item xs={12} sm={12} md={10} lg={10}>
            <TabPanel value={value} index={0}>
              <Appointment />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <ConsultationHistory patientId={user?.id} />
            </TabPanel>
          </Grid>
        </Grid>
      </Container>
      <SessionTimeout isAuthenticated={isAuthenticated} logOut={handleLogout} />
    </>
  );
}
