import { Avatar, CircularProgress, Grid, IconButton, makeStyles, Typography } from "@material-ui/core";
import { CameraAlt, FiberManualRecordRounded, StopSharp } from "@material-ui/icons";
import Button from "components/Button";
import React, { useCallback, useEffect, useRef, useState } from "react";
import Webcam from "react-webcam";
import { useTheme } from "styled-components";

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(0),
    },
    text: {
        color: theme.palette.background.paper,
    },
    webcamContainer: {
        display: "flex",
        justifContent: "center",
        alignItems: "center",
        height: "100vh",
        backgroundColor: "#f0f0f0",
    },
    fullScreen: {
        transform: "scaleX(-1)",/* Flip the video horizontally */
    }
}));

export const VideoRecorder = (props) => {

    const classes = useStyles();
    const theme = useTheme();
    const webcamRef = useRef(null);
    const mediaRecorderRef = useRef(null);
    const [isLoading, setIsLoading] = React.useState(true);
    const [cameraDetected, setCameraDetected] = useState(false);
    const [capturing, setCapturing] = useState(false);
    const [isFullScreen, setIsFullScreen] = useState(false);
    const [recordedChunks, setRecordedChunks] = useState([]);
    const [time, setTime] = useState(0);
    const hours = Math.floor(time / 360000);
    const minutes = Math.floor((time % 360000) / 6000);
    const seconds = Math.floor((time % 6000) / 100);
    const milliseconds = time % 100;

    useEffect(() => {
        checkCamera();
        if (props.recorder) {
            navigator.mediaDevices.ondevicechange = () => {
                handleStopCaptureClick();
                setIsLoading(true);
                checkCamera();
            }
        }
        if (props.display) {
            navigator.mediaDevices.ondevicechange = () => {
                setIsLoading(true);
                checkCamera();
            }
        }
    }, [props.recorder, props.display]);

    useEffect(() => {
        let intervalId;
        if (capturing) {
            // setting time from 0 to 1 every 10 milisecond
            intervalId = setInterval(() => setTime(time + 1), 10);
        }
        return () => clearInterval(intervalId);
    }, [capturing, time]);

    const reset = () => {
        setTime(0);
    };

    const checkCamera = async () => {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({ video: true });
            setCameraDetected(true);
            stream.getTracks().forEach((track) => track.stop());
            setIsLoading(false);
        } catch (error) {
            setCameraDetected(false);
            setIsLoading(false);
        }
    };

    const handleDataAvailable = useCallback(
        ({ data }) => {
            if (data.size > 0) {
                setRecordedChunks((prev) => prev.concat(data));
            }
        },
        [setRecordedChunks]
    );

    const handleStartCaptureClick = useCallback(() => {
        setRecordedChunks([]);
        reset();
        mediaRecorderRef.current = new MediaRecorder(webcamRef.current.stream, {
            mimeType: "video/webm",
        });
        mediaRecorderRef.current.addEventListener(
            "dataavailable",
            handleDataAvailable
        );
        mediaRecorderRef.current.start();
        setCapturing(true);
    }, [webcamRef, setCapturing, mediaRecorderRef, handleDataAvailable]);

    const handleStopCaptureClick = useCallback(() => {
        mediaRecorderRef.current.stop();
        setCapturing(false);
        reset();
    }, [mediaRecorderRef, setCapturing]);

    // const handleDownload = useCallback(() => {
    //     if (recordedChunks.length) {
    //         const blob = new Blob(recordedChunks, {
    //             type: "video/webm",
    //         });
    //         const url = URL.createObjectURL(blob);
    //         const a = document.createElement("a");
    //         document.body.appendChild(a);
    //         a.style = "display: none";
    //         a.href = url;
    //         a.download = "react-webcam-stream-capture.webm";
    //         a.click();
    //         window.URL.revokeObjectURL(url);
    //         setRecordedChunks([]);
    //     }
    // }, [recordedChunks]);

    const toggleFullScreen = () => {
        if (!isFullScreen) {
            // Enter full screen
            if (webcamRef.current) {
                const videoElement = webcamRef.current.video;
                if (videoElement) {
                    if (videoElement.requestFullscreen) {
                        videoElement.requestFullscreen();
                    } else if (videoElement.mozRequestFullScreen) {
                        videoElement.mozRequestFullScreen();
                    } else if (videoElement.webkitRequestFullscreen) {
                        videoElement.webkitRequestFullscreen();
                    } else if (videoElement.msRequestFullscreen) {
                        videoElement.msRequestFullscreen();
                    }
                }
            }
        } else {
            // Exit full screen
            if (document.exitFullscreen) {
                document.exitFullscreen();
            } else if (document.mozCancelFullScreen) {
                document.mozCancelFullScreen();
            } else if (document.webkitExitFullscreen) {
                document.webkitExitFullscreen();
            } else if (document.msExitFullscreen) {
                document.msExitFullscreen();
            }
        }
        setIsFullScreen(false);
    };

    return (
        <div >
            {cameraDetected && !isLoading ?
                <div >
                    <Webcam
                        style={{ borderRadius: 20, padding: 4, borderStyle: isFullScreen ? "none" : 'dashed' }}
                        height={480}
                        width={630}
                        audio={true}
                        mirrored={false}
                        ref={webcamRef}
                        videoConstraints={{
                            width: 1280,
                            height: 720,
                            facingMode: "environment",
                        }} />
                    {cameraDetected && props.recorder ?
                        <Grid container spacing={2} justifyContent="center" alignItems="center" style={{ padding: 10 }}>
                            <Grid item>
                                <IconButton style={{ width: 10, height: 10 }}>
                                    {!capturing ?
                                        <Avatar onClick={handleStartCaptureClick}>
                                            <FiberManualRecordRounded style={{ color: '#E74C3C' }} />
                                        </Avatar>
                                        : <Avatar onClick={handleStopCaptureClick}>
                                            <StopSharp style={{ color: '#E74C3C' }} />
                                        </Avatar>
                                    }
                                </IconButton>
                            </Grid>

                            {recordedChunks && (
                                props.getVideo(recordedChunks)
                            )}
                            {capturing ?
                                <Grid item>
                                    <Typography style={{ fontWeight: 550 }}>
                                        {hours}:{minutes.toString().padStart(2, "0")}:
                                        {seconds.toString().padStart(2, "0")}
                                        {/* {milliseconds.toString().padStart(2, "0")} */}
                                    </Typography>
                                </Grid> : null
                            }
                        </Grid> : null}
                    {cameraDetected && props.display ?
                        <Grid container spacing={2} justifyContent="center" alignItems="center" style={{ padding: 10 }}>
                            <Grid item>
                                <Button align="center"
                                    color="primary"
                                    variant="contained"
                                    onClick={toggleFullScreen}
                                >Full Screen
                                </Button>
                            </Grid>
                        </Grid> : null
                    }
                </div>
                : <Grid container justifyContent="center" alignItems="center"
                    style={{ height: 530, width: 630, background: "#222226", borderRadius: 20, padding: 10 }}>
                    {recordedChunks.length > 0 && (
                        props.getVideo(recordedChunks)
                    )}
                    {isLoading ?
                        <Grid item>
                            <CircularProgress color="white" />
                        </Grid>
                        : <Grid container direction="column" justifyContent="center" alignItems="center" >
                            <Grid item>
                                <CameraAlt fontSize="large" />
                            </Grid>
                            <Grid item>
                                <Typography >No camera detected or camera is unplugged.</Typography>
                            </Grid>
                        </Grid>

                    }
                    {/* <Button
                                    startIcon={< CameraAlt />}
                                    align="center"
                                    color="primary"
                                    variant="contained"
                                    onClick={() => setTurnOnCamera(true)}>Turn on camera</Button> */}
                </Grid>
            }
        </div >
    );
}