import { yupResolver } from "@hookform/resolvers/yup";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";

import { cancelAppointmentUrl } from "pages/Services/URLs";

//material-ui
import {
  Fab,
  Fade,
  Grid,
  InputAdornment,
  makeStyles,
  Table,
  TableBody,
  TextField,
  Tooltip,
  Typography,
  Zoom,
} from "@material-ui/core";
import LinearProgress from "@material-ui/core/LinearProgress";
import TableRow from "@material-ui/core/TableRow";
import {
  Add,
  Cancel,
  CancelOutlined,
  CancelSharp,
  Close,
  Computer,
  Search,
  VideocamOutlined,
} from "@material-ui/icons";

//pages
import { useNavigate } from "react-router-dom";
//redux
import { useDispatch, useSelector } from "react-redux";

//components
import Button from "components/Button";
import useTable, {
  StyledTableCell,
  StyledTableRow,
} from "../../components/UseTable";
import { toast } from "react-toastify";
import { addSelectedAppointmentDetails } from "Redux/Actions/AppointmentAction";
import axiosInstance from "pages/Services/AxiosInstance";
// import Swal from "sweetalert2";
// import TextField from "../../../components/TextField"
// import ClaimDetailsButtons from "./ClaimDetails";

export const startDate = moment(); // Same Date In future they can change the from/Start Date
export const endDate = moment();

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: "100%",
  },
  tableCell: {
    fontSize: "14px",
    border: "0.1px solid #ededed",
    padding: "4px",
    [theme.breakpoints.down("md")]: {
      fontSize: "12px",
    },
  },
  fab: {
    margin: theme.spacing(1),
  },
  image: {
    objectFit: "contain",
    height: 700,
    width: "100%",
  },
  tableColor: {
    color: "white",
  },
  closeDialog: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(0.5),
    color: theme.palette.grey[500],
  },
  color: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.contrastText,
    "&:hover, &:focus": {
      color: theme.palette.primary.contrastText,
      background: theme.palette.primary.main,
    },
  },

  item: {
    minWidth: 300,
    maxWidth: 200,
    paddingRight: 20,
  },
  itemLabel: {
    minWidth: 100,
  },
  dateContainer: {
    display: "flex",
    justifyContent: "",
    alignItems: "center",
    flexWrap: "wrap",
    marginTop: "15px",
    marginBottom: "15px",
  },
  searchInput: {
    marginBottom: 10,
  },
  mt20: {
    marginTop: 20,
  },
}));

const headCells = [
  { id: "Sl No", label: "Sl no", align: "left" },
  { id: "doctorName", label: "Doctor Name", align: "left" },
  { id: "apptStart", label: "Date", align: "left" },
  { id: "apptEnd", label: "Time", align: "left" },
  { id: "action", label: "Action", align: "left" },
];
const arraySchema = yup.object().shape({
  isSelf: yup.string().required("Select Self/Spouse"),
  expenseTypeId: yup.string().required("Expense Type is required"),
  billNo: yup.string().required("Bill No is required"),
});

export default function AppointmentTable({ data, fetchData, fetchslots }) {
  const navigate = useNavigate();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);
  const [records, setRecords] = useState([]);
  const [filterFn, setFilterFn] = useState({ fn: (items) => items });

  const requestSearch = (e) => {
    let val = e.target.value;
    setFilterFn({
      fn: (records) => {
        if (!val) return records;

        return records.filter(
          (row) =>
            row.staffDto.staffName
              .toString()
              .toLowerCase()
              .includes(val.toLowerCase()) ||
            row?.apptStart
              ?.toString()
              .toLowerCase()
              .includes(val.toLowerCase()) ||
            moment(row?.apptStart)
              .format("DD-MM-YYYY")
              .includes(val.toLowerCase()) ||
            moment(row?.apptStart)
              .format("hh:mm A")
              .includes(val.toLowerCase()) ||
            moment(row?.apptEnd)
              .format("hh:mm A")
              .includes(val.toLowerCase())
        );
      },
    });
  };
  const {
    register,
    watch,
    handleSubmit,
    reset,
    control,
    setValue,
    getValues,
    // setValues,
    clearErrors,
    formState: { errors },
  } = useForm({
    // defaultValues,
    resolver: yupResolver(arraySchema),
    mode: "onChange",
  });

  useEffect(() => {
    setRecords(data);
  }, [data]);

  const {
    TblContainer,
    TblHead,
    TblPagination,
    recordsAfterPagingAndSorting,
    getSlNo,
  } = useTable(records, headCells, filterFn, 15);

  const joinAction = (obj) => {
    navigate("/ConsultationCitizen/");
    dispatch(addSelectedAppointmentDetails(obj));
  };
  const primaryColor = useSelector((store) => store.selectedTheme.primary);
  const secondaryColor = useSelector((store) => store.selectedTheme.secondary);
  const handelCancel = (id) => {
    // Swal.fire({
    //   title: "Are you sure?",
    //   text: "You won't be able to revert this!",
    //   icon: "warning",
    //   showCancelButton: true,
    //   confirmButtonColor: "#" + primaryColor,
    //   cancelButtonColor: "#" + secondaryColor,
    //   confirmButtonText: "Yes, Cancel it!",
    // }).then((result) => {
    //   if (result.isConfirmed) {
    //     axiosInstance
    //       .get(cancelAppointmentUrl + "/" + id)
    //       .then((res) => {
    //         if (res.status === 200 || res.status === 201) {
    //           Swal.fire({
    //             title: "Cancel!",
    //             text: "Your Appointment has been Cancelled.",
    //             icon: "success",
    //             confirmButtonColor: "#" + primaryColor,
    //           });
    //           fetchslots();
    //           fetchData();
    //         } else if (res.status === 409) {
    //           toast.warning(res.status.data);
    //         } else {
    //           toast.warning("Something Went Wrong");
    //         }
    //       })
    //       .catch((e) => console.log(e.message));
    //   }
    // });
  };
  return (
    // <Paper>
    <>
      <Grid container className={classes.mt20}>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="h6">Upcoming Appointments</Typography>
          <TextField
            placeholder="Search..."
            variant="outlined"
            size="small"
            className={classes.searchInput}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
            onChange={requestSearch}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            align="center"
            style={{ margin: "auto", overflowX: "auto" }}
          >
            <Grid container>
              <TblContainer style={{ maxHeight: "600px" }}>
                {loading && <LinearProgress />}
                <Table
                  style={{
                    position: "relative",
                    border: "1px solid lightgrey",
                  }}
                  size="small"
                  aria-label="simple table"
                  id="data-table-cleanliness-report"
                >
                  <TblHead />
                  {recordsAfterPagingAndSorting()?.length !== 0 ? (
                    <TableBody>
                      {recordsAfterPagingAndSorting()?.map((item, index) => (
                        <TableRow key={item?.id} className={classes.tableColor}>
                          <StyledTableCell>{index + 1} </StyledTableCell>
                          <StyledTableCell>
                            {item?.staffDto?.staffName}{" "}
                          </StyledTableCell>
                          <StyledTableCell>
                            {moment(item?.apptStart).format("DD-MM-YYYY")}{" "}
                          </StyledTableCell>
                          <StyledTableCell>
                            {moment(item?.apptStart).format("hh:mm A")}
                            {" - "}
                            {moment(item?.apptEnd).format("hh:mm A")}{" "}
                          </StyledTableCell>
                          <StyledTableCell>
                            <>
                              <Tooltip title="Join">
                                <Fab
                                  disabled={!item?.isTeleConsultation}
                                  onClick={() => joinAction(item)}
                                  color="primary"
                                  className={classes.fab}
                                  size="small"
                                >
                                  {!item?.isTeleConsultation ? (
                                    <Computer />
                                  ) : (
                                    <VideocamOutlined />
                                  )}
                                </Fab>
                              </Tooltip>
                              <Tooltip title="Cancel">
                                <Fab
                                  color="secondary"
                                  onClick={() => handelCancel(item.id)}
                                  className={classes.fab}
                                  size="small"
                                >
                                  <Close />
                                </Fab>
                              </Tooltip>
                            </>
                          </StyledTableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  ) : (
                    <TableBody>
                      <StyledTableRow>
                        <StyledTableCell colSpan={8} align="center">
                          No records found
                        </StyledTableCell>
                      </StyledTableRow>
                    </TableBody>
                  )}
                </Table>
                {recordsAfterPagingAndSorting()?.length != 0 &&
                recordsAfterPagingAndSorting()?.length != undefined ? (
                  <TblPagination />
                ) : (
                  ""
                )}
              </TblContainer>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
    // </Paper>
  );
}
