import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
//material-ui
import {
  Paper,
  makeStyles,
  Table,
  TableBody,
  Tooltip,
  Grid,
  IconButton,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@material-ui/core";
import Button from "components/Button";
import LinearProgress from "@material-ui/core/LinearProgress";

import CloseIcon from "@material-ui/icons/Close";
import axiosInstance from "pages/Services/AxiosInstance";
//components
import useTable, { StyledTableCell, StyledTableRow } from "../../../components/UseTable";

import { TableRow, TableCell } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  pageContent: {
    width: "95%",
    padding: theme.spacing(4),
    [theme.breakpoints.down("sm")]: {
      padding: 0,
      width: "100%",
    },
  },
  iconFa: {
    color: theme.palette.primary.dark,
    fontSize: "16px",
    marginRight: "2%",
    marginLeft: "15%",
  },
  size: {
    width: 15,
    height: 15,
  },
  table: {
    minWidth: "100%",
  },
  icon: {
    color: "#ff6600",
    fontSize: "13px",
  },
  icon1: {
    color: "#ff3000",
    fontSize: "13px",
  },
  searchInput: {
    alignContent: "right",
    justifyItems: "right",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  btnActive: {
    backgroundColor: "green",
    fontSize: "12px",
    height: "20px",
    color: "white",
    width: "25px",
  },
  btnInActive: {
    backgroundColor: "red",
    fontSize: "12px",
    height: "20px",
    color: "white",
    width: "25px",
  },
  tableCell: {
    fontSize: "14px",
    border: "1px solid #fff",
    padding: "4px",
    color: "black",
    [theme.breakpoints.down("md")]: {
      fontSize: "12px",
    },
  },
  closeDialog: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(0.5),
    color: theme.palette.grey[500],
  },
  tableColor: {
    // backgroundColor: theme.palette.primary.main,
    color: "white",
  },
  color: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.contrastText,
    "&:hover, &:focus": {
      color: theme.palette.primary.contrastText,
      background: theme.palette.primary.main,
    },
  },
}));

const headCells = [
  { id: "index", label: "Bill Serial No", align: "left" },
  { id: "expenseType", label: "Type of Expenses", align: "left" },
  { id: "billNo", label: "Bill Number", align: "left" },
  { id: "prescriptionDate", label: "Prescription Date", align: "left" },
  { id: "amountClaimed", label: "Amount Claimed", align: "left" },
  { id: "billLocation", label: "Uploaded Bill", align: "left", disableSorting: true },
  { id: "prescriptionLocation", label: "Uploaded Prescription", align: "left", disableSorting: true },
  { id: "settleAmount", label: "Approved Amount", align: "left" },
  { id: "amountPaid", label: "Paid Amount (90% of Approved)", align: "left" },
  {
    id: "deductionRemarks",
    label: "Reason for deduction (if any)",
    align: "left",
  },

  // { id: "actions", label: "Actions", disableSorting: true },
];

export default function PedencyLog(props, { row }) {

  const classes = useStyles();
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const [bill, setBill] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const [records, setRecords] = useState([]);
  const [dateRecords, setDateRecords] = useState([]);
  const [dateRecords1, setDateRecords1] = useState([]);
  const [filterFn, setFilterFn] = useState({ fn: (items) => items });
  // const claimByEmpId = useSelector((store) => store.employeeReducer.claimByEmpId);

  const { TblContainer, TblHead, TblPagination, recordsAfterPagingAndSorting, getSlNo } = useTable(
    records,
    headCells,
    filterFn
  );

  const toDataURL = async (url) => {
    const response = await axiosInstance.get(url, { responseType: "blob" });
    const imageDataUrl = URL.createObjectURL(response.data);

    return imageDataUrl;
  };
  const handleDownload = async (urlImage) => {
    const a = document.createElement("a");
    a.href = await toDataURL(urlImage);
    var filenameBefore = urlImage.split("/").pop();

    a.download = filenameBefore;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };
  React.useEffect(() => {
    if (props?.data?.length !== 0) {
      setRecords(props?.data?.medicalBillDtos);
    }
  }, [props]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleClose1 = () => {
    setBill(false);
  };
  console.log("dateRecords", dateRecords?.billLocation);
  return (
    <div style={{ padding: "0px 30px 0px 30px" }}>
      <Grid container style={{ justifyContent: "center" }}>
        <TblContainer style={{ maxHeight: "600px" }}>
          {loading && <LinearProgress />}
          <Table
            style={{ position: "relative", border: "1px solid lightgrey" }}
            size="small"
            aria-label="simple table"
            id="data-table-cleanliness-report"
          >
            <TblHead />
            {recordsAfterPagingAndSorting()?.length !== 0 ? (
              <TableBody>
                {recordsAfterPagingAndSorting()?.map((item, index) => (
                  <TableRow className={classes.tableColor} key={index}>
                    <StyledTableCell className={classes.tableCell}>{index + 1}</StyledTableCell>
                    <StyledTableCell className={classes.tableCell}>{item?.expenseType}</StyledTableCell>
                    <StyledTableCell className={classes.tableCell}>{item?.billNo}</StyledTableCell>
                    <StyledTableCell className={classes.tableCell}>{item?.prescriptionDate}</StyledTableCell>
                    <StyledTableCell className={classes.tableCell}>{item?.amountClaimed}</StyledTableCell>

                    <StyledTableCell className={classes.tableCell}>
                      <Button
                        variant="contained"
                        className={classes.color}
                        onClick={(e) => {
                          setDateRecords(item);
                          if (item?.billLocation.slice(-4) === ".pdf") {
                            handleDownload(item?.billLocation);
                          } else {
                            setOpen(true);
                          }
                        }}
                        size="small"
                      >
                        Preview
                      </Button>
                    </StyledTableCell>

                    <StyledTableCell className={classes.tableCell}>
                      <Button
                        variant="contained"
                        className={classes.color}
                        onClick={() => {
                          setDateRecords1(item);
                          if (item?.prescriptionLocation.slice(-4) === ".pdf") {
                            handleDownload(item?.prescriptionLocation);
                          } else {
                            setBill(true);
                          }
                        }}
                        size="small"
                      >
                        Preview
                      </Button>
                    </StyledTableCell>

                    <StyledTableCell className={classes.tableCell}>{item?.settleAmount}</StyledTableCell>
                    <StyledTableCell className={classes.tableCell}>{item?.amountPaid}</StyledTableCell>
                    <StyledTableCell className={classes.tableCell}>
                      {item?.deductionRemarks ? item?.deductionRemarks : "--"}
                    </StyledTableCell>
                  </TableRow>
                ))}
              </TableBody>
            ) : (
              <TableBody>
                <TableRow>
                  <TableCell colSpan={6} align="center">
                    No Records Found
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
          </Table>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <br />
              Total Claim Amount: Rs {props?.data?.totalClaimedAmount}
            </Grid>
            {/* <Grid item xs={12}>
              Total Approved Amount: Rs____________
            </Grid> */}
          </Grid>
        </TblContainer>
      </Grid>

      <Dialog onClose={handleClose} open={open} maxWidth="md">
        <DialogTitle id="customized-dialog-title">
          <Grid container spacing={0}>
            <Grid items xs={11} sm={11} md={11}>
              <Typography>
                <b>Preview</b>
              </Typography>
            </Grid>
            <Grid items xs={1}>
              <IconButton aria-label="close" className={classes.closeDialog} onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>

        <DialogContent dividers style={{ maxWidth: "100%", maxHeight: "100%" }}>
          <Grid container spacing={0}>
            <Grid items xs={12} sm={12} md={12}>
              <img
                style={{ maxWidth: "100%", height: "auto" }}
                src={dateRecords?.billLocation}
                alt="Bill Data not found"
              />
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>

      <Dialog onClose={handleClose1} open={bill} maxWidth="md">
        <DialogTitle id="customized-dialog-title">
          <Grid container spacing={0}>
            <Grid items xs={11} sm={11} md={11}>
              <Typography>
                <b>Preview</b>
              </Typography>
            </Grid>
            <Grid items xs={1}>
              <IconButton aria-label="close" className={classes.closeDialog} onClick={handleClose1}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>

        <DialogContent dividers style={{ maxWidth: "100%", maxHeight: "100%" }}>
          <Grid container spacing={0}>
            <Grid items xs={12} sm={12} md={12}>
              <img
                style={{ maxWidth: "100%", height: "auto" }}
                src={dateRecords1?.prescriptionLocation}
                alt="Prescription Data not found"
              />
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
}
