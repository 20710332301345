import React, { useEffect, useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Paper, Grid } from "@material-ui/core";
import BillingTable from "./BillingTable";
//components
import { PatientDetailBox } from "../../components/PatientDetailBox";
import { OTBillingChecklist, OTBillingConsumables, OTBillingEquipments, OTBillingMedicines, OTBillingSurgeryTeam, OTBillingTechnicalTeam } from "pages/Services/URLs";
import axiosInstance from "pages/Services/AxiosInstance";
import { useSelector } from "react-redux";
import { BillPreview } from "./BillPreview";
import { toast } from "react-toastify";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        padding: theme.spacing(0),
    },
    container: {
        background: theme.palette.background.light,
        borderRadius: 6,
        padding: 15,
        height: 560,
    },
    label: {
        fontSize: "0.875rem",
        color: theme.palette.text.primary,
    },
}));

var OTdata =
    [{ "id": 1, "departments": "Surgery Team", "status": "pending", "lastupdate": "27-09-2022 16:00" },
    { "id": 2, "departments": "Technician Team", "status": "pending", "lastupdate": "22-06-2021 04:23" },
    { "id": 3, "departments": "Checklist Charges", "status": "pending", "lastupdate": "22-06-2021 04:23" },
    { "id": 4, "departments": "Inventory Consumable Charges", "status": "pending", "lastupdate": "22-06-2021 04:23" },
    { "id": 5, "departments": "Equipment and Usage Charges", "status": "pending", "lastupdate": "22-06-2021 04:23" },
    { "id": 6, "departments": "Medicine Charges", "status": "pending", "lastupdate": "22-06-2021 04:23" }
    ];

export const BillingActionItem = (props) => {
    const classes = useStyles();
    const theme = useTheme();
    const [openPreview, setOpenPreview] = React.useState(false);
    const [billingItems, setBillingItems] = React.useState([])
    const bookingId = useSelector((store) => store.OTPatients.bookingId);

    const fetchBillingPreview = (URL) => {
        setBillingItems([]);
        axiosInstance
            .get(URL + bookingId)
            .then((res) => {
                setBillingItems(res.data);
                if (res.status === 409) {
                    toast.warning(res.response.data);
                }
            })
            .catch((error) => {
                if (error.response.status === 409) {
                    toast.warning(error.response.data);
                }
                else {
                    toast.error("Something went wrong");
                }
            });
    }

    const setEditdata = (item) => {
        setOpenPreview(!openPreview);
        try {
            item?.departments === "Surgery Team" ? fetchBillingPreview(OTBillingSurgeryTeam) :
                item?.departments === "Technician Team" ?
                    fetchBillingPreview(OTBillingTechnicalTeam) :
                    item?.departments === "Inventory Consumable Charges" ?
                        fetchBillingPreview(OTBillingConsumables) :
                        item?.departments === "Checklist Charges" ?
                            fetchBillingPreview(OTBillingChecklist) :
                            item?.departments === "Equipment and Usage Charges" ?
                                fetchBillingPreview(OTBillingEquipments) :
                                fetchBillingPreview(OTBillingMedicines)

        }
        catch (e) {
            console.log("setEditdata:" + e.message);
        }
    }
    useEffect(() => {
    }, []);

    return (
        <div className={classes.root}>
            <Grid container spacing={2} direction="column">
                <Grid item>
                    <PatientDetailBox />
                </Grid>
                <Grid item>
                    <div className={classes.container}>
                        <BillingTable data={OTdata} setEditdata={setEditdata} />
                    </div>
                </Grid>
            </Grid>
            <BillPreview billingItems={billingItems}
                openPreview={openPreview}
                closePreview={() => setOpenPreview(!openPreview)}
            // onClick={() => executeDelete(item)}
            />
        </div>
    );
}