import React, { useEffect } from "react";
import { Grid, Typography, makeStyles } from "@material-ui/core";
import { toast } from "react-toastify";
import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from "react-hook-form";
import DateFnsUtils from "@date-io/date-fns";
//components
import TextField from "components/TextField";
import Button from "components/Button";
import axiosInstance from "pages/Services/AxiosInstance";
import { DepartmentMstDataURL, OTMstDeleteURL, OTMstURL, SlotGeneratorDataURL, SlotGeneratorURL, } from "pages/Services/URLs";
import { DeleteDialogBox } from "pages/OT/components/DeleteDailogBox";
import { Autocomplete } from "@material-ui/lab";
import { restrict } from "restrict";
import { KeyboardTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        padding: theme.spacing(0),
    },
    label: {
        fontSize: "0.875rem",
        color: theme.palette.text.primary,
    },
}));

export const SlotGeneratorForm = (props) => {
    const classes = useStyles();
    const [slotGenerator, setSlotGenerator] = React.useState([]);

    const defaultValues = {
        startTime: null,
        interval: "",
    }

    const Schema = yup.object().shape({
        startTime: yup.string().nullable("Start Time is required").required("Start Time is required"),
        interval: yup.number().min(30).typeError("Time Interval is required").required("Time Interval is required"),
    });

    const {
        watch,
        handleSubmit,
        reset,
        control,
        setValue,
        resetField,
        getValues,
        clearErrors,
        formState: { errors },
    } = useForm({
        defaultValues,
        resolver: yupResolver(Schema),
        mode: "onChange",
    });

    const resetForm = () => {
        reset(defaultValues
        );
    };

    useEffect(() => {
        fetchSlotGenerator();
    }, []);

    useEffect(() => {
        if (slotGenerator) {
            const formattedTime = moment(slotGenerator?.startTime, "HH:mm:ss").format("ddd DD MMM YYYY hh:mm A");
            setValue("startTime", formattedTime);
            setValue("interval", slotGenerator.interval);
        }
    }, [slotGenerator]);

    const fetchSlotGenerator = () => {
        axiosInstance
            .get(SlotGeneratorDataURL)
            .then((res) => {
                setSlotGenerator(res.data[0]);
            })
            .catch((e) => console.log("fetchSlotGeneratorError:" + e.message));
    }

    const executeSubmit = async (e) => {
        const formattedTime = moment(e.startTime).format("HH:mm");
        const data = {
            id: slotGenerator.id,
            startTime: formattedTime,
            interval: e.interval,
        }
        await axiosInstance
            .post(SlotGeneratorURL, { ...data })
            .then((res) => {
                if (res.status === 200 || res.status === 201) {
                    const strsubmit = "Data Submited Successfully";
                    toast.success(strsubmit);
                    resetForm();
                    fetchSlotGenerator();
                } else if (res.status === 409) {
                    toast.warning(res.response.data);
                }
            })
            .catch((error) => {
                if (error.response.status === 409) {
                    toast.warning(error.response.data);
                }
                else {
                    toast.error("Something went wrong");
                }
            });
    }

    return (
        <div className={classes.root}>
            <form onSubmit={handleSubmit(executeSubmit)}>
                <Grid container spacing={2} alignItems="center" direction="row" >
                    <Grid item >
                        <Typography>Slot Start Time<span style={{ color: "red" }}>*</span></Typography>
                    </Grid>
                    <Grid item xs={12} sm={7} md={3} lg={3}>
                        <Controller
                            name="startTime"
                            control={control}
                            render={({ field: { onChange, value } }) => {
                                return (
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardTimePicker
                                            autoOk
                                            fullWidth
                                            disablePast
                                            size="small"
                                            format="hh:mm a"
                                            placeholder="Select Time"
                                            inputVariant="outlined"
                                            value={value}
                                            onChange={onChange}
                                            KeyboardButtonProps={{ "aria-label": "change date" }}
                                            inputProps={{ disabled: true }}
                                            error={!!errors.startTime}
                                            helperText={errors.startTime?.message}
                                        />
                                    </MuiPickersUtilsProvider>
                                );
                            }}
                        />
                    </Grid>
                    <Grid item >
                        <Typography>Time Interval(Mins)<span style={{ color: "red" }}>*</span></Typography>
                    </Grid>
                    <Grid item xs={12} sm={7} md={3} lg={3}>
                        <Controller
                            name="interval"
                            control={control}
                            render={({ field: { onChange, value } }) => (
                                <TextField
                                    placeholder="Time Interval"
                                    size="small"
                                    variant="outlined"
                                    value={value}
                                    onChange={onChange}
                                    onInput={(e) => { restrict.digits(e) }}
                                    inputProps={{ maxLength: 3 }}
                                    error={!!errors.interval}
                                    helperText={errors.interval?.message}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item container spacing={2} xs={12} sm={3} md={3} lg={2}>
                        <Grid item>
                            <Button
                                align="center"
                                type="submit"
                                color="primary"
                                variant="contained"
                                onClick={handleSubmit(executeSubmit)}
                            >
                                Submit
                            </Button>
                        </Grid>

                        <Grid item>
                            <Button
                                onClick={() => {
                                    resetForm();
                                }}
                                align="center"
                                type="submit"
                                color="primary"
                                variant="outlined"
                            >
                                Reset
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </form >
        </div >
    )
}