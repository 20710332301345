import React, { useState, useEffect } from "react";
import moment from "moment";
//material-ui
import {
  makeStyles,
  IconButton,
  Table,
  TableBody,
  Grid,
  TextField,
  InputAdornment,
  Dialog,
  DialogTitle,
  Typography,
  DialogContent,
  Card,
  Container,
  Box,
} from "@material-ui/core";
import Button from "components/Button";
import axiosInstance from "pages/Services/AxiosInstance";
import { yupResolver } from "@hookform/resolvers/yup";

import { useDispatch, useSelector } from "react-redux";
import TableRow from "@material-ui/core/TableRow";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import LinearProgress from "@material-ui/core/LinearProgress";
import { Search } from "@material-ui/icons";
import CloseIcon from "@material-ui/icons/Close";
import * as yup from "yup";
//components
import useTable, { StyledTableCell, StyledTableRow } from "../../../components/UseTable";
// import TextField from "../../../components/TextField"
import { fetchSubmittedClaims } from "../../../Redux/Actions/ClaimAction";
import ClaimDetailsButtons from "./ClaimDetails";
import GetAppIcon from "@material-ui/icons/GetApp";
import belLogo from "../../../assets/Images/bel_logo.jpg";
import DateFnsUtils from "@date-io/date-fns";
import { Controller, useForm, useFieldArray } from "react-hook-form";
import { grey } from "@material-ui/core/colors";
import { Autocomplete } from "@material-ui/lab";
import { KeyboardDatePicker } from "@material-ui/pickers";

export const startDate = moment(); // Same Date In future they can change the from/Start Date
export const endDate = moment();

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: "100%",
  },
  tableCell: {
    fontSize: "14px",
    border: "0.1px solid #ededed",
    padding: "4px",
    [theme.breakpoints.down("md")]: {
      fontSize: "12px",
    },
  },
  image: {
    objectFit: "contain",
    height: 700,
    width: "100%",
  },
  tableColor: {
    color: "white",
  },
  closeDialog: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(0.5),
    color: theme.palette.grey[500],
  },
  color: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.contrastText,
    "&:hover, &:focus": {
      color: theme.palette.primary.contrastText,
      background: theme.palette.primary.main,
    },
  },

  item: {
    minWidth: 300,
    maxWidth: 200,
    paddingRight: 20,
  },
  itemLabel: {
    minWidth: 100,
  },
  dateContainer: {
    display: "flex",
    justifyContent: "",
    alignItems: "center",
    flexWrap: "wrap",
    marginTop: "15px",
    marginBottom: "15px",
  },
}));

const headCells = [
  //    { id: "id", label: "S.No.", align: "left" },

  { id: "claimRef", label: "Claim Ref No. ", align: "left" },
  { id: "claimDate", label: "Claim date", align: "left" },
  { id: "employeeId", label: "Staff No.", align: "left" },
  { id: "claimYear", label: "Claim Year", align: "left" },
  // { id: "i", label: "Bill Serial No.", align: "left" },
  { id: "type", label: "Type of Expenses", align: "left" },
  { id: "billDate", label: "Bill Date", align: "left" },
  { id: "prescriptionDate", label: "Prescription Date", align: "left" },
  { id: "downloadBill", label: "Download Bill", align: "left" },
  { id: "downloadPres", label: "Download Prescription", align: "left" },
  { id: "amountClaimed", label: "Claim Amount", align: "left" },
  { id: "isSelf", label: "Self/ Spouse", align: "left" },
  { id: "claimStatus", label: "Claim Status", align: "left" },

  // { id: "actions", label: "Actions", disableSorting: true },
];
const arraySchema = yup.object().shape({
  isSelf: yup.string().required("Select Self/Spouse"),
  expenseTypeId: yup.string().required("Expense Type is required"),
  billNo: yup.string().required("Bill No is required"),
  // billDate: yup.date().required("Bill date is required"),
  // prescriptionDate: yup.date().required("Prescription date is required"),
  amountClaimed: yup.string().required("Amount is required"),
  prescriptionDate: yup
    .date()
    .typeError("Invalid Date")
    .nullable()
    .required("Prescription date is Required"),
  billDate: yup
    .date()
    .typeError("Invalid Date")
    .nullable()
    .required("Bill date is Required"),
  medicinebill: yup.string().required("Medicinebill is required. Please select only Image type File"),
  prescriptionBill: yup.string().required("prescriptionBill is required.Please select only Image type File"),
});
export default function PedencyLog({ row }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);

  const [records, setRecords] = useState([]);

  const [filterFn, setFilterFn] = useState({ fn: (items) => items });
  //reducer
  const submittedClaims = useSelector((store) => store.claimReducer.submittedClaims);

  const requestSearch = (e) => {
    let val = e.target.value;
    setFilterFn({
      fn: (records) => {
        if (!val) return records;

        return records.filter(
          (row) =>
            row?.claimRef?.toString().includes(val.toLowerCase()) ||
            moment(row?.claimDate)
              .format("DD.MM.YYYY")
              .includes(val.toLowerCase()) ||
            row?.claimYear?.toString().includes(val.toLowerCase()) ||
            row?.type?.toString().includes(val.toLowerCase()) ||
            row?.billDate?.toLowerCase().includes(val.toLowerCase()) ||
            moment(row?.prescriptionDate)
              .format("DD.MM.YYYY")
              .includes(val.toLowerCase()) ||
            row?.amountClaimed?.toString().includes(val.toLowerCase()) ||
            row?.isSelf?.toLowerCase().includes(val.toLowerCase()) ||
            row?.claimStatus?.toLowerCase().includes(val.toLowerCase())
        );
      },
    });
  };

  const [open, setOpen] = React.useState(false);
  const [openBillDialog, setOpenBillDialog] = React.useState(null);
  const [openPresDialog, setOpenPresDialog] = React.useState(null);

  const [selectedStartDate, setSelectedStartDate] = React.useState(startDate);
  const [endDateError, setEndDateError] = React.useState("");
  const [selectedEndDate, setSelectedEndDate] = React.useState(endDate);
  const [staffNo, setStaffNo] = React.useState("");
  const [allStaffs, setAllStaffs] = React.useState([]);
  const [error, setError] = React.useState("Please select Staff No");

  //const [inputValue, setInputValue] = React.useState("");
  const [inputValueStaff, setInputValueStaff] = React.useState("");
  const [filteredStaff, setFilteredStaff] = React.useState(null);

  const handleStartDateChange = (date) => {
    setSelectedStartDate(date);
    if (moment(date).isSameOrBefore(selectedEndDate, "d")) {
      setEndDateError("");
    } else {
      setEndDateError("Should be greater than start date");
    }
  };
  const handleEndDateChange = (date) => {
    setSelectedEndDate(date);
    if (moment(date).isSameOrAfter(selectedStartDate, "d")) {
      setEndDateError("");
    } else {
      setEndDateError("Should be greater than start date");
    }
  };

  const handleClose = () => {
    setOpen(false);
  };
  const [openPres, setOpenPres] = React.useState(false);
  const handleClosePres = () => {
    setOpenPres(false);
  };
  const fetch = async () => {
    await dispatch(fetchSubmittedClaims());
  };

  const capitalize = (s) => (s && s[0]?.toUpperCase() + s.slice(1)) || "";
  const capitalizeFirstLetter = (string) => {
    const newString = string.charAt(0) + string.slice(1, string.maxLength)?.toLowerCase();
    return newString;
  };

  const {
    register,
    watch,
    handleSubmit,
    reset,
    control,
    setValue,
    getValues,
    // setValues,
    clearErrors,
    formState: { errors },
  } = useForm({
    // defaultValues,
    resolver: yupResolver(arraySchema),
    mode: "onChange",
  });

  useEffect(() => {
    fetch();
  }, []);

  useEffect(() => {
    if (submittedClaims.length !== 0) {
      const mappedSubmittedClaims = submittedClaims.flatMap((el) =>
        el.medicalBillDtos.map((inner) => ({
          ...inner,
          claimId: el.claimId,
          claimDate: el.claimDate,
          claimYear: el.claimYear,
          claimStatus: el.claimStatus,
          employeeId: el.employeeId,
        }))
      );
      setRecords(mappedSubmittedClaims);
    }
  }, [submittedClaims]);

  const { TblContainer, TblHead, TblPagination, recordsAfterPagingAndSorting, getSlNo } = useTable(
    records,
    headCells,
    filterFn,
    15
  );
  const toDataURL = async (url) => {
    const response = await axiosInstance.get(url, { responseType: "blob" });
    const imageDataUrl = URL.createObjectURL(response.data);

    return imageDataUrl;
  };

  const handleDownload = async (urlImage) => {
    const a = document.createElement("a");
    a.href = await toDataURL(urlImage);
    var filenameBefore = urlImage.split("/").pop();

    a.download = filenameBefore;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  return (
    // <Paper>
    <div>
      <Grid>
        <Grid container>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <ClaimDetailsButtons />
          </Grid>
        </Grid>

        <div className={classes.dateContainer}>
          <div className={classes.item}>
            <span className={classes.itemLabel}> Start Date </span>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                autoOk
                fullWidth
                disableToolbar
                disableFuture
                size="small"
                variant="inline"
                format="dd/MM/yyyy"
                id="date-picker-inline"
                inputVariant="outlined"
                value={selectedStartDate}
                onChange={handleStartDateChange}
                KeyboardButtonProps={{ "aria-label": "change date" }}
              />
            </MuiPickersUtilsProvider>
          </div>

          <div className={classes.item}>
            <span className={classes.itemLabel}> End Date </span>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                autoOk
                fullWidth
                disableToolbar
                disableFuture
                variant="inline"
                inputVariant="outlined"
                size="small"
                format="dd/MM/yyyy"
                id="End Date"
                value={selectedEndDate}
                onChange={handleEndDateChange}
                KeyboardButtonProps={{ "aria-label": "change date" }}
                error={!!endDateError.length}
                helperText={endDateError}
              />
            </MuiPickersUtilsProvider>
          </div>

          <div>
            <span className={classes.itemLabel}>Staff No</span>
            <Autocomplete
              size="small"
              options={allStaffs}
              value={filteredStaff}
              className={classes.item}
              fullWidth
              getOptionLabel={(option) => option.deptName}
              filterSelectedOptions
              onChange={(e, v) => {
                setStaffNo(v?.id);
                setFilteredStaff(v);
                if (v) {
                  setError("");
                }
              }}
              renderInput={(params) => (
                <TextField multiline {...params} variant="outlined" placeholder="Search Staff No" />
              )}
              inputValue={inputValueStaff}
              onInputChange={(event, newInputValue) => {
                setInputValueStaff(newInputValue);
              }}
            />
          </div>

          <Grid item lg={4} style={{ marginLeft: "20px", marginTop: "25px" }}>
            <Button
              variant="contained"
              color="primary"
              // onClick={handleSubmit}

              type="submit"
            >
              Submit
            </Button>
          </Grid>
        </div>

        {/* <Grid item xs={12} sm={6} md={6} lg={6} xl={3} align="right"></Grid> */}
        <Grid item xs={12} sm={6} md={6} lg={4} xl={2} align="right">
          <TextField
            placeholder="Search..."
            variant="outlined"
            size="small"
            fullWidth
            style={{ marginBottom: "10px", marginLeft: "1000px" }}
            // className={classes.searchInput}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
            onChange={requestSearch}
          />
        </Grid>
        {/* </div> */}
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center" style={{ margin: "auto", overflowX: "auto" }}>
          <Grid container>
            <TblContainer style={{ maxHeight: "600px" }}>
              {loading && <LinearProgress />}
              <Table
                style={{ position: "relative", border: "1px solid lightgrey" }}
                size="small"
                aria-label="simple table"
                id="data-table-cleanliness-report"
              >
                <TblHead />
                {recordsAfterPagingAndSorting()?.length !== 0 ? (
                  <TableBody>
                    {recordsAfterPagingAndSorting()?.map((item, index) => (
                      <TableRow key={item?.id} className={classes.tableColor}>
                        <StyledTableCell className={classes.tableCell}>{item?.claimId}</StyledTableCell>
                        <StyledTableCell className={classes.tableCell}>
                          {item?.claimDate ? moment(item?.claimDate)?.format("DD.MM.YYYY") : "--"}
                        </StyledTableCell>
                        <StyledTableCell className={classes.tableCell}>{item?.employeeId}</StyledTableCell>
                        <StyledTableCell className={classes.tableCellEmail}>{item?.claimYear}</StyledTableCell>

                        <StyledTableCell>{item?.expenseType} </StyledTableCell>
                        <StyledTableCell>
                          {item?.billDate ? moment(item?.billDate)?.format("DD.MM.YYYY") : "--"}
                        </StyledTableCell>
                        <StyledTableCell>
                          {item?.prescriptionDate ? moment(item?.prescriptionDate)?.format("DD.MM.YYYY") : "--"}
                        </StyledTableCell>
                        <StyledTableCell>
                          <Button
                            variant="contained"
                            className={classes.color}
                            onClick={() => {
                              setOpen(true);
                              setOpenBillDialog(item?.billLocation);
                            }}
                            size="small"
                          >
                            Preview
                          </Button>
                        </StyledTableCell>
                        <StyledTableCell>
                          <Button
                            variant="contained"
                            className={classes.color}
                            onClick={() => {
                              setOpenPres(true);
                              setOpenPresDialog(item?.prescriptionLocation);
                            }}
                            size="small"
                          >
                            Preview
                          </Button>
                        </StyledTableCell>
                        <StyledTableCell>{item?.amountClaimed} </StyledTableCell>
                        <StyledTableCell>{item?.isSelf && item?.isSelf === "t" ? "Self" : "Spouse"} </StyledTableCell>

                        <StyledTableCell>{item?.claimStatus} </StyledTableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                ) : (
                  <TableBody>
                    <StyledTableRow>
                      <StyledTableCell colSpan={8} align="center">
                        No records found
                      </StyledTableCell>
                    </StyledTableRow>
                  </TableBody>
                )}
              </Table>
              {recordsAfterPagingAndSorting()?.length != 0 && recordsAfterPagingAndSorting()?.length != undefined ? (
                <TblPagination />
              ) : (
                ""
              )}
            </TblContainer>
          </Grid>
        </Grid>
      </Grid>

      <Dialog onClose={handleClose} open={open}>
        <DialogTitle id="customized-dialog-title">
          <Grid container spacing={0}>
            <Grid items xs={11} sm={11} md={11}>
              <Typography>
                <b>Preview Bills</b>
              </Typography>
            </Grid>
            <Grid items xs={1}>
              <IconButton aria-label="close" className={classes.closeDialog} onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>

        <DialogContent dividers style={{ maxWidth: "lg" }}>
          <Grid container spacing={0}>
            <Grid items xs={12} sm={12} md={12} lg={12} xl={12} align="center">
              <Card
                style={{
                  border: "1px dashed #2c786c",
                  borderStyle: "dashed",
                  padding: 16,
                }}
              >
                {<img className={classes.image} src={!openBillDialog ? openBillDialog : openBillDialog} alt="Bill" />}
              </Card>
            </Grid>

            <Grid items xs={12} sm={12} md={12} lg={12} xl={12} align="center" style={{ padding: 16 }}>
              <Button
                className={classes.browseText}
                color="secondary"
                variant="contained"
                size="small"
                style={{ color: "white", textTransform: "capitalize" }}
                onClick={() => {
                  handleDownload(openBillDialog);
                }}
                startIcon={<GetAppIcon />}
                download={openBillDialog}
              >
                Download Before Image
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>

      <Dialog onClose={handleClosePres} open={openPres}>
        <DialogTitle id="customized-dialog-title">
          <Grid container spacing={0}>
            <Grid items xs={11} sm={11} md={11}>
              <Typography>
                <b>Preview Prescription</b>
              </Typography>
            </Grid>
            <Grid items xs={1}>
              <IconButton aria-label="close" className={classes.closeDialog} onClick={handleClosePres}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>

        <DialogContent dividers style={{ maxWidth: "lg" }} align="center">
          <Grid container spacing={0}>
            <Grid items xs={12} sm={12} md={12} lg={12} xl={12}>
              <Card
                style={{
                  border: "1px dashed #2c786c",
                  borderStyle: "dashed",
                  padding: 16,
                }}
              >
                {<img className={classes.image} src={!openPresDialog ? belLogo : openPresDialog} alt="Prescription" />}
              </Card>
            </Grid>
            <Grid items xs={12} sm={12} md={12} lg={12} xl={12} align="center" style={{ padding: 16 }}>
              <Button
                className={classes.browseText}
                color="secondary"
                variant="contained"
                size="small"
                style={{ color: "white", textTransform: "capitalize" }}
                onClick={() => {
                  handleDownload(openPresDialog);
                }}
                startIcon={<GetAppIcon />}
                download={openPresDialog}
              >
                Download Before Image
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
    // </Paper>
  );
}
