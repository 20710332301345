import { yupResolver } from "@hookform/resolvers/yup";
import { FormControl, FormControlLabel, FormHelperText, Grid, Radio, RadioGroup, Switch, Typography, makeStyles } from "@material-ui/core";
import * as yup from "yup";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import TextField from "components/TextField";
import { KeyboardDatePicker, KeyboardTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import Button from "components/Button";
import { restrict } from "restrict";
import { BloodRequestSaveURL, OTBookingStatusUpdate } from "pages/Services/URLs";
import axiosInstance from "pages/Services/AxiosInstance";
import { toast } from "react-toastify";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { addSelectedItem } from "Redux/Actions/OTActions/SeletedItem";
import { OTNotes } from "pages/OT/Pages/OTNotes/OTNotes";
import { SELECTED_ACTION_ITEM } from "Redux/Types";
import { fetchScheduledList } from "Redux/Actions/OTActions/OTPatientsAction";
import { SubmitDialogBox } from "pages/OT/components/SubmitDailogBox";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        padding: theme.spacing(1),
    },

    form: {
        padding: theme.spacing(1),
    }
}));


export const BloodRequestTab = (props) => {
    const classes = useStyles();
    const [bloodRequestToggle, setBloodRequestToggle] = React.useState(false);
    const dispatch = useDispatch();
    const [openSubmitBox, setOpenSubmitBox] = React.useState(false);
    const bookingId = useSelector((store) => store.OTPatients.bookingId);
    const updateOTBooking = useSelector((store) => store.OTPatients.updateOTBooking);

    const handleToggle = () => {
        setOpenSubmitBox(!openSubmitBox);
    }
    const defaultValues = {
        bookingId: bookingId ? bookingId : "",
        procedureName: "",
        transfusionReason: "",
        whetherTransfused: false,
        transfusionHistory: "",
        pregnancyHistory: "",
        dateRequirement: null,
        timeRequirement: null,
        hb: "",
        platelet: "",
        ptaptt: "",
        wholeBlood: "",
        packedCell: "",
        freshFrozenPlasma: "",
        plateletConcentrate: "",
        cryoPrecipitate: "",
        singleDonorPlatelet: "",
        cryoPoorPlasma: "",
        specialOthers: "",
    }

    const Schema = yup.object().shape({
        procedureName: yup.string().matches(/^(?!\s)(?!-)(?!.*--)[A-Za-z0-9 -]*$/, 'Only alpha, numeric, "-" are allowed').required("Enter Procedure Name"),
        transfusionReason: yup.string().matches(/^(?!\s)(?!-)(?!.*--)[A-Za-z0-9 -]*$/, 'Only alpha, numeric, "-" are allowed').required("Enter Transfusion Reason"),
        // whetherTransfused: yup.string().typeError("Option is required").required("Choose Yes or No"),
        transfusionHistory: yup.string().matches(/^(?!\s)(?!-)(?!.*--)[A-Za-z0-9 -]*$/, 'Only alpha, numeric, "-" are allowed').required("Enter Transfusion History details"),
        pregnancyHistory: yup.string().matches(/^(?!\s)(?!-)(?!.*--)[A-Za-z0-9 -]*$/, 'Only alpha, numeric, "-" are allowed').required("Enter Pregnancy History details"),
        dateRequirement: yup.date().typeError("Select Date").required("Date is required"),
        timeRequirement: yup.string().typeError("Select Time").required("Time is required"),
        hb: yup.string().required("Enter HB Value"),
        platelet: yup.string().required("Enter Platelet Value"),
        ptaptt: yup.string().required("Enter PT/APTT Value"),
        wholeBlood: yup.string().required("Enter Whole Blood Units"),
        packedCell: yup.string().required("Enter Packed Cell Units"),
        freshFrozenPlasma: yup.string().required("Enter FFP Units"),
        plateletConcentrate: yup.string().required("Enter PC Units"),
        cryoPrecipitate: yup.string().required("Enter CryoPrecipitate Units"),
        singleDonorPlatelet: yup.string().required("Enter SDP Units"),
        cryoPoorPlasma: yup.string().required("Enter CPP Units"),
        specialOthers: yup.string().required("Enter Special Others Units"),
    });

    const {
        handleSubmit,
        reset,
        watch,
        value,
        setValue,
        getValues,
        control,
        formState: { errors },
    } = useForm({
        defaultValues,
        resolver: yupResolver(Schema),
        mode: "onChange",
    });

    const executeBloodRequestSubmit = async (data) => {
        const formattedTime = moment(data.timeRequirement).format("HH:mm");
        data.timeRequirement = formattedTime;
        await axiosInstance
            .post(BloodRequestSaveURL, { ...data })
            .then((res) => {
                if (res.status === 200 || res.status === 201) {
                    //const strsubmit = "Blood Request Added Successfully";
                    if(updateOTBooking === 'reschedule'){
                        executeReschedule();
                    }else{
                        executeSchedule();
                    }
                   // toast.success(strsubmit);
                    resetForm();
                } else if (res.status === 409) {
                    toast.warning(res.response.data);
                }
            })
            .catch((error) => {
                if (error.response.status === 409) {
                    toast.warning(error.response.data);
                }
                else {
                    toast.error("Something went wrong");
                }
            });
    }

    const executeSchedule = async () => {
        await axiosInstance
            .post(OTBookingStatusUpdate, {
                bookingId: bookingId,
                otStatus: "Scheduled"
            })
            .then((res) => {
                if (res.status === 200 || res.status === 201) {
                    const strsubmit = "Patient Scheduled Successfully";
                    toast.success(strsubmit);
                    handleToggle();
                    dispatch(addSelectedItem(<OTNotes />));
                } else if (res.response.status === 409) {
                    toast.warning(res.response.data);
                }
            })
            .catch((error) => {
                toast.error("Something went wrong");
            });
    };

    const executeReschedule = async () => {
        await axiosInstance
            .post(OTBookingStatusUpdate, {
                bookingId: bookingId,
                otStatus: "Scheduled"
            })
            .then((res) => {
                if (res.status === 200 || res.status === 201) {
                    const strsubmit = "Patient Rescheduled Successfully";
                    toast.success(strsubmit);
                    handleToggle();
                    dispatch({ type: SELECTED_ACTION_ITEM, payload: null });
                    dispatch(fetchScheduledList());
                } else if (res.response.status === 409) {
                    toast.warning(res.response.data);
                }
            })
            .catch((error) => {
                toast.error("Something went wrong");
            });
    };

    const resetForm = () => {
        reset(defaultValues);
    };

    return (

        <div className={classes.root}>
            <Grid container spacing={3} alignItems="center">
                <Grid item  >
                    <Typography noWrap >
                        <span style={{ fontWeight: 500 }}>Add Blood Request</span>
                    </Typography>
                </Grid>
                <Grid item >
                    <FormControl component="fieldset">
                        <RadioGroup
                            defaultValue="false"
                            row
                            value={value}
                            onChange={(e) => (setBloodRequestToggle(e.target.value === 'true'))}
                        >
                            <FormControlLabel value="true" control={<Radio />} label="Yes" />
                            <FormControlLabel value="false" control={<Radio />} label="No" />
                        </RadioGroup>

                    </FormControl>

                </Grid>
            </Grid>

            {bloodRequestToggle ?
                <form onSubmit={handleSubmit(executeBloodRequestSubmit)}>
                    <Grid container spacing={2} alignItems="center" className={classes.form}>
                        <Grid item xs={12} sm={12} md={2} lg={3}>
                            <Typography>Name of Operative Procedure<span style={{ color: 'red' }}>*</span> </Typography>
                        </Grid>
                        <Grid item xs={12} sm={7} md={3} lg={3}>
                            <Controller
                                name="procedureName"
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                    <TextField
                                        placeholder="Enter Procedure Name"
                                        size="small"
                                        variant="outlined"
                                        value={value}
                                        onChange={onChange}
                                        onInput={(e) => { restrict.OTInputs(e) }}
                                        error={!!errors.procedureName}
                                        helperText={errors.procedureName?.message}
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} alignItems="center" className={classes.form}>
                        <Grid item xs={12} sm={12} md={2} lg={3}>
                            <Typography>Reason for Transfusion<span style={{ color: 'red' }}>*</span> </Typography>
                        </Grid>
                        <Grid item xs={12} sm={7} md={3} lg={3}>
                            <Controller
                                name="transfusionReason"
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                    <TextField
                                        placeholder="Enter Transfusion Reason"
                                        size="small"
                                        variant="outlined"
                                        value={value}
                                        onChange={onChange}
                                        onInput={(e) => { restrict.OTInputs(e) }}
                                        error={!!errors.transfusionReason}
                                        helperText={errors.transfusionReason?.message}
                                    />
                                )}
                            />
                        </Grid>

                        <Grid item xs={12} sm={12} md={2} lg={3}>
                            <Typography>Whether Previously Transfused<span style={{ color: 'red' }}>*</span> </Typography>
                        </Grid>
                        <Grid item xs={12} sm={7} md={3} lg={3}>
                            <FormControl component="fieldset">
                                <Controller
                                    control={control}
                                    name="whetherTransfused"
                                    render={({ field }) => (
                                        <RadioGroup
                                            row
                                            value={field.value ? '1' : '0'}
                                            onChange={(e) => field.onChange(e.target.value === '1')}
                                        >
                                            <FormControlLabel value="1" control={<Radio />} label="Yes" />
                                            <FormControlLabel value="0" control={<Radio />} label="No" />
                                        </RadioGroup>
                                    )}
                                />
                                <FormHelperText error={!!errors.whetherTransfused}>
                                    {errors.whetherTransfused?.message}
                                </FormHelperText>
                            </FormControl>

                        </Grid>
                    </Grid>
                    <Grid container spacing={2} alignItems="center" className={classes.form}>
                        <Grid item xs={12} sm={12} md={2} lg={3}>
                            <Typography>Any Previous Transfusion Reaction History<span style={{ color: 'red' }}>*</span> </Typography>
                        </Grid>
                        <Grid item xs={12} sm={7} md={3} lg={3}>
                            <Controller
                                name="transfusionHistory"
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                    <TextField
                                        placeholder="Enter Transfusion Reaction History"
                                        size="small"
                                        variant="outlined"
                                        value={value}
                                        onChange={onChange}
                                        onInput={(e) => { restrict.OTInputs(e) }}
                                        error={!!errors.transfusionHistory}
                                        helperText={errors.transfusionHistory?.message}
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} alignItems="center" className={classes.form}>
                        <Grid item xs={12} sm={12} md={2} lg={3}>
                            <Typography>Any Previous Pregnancy with HDFN, still birth, Miscarriage(If Applicable)<span style={{ color: 'red' }}>*</span> </Typography>
                        </Grid>
                        <Grid item xs={12} sm={7} md={3} lg={3}>
                            <Controller
                                name="pregnancyHistory"
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                    <TextField
                                        placeholder="Enter Pregnancy HDFN"
                                        size="small"
                                        variant="outlined"
                                        value={value}
                                        onChange={onChange}
                                        onInput={(e) => { restrict.OTInputs(e) }}
                                        error={!!errors.pregnancyHistory}
                                        helperText={errors.pregnancyHistory?.message}
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} alignItems="center" className={classes.form}>
                        <Grid item xs={12} sm={12} md={2} lg={3}>
                            <Typography> Date of Requirement <span style={{ color: 'red' }}>*</span> </Typography>
                        </Grid>
                        <Grid item xs={12} sm={7} md={3} lg={3}>
                            <Controller
                                name="dateRequirement"
                                control={control}
                                defaultValue={defaultValues.dateRequirement}
                                render={({ field: { onChange, value } }) => {
                                    return (
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <KeyboardDatePicker
                                                autoOk
                                                fullWidth
                                                disablePast
                                                size="small"
                                                format="yyyy-MM-dd"
                                                placeholder="Select Date"
                                                inputVariant="outlined"
                                                value={value}
                                                onChange={onChange}
                                                KeyboardButtonProps={{ "aria-label": "change date" }}
                                                inputProps={{ disabled: true }}
                                                error={!!errors.dateRequirement}
                                                helperText={errors.dateRequirement?.message}
                                            />
                                        </MuiPickersUtilsProvider>
                                    );
                                }}
                            />
                        </Grid>

                        <Grid item xs={12} sm={12} md={2} lg={2}>
                            <Typography>Time of Requirement <span style={{ color: 'red' }}>*</span> </Typography>
                        </Grid>
                        <Grid item xs={12} sm={7} md={3} lg={3}>
                            <Controller
                                name="timeRequirement"
                                control={control}
                                render={({ field: { onChange, value } }) => {
                                    return (
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <KeyboardTimePicker
                                                autoOk
                                                fullWidth
                                                disablePast
                                                size="small"
                                                format="hh:mm a"
                                                placeholder="Select Time"
                                                inputVariant="outlined"
                                                value={value}
                                                onChange={onChange}
                                                KeyboardButtonProps={{ "aria-label": "change date" }}
                                                inputProps={{ disabled: true }}
                                                error={!!errors.timeRequirement}
                                                helperText={errors.timeRequirement?.message}
                                            />
                                        </MuiPickersUtilsProvider>
                                    );
                                }}
                            />
                        </Grid>
                    </Grid>

                    <Grid container spacing={5} className={classes.form}>
                        <Grid item xs={12}>
                            <Typography> <span style={{ fontWeight: 600 }}>Investigation Values: </span></Typography>
                        </Grid>
                    </Grid>

                    <Grid container spacing={4} xs={12} alignItems="center" className={classes.form} justifyContent="center">
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                            <Typography>HB(gm/dl)<span style={{ color: "red" }}>*</span></Typography>
                            <Controller
                                name="hb"
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                    <TextField
                                        placeholder="Enter HB Value"
                                        size="small"
                                        variant="outlined"
                                        value={value}
                                        onInput={(e) => { restrict.bloodRequest(e) }}
                                        onChange={onChange}
                                        error={!!errors.hb}
                                        helperText={errors.hb?.message}
                                    />
                                )}
                            />
                        </Grid>


                        <Grid item xs={12} sm={6} md={4} lg={3}>
                            <Typography> Platelet(cumm) <span style={{ color: 'red' }}>*</span>  </Typography>
                            <Controller
                                name="platelet"
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                    <TextField
                                        placeholder="Enter Platelet Value"
                                        size="small"
                                        variant="outlined"
                                        value={value}
                                        onChange={onChange}
                                        onInput={(e) => { restrict.bloodRequest(e) }}
                                        error={!!errors.platelet}
                                        helperText={errors.platelet?.message}
                                    />
                                )}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6} md={4} lg={3}>
                            <Typography> PT/APTT(Sec) <span style={{ color: 'red' }}>*</span>  </Typography>
                            <Controller
                                name="ptaptt"
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                    <TextField
                                        placeholder="Enter PT/APTT Value"
                                        size="small"
                                        variant="outlined"
                                        value={value}
                                        onChange={onChange}
                                        onInput={(e) => { restrict.bloodRequest(e) }}
                                        error={!!errors.ptaptt}
                                        helperText={errors.ptaptt?.message}
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>

                    <Grid container spacing={5} className={classes.form}>
                        <Grid item xs={12}>
                            <Typography> <span style={{ fontWeight: 600 }}>Required Blood Unit's: </span></Typography>
                        </Grid>
                    </Grid>

                    <Grid container spacing={4} xs={12} alignItems="center" className={classes.form} justifyContent="center">

                        <Grid item xs={12} sm={6} md={4} lg={3}>
                            <Typography>Whole Blood<span style={{ color: "red" }}>*</span></Typography>
                            <Controller
                                name="wholeBlood"
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                    <TextField
                                        placeholder="Enter in Units"
                                        size="small"
                                        variant="outlined"
                                        value={value}
                                        onChange={onChange}
                                        onInput={(e) => { restrict.bloodRequest(e) }}
                                        error={!!errors.wholeBlood}
                                        helperText={errors.wholeBlood?.message}
                                    />
                                )}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6} md={4} lg={3}>
                            <Typography> Packed cell(RCC/RBC) <span style={{ color: 'red' }}>*</span>  </Typography>
                            <Controller
                                name="packedCell"
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                    <TextField
                                        placeholder="Enter in Units"
                                        size="small"
                                        variant="outlined"
                                        value={value}
                                        onChange={onChange}
                                        onInput={(e) => { restrict.bloodRequest(e) }}
                                        error={!!errors.packedCell}
                                        helperText={errors.packedCell?.message}
                                    />
                                )}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6} md={4} lg={3}>
                            <Typography> Fresh Frozen Plasma(FFP) <span style={{ color: 'red' }}>*</span>  </Typography>
                            <Controller
                                name="freshFrozenPlasma"
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                    <TextField
                                        placeholder="Enter in Units"
                                        size="small"
                                        variant="outlined"
                                        value={value}
                                        onChange={onChange}
                                        onInput={(e) => { restrict.bloodRequest(e) }}
                                        error={!!errors.freshFrozenPlasma}
                                        helperText={errors.freshFrozenPlasma?.message}
                                    />
                                )}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6} md={4} lg={3}>
                            <Typography> Platelet Concentrate(PC) <span style={{ color: 'red' }}>*</span>  </Typography>
                            <Controller
                                name="plateletConcentrate"
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                    <TextField
                                        placeholder="Enter in Units"
                                        size="small"
                                        variant="outlined"
                                        value={value}
                                        onChange={onChange}
                                        onInput={(e) => { restrict.bloodRequest(e) }}
                                        error={!!errors.plateletConcentrate}
                                        helperText={errors.plateletConcentrate?.message}
                                    />
                                )}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6} md={4} lg={3}>
                            <Typography>CryoPrecipitate<span style={{ color: "red" }}>*</span></Typography>
                            <Controller
                                name="cryoPrecipitate"
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                    <TextField
                                        placeholder="Enter in Units"
                                        size="small"
                                        variant="outlined"
                                        value={value}
                                        onChange={onChange}
                                        onInput={(e) => { restrict.bloodRequest(e) }}
                                        error={!!errors.cryoPrecipitate}
                                        helperText={errors.cryoPrecipitate?.message}
                                    />
                                )}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6} md={4} lg={3}>
                            <Typography> Single Donor Platelet(SDP) <span style={{ color: 'red' }}>*</span>  </Typography>
                            <Controller
                                name="singleDonorPlatelet"
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                    <TextField
                                        placeholder="Enter in Units"
                                        size="small"
                                        variant="outlined"
                                        value={value}
                                        onChange={onChange}
                                        onInput={(e) => { restrict.bloodRequest(e) }}
                                        error={!!errors.singleDonorPlatelet}
                                        helperText={errors.singleDonorPlatelet?.message}
                                    />
                                )}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6} md={4} lg={3}>
                            <Typography> Cryo Poor Plasma(CPP)<span style={{ color: 'red' }}>*</span>  </Typography>
                            <Controller
                                name="cryoPoorPlasma"
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                    <TextField
                                        placeholder="Enter in Units"
                                        size="small"
                                        variant="outlined"
                                        value={value}
                                        onChange={onChange}
                                        onInput={(e) => { restrict.bloodRequest(e) }}
                                        error={!!errors.cryoPoorPlasma}
                                        helperText={errors.cryoPoorPlasma?.message}
                                    />
                                )}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6} md={4} lg={3}>
                            <Typography> Special/Others <span style={{ color: 'red' }}>*</span>  </Typography>
                            <Controller
                                name="specialOthers"
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                    <TextField
                                        placeholder="Enter in Units"
                                        size="small"
                                        variant="outlined"
                                        value={value}
                                        onChange={onChange}
                                        onInput={(e) => { restrict.bloodRequest(e) }}
                                        error={!!errors.specialOthers}
                                        helperText={errors.specialOthers?.message}
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>
                </form> : null
            }


            <Grid container spacing={4} justifyContent='center'
                style={{ marginTop: 10 }}>
                <Grid item>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={props.onClickBack}
                    >
                        Back
                    </Button>
                </Grid>
                <Grid item >
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={handleToggle}
                    >
                        Submit
                    </Button>
                </Grid>
            </Grid>
            {
                bloodRequestToggle ?
                    <SubmitDialogBox openSubmitBox={openSubmitBox}
                        closeSubmitBox={handleToggle}
                        onClick={handleSubmit(executeBloodRequestSubmit) }
                    /> :
                    <SubmitDialogBox openSubmitBox={openSubmitBox}
                        closeSubmitBox={handleToggle}
                        onClick={()=>updateOTBooking === 'reschedule' ? executeReschedule() : executeSchedule()}
                    />
            }

        </div>

    )
}
