import { yupResolver } from "@hookform/resolvers/yup";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { restrict } from "restrict";
import * as yup from "yup";
import configData from "../../config.json";
import { useTranslation } from "react-i18next";
import CryptoJS from "crypto-js";
import axiosInstance from "pages/Services/AxiosInstance";
//icons
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Grid, InputAdornment, Typography, makeStyles } from "@material-ui/core";
import { Lock, Person, Visibility, VisibilityOff, Phone } from "@material-ui/icons";
//pages
import { saveReg } from "pages/Services/URLs";
import Button from "components/Button";
import TextField from "components/TextField";
import { Email } from "@material-ui/icons";
import { emailRegex } from "NewLayout/ProfilePage";




const useStyles = makeStyles((theme) => ({
    btn: {
        fontSize: "16px",
        textTransform: "none",
        // marginRight: "10%",
        marginTop: 20,
        justifyContent: "center",
        alignContent: "center",
        display: "flex",
        // backgroundColor: "#fd7e14",
        // color: theme.palette.common.white,
        // "&:hover, &:focus": {
        //     color: theme.palette.common.white,
        //     background: "#E26A2C",
        // },
    },
    heading: {
        fontSize: "1.5rem",
        paddingBottom: 24,
        color: "#15133C",
        [theme.breakpoints.down("xs")]: {
            fontSize: "1.2rem",
        },
    },
    text: {
        paddingBottom: 12,
    },
    card: {
        padding: "105px 58px 105px 58px",
        display: "flex",
        flexDirection: "column",
        [theme.breakpoints.down("xs")]: {
            padding: "18px",
        },
        [theme.breakpoints.down("sm")]: {
            padding: "12px",
        },
    },
    buttonProgress: {
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12,
    },
}));

export const encryptFn = (data) => {
    const encryptionSecret = configData.encryptionSecret;
    var key = CryptoJS.enc.Latin1.parse(encryptionSecret);
    var iv = CryptoJS.enc.Latin1.parse(encryptionSecret);
    var encrypted = CryptoJS.AES.encrypt(data, key, {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.ZeroPadding,
    });
    return encrypted.toString();
};


export default function RegistrationCitizen() {
    const classes = useStyles();
    const { t } = useTranslation();
    const [showPassword, setShowPassword] = React.useState(false);
    const [showPassword1, setShowPassword1] = React.useState(false);
    const [open,setOpen] = React.useState(false);
    const schema = yup.object().shape({
        firstname: yup
            .string()
            .typeError("First Name is required")
            .required("First Name is required"),
        lastname: yup
            .string()
            .typeError("Last Name is required")
            .required("Last Name is required"),
        emailId: yup
            .string()
            .email("Email Should be valid").matches(emailRegex, 'Invalid Email')
            .required("Email should be valid"),
        phoneNumber: yup
            .string()
            // .matches(/^[7-9]\d{9}$/, "Mobile number must be start with 7,8 and 9")
            .required("Mobile Number is required"),
        // staffName: yup
        //     .string()
        //     .typeError("Staff id is required")
        //     .required("Staff id is required"),
        username: yup
            .string()
            .trim()
            .typeError("User Name is required")
            .required("User Name is required"),

        password: yup
            .string()
            .required("Password is required")
            .min(8, 'Password must be 8 characters long')
            .matches(/[0-9]/, 'Password requires a number')
            .matches(/[a-z]/, 'Password requires a lowercase letter')
            .matches(/[A-Z]/, 'Password requires an uppercase letter')
            .matches(/[^\w]/, 'Password requires a symbol'),

        confirmPwd: yup
            .string()
            .oneOf([yup.ref("password"), null], "Passwords must match").required("Confirm Password is required"),
    });

    const handleCloseReg = () => {
        setOpen(false);
      };

    const defaultValues = {
        id: 0,
        firstname: "",
        lastname: "",
        phoneNumber: "",
        emailId: "",
        staffName: null,
        gender: "",
        dob: "",
        address: "",
        employeeRoleId: "",
        username: "",
        password: "",
        confirmPwd: "",
    }
    const {
        register,
        watch,
        handleSubmit,
        reset,
        control,
        setValue,
        getValues,
        clearErrors,
        formState: { errors },
    } = useForm({
        defaultValues,
        resolver: yupResolver(schema),
    });

    const savesubmit = async (data) => {

        const encryptedUserName = await encryptFn(data.username);
        const encryptedPassword = await encryptFn(data.password);
        const encryptedConfirmPwd = await encryptFn(data.confirmPwd);
        const data_ = {
            id: 0,
            username: encryptedUserName,
            password: encryptedPassword,
            phoneNumber: parseInt(data.phoneNumber),
            staffName: data.firstname + data.lastname,
            emailId: data.emailId,
            confirmPwd: encryptedConfirmPwd,
        }
        await axiosInstance
            .post(saveReg, data_).then((res) => {
                if (res.status === 200 || res.status === 201) {
                    console.log("Submitted", data)
                    toast.success("User Registered Successfully");
                }

            }).catch((error) => {
                if (error.response.status !== 200) {
                    console.log("Conflicted")
                    toast.warning(error.response.data);
                }
                else {
                    toast.error("Something went wrong");
                }

            })
            handleCloseReg();
    }
    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleClickShowPassword1 = () => {
        setShowPassword1(!showPassword1);
    };

    return (

        <Box>
            <form onSubmit={handleSubmit(savesubmit)}>
                <h3 id="customized-dialog-title"   >User Details</h3>
                <Box sx={{ border: "1px", p: 2 }}>
                    <Grid container spacing={2}>
                        <Grid item container spacing={2}>
                            <Grid item xs={6}>
                                <Typography>First Name <span style={{ color: "red" }}>*</span> :</Typography>
                                <Controller
                                    name="firstname"
                                    control={control}
                                    render={({ field: { onChange, value } }) => {
                                        return (
                                            <TextField
                                                placeholder={"First Name"}

                                                variant="outlined"
                                                inputProps={{ minLength: 2, maxLength: 50 }}
                                                onInput={restrict.alphanumeric}
                                                value={value}
                                                onChange={onChange}
                                                error={!!errors.firstname}
                                                helperText={errors.firstname?.message}
                                                InputProps={{
                                                    classes: {
                                                        input: classes.inputSize,
                                                    },

                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <Person />
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                        );
                                    }}
                                ></Controller>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography>Last Name<span style={{ color: "red" }}>*</span> :</Typography>
                                <Controller
                                    name="lastname"
                                    control={control}
                                    render={({ field: { onChange, value } }) => {
                                        return (
                                            <TextField
                                                placeholder={"Last Name"}
                                                // fullWidth
                                                variant="outlined"
                                                //   disabled={isDirty}
                                                inputProps={{ minLength: 2, maxLength: 50 }}
                                                onInput={restrict.alphanumeric}
                                                value={value}
                                                onChange={onChange}
                                                error={!!errors.lastname}
                                                helperText={errors.lastname?.message}
                                                InputProps={{
                                                    classes: {
                                                        input: classes.inputSize,
                                                    },

                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <Person />
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                        );
                                    }}
                                ></Controller>
                            </Grid>
                        </Grid>
                        {/* <Grid item xs={12} >
                                <Controller
                                    name="staffName"
                                    control={control}
                                    render={({ field: { onChange, value } }) => {
                                        return (
                                            <TextField
                                                label={"Staff Name"}
                                                placeholder={"Staff Name"}
                                                
                                                fullWidth
                                                variant="outlined"
                                                inputProps={{ minLength: 2, maxLength: 50 }}
                                                onInput={restrict.alphanumeric}
                                                value={value}
                                                onChange={onChange}
                                                error={!!errors.staffName}
                                                helperText={errors.staffName?.message}
                                            />
                                        );
                                    }}
                                ></Controller>
                            </Grid> */}
                        <Grid item xs={12} >
                            <Typography>Phone Number<span style={{ color: "red" }}>*</span> :</Typography>
                            <Controller
                                name="phoneNumber"
                                control={control}
                                render={({ field: { onChange, value } }) => {
                                    return (
                                        <TextField
                                            placeholder={"Phone Number"}
                                            fullWidth
                                            variant="outlined"
                                            //   disabled={isDirty}
                                            inputProps={{ maxLength: 10 }}
                                            onInput={restrict.digits}
                                            value={value}
                                            onChange={onChange}
                                            error={!!errors.phoneNumber}
                                            helperText={errors.phoneNumber?.message}
                                            InputProps={{
                                                classes: {
                                                    input: classes.inputSize,
                                                },

                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <Phone />
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    );
                                }}
                            ></Controller>
                        </Grid>
                        <Grid item xs={12} >
                            <Typography>Email Id<span style={{ color: "red" }}>*</span> :</Typography>
                            <Controller
                                name="emailId"
                                control={control}
                                render={({ field: { onChange, value } }) => {
                                    return (
                                        <TextField
                                            placeholder={"Email Id"}
                                            fullWidth
                                            variant="outlined"
                                            inputProps={{ maxLength: 25 }}
                                            value={value}
                                            onChange={onChange}
                                            error={!!errors.emailId}
                                            helperText={errors.emailId?.message}
                                            InputProps={{
                                                classes: {
                                                    input: classes.inputSize,
                                                },

                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <Email />
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    );
                                }}
                            ></Controller>
                        </Grid>
                    </Grid>
                </Box>
                <h3>Login Details</h3>
                <Box sx={{ border: "1px ", p: 2 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} >
                            <Typography>User Name<span style={{ color: "red" }}>*</span> :</Typography>
                            <Controller
                                name="username"
                                control={control}
                                render={({ field: { onChange, value } }) => {
                                    return (
                                        <TextField
                                            placeholder={"User Name"}
                                            fullWidth
                                            variant="outlined"
                                            //   disabled={isDirty}
                                            inputProps={{ minLength: 2, maxLength: 50 }}
                                            onInput={restrict.alphanumeric}
                                            value={value}
                                            onChange={onChange}
                                            error={!!errors.username}
                                            helperText={errors.username?.message}
                                            InputProps={{
                                                classes: {
                                                    input: classes.inputSize,
                                                },

                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <Person />
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    );
                                }}
                            ></Controller>
                        </Grid>
                        <Grid item xs={12} >
                            <Typography>Password<span style={{ color: "red" }}>*</span> :</Typography>
                            <Controller
                                name="password"
                                control={control}
                                render={({ field: { onChange, value } }) => {
                                    return (
                                        <TextField
                                            placeholder={"Password"}
                                            fullWidth
                                            variant="outlined"
                                            //   disabled={isDirty}
                                            // type="password"
                                            inputProps={{ minLength: 2, maxLength: 50 }}
                                            onInput={restrict.alphanumeric}
                                            value={value}
                                            onChange={onChange}
                                            type={showPassword ? "text" : "password"}
                                            InputProps={{

                                                endAdornment: (
                                                    <InputAdornment position="start">
                                                        {showPassword ? (
                                                            <Visibility style={{ cursor: "pointer" }} onClick={handleClickShowPassword} />
                                                        ) : (
                                                            <VisibilityOff style={{ cursor: "pointer" }} onClick={handleClickShowPassword} />
                                                        )}
                                                    </InputAdornment>
                                                ),
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <Lock />
                                                    </InputAdornment>
                                                ),
                                            }}
                                            error={!!errors.password}
                                            helperText={errors.password?.message}
                                        />
                                    );
                                }}
                            ></Controller>
                        </Grid>
                        <Grid item xs={12} >
                            <Typography>Confirm Password <span style={{ color: "red" }}>*</span> :</Typography>
                            <Controller
                                name="confirmPwd"
                                control={control}
                                render={({ field: { onChange, value } }) => {
                                    return (
                                        <TextField
                                            placeholder={"Confirm Password"}
                                            fullWidth
                                            variant="outlined"
                                            //   disabled={isDirty}
                                            // type="password"
                                            inputProps={{ minLength: 2, maxLength: 50 }}
                                            onInput={restrict.alphanumeric}
                                            value={value}
                                            onChange={onChange}
                                            type={showPassword1 ? "text" : "password"}
                                            InputProps={{

                                                endAdornment: (
                                                    <InputAdornment position="start">
                                                        {showPassword1 ? (
                                                            <Visibility style={{ cursor: "pointer" }} onClick={handleClickShowPassword1} />
                                                        ) : (
                                                            <VisibilityOff style={{ cursor: "pointer" }} onClick={handleClickShowPassword1} />
                                                        )}
                                                    </InputAdornment>
                                                ),
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <Lock />
                                                    </InputAdornment>
                                                ),
                                            }}
                                            error={!!errors.confirmPwd}
                                            helperText={errors.confirmPwd?.message}
                                        />
                                    );
                                }}
                            ></Controller>
                        </Grid>
                    </Grid>
                </Box>
                <DialogActions>
                    <Grid container justifyContent="center">
                        <Button autoFocus variant="contained" type="submit" color="primary" >
                            Submit
                        </Button>
                    </Grid>
                </DialogActions>
            </form>
        </Box>
    )
}

