import React, { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import { makeStyles } from "@material-ui/core/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUsers } from "@fortawesome/free-solid-svg-icons";
import { useTheme } from "@material-ui/core/styles";
import { CallEndRounded, Laptop, VideoCallRounded } from "@material-ui/icons";

import { Box, Dialog, DialogContent, Fab, Grid } from "@material-ui/core";

//components
import { DateTime } from "components/Greeting";
import Slider from "./Slider";
import InfoCard from "./InfoCard";
import PatientDetailsCard from "./PatientDetailsCard";
import Button from "components/Button";
import Consultation from "./Consultation";
import axiosInstance from "pages/Services/AxiosInstance";
import ConsultationHistory from "pages/Citizen/ConsultationHistory";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import AllPatients from "./AllPatients";
import { toast } from "react-toastify";
import { getDoctorStatsUrl } from "pages/Services/URLs";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
      display: "flex",
      justifyContent: "center",
    },
  },
  fab: {
    backgroundColor: theme.palette.primary.dark,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },
  },
  icon: {
    color: theme.palette.primary.contrastText,
  },
  box: {
    display: "flex",
    justifyContent: "space-between",
  },
  infoCard: {
    display: "flex",
    justifyContent: "center",
  },
  btn: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    "&:hover, &:focus": {
      color: theme.palette.primary.contrastText,
      background: theme.palette.primary.dark,
    },
  },
  backbtn: {
    marginTop: "10px",
    marginLeft: "10px",
  },
}));

export default function Doctor() {
  const classes = useStyles();
  const theme = useTheme();
  const patientDetails = useSelector(
    (store) => store.PatientDetails.patientDetails
  );
  const [openSlider, setOpenSlider] = useState(false);
  const [openVideo, setOpenVideo] = useState(false);
  const [openPastHistory, setOpenPastHistory] = React.useState(false);
  const [openAllPatientHistory, setOpenAllPatientHistory] = React.useState(
    false
  );

  const onlineData = [
    {
      title: "Online",
      value: "",
      color: theme.palette.infocard.green.dark,
      bgcolor: theme.palette.infocard.green.light,
    },
  ];
  const offlineData = [
    {
      title: "Offline",
      value: "",
      color: theme.palette.infocard.red.dark,
      bgcolor: theme.palette.infocard.red.light,
    },
  ];
  const pendingData = [
    {
      title: "Pending",
      value: "",
      color: theme.palette.infocard.brown.dark,
      bgcolor: theme.palette.infocard.brown.light,
    },
  ];
  const regData = [
    {
      title: "Registered ",
      value: "",
      color: theme.palette.infocard.blue.dark,
      bgcolor: theme.palette.infocard.blue.light,
    },
  ];

  const onClickHandler = () => {
    setOpenVideo(true);
    window.scrollBy(0, 250);
  };

  const findPatientHistory = () => {
    console.log("fgdkjfhjk");
    setOpenAllPatientHistory(true);
  };

  const onClickHistory = () => {
    setOpenPastHistory(true);
  };
  const handleBackClick = () => {
    setOpenPastHistory(false);
    setOpenAllPatientHistory(false);
  };
  const user =
    useSelector((store) => store.loggedInUser) ||
    JSON.parse(localStorage.getItem("User"));

  const [counts, setCounts] = React.useState("");

  const getData = async () => {
    axiosInstance
      .get(getDoctorStatsUrl + user?.empId)
      .then((res) => {
        setCounts(res.data);
      })
      .catch((error) => {
        toast.error("Something went wrong");
      });
  };

  useEffect(() => {
    getData();
  }, []);
  console.log(patientDetails);
  return (
    <div className={classes.root}>
      <Slider
        openSlider={openSlider}
        closeSlider={() => setOpenSlider(!openSlider)}
      />
      <Box className={classes.box}>
        <Fab
          size="medium"
          className={classes.fab}
          variant="circular"
          onClick={() => setOpenSlider(!openSlider)}
        >
          <FontAwesomeIcon size="lg" icon={faUsers} className={classes.icon} />
        </Fab>
        <DateTime />
      </Box>

      {openPastHistory === false && openAllPatientHistory === false ? (
        <Box>
          <Grid container spacing={2} justifyContent="center">
            <Grid item xs={12} sm={12} md={12} lg={8}>
              <PatientDetailsCard patientDetails={patientDetails} />
              <Box
                style={{
                  padding: 12,
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {patientDetails !== null &&
                patientDetails?.isTeleConsultation == true ? (
                  openVideo === false ? (
                    <>
                      {" "}
                      <Button
                        onClick={onClickHandler}
                        startIcon={<VideoCallRounded />}
                        className={classes.btn}
                        variant="contained"
                      >
                        Join
                      </Button>{" "}
                    </>
                  ) : (
                    <>
                      <Button
                        onClick={() => setOpenVideo(false)}
                        startIcon={<CallEndRounded />}
                        color="secondary"
                        variant="contained"
                      >
                        End Call
                      </Button>
                    </>
                  )
                ) : (
                  ""
                )}

                {patientDetails !== null &&
                  patientDetails?.isTeleConsultation == false && (
                    <>
                      {" "}
                      <Button
                        onClick={onClickHandler}
                        startIcon={<Laptop />}
                        className={classes.btn}
                        variant="contained"
                      >
                        Continue
                      </Button>{" "}
                    </>
                  )}
                {/* {patientDetails?.isTeleConsultation === false && (
                <Button
                  onClick={onClickHandler}
                  className={classes.btn}
                  variant="contained"
                >
                  Continue
                </Button>
              )} */}
              </Box>
              <Box>
                <Button
                  onClick={findPatientHistory}
                  className={classes.btn}
                  variant="contained"
                >
                  Find Patients
                </Button>
              </Box>
              {/* <Box style={{ display: "flex", justifyContent: "right" }}>
                <Button
                  onClick={onClickHistory}
                  className={classes.btn}
                  variant="contained"
                >
                  Past History Details
                </Button>
              </Box> */}
            </Grid>
            <Grid item xs={12} sm={12} md={8} lg={3}>
              <Grid container spacing={2}>
                {onlineData.map((data, index) => (
                  <Grid item xs={6} sm={6} md={3} lg={6} key={index}>
                    <InfoCard
                      title={data.title}
                      value={counts?.online_patient}
                      color={data.color}
                      bgcolor={data.bgcolor}
                    />
                  </Grid>
                ))}
                {offlineData.map((data, index) => (
                  <Grid item xs={6} sm={6} md={3} lg={6} key={index}>
                    <InfoCard
                      title={data.title}
                      value={counts?.offline_patient}
                      color={data.color}
                      bgcolor={data.bgcolor}
                    />
                  </Grid>
                ))}
                {pendingData.map((data, index) => (
                  <Grid item xs={6} sm={6} md={3} lg={6} key={index}>
                    <InfoCard
                      title={data.title}
                      value="0"
                      color={data.color}
                      bgcolor={data.bgcolor}
                    />
                  </Grid>
                ))}
                {regData.map((data, index) => (
                  <Grid item xs={6} sm={6} md={3} lg={6} key={index}>
                    <InfoCard
                      title={data.title}
                      value={counts?.register_patient}
                      color={data.color}
                      bgcolor={data.bgcolor}
                    />
                  </Grid>
                ))}
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={8} lg={11}>
              {openVideo && (
                <Consultation
                  meetId={patientDetails?.meetId}
                  isOnline={patientDetails?.isTeleConsultation}
                />
              )}
            </Grid>
          </Grid>
        </Box>
      ) : (
        <>
          {" "}
          <Button
            color="secondary"
            onClick={handleBackClick}
            variant="contained"
            startIcon={<ArrowBackIcon />}
          >
            Back
          </Button>
          <Box>
            <Grid container spacing={2} justifyContent="center">
              <Grid item xs={12} sm={12} md={12} lg={8}>
                {/* <ConsultationHistory patientDetails={patientDetails} />    */}
                <AllPatients />
              </Grid>
            </Grid>
          </Box>
        </>
      )}
    </div>
  );
}
