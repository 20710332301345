import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from '@material-ui/core/styles';
import { Avatar, Typography, Grid, Divider } from "@material-ui/core";
import { Person } from "@material-ui/icons";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(0),
    },
    container: {
        background: theme.palette.background.light,
        borderRadius: 6,
        padding: 6,
        paddingInline: 12,
    },
    responsive: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        [theme.breakpoints.down("xs")]: {
            justifyContent: "center",
            alignItems: "center",
        },
    },
    label: {
        fontSize: "0.875rem",
        color: theme.palette.text.primary,
    },
}));

export const PatientDetailBox = (props) => {
    const classes = useStyles();
    const theme = useTheme();
    const patientsDetails = useSelector((store) => store.OTPatients.patientDetails);
    const updateOTBooking = useSelector((store) => store.OTPatients.updateOTBooking);
    const selectedItem = useSelector((store) => store.selectedItem.item);

    // const calculateAge = dob => {
    //     const birthDate = new Date(dob);
    //     const difference = Date.now() - birthDate.getTime();
    //     const age = new Date(difference);
    //     console.log(dob);
    //     console.log(birthDate);
    //     console.log(Math.abs(age.getUTCFullYear() - 1970));
    //     return Math.abs(age.getUTCFullYear() - 1970);
    // }

    return (
        <div className={classes.root}>
            <div className={classes.container}>
                <Grid container
                    className={classes.responsive}
                    alignItems="center" spacing={2} justifyContent="center" >
                    <Grid item >
                        <Grid container spacing={2}
                            alignItems="center" justifyContent="center">
                            <Grid item >
                                <Avatar><Person /></Avatar>
                            </Grid>
                            <Grid item>
                                <Grid container direction="column">
                                    <Grid item >
                                        <Typography noWrap
                                            style={{ fontSize: "1rem", }}
                                            className={classes.label}>
                                            <span style={{ fontWeight: 500 }}>
                                                {patientsDetails?.staffName}</span>
                                        </Typography>
                                    </Grid>
                                    <Grid item >
                                        {/* calculateAge(moment(patientsDetails?.dob).format("DD-MM-YYYY")) */}
                                        {patientsDetails?.age ?
                                            patientsDetails?.age[0] === 0 && patientsDetails?.age[1] !== 0 ? (
                                            <Typography noWrap className={classes.label}>
                                                {patientsDetails?.age[1] === 1 ?
                                                    patientsDetails?.age[1] + " month" :
                                                    patientsDetails?.age[1] + " months"
                                                    } / {patientsDetails?.gender ? patientsDetails?.gender : ""}
                                            </Typography>
                                        ) : patientsDetails?.age[0] === 0 && patientsDetails?.age[1] === 0 ? (
                                            <Typography noWrap className={classes.label}>
                                                {patientsDetails?.age[2] === 1 ?
                                                    patientsDetails?.age[2] + " day" :
                                                    patientsDetails?.age[2] + " days"
                                                } / {patientsDetails?.gender ? patientsDetails?.gender : ""}

                                            </Typography>
                                        ) : (
                                            <Typography noWrap className={classes.label}>
                                                {patientsDetails?.age[0] === 1 ?
                                                    patientsDetails?.age[0] + " year" :
                                                    patientsDetails?.age[0] + " years"
                                                } / {patientsDetails?.gender ? patientsDetails?.gender : ""}
                                            </Typography>
                                        ) : null} 
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item >
                        <Grid container spacing={2}
                            alignItems="center" justifyContent="center" >
                            {(selectedItem.type.name === 'OTNotes' || updateOTBooking === 'reschedule') &&
                                < Grid item >
                                    <Typography noWrap style={{ fontSize: "1rem", }}
                                        className={classes.label}>
                                        Doctor :
                                        <span style={{ fontWeight: 500 }}> {patientsDetails?.doctors ?
                                            patientsDetails?.doctors[0] : "N/A"}
                                        </span>
                                    </Typography>
                                </Grid>
                            }
                            <Grid item>
                                <Typography noWrap style={{ fontSize: "1rem" }}
                                    className={classes.label}>
                                    Registration Id :
                                    <span style={{ fontWeight: 500 }}> {patientsDetails?.regId}</span>
                                </Typography>
                            </Grid>
                            <Grid item >
                                <Typography noWrap style={{ fontSize: "1rem", }}
                                    className={classes.label}>
                                    Reg Date :
                                    <span style={{ fontWeight: 500 }}> {moment(patientsDetails?.regDate).format("DD-MM-YYYY")}</span>
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        </div >
    );
}