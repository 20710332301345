import axios from "axios";
import { SERVICE_URL } from "../../config.json";

const axiosInstance = axios.create({
  baseURL: SERVICE_URL,
});

axiosInstance.interceptors.request.use((config) => {
  const accessToken = JSON.parse(localStorage.getItem("AccessToken"));
  config.headers.authorization = `Bearer ${accessToken}`;
  return config;
});

export default axiosInstance;
