import React, { useEffect, useRef, useState } from "react";
import moment from "moment";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
//material-ui
import {
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  InputAdornment,
  makeStyles,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Typography,
  DialogTitle,
  IconButton,
  MenuItem,
  ListItemText,
} from "@material-ui/core";
import LinearProgress from "@material-ui/core/LinearProgress";
import {
  CloseOutlined,
  FileCopy,
  PhotoLibrary,
  PictureAsPdf,
  Search,
} from "@material-ui/icons";
//pages
import axiosInstance from "pages/Services/AxiosInstance";
//redux
import { useDispatch, useSelector } from "react-redux";
//components
import Button from "components/Button";
import useTable, {
  StyledTableCell,
  StyledTableRow,
} from "../../components/UseTable";
import {
  appontmentDownloadFileUrl,
  getAllConsultation,
  getAppontmentPatientUrl,
  getByPatientDetailsUrl,
  getConsultationBypatientIdUrl,
} from "pages/Services/URLs";
import ReactToPrint from "react-to-print";
import belLogo from "assets/Images/bel_logo_bg.png";
import Divider from "@material-ui/core/Divider";

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: "100%",
  },
  tableCell: {
    fontSize: "14px",
    border: "0.1px solid #ededed",
    padding: "4px",
    [theme.breakpoints.down("md")]: {
      fontSize: "12px",
    },
  },
  image: {
    objectFit: "contain",
    height: 700,
    width: "100%",
  },
  tableColor: {
    color: "white",
  },
  closeDialog: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(0.5),
    color: theme.palette.grey[500],
  },
  color: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.contrastText,
    "&:hover, &:focus": {
      color: theme.palette.primary.contrastText,
      background: theme.palette.primary.main,
    },
  },
  heading: {
    textDecoration: "underline",
    fontWeight: 600,
    display: "flex",
    justifyContent: "center",
  },
  head: {
    backgroundColor: theme.palette.primary.main,
  },
  title: {
    fontSize: "1.3rem",
    color: theme.palette.primary.contrastText,
  },
  item: {
    minWidth: 300,
    maxWidth: 200,
    paddingRight: 20,
  },
  itemLabel: {
    minWidth: 100,
  },
  weight: {
    fontWeight: "bold",
  },
  dateContainer: {
    display: "flex",
    justifyContent: "",
    alignItems: "center",
    flexWrap: "wrap",
    marginTop: "15px",
    marginBottom: "15px",
  },
}));

const headCells = [
  { id: "id", label: "S.No.", align: "left" },
  { id: "staffName", label: "Doctor Name", align: "left" },
  { id: "createdDatetime", label: "Date", align: "left" },
  // { id: "apptEnd", label: "Time", align: "left" },
  { id: "action", label: "Action", align: "left" },
];
const arraySchema = yup.object().shape({
  isSelf: yup.string().required("Select Self/Spouse"),
  expenseTypeId: yup.string().required("Expense Type is required"),
  billNo: yup.string().required("Bill No is required"),
});
export default function ConsultationHistory({ patientId }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);
  const [records, setRecords] = useState(null);
  const [filterFn, setFilterFn] = useState({ fn: (items) => items });
  const user =
    useSelector((store) => store.loggedInUser) ||
    JSON.parse(localStorage.getItem("User"));
  const patientDetails = useSelector(
    (store) => store.PatientDetails.patientDetails
  );

  const [printdiaglogue, setPrintDiaglogue] = React.useState(false);
  const [modaldata, setModalData] = React.useState(false);
  const printref = useRef(null);
  const current = new Date();
  const day = `${current.getDate()}-${current.getMonth() +
    1}-${current.getFullYear()}`;
  const [date, setDate] = useState(new Date());

  useEffect(() => {
    var timer = setInterval(() => setDate(new Date()), 1000);
    return function cleanup() {
      clearInterval(timer);
    };
  });
  const handleClose = () => {
    setPrintDiaglogue(false);
  };
  const openViewDetailsModal = (data, status) => {
    setModalData(data);
    setPrintDiaglogue(status);
    setDownladFiles(null);
    fetchFiles(data?.id);
  };
  const fetchConsultationList = (patientId) => {
    setLoading(true);
    axiosInstance
      .get(getConsultationBypatientIdUrl + "/" + patientId)
      .then((res) => {
        setRecords(res.data);
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchConsultationList(patientId);
  }, [patientId]);

  const requestSearch = (e) => {
    let val = e.target.value;
    setFilterFn({
      fn: (records) => {
        if (!val) return records;
        return records.filter(
          (row) =>
            row?.id
              ?.toString()
              .toLowerCase()
              .includes(val.toLowerCase()) ||
            row?.patientappointmentDto?.staffDto?.staffName
              ?.toString()
              .toLowerCase()
              .includes(val.toLowerCase()) ||
            row?.patientappointmentDto?.apptStart
              ?.toString()
              .toLowerCase()
              .includes(val.toLowerCase()) ||
            moment(row?.patientappointmentDto?.apptStart)
              .format("DD-MM-YYYY")
              .includes(val.toLowerCase()) ||
            moment(row?.patientappointmentDto?.apptStart)
              .format("hh:mm A")
              .includes(val.toLowerCase()) ||
            moment(row?.patientappointmentDto?.apptEnd)
              .format("hh:mm A")
              .includes(val.toLowerCase())
        );
      },
    });
  };
  const {
    register,
    watch,
    handleSubmit,
    reset,
    control,
    setValue,
    getValues,
    // setValues,
    clearErrors,
    formState: { errors },
  } = useForm({
    // defaultValues,
    resolver: yupResolver(arraySchema),
    mode: "onChange",
  });
  const {
    TblContainer,
    TblHead,
    TblPagination,
    recordsAfterPagingAndSorting,
    getSlNo,
  } = useTable(records, headCells, filterFn, 15);

  const [downloadFiles, setDownladFiles] = useState(null);
  //file download
  const fetchFiles = (id) => {
    axiosInstance
      .get(appontmentDownloadFileUrl + "/" + id)
      .then(({ data }) => {
        setDownladFiles(data);
      })
      .catch((error) => {
        // toast.error("Something went wrong");
      });
  };

  const FileIcon = ({ extName }) => {
    var extName = extName.toLowerCase();
    if (extName == "jpg" || extName == "png" || extName == "jpeg") {
      return <PhotoLibrary />;
    } else if (extName == "pdf") {
      return <PictureAsPdf />;
    } else {
      return <FileCopy />;
    }
  };

  const toDataURL = async (url) => {
    const response = await axiosInstance.get(url, { responseType: "blob" });
    const imageDataUrl = URL.createObjectURL(response.data);
    return imageDataUrl;
  };

  const handleDownload = async (urlImage) => {
    const a = document.createElement("a");
    a.href = await toDataURL(urlImage);
    var filenameBefore = urlImage.split("/").pop();
    a.download = filenameBefore;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "end",
          marginBottom: "8px",
        }}
      >
        <TextField
          placeholder="Search..."
          variant="outlined"
          size="small"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
          onChange={requestSearch}
        />
      </div>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          align="center"
          style={{ margin: "auto", overflowX: "auto" }}
        >
          <Grid container>
            <TblContainer style={{ maxHeight: "600px" }}>
              {loading && <LinearProgress />}
              <Table
                style={{ position: "relative", border: "1px solid lightgrey" }}
                size="small"
                aria-label="simple table"
                id="data-table-cleanliness-report"
              >
                <TblHead />
                {recordsAfterPagingAndSorting()?.length !== 0 ? (
                  <TableBody>
                    {recordsAfterPagingAndSorting()?.map((item, index) => (
                      <TableRow key={index} className={classes.tableColor}>
                        <StyledTableCell className={classes.tableCell}>
                          {index + 1}
                        </StyledTableCell>
                        <StyledTableCell className={classes.tableCell}>
                          {item?.staffDto?.staffName}
                        </StyledTableCell>
                        <StyledTableCell className={classes.tableCell}>
                          {item?.consultationDto?.createdDatetime &&
                            moment(
                              item?.consultationDto?.createdDatetime
                            ).format("DD-MM-yyyy")}
                        </StyledTableCell>
                        {/* <StyledTableCell className={classes.tableCell}>
                          {moment(item?.patientappointmentDto?.apptStart).format("hh:mm A")}
                          {" - "}
                          {moment(item?.patientappointmentDto?.apptEnd).format("hh:mm A")}{" "}
                        </StyledTableCell> */}

                        <StyledTableCell className={classes.tableCell}>
                          <Button
                            onClick={() => openViewDetailsModal(item, true)}
                            align="center"
                            color="secondary"
                            variant="contained"
                          >
                            View More
                          </Button>
                        </StyledTableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                ) : (
                  <TableBody>
                    <StyledTableRow>
                      <StyledTableCell colSpan={8} align="center">
                        No records found
                      </StyledTableCell>
                    </StyledTableRow>
                  </TableBody>
                )}
              </Table>
              {recordsAfterPagingAndSorting()?.length != 0 &&
              recordsAfterPagingAndSorting()?.length != undefined ? (
                <TblPagination />
              ) : (
                ""
              )}
            </TblContainer>
          </Grid>
        </Grid>
      </Grid>

      <Dialog open={printdiaglogue} onClose={handleClose} maxWidth="lg">
        <DialogTitle>
          <Grid container justifyContent="space-between">
            <Grid item>
              <Typography align="left" variant="">
                Consultation Details
              </Typography>
            </Grid>
            <Grid item>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleClose}
                aria-label="close"
              >
                <CloseOutlined />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <Divider />
        <DialogContent>
          <Card ref={printref}>
            <CardContent>
              <Grid
                container
                alignItems="center"
                spacing={4}
                justifyContent="center"
              >
                <Grid item xs={3} align="center">
                  <img style={{ height: 60, width: 160 }} src={belLogo} />
                </Grid>
                <Grid item container xs={6} align="center">
                  <Grid item xs={12}>
                    <Typography>
                      <strong>BHARAT ELECTRONICS HOSPITAL</strong>
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography>Medical Department</Typography>
                  </Grid>
                </Grid>
                <Grid item xs={3} align="center">
                  <Typography>{day}</Typography>
                  {/* <Typography>{date.toLocaleTimeString()}</Typography> */}
                </Grid>
                <Grid item container xs={12} justifyContent="center">
                  <Grid item xs={6}>
                    <Typography>
                      Patient ID
                      <span style={{ marginLeft: "3.9rem" }}></span>:{" "}
                      {modaldata?.patientId}
                    </Typography>
                    <Typography>
                      Patient Name
                      <span style={{ marginLeft: "1.8rem" }}></span>:{" "}
                      {modaldata?.userDto?.ename}
                    </Typography>
                    <Typography>
                      appointment ID
                      <span style={{ marginLeft: "0.8rem" }}></span>:{" "}
                      {modaldata?.consultationDto?.appointmentId}
                    </Typography>
                    <Typography>
                      Mode
                      <span style={{ marginLeft: "5.8rem" }}></span>:{" "}
                      {modaldata?.isTeleConsultation ? "Online" : "Offline"}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography>
                      Scheduled Date
                      <span style={{ marginLeft: "0.8rem" }}></span>:{" "}
                      {moment(modaldata?.apptStart).format("DD-MM-YYYY")}
                    </Typography>
                    <Typography>
                      Start Time
                      <span style={{ marginLeft: "3.7rem" }}></span>:{" "}
                      {moment(modaldata?.apptStart).format("hh:mm A")}
                    </Typography>
                    <Typography>
                      End Time
                      <span style={{ marginLeft: "4.3rem" }}></span>:{" "}
                      {moment(modaldata?.apptEnd).format("hh:mm A")}
                    </Typography>
                    <Typography>
                      Consulted to
                      <span style={{ marginLeft: "2.45rem" }}></span>:{" "}
                      {modaldata?.staffDto?.staffName}
                    </Typography>
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <Divider />
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow align="center">
                        <TableCell align="center">Vitals</TableCell>
                        <TableCell align="center">Prescription</TableCell>
                        <TableCell align="center">Diagnosis</TableCell>
                        <TableCell align="center">Lab Investigation</TableCell>
                        <TableCell align="center">Advice</TableCell>
                        <TableCell align="center">Remarks</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell align="center">
                          {modaldata?.consultationDto?.vitals}
                        </TableCell>
                        <TableCell align="center">
                          {modaldata?.consultationDto?.prescription}
                        </TableCell>
                        <TableCell align="center">
                          {modaldata?.consultationDto?.diagnosis}
                        </TableCell>
                        <TableCell align="center">
                          {modaldata?.consultationDto?.labInvestigations}
                        </TableCell>
                        <TableCell align="center">
                          {modaldata?.consultationDto?.advice}
                        </TableCell>
                        <TableCell align="center">
                          {modaldata?.consultationDto?.remarks}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Grid>

                <Grid item xs={12} align="right" style={{ marginTop: 80 }}>
                  <Typography>(Signature of Doctor)</Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <DialogActions>
            <ReactToPrint
              trigger={() => (
                <Button
                  variant="contained"
                  color="primary"
                  className="printbtn"
                >
                  Print{" "}
                </Button>
              )}
              content={() => printref.current}
            />
            <Button variant="contained" color="secondary" onClick={handleClose}>
              Close
            </Button>
          </DialogActions>
          <Grid xs={4} align="center">
            {downloadFiles && (
              <>
                <Typography variant="h6">Uploads Files</Typography>
                {downloadFiles?.map((item, index) => (
                  <>
                    <MenuItem
                      key={index}
                      onClick={() => {
                        handleDownload(item);
                      }}
                    >
                      <ListItemText
                        disableTypography
                        primary={
                          <Typography variant="body2">
                            {" "}
                            {item.split("/").pop()}
                          </Typography>
                        }
                      />
                      <IconButton size="small">
                        <FileIcon
                          extName={item
                            .split("/")
                            .pop()
                            .split(".")
                            .pop()}
                        />
                      </IconButton>
                    </MenuItem>
                  </>
                ))}
              </>
            )}
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
}
