import React, { useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import tinyColor from "tinycolor2";
import { BrowserRouter as Router, useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import IdleTimer from "react-idle-timer";
import { Button, Dialog } from "@material-ui/core";
import "./toast-container.css";
//
import Socket from "react-stomp";
import { DialogTitle, DialogContent, DialogActions } from "Utils";
import AxiosInstance from "./pages/Services/AxiosInstance";
import { LOGGED_IN_USER } from "Redux/Types";
import { SERVICE_URL } from "./config.json";
import Loader from "Loader";
import RouterElement from "Routes.js";
import {
  warning,
  success,
  info,
  btn,
  lightenRate,
  darkenRate,
  overrides,
} from "ThemeConst.js";
import { UserAuthContextProvider } from "./pages/context/UserAuthContext";
import GlobalSocket from "webscokets/GlobalSocket";

function MainComponent() {
  const primaryColor = useSelector((store) => store.selectedTheme.primary);
  const secondaryColor = useSelector((store) => store.selectedTheme.secondary);
  const user =
    useSelector((store) => store.loggedInUser) ||
    JSON.parse(localStorage.getItem("User"));

  const primary = "#" + primaryColor;
  const secondary = "#" + secondaryColor;

  const defaultTheme = {
    palette: {
      type: "light",
      primary: {
        main: primary,
        light: tinyColor(primary)
          .lighten(lightenRate)
          .toHexString(),
        dark: tinyColor(primary)
          .darken(darkenRate)
          .toHexString(),
      },
      secondary: {
        main: secondary,
        light: tinyColor(secondary)
          .lighten(lightenRate)
          .toHexString(),
        dark: tinyColor(secondary)
          .darken(darkenRate)
          .toHexString(),
      },
      warning: {
        main: warning,
        light: tinyColor(warning)
          .lighten(lightenRate)
          .toHexString(),
        dark: tinyColor(warning)
          .darken(darkenRate)
          .toHexString(),
      },
      success: {
        main: success,
        light: tinyColor(success)
          .lighten(lightenRate)
          .toHexString(),
        dark: tinyColor(success)
          .darken(darkenRate)
          .toHexString(),
      },
      info: {
        main: info,
        light: tinyColor(info)
          .lighten(lightenRate)
          .toHexString(),
        dark: tinyColor(info)
          .darken(darkenRate)
          .toHexString(),
      },
      btn: {
        main: btn,
        light: tinyColor(btn)
          .lighten(lightenRate)
          .toHexString(),
        dark: tinyColor(btn)
          .darken(darkenRate)
          .toHexString(),
      },
      text: {
        primary: "#4A4A4A",
        secondary: "#6E6E6E",
        hint: "#B9B9B9",
      },
      background: {
        default: "#ebebeb",
        light: "white",
      },
      infocard: {
        green: {
          light: "#D5F5E3",
          dark: "#186A3B",
        },
        red: {
          light: "#FADBD8",
          dark: "#78281F",
        },
        brown: {
          light: "#F6DDCC",
          dark: "#784212",
        },
        blue: {
          light: "#D6EAF8",
          dark: "#1B4F72",
        },
      },
      deleteIcon: {
        light: "#E74C3C",
      },
      normal: {
        light: '#55CE5F',
        dark: '#419A4D',
      },
      emergency: {
        light: '#C65C5C',
        dark: '#9A4141',
      }
    },
    customShadows: {
      widget:
        "0px 3px 11px 0px #E8EAFC, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A",
      widgetDark:
        "0px 3px 18px 0px #4558A3B3, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A",
      widgetWide:
        "0px 12px 33px 0px #E8EAFC, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A",
    },
  };

  const dispatch = useDispatch();
  const idleTimerRef = useRef(null);

  const themes = createTheme({ ...defaultTheme, ...overrides });

  useEffect(() => {
    if (JSON.parse(localStorage.getItem("User"))) {
      dispatch({
        type: LOGGED_IN_USER,
        payload: JSON.parse(localStorage.getItem("User")),
      });
    }
  }, [dispatch]);

  const logOut = () => {
    console.log("inside logout...");
    AxiosInstance.post(SERVICE_URL + "user/logout", {
      username: user?.userName,
    })
      .then(() => {
        localStorage.clear();
        dispatch({ type: LOGGED_IN_USER, payload: null });
      })
      .catch((e) => console.log(e.message));
    console.log("coming out of logout...");
  };

  const alertUser = (e) => {
    e.preventDefault();
    if (e) {
      e.returnValue = ""; // Legacy method for cross browser support
      console.log("inside reload...");
    }
    return "";
  };

  useEffect(() => {
    // window.addEventListener("beforeunload", alertUser);
    // // window.confirm(message);
    // return () => {
    //   window.removeEventListener("beforeunload", alertUser);
    // };

    logOut();

    const handleTabClose = (event) => {
      event.preventDefault();

      console.log("beforeunload event triggered");

      return (event.returnValue = "Are you sure you want to exit?");
    };

    window.addEventListener("beforeunload", handleTabClose);

    return () => {
      console.log("inside return beforeunload event");
      window.removeEventListener("beforeunload", handleTabClose);
    };
  }, []);

  return (
    <>
      {user && (
        <>
          <GlobalSocket loginuser={user} />
        </>
      )}

      <ToastContainer position="bottom-center" closeButton={true} />
      <ThemeProvider theme={themes}>
        {/* Routes */}
        <RouterElement />
        {/* Routes */}
      </ThemeProvider>
    </>
  );
}

export default function App() {

  if (process.env.NODE_ENV === "production") {
    console.log = () => {};
    console.warn = () => {};
    console.error = () => {};
    console.info = () => {};
    console.debug = () => {};
  }
  return (
    <React.Suspense fallback={<Loader />}>
      <UserAuthContextProvider>
        <Router>
          <MainComponent />
        </Router>
      </UserAuthContextProvider>
    </React.Suspense>
  );
}
