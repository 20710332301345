
import React, { useEffect, useRef, useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Button, Box, Grid, Divider, Typography, DialogActions, DialogTitle, DialogContent, Dialog, IconButton, TableContainer, TableRow, TableCell, TableHead, Table, Paper, TableBody } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import belLogo from "assets/Images/bel_logo_bg.png";
import ReactToPrint from "react-to-print";
import { useSelector } from "react-redux";
import moment from "moment";


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        padding: theme.spacing(0),
    },
    label: {
        fontSize: "0.875rem",
        color: theme.palette.text.primary,
    },
    paperHead: {
        display: 'flex',
        justifyContent: 'space-evenly',
        alignItems: 'center',
        paddingTop: 10,
        paddingBottom: 10,
        borderTopRightRadius: 0,
        borderTopLeftRadius: 0
    },
}));

export const BillPreview = (props) => {
    const classes = useStyles();
    const theme = useTheme();
    const patientsDetails = useSelector((store) => store.OTPatients.patientDetails);
    let componentRef = useRef();
    const current = new Date();
    const day = `${current.getDate()}-${current.getMonth() + 1}-${current.getFullYear()}`;
    const [date, setDate] = useState(new Date());
    const [billItems, setBillItems] = useState([]);
    const [totalAmount, setTotalAmount] = useState(0);

    const getTotalAmount = () => {
        let totalAmount = 0;
        for (const item of props.billingItems) {
            totalAmount += item.quantity ? (item.price ? item.price : 0) * item.quantity
                : (item.price ? item.price : 0);
        }
        setTotalAmount(totalAmount);
    };
    // function createData(name, calories, fat, carbs, protein) {
    //     return { name, calories, fat, carbs, protein };
    // }

    // const rows = [
    //     createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
    //     createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
    //     createData('Eclair', 262, 16.0, 24, 6.0),
    //     createData('Cupcake', 305, 3.7, 67, 4.3),
    //     createData('Gingerbread', 356, 16.0, 49, 3.9),
    // ];

    useEffect(() => {
        setBillItems(props.billingItems);
        getTotalAmount();
        var timer = setInterval(() => setDate(new Date()), 1000)
        return function cleanup() {
            clearInterval(timer)
        }
    }, [props.billingItems]);

    return (
        <div className={classes.root}>

            <Box className={classes.paperHead} >

                <Dialog
                    open={props.openPreview}
                    onClose={props.closePreview}
                    fullWidth
                    maxWidth="lg"
                >
                    <DialogTitle id="customized-dialog-title">
                        <Grid container spacing={0}>
                            <Grid items xs={11} >
                                {/* <Typography ><strong>Service Charges</strong></Typography> */}
                            </Grid>
                            <Grid items xs={1} align="right">
                                <IconButton aria-label="close" onClick={props.closePreview}>
                                    <Close />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </DialogTitle>
                    <DialogContent id="Print" ref={(el) => (componentRef = el)}>
                        <Grid container alignItems="center" spacing={4} justifyContent="center">
                            <Grid item xs={3} align="center" >
                                <img
                                    style={{ height: 60, width: 160 }}
                                    src={belLogo}
                                />
                            </Grid>
                            <Grid item container xs={6} align="center">
                                <Grid item xs={12}  >
                                    <Typography><strong>BHARAT ELECTRONICS HOSPITAL</strong></Typography>
                                </Grid>
                                <Grid item xs={12} >
                                    <Typography>Medical Department</Typography>
                                </Grid>
                            </Grid>
                            <Grid item xs={3} align="center">
                                <Typography>{day}</Typography>
                                <Typography>{date.toLocaleTimeString()}</Typography>
                            </Grid>

                            <Grid item xs={12} align="center">
                                <Typography> <span style={{ fontWeight: 600 }}> OPERATION THEATRE BILL</span> </Typography>
                            </Grid>
                            <Grid item xs={12} >
                                <Typography style={{ letterSpacing: '1.5px', textDecoration: 'underline' }}
                                ><strong>SERVICE CHARGES</strong></Typography>
                            </Grid>
                            <Grid item container xs={12} justifyContent="center" >
                                <Grid item xs={6}>
                                    <Typography>Patient ID
                                        <span style={{ marginLeft: '2.7rem' }} ></span>
                                        : <span style={{ fontWeight: 600 }} >
                                            {patientsDetails?.regId}
                                        </span>
                                    </Typography>
                                    <Typography>Patient Name
                                        <span style={{ marginLeft: '0.6rem' }} ></span>
                                        : <span style={{ fontWeight: 600 }} >
                                            {patientsDetails?.staffName}
                                        </span >
                                    </Typography>
                                    <Typography>Date of Birth
                                        <span style={{ marginLeft: '1.3rem' }} ></span>
                                        : <span style={{ fontWeight: 600 }} >
                                            {moment(patientsDetails?.dob).format("DD-MM-YYYY")}
                                        </span >
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography>Gender
                                        <span style={{ marginLeft: '4.0rem' }} ></span>
                                        : <span style={{ fontWeight: 600 }} >
                                            {patientsDetails?.gender}
                                        </span>
                                    </Typography>
                                    <Typography>Surgery Name
                                        <span style={{ marginLeft: '0.6rem' }} ></span>
                                        : <span style={{ fontWeight: 600 }} >
                                            {patientsDetails?.surgeryName}
                                        </span>
                                    </Typography>
                                    <Typography>Priority
                                        <span style={{ marginLeft: '4.4rem' }} ></span>
                                        : <span style={{ fontWeight: 600 }} >
                                            {patientsDetails?.priority}
                                        </span>
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid item xs={12}>
                                <Divider />
                            </Grid>

                            {/* <TableContainer >
                                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Dessert (100g serving)</TableCell>
                                            <TableCell align="right">Calories</TableCell>
                                            <TableCell align="right">Fat&nbsp;(g)</TableCell>
                                            <TableCell align="right">Carbs&nbsp;(g)</TableCell>
                                            <TableCell align="right">Protein&nbsp;(g)</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {rows.map((row) => (
                                            <TableRow
                                                key={row.name}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <TableCell component="th" scope="row">
                                                    {row.name}
                                                </TableCell>
                                                <TableCell align="right">casdad</TableCell>
                                                <TableCell align="right"></TableCell>
                                                <TableCell align="right"></TableCell>
                                                <TableCell align="right"></TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer> */}

                            <Grid container xs={12} align="center" justifyContent="center">
                                <Grid item xs={3}>
                                    <Typography>
                                        <span style={{ fontWeight: 600 }} >SI No</span>
                                    </Typography>
                                </Grid>
                                {
                                    billItems[0]?.departmentName &&
                                    <Grid item xs={3}>
                                        <Typography>
                                            <span style={{ fontWeight: 600 }} >Department</span>
                                        </Typography>
                                    </Grid>
                                }
                                <Grid item xs={3}>
                                    <Typography>
                                        <span style={{ fontWeight: 600 }} >Name</span>
                                    </Typography>
                                </Grid>
                                {/* {
                                    props.billingItems[0]?.quantity &&

                                    <Grid item xs={3}>
                                        <Typography>
                                            <span style={{ fontWeight: 600 }} >Charges</span>
                                        </Typography>
                                    </Grid>

                                } */}
                                {
                                    billItems[0]?.quantity &&

                                    <Grid item xs={3}>
                                        <Typography>
                                            <span style={{ fontWeight: 600 }} >Quantity</span>
                                        </Typography>
                                    </Grid>

                                }
                                <Grid item xs={3}>
                                    <Typography>
                                        <span style={{ fontWeight: 600 }} >Price</span>
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid item xs={12}>
                                <Divider />
                            </Grid>

                            {billItems?.map((item, index) => (
                                <Grid container xs={12} align="center" justifyContent="center">
                                    <Grid item xs={3}>
                                        <Typography>
                                            <span style={{ fontWeight: 600 }} >
                                                {index + 1}
                                            </span>
                                        </Typography>
                                    </Grid>
                                    {
                                        item.departmentName &&
                                        <Grid item xs={3}>
                                            <Typography>
                                                <span style={{ fontWeight: 600 }} >
                                                    {item.departmentName}
                                                </span>
                                            </Typography>
                                        </Grid>
                                    }
                                    <Grid item xs={3}>
                                        <Typography>
                                            <span style={{ fontWeight: 600 }} >
                                                {item.serviceName}
                                            </span>
                                        </Typography>
                                    </Grid>
                                    {/* {
                                        item.quantity &&

                                        <Grid item xs={3}>
                                            <Typography>
                                                <span style={{ fontWeight: 600 }} >
                                                    {item.price}
                                                </span>
                                            </Typography>
                                        </Grid>
                                    } */}
                                    {
                                        item.quantity &&

                                        <Grid item xs={3}>
                                            <Typography>
                                                <span style={{ fontWeight: 600 }} >
                                                    {item.quantity}
                                                </span>
                                            </Typography>
                                        </Grid>

                                    }

                                    <Grid item xs={3}>
                                        <Typography>
                                            <span style={{ fontWeight: 600 }} >
                                                {item.price ? "₹ " + item.price + "/-" : "-"}
                                            </span>
                                        </Typography>
                                    </Grid>
                                </Grid>
                            ))}
                            <Grid item xs={12}>
                                <Grid container xs={12} justifyContent="flex-end">
                                    <Typography >
                                        <span style={{ marginRight: '1.3rem', fontWeight: 600 }} >
                                            Total :
                                        </span>
                                        <span style={{
                                            marginRight: '6.7rem', fontWeight: 600
                                        }} >  ₹ {totalAmount ? totalAmount : 0}/-
                                        </span>
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Divider />
                            </Grid>
                        </Grid>

                    </DialogContent>
                    <DialogActions>
                        <ReactToPrint
                            trigger={() => (
                                <Button variant="contained" color="secondary">
                                    Print
                                </Button>
                            )}
                            content={() => componentRef}
                        />
                    </DialogActions>
                </Dialog>
            </Box>
        </div>
    );
}