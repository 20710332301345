import { React, useEffect, useState } from 'react';
import { Grid, Typography, makeStyles } from "@material-ui/core";
import { CalendarTodayRounded, } from '@material-ui/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar, faCalendarAlt, faClock } from '@fortawesome/free-solid-svg-icons';

const useStyles = makeStyles((theme) => ({

    calendarIcon: {
        color: theme.palette.primary.dark,
        marginBottom:2,
    },
    label: {
        fontSize: "0.875rem"
    },
    timerIcon: {
        color: theme.palette.primary.dark,
        marginBottom: 2,
    },
}));

const DateTime = () => {

    const classes = useStyles();
    const [today, setToday] = useState(new Date())
    const day = today.toLocaleDateString('en', { weekday: 'long' });
    const date = `${day}, ${today.getDate()} ${today.toLocaleDateString('en', { month: 'short' })}`;
    const time = today.toLocaleTimeString('en', {
        hour12: true,
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric'
    });
    useEffect(() => {
        setInterval(() => setToday(new Date()), 1000);
    }, []);

    return (
        <div>
            <Grid container spacing={2}>
                <Grid item>
                    <Grid container spacing={1}>
                        <Grid item>
                            <FontAwesomeIcon size="sm" icon={faCalendarAlt} className={classes.calendarIcon} />

                        </Grid>
                        <Grid item>
                            <Typography className={classes.label} >
                                {date}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    <Grid container spacing={1}>
                        <Grid item>
                            <FontAwesomeIcon size="sm" icon={faClock}
                                className={classes.timerIcon} />
                        </Grid>
                        <Grid item>
                            <Typography className={classes.label} >
                                {time}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
}
export { DateTime }



