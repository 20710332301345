import { Button, Grid, IconButton, Typography } from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import MaterialTable from "material-table";
import { useRef, useState } from "react";
import { toast } from "react-toastify";
import * as XLSX from "xlsx";
//
import { default as MuiDialogActions, default as MuiDialogContent } from "@material-ui/core/DialogActions";
import { Close } from "@material-ui/icons";

// import { AnnexA_URL } from "pages/Services/URLs";

//
// const phoneRegExp = /^[6-9]{1}[0-9]{9}$/;
// const emailRegExp = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

const readExcel = (file) => {
  const promise = new Promise((resolve, reject) => {
    const fileReader = new FileReader();

    if (!file.type || file.type !== "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
      toast.info("Import file must be a valid Excel file");
      reject("File must be a valid Excel file");
      return;
    }

    fileReader.readAsArrayBuffer(file);
    fileReader.onload = (e) => {
      const bufferArray = e.target.result;

      const wb = XLSX.read(bufferArray, { type: "buffer" });

      const wsname = wb.SheetNames[0];

      const ws = wb.Sheets[wsname];

      const data = XLSX.utils.sheet_to_json(ws);
      resolve(data);
      console.log(data, "Parsed - Excel");
    };

    fileReader.onerror = (error) => {
      reject(error);
    };
  });

  return promise;
};

const requiredColumns = [
  "*ID No./Emp ID",
  "*Salutation",
  "*Name (With Decoration)",
  "**Designation",
  "*Mobile No.",
  "*Email ID",
  "**Married/ Unmarried",
];

export const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

export const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const useStyles = makeStyles((theme) => ({
  browseBtnWrapper: {
    border: "1px solid",
    borderColor: theme.palette.primary.light,
    borderRadius: "4px",
    display: "inline-block",
    borderBlockStyle: "ridge",
    minWidth: 300,
    position: "absolute",
    top: "6px",
    left: "80px",
    zIndex: 1,
  },
  browseBtnContainer: {
    paddingRight: 12,
    // borderRadius: "0 !important" ,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  browseBtn: {
    textTransform: "capitalize",
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.contrastText,
    borderRadius: "4px",
  },
}));

const ImportTable = ({ onClose }) => {
  const classes = useStyles();

  let fileInputRef = useRef(null);

  const [importItem, setImportItem] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [columns] = useState([
    {
      title: "ID No./Emp ID*",
      width: "auto",
      field: "empId",
      //   validate: (rowData) => (!rowData["empId"] ? { isValid: false, helperText: "Emp ID cannot be empty" } : true),
    },
    {
      align: "right",
      // filtering:false,
      title: "Salutation*",
      field: "salutation",
      width: "auto",
      //   validate: (rowData) =>
      //     !rowData["salutation"] ? { isValid: false, helperText: "Salutation cannot be empty" } : true,
    },
    {
      title: "Name",
      field: "name",
      // width: "auto",
      //   validate: (rowData) => (!rowData["name"] ? { isValid: false, helperText: "Name cannot be empty" } : true),
    },
    {
      title: "Designation*",
      field: "designation",
      width: "auto",
      //   validate: (rowData) =>
      //     !rowData["designation"] ? { isValid: false, helperText: "Designation cannot be empty" } : true,
    },

    {
      title: "Mobile No.*",
      field: "mobile",
      width: "auto",
      align: "left",
      type: "numeric",
      //   validate: (rowData) =>
      //     !rowData["mobile"]
      //       ? { isValid: false, helperText: "Mobile No. cannot be empty" }
      //       : phoneRegExp.test(rowData["mobile"])
      //       ? true
      //       : { isValid: false, helperText: "InValid Mobile No." },
    },
    {
      title: "Email Id*",
      field: "email",
      // width: "auto",
      //   validate: (rowData) =>
      //     !rowData["email"]
      //       ? { isValid: false, helperText: "Email cannot be empty" }
      //       : emailRegExp.test(rowData["email"])
      //       ? true
      //       : { isValid: false, helperText: "InValid EmailId" },
    },

    {
      title: "Pay Level*",
      field: "payLevel",
      width: "auto",
      type: "numeric",
      //   validate: (rowData) =>
      //     !rowData["payLevel"] ? { isValid: false, helperText: "Pay Level cannot be empty" } : true,
    },
    {
      title: "Basic Pay*",
      field: "basicPay",
      width: "auto",
      type: "numeric",
      //   validate: (rowData) =>
      //     !rowData["basicPay"] ? { isValid: false, helperText: "Basic Pay cannot be empty" } : true,
    },
    {
      title: "Married/ Unmarried*",
      field: "isMarried",
      width: "auto",
      //   validate: (rowData) =>
      //     !rowData["isMarried"] ? { isValid: false, helperText: "Married/ Unmarried cannot be empty" } : true,
    },
    {
      title: "Official Address",
      field: "officialAddress",
      width: "auto",
    },
    {
      title: "Residential Address",
      field: "resAddress",
      width: "auto",
    },
    {
      title: "Opt to sit N/S of Rajpath",
      field: "optFor",
      width: "auto",
    },
    {
      title: "Remarks",
      field: "remarks",
      width: "auto",
    },
  ]);

  const handleFileInput = (e) => {
    setIsLoading(true);
    const file = e.target.files[0];

    readExcel(file)
      .then((d) => {
        if (Array.isArray(d)) {
          const isValidColumns = requiredColumns.every((el) => el in d[0]);
          if (isValidColumns) {
            if (file.size / 1000 > 1024) {
              toast.info("File size cannot exceed more than 1MB");
            } else {
              const mappedExcel = d.map((el) => ({
                empId: el["*ID No./Emp ID"],
                salutation: el["*Salutation"],
                name: el["*Name (With Decoration)"],
                designation: el["**Designation"],
                basicPay: el["Basic Pay ↵(₹)"],
                payLevel: el["**Pay Level"],
                mobile: el["*Mobile No."],
                email: el["*Email ID"],
                isMarried: el["**Married/ Unmarried"],
                officialAddress: el["Official address showing the place of posting"],
                resAddress: el["Residential Address"],
                optFor: el["Would opt to sit on North or South of Rajpath"],
                remarks: el["Remarks, if any"],
              }));
              setImportItem(mappedExcel);
              setSelectedFile(file);
            }
          } else {
            toast.info("In-valid template");
          }
        } else {
          setImportItem([]);
          setSelectedFile(null);
          toast.error("In-valid template");
        }
        setIsLoading(false);
      })
      .catch((e) => {
        setIsLoading(false);
        console.log(e);
        toast.error("Failed to read file!");
      });
  };

  const handleSave = () => {
    //call API service

    // console.log(importItem, "req body");
    // const isValid = importItem.every((item) =>
    //   Object.values(item)
    //     .slice(0, 9)
    //     .every((el) => el)
    // );

    // if (isValid) {
    if (selectedFile) {
      const formData = new FormData();
      formData.append("file", selectedFile);
      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };

      //   axios
      //     .post(baseURL + AnnexA_URL, formData, config)
      //     .then(() => {
      //       toast.success("valid");
      //       onClose();
      //     })
      //     .catch(() => {
      //       toast.error("Failed to upload!");
      //     });
    } else {
      toast.info("Please Select File");
    }
  };

  return (
    <>
      {/*     
      <Dialog open={true} maxWidth="xl" fullWidth>
     
        <DialogContent dividers style={{ padding: 0, position: "relative" }}> */}
           
      <input
        ref={(refParam) => (fileInputRef = refParam)}
        type="file"
        style={{ display: "none" }}
        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        onChange={handleFileInput}
      />
       <Grid container spacing={0} >
              <Grid items xs={12} className={classes.browseBtnContainer}>
      {/* <div className={classes.browseBtnWrapper}> */}
        {/* <div className={classes.browseBtnContainer}> */}
          <Typography>
            <Button onClick={() => fileInputRef.click()} className={classes.browseBtn} variant="outlined">
              Browse
            </Button>
            &nbsp;
            {selectedFile ? (
              <span> {selectedFile.name}</span>
            ) : (
              <Typography component={"span"} variant="caption" color="textSecondary">
                No file selected.
              </Typography>
            )}
          </Typography>
          <IconButton
            size="small"
            onClick={() => {
              setImportItem([]);
              setSelectedFile(null);
            }}
          >
            <Close fontSize="small" color="action" />
          </IconButton>
        {/* </div> */}
      {/* </div> */}
      </Grid>
      {/* +++++++++++++++++MaterialTable++++++++++++++++++ */}
  
        <Grid  items xs={12}>
       
      <MaterialTable
        columns={columns}
        data={importItem}
        isLoading={isLoading}
        title="Export User Details"
        options={{
          showTitle: false,
          filtering: false,
          exportButton: false,
          // editable: !!importItem?.length,
        }}
        // editable={!!importItem?.length && editable}
      />
         
         </Grid>
      </Grid>

      {/* </DialogContent>
       
        <DialogActions>
        
          <Button variant="contained" color="primary" onClick={handleSave} type="submit">
           update 
          </Button>
        
          <Button variant="contained" color="secondary" onClick={onClose}>
            close 
          </Button>
       
        </DialogActions>
     
      </Dialog> */}
    </>
  );
};

export default ImportTable;
