import React, { useState, useEffect } from "react";
import { redirect, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import CryptoJS from "crypto-js";
import configData from "../../config.json";
import { LOGIN_TYPE } from "../../Redux/Types";
import moment from "moment";
//component
import Button from "components/Button";
import TextField from "components/TextField";
import { restrict } from "restrict";
import { LOGGED_IN_USER } from "Redux/Types";
//material-ui
import { Controller, useForm, useWatch } from "react-hook-form";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { Typography, Card, InputAdornment, Grid, Dialog, IconButton } from "@material-ui/core";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";

import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Lock, VisibilityOff, Visibility, Person } from "@material-ui/icons";
import { useGlobalStyles } from "Styles";
import { toast } from "react-toastify";
import CloseIcon from "@material-ui/icons/Close";
import CircularProgress from "@material-ui/core/CircularProgress";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import PhoneAndroidIcon from "@material-ui/icons/PhoneAndroid";
import Otp from "../HomePage/Otp";
import { empDetailsURL } from "../Services/URLs";

import axiosInstance from "pages/Services/AxiosInstance";
import { useUserAuth } from "pages/context/UserAuthContext";

const useStyles = makeStyles((theme) => ({
  btn: {
    fontSize: "16px",
    textTransform: "none",
    // marginRight: "10%",
    marginTop: 20,
    backgroundColor: "#fd7e14",
    color: theme.palette.common.white,
    "&:hover, &:focus": {
      color: theme.palette.common.white,
      background: "#E26A2C",
    },
  },
  heading: {
    fontSize: "1.5rem",
    paddingBottom: 24,
    color: "#15133C",
    [theme.breakpoints.down("xs")]: {
      fontSize: "1.2rem",
    },
  },
  text: {
    paddingBottom: 12,
  },
  mobText: {
    paddingBottom: 12,
    width: 348,
  },
  card: {
    padding: "105px 58px 105px 58px",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("xs")]: {
      padding: "18px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "12px",
    },
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  closeDialog: { fontSize: 12, width: 8, height: 8 },
}));

const DialogTitle = withStyles()((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/;

const schema = yup.object().shape({
  staffNumber: yup
    .string()
    .trim()
    .typeError("Staff Number is required")
    .required("Staff Number is required"),
  dob: yup
    .string()
    .trim()
    .typeError("Date of Birth is required")
    .required("Date of Birth is required"),
  // mobileNumber: yup
  //   .string()
  //   .typeError("Mobile number is required")
  //   .required("Mobile number is required")
  //   .matches(/^[6-9]\d{9}$/, "Invalid Mobile Number"),
  password: yup
    .string()
    .trim()
    .typeError("Password is required")
    .required("Password is required")
    .matches(passwordRegex, "Please enter a strong password"),
  confirmPassword: yup
    .string()
    .trim()
    .typeError(" Confirm Password is required")
    .required(" Confirm Password is required")
    .matches(passwordRegex, "Please enter a strong password"),
});

export const encryptFn = (data) => {
  const encryptionSecret = configData.encryptionSecret;
  var key = CryptoJS.enc.Latin1.parse(encryptionSecret);
  var iv = CryptoJS.enc.Latin1.parse(encryptionSecret);
  var encrypted = CryptoJS.AES.encrypt(data, key, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.ZeroPadding,
  });
  return encrypted.toString();
};

export default function Registration({ isRegistered }) {
  const classes = useStyles();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [showPassword, setShowPassword] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [isDirty, setIsDirty] = React.useState(false);
  const [submit, setSubmit] = React.useState(false);
  const [verify, setVerify] = React.useState(false);
  const [contactNo, setContactNo] = React.useState("");
  const [truncatedContact, setTruncatedContact] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const dispatch = useDispatch();
  const classes1 = useGlobalStyles();
  const [val, setVal] = useState([]);
  const { setUpRecaptha } = useUserAuth();
  const [result, setResult] = useState("");
  const [isClear, setIsClear] = useState(false);

  let defaultValues = {
    id: "",
    dob: "",
  };

  const {
    register,
    watch,
    handleSubmit,
    reset,
    control,
    setValue,
    getValues,
    clearErrors,
    formState: { errors },
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
  });

  const staffNo = useWatch({ control, name: "staffNumber" });
  const dob = useWatch({ control, name: "dob" });

  const verifyOtp = async (e, otp) => {
    setIsClear(false);
    e.preventDefault();
    // console.log("first otp", otp);

    if (otp === "" || otp === null) return;
    try {
      await result.confirm(otp);
      toast.success("OTP verification successful");
      setSubmit(true);
    } catch (err) {
      toast.error("Wrong OTP");
      setIsClear(true);
    }
  };

  // const onChangeStaffNo = async (event) => {
  //   const staffNo = event.target.value;
  //   if (staffNo && staffNo.length === 6) {
  //     const url = configData.SERVICE_URL + empDetailsURL + staffNo;
  //     console.log("url", url);
  //     await axios
  //       .get(url)
  //       .then(({ data }) => {
  //         console.log("emp data", data);
  //         setVal(data);
  //       })
  //       .catch((e) => {
  //         toast.error("Failed to fetch Employee details. Plz try again.");
  //         console.log("Error", e);
  //       });
  //   }
  // };

  console.log("error", errors);
  const handleBtnClick = (type) => {
    dispatch({ type: LOGIN_TYPE, payload: type });
  };
  const submitLogin = async (data, event) => {
    if (event.key === "Enter" || event.type === "click") {
      const url = configData.SERVICE_URL + "user/userSignUp";

      const encryptedUserName = await encryptFn(staffNo);
      const encryptedPassword = await encryptFn(data.password);
      setLoading(true);
      // const res = await fetch(url, {
      //   method: "POST",
      //   cache: "no-cache",
      //   credentials: "same-origin",
      //   redirect: "follow",
      //   referrerPolicy: "no-referrer",
      //   body: JSON.stringify({
      //     username: encryptedUserName,
      //     password: encryptedPassword,
      //   }),
      // });

      await axiosInstance
        .post(url, {
          username: encryptedUserName,
          password: encryptedPassword,
        })
        .then((res) => {
          const value = res.data;
          setLoading(false);
          localStorage.setItem("User", JSON.stringify(value));
          dispatch({ type: LOGGED_IN_USER, payload: value });
          toast.success("User Signed-up successfully. Redirecting to Login Page...");

          //redirect to login after delay
          var delayInMilliseconds = 1500; //1.5 second
          setTimeout(() => {
            redirect("/");
          }, delayInMilliseconds);
        })
        .catch((error) => {
          console.log("signup error", error?.response?.data);
          toast.error(error?.response?.data);
          setLoading(false);
        });

      // if (res.status === 403) {
      //   toast.error("Concurrent Login not allowed");
      //   setLoading(false);
      // } else {
      //   const value = await res?.json();
      //   if (value.errorMessage === "" || value.errorMessage === undefined) {
      //     setLoading(false);
      //     localStorage.setItem("User", JSON.stringify(value));
      //     dispatch({ type: LOGGED_IN_USER, payload: value });
      //   } else {
      //     setLoading(false);
      //     toast.error(value.errorMessage);
      //   }
      // }
    }
  };
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleOtp = async (e) => {
    e.preventDefault();
    // let phoneNumber = "+91" + e.target.phone.value;
    let phoneNumber = `+91${contactNo}`;

    try {
      const response = await setUpRecaptha(phoneNumber);
      setResult(response);
      setOpen(true);
    } catch (err) {}
  };

  const handleVerify = async () => {
    await axiosInstance
      .get(`employee/verifyEmployeeData/${staffNo}/${moment(dob).format("YYYY-MM-DD")}`)
      .then((res) => {
        // setValue("contactNo", res.data)

        setContactNo(res.data);
        console.log("res.data", res.data);
        const subContact = res.data?.toString().slice(-4);
        setTruncatedContact(subContact);
        setVerify(true);
        // console.log("masked mobile", res.data.replace(/\d(?=\d{4})/g, "*"))
        toast.success(`User Verified Successfully ******${subContact}`);
      })
      .catch((error) => {
        console.log("employee credentails error: ", error);
        toast.error("User Verification Failed");
      });
  };

  const handleClose = () => {
    setOpen(false);
  };

  React.useEffect(() => {
    if (submit) {
      setOpen(false);
    }
  }, [submit]);

  useEffect(() => {
    if (val.length !== 0) {
      console.log("klkllk", val?.dob);
      reset({
        id: val?.id,
        // dob:val?.dob,
        dob: moment(val?.dob)?.format("YYYY-MM-DD"),
        contactNo: val?.contactNo,
      });
    }
  }, [val]);

  // console.log("staff", moment(dob)?.format("DD-MM-YYYY"));
  // useEffect(() => {
  //   (async () => {
  //     if (staffNo && staffNo.length === 7) {
  //       const url = configData.SERVICE_URL + empDetailsURL + staffNo;
  //       console.log("url", url);
  //       await axios
  //         .get(url)
  //         .then(({ data }) => {
  //           console.log("emp data", data);
  //           setVal(data)
  //         })
  //         .catch((e) => {
  //           toast.error("Failed to fetch Employee details. Plz try again.");
  //           console.log("Error", e);
  //         });
  //     }
  //   })();
  // }, [staffNo]);
  return (
    <>
      <div id="recaptcha-container"></div>
      <form onSubmit={handleSubmit(submitLogin)}>
        <Card className={classes.card}>
          <Typography align="center" className={classes.heading}>
            {t("SignUp")}
          </Typography>

          <>
            <div className={classes1.formGroup}>
              <Controller
                name="staffNumber"
                control={control}
                render={({ field: { onChange, value } }) => {
                  return (
                    <TextField
                      labelText={t("Staff Number")}
                      placeholder={t("Staff Number")}
                      required
                      disabled={isDirty}
                      className={classes.text}
                      inputProps={{ maxLength: 7 }}
                      onInput={restrict.alphanumeric}
                      autoFocus={true}
                      value={value}
                      onChange={onChange}
                      error={!!errors.staffNumber}
                      InputProps={{
                        classes: {
                          input: classes.inputSize,
                        },

                        startAdornment: (
                          <InputAdornment position="start">
                            <Person />
                          </InputAdornment>
                        ),
                      }}
                      helperText={errors.staffNumber?.message}
                    />
                  );
                }}
              ></Controller>
              <Controller
                name="dob"
                control={control}
                render={({ field: { onChange, value } }) => {
                  return (
                    <TextField
                      labelText={t("Date of Birth")}
                      placeholder={t("Date of Birth")}
                      required
                      // disabled={true}
                      className={classes.text}
                      inputProps={{ maxLength: 20 }}
                      onInput={restrict.alphanumeric}
                      autoFocus={true}
                      value={value}
                      // onChange={onChange}
                      onChange={(newValue) => {
                        onChange(newValue);
                        // console.log("llll", newValue);
                        // setValue("dob", moment(newValue).format("yyyy-MM-DD"));
                      }}
                      type="date"
                      error={!!errors.dob}
                      InputProps={{
                        classes: {
                          input: classes.inputSize,
                        },

                        startAdornment: (
                          <InputAdornment position="start">
                            <CalendarTodayIcon />
                          </InputAdornment>
                        ),
                      }}
                      helperText={errors.dob?.message}
                    />
                  );
                }}
              ></Controller>

              {/* <Grid container spacing={0}>
               <Grid xs={8}> */}
              {/* <Controller
                name="contactNo"
                control={control}
                render={({ field: { onChange, value } }) => {
                  return (
                    <TextField
                      labelText={t("Mobile Number")}
                      placeholder={t("Mobile Number")}
                      required
                      disabled={true}
                      className={classes.text}
                      inputProps={{ maxLength: 10 }}
                      onInput={restrict.digits}
                      autoFocus={true}
                      value={value}
                      // onChange={onChange}
                      onChange={(e) => {
                        onChange(e);
                        const value = e.target.value;
                        if (
                          +value === 9999999999 ||
                          +value === 8888888888 ||
                          +value === 7777777777 ||
                          +value === 6666666666
                        ) {
                          setMobileError("All 10 digits are same, Invalid Number");
                        } else if (!/(^[6-9][0-9]{9})$/.test(value)) {
                          setMobileError("Please enter valid number");
                        } else {
                          setMobileError("");
                        }
                      }}
                      InputProps={{
                        classes: {
                          input: classes.inputSize,
                        },

                        startAdornment: (
                          <InputAdornment position="start">
                            <PhoneAndroidIcon />
                          </InputAdornment>
                        ),
                      }}
                      error={!!errors.contactNo || !!mobileError.length}
                      helperText={errors.contactNo?.message || mobileError}
                    />
                  );
                }}
              ></Controller> */}
              {/* </Grid>     */}
              {/* <Grid xs={3} style={{ marginTop: "20px",marginLeft:39 }}> */}

              {/* </Grid>
            </Grid> */}
              {submit ? (
                <>
                  <Controller
                    name="password"
                    control={control}
                    render={({ field: { onChange, value } }) => {
                      return (
                        <TextField
                          labelText={t("Password")}
                          placeholder={t("Password")}
                          required
                          autoComplete="off"
                          disabled={isDirty}
                          className={classes.text}
                          inputProps={{ maxLength: 20 }}
                          value={value}
                          onChange={onChange}
                          type={showPassword ? "text" : "password"}
                          InputProps={{
                            classes: {
                              input: classes.inputSize,
                            },
                            endAdornment: (
                              <InputAdornment position="start">
                                {showPassword ? (
                                  <Visibility style={{ cursor: "pointer" }} onClick={handleClickShowPassword} />
                                ) : (
                                  <VisibilityOff style={{ cursor: "pointer" }} onClick={handleClickShowPassword} />
                                )}
                              </InputAdornment>
                            ),
                            startAdornment: (
                              <InputAdornment position="start">
                                <Lock />
                              </InputAdornment>
                            ),
                          }}
                          error={!!errors.password}
                          helperText={errors.password?.message}
                        />
                      );
                    }}
                  ></Controller>
                  <Controller
                    name="confirmPassword"
                    control={control}
                    render={({ field: { onChange, value } }) => {
                      return (
                        <TextField
                          labelText={t("Confirm Password")}
                          placeholder={t("Confirm Password")}
                          required
                          autoComplete="off"
                          disabled={isDirty}
                          className={classes.text}
                          inputProps={{ maxLength: 20 }}
                          value={value}
                          onChange={onChange}
                          type={showPassword ? "text" : "password"}
                          InputProps={{
                            classes: {
                              input: classes.inputSize,
                            },
                            endAdornment: (
                              <InputAdornment position="start">
                                {showPassword ? (
                                  <Visibility style={{ cursor: "pointer" }} onClick={handleClickShowPassword} />
                                ) : (
                                  <VisibilityOff style={{ cursor: "pointer" }} onClick={handleClickShowPassword} />
                                )}
                              </InputAdornment>
                            ),
                            startAdornment: (
                              <InputAdornment position="start">
                                <Lock />
                              </InputAdornment>
                            ),
                          }}
                          error={!!errors.confirmPassword}
                          helperText={errors.confirmPassword?.message}
                        />
                      );
                    }}
                  ></Controller>
                </>
              ) : null}
            </div>

            {submit ? (
              <Button
                type="submit"
                form="form"
                align="center"
                style={{ marginTop: "15px" }}
                disabled={loading}
                color="primary"
                variant="contained"
                onClick={handleSubmit(submitLogin)}
                // onClick={handleSubmit(submitLogin)}
              >
                {loading && <CircularProgress size={24} className={classes.buttonProgress} color="primary" />} Submit
              </Button>
            ) : verify ? (
              <Button
                type="submit"
                form="form"
                align="center"
                onClick={(e) => {
                  handleSubmit(handleOtp(e));
                  console.log("mmmk");
                }}
                disabled={loading}
                color="primary"
                variant="contained"
              >
                Send OTP
              </Button>
            ) : (
              <Button
                type="submit"
                form="form"
                align="center"
                onClick={(e) => {
                  handleSubmit(handleVerify(e));
                  console.log("mmmk");
                }}
                disabled={loading}
                color="primary"
                variant="contained"
              >
                Verify Credentials
              </Button>
            )}
            <div className={classes1.formGroup}>
              <Grid align="left" style={{ marginTop: 10 }}>
                {/* <Button onClick={() => handleBtnClick("login")}> */}
                <Typography color="primary" style={{ fontSize: "12px" }} onClick={() => handleBtnClick("login")}>
                  Already have an account? Log in
                </Typography>
                {/* </Button> */}
              </Grid>
            </div>
          </>
        </Card>
      </form>

      <Dialog onClose={handleClose} open={open}>
        <DialogTitle id="customized-dialog-title">
          <Grid container spacing={0} className={classes.diagHeader}>
            <Grid items xs={11} sm={11} md={11}>
              <Typography className={classes.dialogText}>
                <b>{`Enter the OTP sent to your mobile ******${truncatedContact}`}</b>
              </Typography>
            </Grid>
            <Grid items xs={1}>
              <IconButton aria-label="close" className={classes.closeDialog} onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>

        <DialogContent dividers style={{ maxWidth: "347px" }}>
          <Otp isClear={isClear} otpSubmit={verifyOtp} />
        </DialogContent>
      </Dialog>
    </>
  );
}
