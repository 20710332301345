import { FETCH_SUBMITTED_CLAIM } from "../Types";

const initialState = {
  submittedClaims: [],
};

const ClaimReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_SUBMITTED_CLAIM:
      return {
        ...state,
        submittedClaims: action.payload,
      };

    default:
      return state;
  }
};

export default ClaimReducer;
