import React, { useState, useEffect } from "react";
//material-ui
import { makeStyles, Table, TableBody, Grid, InputAdornment, Button, Fab, IconButton } from "@material-ui/core";
import LinearProgress from "@material-ui/core/LinearProgress";
import { Delete, Search } from "@material-ui/icons";
import EditIcon from '@material-ui/icons/Edit';
//components
import useTable, { StyledTableCell, StyledTableRow } from "components/UseTable";
import TextField from "components/TextField";

const useStyles = makeStyles((theme) => ({
    tableCell: {
        fontSize: "14px",
        textAlign: "center",
        border: "0.1px solid #ededed",
        padding: "4px",
        [theme.breakpoints.down("md")]: {
            fontSize: "12px",
        },

    },
    fontstyle: {
        transform: "lowerCase",
    },
    tableColor: {
        color: "white",
    },
    deleteIcon: { color: theme.palette.deleteIcon.light },
    padding: {
        [theme.breakpoints.down("sm")]: {
            padding: "4px 0px 10px 25px",
        },
        [theme.breakpoints.down("lg")]: {
            padding: "2px 4px 6px 1px",
        },
    },
}));

const headCells = [
    //{ id: "id", label: "SI.No", align: "center" },
    { id: "name", label: "Item Name", align: "center" },
    // { id: "rate", label: "Rate", align: "center" },
    { id: "quantity", label: "Quantity", align: "center" },
    // { id: "amount", label: "Amount", align: "center" },
    // { id: "discAmount", label: "Disc Amount", align: "center" },
    // { id: "gst", label: "GST", align: "center" },
    // { id: "total", label: "Total", align: "center" },
    { id: "action", label: "Action", align: "center" },
];
export default function InventoryTable(props) {
    const classes = useStyles();
    const [records, setRecords] = useState([]);
    const [filterFn, setFilterFn] = useState({ fn: (items) => items });

    const requestSearch = (e) => {
        let val = e.target.value;
        setFilterFn({
            fn: (records) => {
                if (!val) return records;
                return records.filter(
                    (row) =>
                        //row?.id?.toString().includes(val.toLowerCase()) ||
                        row?.name?.toString().includes(val.toLowerCase()) ||
                        // row?.rate?.toString().includes(val.toLowerCase()) ||
                        row?.quantity?.toString().includes(val.toLowerCase())
                    // row?.amount?.toString().includes(val.toLowerCase()) ||
                    // row?.discAmoount?.toString().includes(val.toLowerCase()) ||
                    // row?.gst?.toString().includes(val.toLowerCase()) ||
                    // row?.total?.toString().includes(val.toLowerCase()) ||
                    // row?.action?.toString().includes(val.toLowerCase())
                );
            },
        });
    };
    const { TblContainer, TblHead, TblPagination, recordsAfterPagingAndSorting, getSlNo } = useTable(
        records,
        headCells,
        filterFn
    );
    useEffect(() => {
        setRecords(props.data);
    }, [props.data]);
    return (
        <div>
            <Grid container style={{ justifyContent: "end" }} >
                {/* <Grid item >
                    <TextField className={classes.padding}
                        placeholder="Search..."
                        variant="outlined"
                        size="small"
                        // className={classes.searchInput}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Search />
                                </InputAdornment>
                            ),
                        }}
                        onChange={requestSearch}
                    />
                </Grid> */}
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center" style={{ margin: "auto", overflowX: "auto" }}>
                    <Grid container >
                        <TblContainer style={{ maxHeight: "600px" }}>
                            {props.isLoading && <LinearProgress />}
                            <Table
                                style={{ position: "relative", border: "1px solid lightgrey" }}
                                size="small"
                                aria-label="simple table"
                                id="data-table-cleanliness-report"
                            >
                                <TblHead />
                                {recordsAfterPagingAndSorting()?.length !== 0 ? (
                                    <TableBody>
                                        {recordsAfterPagingAndSorting()?.map((item, index) => (
                                            <StyledTableRow className={classes.tableColor} key={index}>
                                                {/* <StyledTableCell className={classes.tableCell} style={{ width: "1%" }}>{index + 1}</StyledTableCell> */}
                                                <StyledTableCell className={classes.tableCell} style={{ width: "25%" }}>{item?.name} </StyledTableCell>
                                                {/* <StyledTableCell className={classes.tableCell} style={{ width: "5%" }}>{item?.rate} </StyledTableCell> */}
                                                <StyledTableCell className={classes.tableCell} style={{ width: "25%" }}>{item?.quantity} </StyledTableCell>
                                                {/* <StyledTableCell className={classes.tableCell} style={{ width: "2%" }}>{item?.amount} </StyledTableCell>
                                                <StyledTableCell className={classes.tableCell} style={{ width: "2%" }}>{item?.discAmount} </StyledTableCell>
                                                <StyledTableCell className={classes.tableCell} style={{ width: "2%" }}>{item?.gst} </StyledTableCell>
                                                <StyledTableCell className={classes.tableCell} style={{ width: "2%" }}>{item?.total} </StyledTableCell> */}
                                                <StyledTableCell className={classes.tableCell} style={{ width: "2%" }}>{item?.action}
                                                    <IconButton
                                                        style={{ width: 10, height: 10 }}
                                                        onClick={() => props.setEditdata(item)} color="primary">
                                                        <EditIcon />
                                                    </IconButton>
                                                    <IconButton
                                                        style={{ width: 10, height: 10 }}
                                                        onClick={() => props.onClickOpenDeleteBox(item)} color="primary">
                                                        <Delete className={classes.deleteIcon} />
                                                    </IconButton>
                                                </StyledTableCell>
                                            </StyledTableRow>
                                        ))}
                                    </TableBody>
                                ) : (
                                    <TableBody>
                                        <StyledTableRow>
                                            <StyledTableCell colSpan={8} align="center">
                                                No records found
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    </TableBody>
                                )}
                            </Table>
                            {recordsAfterPagingAndSorting()?.length != 0 && recordsAfterPagingAndSorting()?.length != undefined ? (
                                <TblPagination />
                            ) : (
                                ""
                            )}
                        </TblContainer>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
}
