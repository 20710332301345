import { InputAdornment, Typography, makeStyles } from "@material-ui/core";
import React from "react";
import DutyRoasterTable from "./DutyRosterTable";

import { useEffect } from "react";
import { toast } from "react-toastify";

import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// material-ui
import { Grid, TextField } from "@material-ui/core";
import axiosInstance from "pages/Services/AxiosInstance";
import {
  getAllDetailsDutyRoster,
  getAllDoctors,
  saveDutyRosterDetails,
  updateDutyRosterDetails,
} from "pages/Services/URLs";
import {
  Clock,
  ClockView,
  KeyboardDatePicker,
  KeyboardDateTimePicker,
  KeyboardTimePicker,
  TimePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

import { restrict } from "restrict";
import Button from "components/Button";
import { Autocomplete } from "@material-ui/lab";
import moment from "moment";

import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { AccessTime, Home } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  center: {
    alignItems: "center",
    display: "Grid",
  },
  head: {
    fontSize: "1.3rem",
    fontWeight: 400,
    paddingBottom: "25px",
  },
  padding: {
    [theme.breakpoints.down("sm")]: {
      padding: "4px 0px 8px 8px",
    },
    [theme.breakpoints.down("lg")]: {
      padding: "4px 0px 8px 8px",
    },
  },
  margin: {
    marginTop: "2.3rem",
    marginBottom: "2.3rem",
  },
}));
export default function DutyRoster() {
  const classes = useStyles();
  const [records, setRecords] = React.useState([]);
  const [edit, setEdit] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [doctorlist, setDoctorlist] = React.useState([]);
  const defaultValues = {
    id: 0,
    doctorId: null,
    slotStartTime: null,
    totalHours: "",
  };
  const editTable = (edit) => {
    setEdit(edit);
    reset(edit);
  };
  const Schema = yup.object().shape({
    doctorId: yup
      .number()
      .typeError("Enter Doctor Name")
      .required("Select Doctor Name"),
    slotStartTime: yup
      .date()
      .typeError("Enter Date")
      .required(" date & time  is Required"),
    totalHours: yup
      .number()
      .max(18)
      .min(1)
      .typeError("Total Hours required")
      .required("Total Hours required"),
  });
  const {
    register,
    watch,
    handleSubmit,
    reset,
    control,
    setValue,
    getValues,
    // setValues,
    clearErrors,
    formState: { errors },
  } = useForm({
    defaultValues,
    resolver: yupResolver(Schema),
    mode: "onChange",
  });
  const fetchDoctorList = () => {
    axiosInstance.get(getAllDoctors).then((res) => {
      setDoctorlist(res.data);
    });
  };
  useEffect(() => {
    fetchDoctorList();
  }, []);
  const updateDutyRosterData = (data) => {
    let updatedata = {
      id: data?.id,
      doctorId: data.doctorId,
      date: moment(data?.slotStartTime).format("yyyy-MM-DD"),
      slotStartTime: moment(data?.slotStartTime).format("YYYY-MM-DDTHH:mm"),
      totalHours: data.totalHours,
    };
    console.log(updatedata);
    axiosInstance
      .post(updateDutyRosterDetails, updatedata)
      .then((res) => {
        if (res.status === 200) {
          const strupdate = "Data Successfully Updated";
          fetchDetails();
          toast.success(strupdate);
          resetForm();
        } else if (res.response.status === 409) {
          toast.warning(res.response.data);
        }
      })
      .catch((error) => {
        toast.error("Something went wrong");
      });
  };

  const saveDutyRosterData = (data) => {
    const savedata = {
      doctorId: data.doctorId,
      date: moment(data?.slotStartTime).format("yyyy-MM-DD"),
      slotStartTime: moment(data?.slotStartTime).format("YYYY-MM-DDTHH:mm"),
      totalHours: data.totalHours,
    };
    axiosInstance
      .post(saveDutyRosterDetails, savedata)
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          const strsubmit = "Data Successfully Submitted.";
          fetchDetails();
          toast.success(strsubmit);
          resetForm();
        } else if (res.response.status === 409) {
          toast.warning(res.response.data);
        }
      })
      .catch((error) => {
        toast.error("Data Already Exists");
      });
  };
  const resetForm = () => {
    reset(defaultValues);
    setEdit(false);
  };
  const fetchDetails = async () => {
    setLoading(true);
    await axiosInstance
      .get(getAllDetailsDutyRoster)
      .then((res) => {
        const mappeddata = res.data?.map((item) => ({
          ...item,
          doctorId: item?.staffDto?.id,
        }));
        console.log(mappeddata);
        setRecords(mappeddata);
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setLoading(false);
      });
  };
  useEffect(() => {
    fetchDetails();
  }, []);
  return (
    <>
      <form>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12} md={3} lg={3}>
            <Typography>Doctor Name</Typography>
            <Controller
              name="doctorId"
              control={control}
              render={({ field: { onChange, value } }) => (
                <Autocomplete
                  componentsProps={{
                    popper: { style: { width: "fit-content" } },
                  }}
                  size="small"
                  options={doctorlist}
                  value={doctorlist.find((o) => o.id === value) || ""}
                  getOptionLabel={(option) =>
                    option.staffName ? option.staffName : ""
                  }
                  onChange={(e, v) => {
                    onChange(parseInt(v?.id));
                    console.log(v);
                  }}
                  renderInput={(params) => (
                    <TextField
                      multiline
                      {...params}
                      variant="outlined"
                      placeholder="Select Doctor"
                      error={!!errors.doctorId}
                      helperText={errors.doctorId?.message}
                    />
                  )}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={3} lg={3}>
            <Typography>Date & Time</Typography>
            <Controller
              name="slotStartTime"
              control={control}
              render={({ field: { onChange, value } }) => {
                return (
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDateTimePicker
                      format="dd-MM-yyyy hh:mm aa"
                      fullWidth
                      required
                      size="small"
                      disablePast
                      inputVariant="outlined"
                      value={value}
                      onChange={onChange}
                      KeyboardButtonProps={{ "aria-label": "change date" }}
                      error={!!errors.slotStartTime}
                      helperText={errors.slotStartTime?.message}
                    />
                  </MuiPickersUtilsProvider>
                );
              }}
            />
          </Grid>

          <Grid item xs={12} md={3} lg={3}>
            <Typography> Total hours </Typography>
            <Controller
              name="totalHours"
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  variant="outlined"
                  size="small"
                  placeholder="Total Hours"
                  required
                  number
                  fullWidth
                  value={value}
                  onChange={onChange}
                  InputProps={{
                    inputProps: {
                      maxLength: 5,
                    },
                  }}
                  error={!!errors.totalHours}
                  helperText={errors.totalHours?.message}
                  onInput={(e) => {
                    restrict.digits(e);
                  }}
                />
              )}
            />
          </Grid>
        </Grid>

        <Grid
          container
          justifyContent="center"
          spacing={2}
          className={classes.margin}
        >
          <Grid item>
            {edit ? (
              <Button
                variant="contained"
                color="primary"
                type="submit"
                onClick={handleSubmit(updateDutyRosterData)}
              >
                Update
              </Button>
            ) : (
              <Button
                variant="contained"
                color="primary"
                type="submit"
                onClick={handleSubmit(saveDutyRosterData)}
              >
                Submit
              </Button>
            )}
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                resetForm();
              }}
            >
              Reset
            </Button>
          </Grid>
        </Grid>
        <Grid container className={classes.center}>
          <DutyRoasterTable data={records} editTable={editTable} />
        </Grid>
      </form>
    </>
  );
}
