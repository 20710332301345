import React, { useState } from "react";
//material-ui

import {
  Paper,
  Table,
  TableBody,
  TextField,
  Grid,
  Button,
  InputAdornment,
  IconButton,
  Dialog,
} from "@material-ui/core";
import moment from "moment";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { Close } from "@material-ui/icons";
import ClaimTable from "../ClaimStatus/ClaimDailogTable";
import LinearProgress from "@material-ui/core/LinearProgress";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import CloseIcon from "@material-ui/icons/Close";
//components
import useTable, { StyledTableCell, StyledTableRow } from "../../../components/UseTable";
//icons

import { theme, Typography } from "antd";
import { Search } from "@material-ui/icons";
import { fetchGetAllClaimsByEmpId } from "../../../Redux/Actions/EmployeeAction";

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);
const useStyles = makeStyles((theme) => ({
  pageContent: {
    width: "95%",
    padding: theme.spacing(4),
    [theme.breakpoints.down("sm")]: {
      padding: 0,
      width: "100%",
    },
  },
  iconFa: {
    color: theme.palette.primary.dark,
    fontSize: "16px",
    marginRight: "2%",
    marginLeft: "15%",
  },
  size: {
    width: 15,
    height: 15,
  },
  table: {
    minWidth: "100%",
  },
  icon: {
    color: "#ff6600",
    fontSize: "13px",
  },
  icon1: {
    color: "#ff3000",
    fontSize: "13px",
  },
  searchInput: {
    alignContent: "right",
    justifyItems: "right",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  btnActive: {
    backgroundColor: "green",
    fontSize: "12px",
    height: "20px",
    color: "white",
    width: "25px",
  },
  btnInActive: {
    backgroundColor: "red",
    fontSize: "12px",
    height: "20px",
    color: "white",
    width: "25px",
  },
}));

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(0.1),
    color: theme.palette.grey[500],
  },
});
const style = {
  cellPadding: 1, // a number, array or object (see margin below)
  fontSize: 8,
  font: "helvetica", // helvetica, times, courier
  lineWidth: 0.01,
  lineColor: 127,
  fontStyle: "normal", // normal, bold, italic, bolditalic
  overflow: "linebreak", // visible, hidden, ellipsize or linebreak
  fillColor: false, // false for transparent or a color as described below
  textColor: 0,
  halign: "left", // left, center, right
  valign: "middle", // top, middle, bottom
  cellWidth: "auto", // 'auto', 'wrap' or a number
};
const headCells = [
  // { id: "index", label: "S.No.", align: "left" },
  { id: "claimId", label: "Claim Number", align: "left" },
  { id: "claimDate", label: "Claim Date", align: "left" },
  { id: "claimStatus", label: "Status", align: "left" },
  { id: "totalClaimedAmount", label: "Claim Amount", align: "left" },
  { id: "settleAmount", label: "Settle Amount", align: "left" },
  { id: "settleDate", label: "Settlement Date ", align: "left" },
  { id: "Details", label: "Details", align: "left", disableSorting: true },

  // { id: "actions", label: "Actions", disableSorting: true },
];

const myhead = [
  [
    { content: "Bill Serial No", styles: { halign: "center" } },
    { content: "Type of Expenses", styles: { halign: "center" } },
    { content: "Bill No.", styles: { halign: "center" } },
    { content: "Prescription Date", styles: { halign: "center" } },
    { content: "Amt Claimed", styles: { halign: "center" } },
    { content: "Uploaded Bill", styles: { halign: "center" } },
    { content: "Uploaded Prescription", styles: { halign: "center" } },
    { content: "Approved Amt", styles: { halign: "center" } },
    { content: "Paid Amt", styles: { halign: "center" } },
    { content: "Deduction Reason", styles: { halign: "center" } },
  ],
];
const [W, H] = [210, 297];
const margin = 10;
const generatePdf = (data) => {
  const now = moment(new Date()).format("DD-MM-YYYY");
  const time = new Date().toLocaleTimeString();
  const pdf = new jsPDF("p", "mm", [W, H], true);
  let Y = margin;
  const claimId = data?.claimId;
  let body;
  body = data?.medicalBillDtos?.map(
    (d, i) => [
      i + 1,
      d.expenseType,
      d.billNo,
      d.prescriptionDate,
      d.amountClaimed,
      "Uploaded Bill",
      // d.billLocation,
      // d.prescriptionLocation,
      "Uploaded Prescription",
      d.settleAmount,
      d.amountPaid,
      d.deductionRemarks,
    ]
    // pdf.addImage(d.billLocation, "PNG", margin, Y + 2, 15, 15)
  );
  const img = data?.medicalBillDtos[0].billLocation;
  // const total = body.reduce((p, c) => c.slice(2).map((v, i) => p[i] + v), Array(16).fill(0));

  // const totalRow = ["Total", null, ...total];
  // body.push(totalRow);

  // pdf.addImage(img, "PNG", margin, Y + 2, 15, 15);
  // pdf.addImage(leaf, "PNG", W - margin - 20, Y + 2, 15, 15);
  // pdf.setFontSize(14);
  // let splitTitle = pdf.splitTextToSize(org.toUpperCase(), 150);
  // pdf.text(splitTitle, W / 2, Y, { align: "center" });
  // pdf.setFontSize(13);
  // pdf.text("Report", W / 2, Y + 15, "center");
  // Y += 30;
  pdf.setFontSize(10);
  pdf.text(`Claim Number: ${claimId}`, margin, Y);
  // pdf.text(`To Date: ${end}`, margin, Y + 7);
  pdf.text(`Claim Status Report generated on: ${now}, ${time}`, W - margin, Y + 7, "right");
  Y += 14;
  pdf.autoTable({
    head: myhead,
    body,
    startY: Y,
    margin,
    headStyles: {
      ...style,
      fillColor: "#215779",
      textColor: 255,
      lineColor: 255,
    },
    bodyStyles: { ...style },
  });

  pdf.save(`${claimId}  ${now}, ${time}.pdf`);
};

export default function PedencyLog() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [format, setFormat] = React.useState([]);
  const [records, setRecords] = useState([]);
  const [dataRecords, setDataRecords] = useState([]);
  //const [id, setId] = useState("");

  const user = useSelector((store) => store.loggedInUser) || JSON.parse(localStorage.getItem("User"));
  const [filterFn, setFilterFn] = useState({ fn: (items) => items });
  const allClaimByEmpId = useSelector((store) => store.employeeReducer.allClaimByEmpId);

  const requestSearch = (e) => {
    let val = e.target.value;

    setFilterFn({
      fn: (records) => {
        if (!val) return records;

        return records.filter((row) => row?.claimDate.includes(val));
      },
    });
  };

  const { TblContainer, TblHead, TblPagination, recordsAfterPagingAndSorting, getSlNo } = useTable(
    records,
    headCells,
    filterFn
  );

  const handleClose = () => {
    setOpen(false);
  };

  React.useEffect(() => {
    async function fetchData() {
      const data = await dispatch(fetchGetAllClaimsByEmpId(user?.empId));
      setRecords(data);
    }
    fetchData();
  }, []);

  React.useEffect(() => {
    if (allClaimByEmpId.length !== 0) {
      setRecords(allClaimByEmpId);
    }
  }, [allClaimByEmpId]);

  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "end",
          marginBottom: "8px",
        }}
      >
        <TextField
          placeholder="Search..."
          variant="outlined"
          size="small"
          // className={classes.searchInput}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
          onChange={requestSearch}
        />
      </div>
      <Paper>
        {/* <Grid container style={{ justifyContent: "end" }}> */}
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            align="center"
            style={{ margin: "auto", overflowX: "auto" }}
          >
            <Grid container>
              <TblContainer style={{ maxHeight: "600px" }}>
                {loading && <LinearProgress />}
                <Table
                  style={{ position: "relative", border: "1px solid lightgrey" }}
                  size="small"
                  aria-label="simple table"
                  id="data-table-cleanliness-report"
                >
                  <TblHead />
                  {recordsAfterPagingAndSorting()?.length !== 0 ? (
                    <TableBody>
                      {recordsAfterPagingAndSorting()?.map((item, index) => (
                        <StyledTableRow className={classes.tableColor} key={index}>
                          <StyledTableCell className={classes.tableCell}>{item?.claimId}</StyledTableCell>
                          <StyledTableCell className={classes.tableCell}>{item?.claimDate}</StyledTableCell>
                          <StyledTableCell className={classes.tableCell}>{item?.claimStatus}</StyledTableCell>

                          <StyledTableCell className={classes.tableCellEmail}>
                            {item?.totalClaimedAmount}
                          </StyledTableCell>
                          <StyledTableCell className={classes.tableCellEmail}>{item?.settleAmount}</StyledTableCell>
                          <StyledTableCell className={classes.tableCell}>
                            {item?.settleDate ? item?.settleDate : "--"}
                          </StyledTableCell>
                          <StyledTableCell>
                            <Button
                              color="primary"
                              onClick={(e) => {
                                setOpen(true);
                                setDataRecords(item);
                              }}
                            >
                              Click for details
                            </Button>
                          </StyledTableCell>
                        </StyledTableRow>
                      ))}
                    </TableBody>
                  ) : (
                    <TableBody>
                      <StyledTableRow>
                        <StyledTableCell colSpan={8} align="center">
                          No records found
                        </StyledTableCell>
                      </StyledTableRow>
                    </TableBody>
                  )}
                </Table>
                {recordsAfterPagingAndSorting()?.length != 0 && recordsAfterPagingAndSorting()?.length != undefined ? (
                  <TblPagination />
                ) : (
                  ""
                )}
              </TblContainer>
            </Grid>
          </Grid>
        </Grid>
      </Paper>

      <Dialog onClose={handleClose} open={open} maxWidth="xl">
        <DialogTitle id="customized-dialog-title" style={{ fontWeight: "bold" }}>
          <Grid container spacing={0}>
            <Grid item xs={6}>
              Details of Claim Number: {dataRecords?.claimId}
            </Grid>
            <Grid item xs={6} align="right">
              <IconButton size="small" className={classes.closeButton} onClick={handleClose}>
                <Close fontSize="small" />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>

        <DialogContent dividers>
          <ClaimTable data={dataRecords} />
        </DialogContent>
        <Grid container spacing={0}>
          <Grid item xs={12} align="right" style={{ padding: 10 }}>
            <Button variant="outlined" color="primary" onClick={() => format === generatePdf(dataRecords)}>
              Print PDF
            </Button>
          </Grid>
        </Grid>
      </Dialog>
    </>
  );
}
