import { yupResolver } from "@hookform/resolvers/yup";
import CryptoJS from "crypto-js";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import OtpInput from "react-otp-input";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import configData from "../../config.json";
import { LOGIN_TYPE } from "../../Redux/Types";
//component
import Button from "components/Button";
import TextField from "components/TextField";
import { LOGGED_IN_USER } from "Redux/Types";
import { restrict } from "restrict";
//material-ui
import {
  Card,
  Dialog,
  DialogActions,
  Grid,
  IconButton,
  InputAdornment,
  Typography,
} from "@material-ui/core";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { Lock, Person, Visibility, VisibilityOff } from "@material-ui/icons";
import { toast } from "react-toastify";
import { useGlobalStyles } from "Styles";

import CircularProgress from "@material-ui/core/CircularProgress";
import CloseIcon from "@material-ui/icons/Close";

import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import RegistrationCitizen from "./RegistrationCitizen";

const useStyles = makeStyles((theme) => ({
  btn: {
    fontSize: "16px",
    textTransform: "none",
    // marginRight: "10%",
    marginTop: 20,
    backgroundColor: "#fd7e14",
    color: theme.palette.common.white,
    "&:hover, &:focus": {
      color: theme.palette.common.white,
      background: "#E26A2C",
    },
  },
  heading: {
    fontSize: "1.5rem",
    paddingBottom: 24,
    color: "#15133C",
    [theme.breakpoints.down("xs")]: {
      fontSize: "1.2rem",
    },
  },
  text: {
    paddingBottom: 12,
  },
  textfield: {
    padding: "0px 0px 8px 0px ",
  },
  card: {
    padding: "105px 58px 105px 58px",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("xs")]: {
      padding: "18px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "12px",
    },
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));

const schema = yup.object().shape({
  username: yup
    .string()
    .trim()
    .typeError("User Name is required")
    .required("User Name is required"),

  password: yup
    .string()
    .trim()
    .typeError("Passsord is required")
    .required("Password is required"),
});

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(0.1),
    color: theme.palette.grey[500],
  },
});

export const encryptFn = (data) => {
  const encryptionSecret = configData.encryptionSecret;
  var key = CryptoJS.enc.Latin1.parse(encryptionSecret);
  var iv = CryptoJS.enc.Latin1.parse(encryptionSecret);
  var encrypted = CryptoJS.AES.encrypt(data, key, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.ZeroPadding,
  });
  return encrypted.toString();
};

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

export default function Registration({ history }) {
  const classes = useStyles();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [showPassword, setShowPassword] = React.useState(false);
  const [signUp, setSignUp] = React.useState(false);
  const [isDirty, setIsDirty] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [code, setCode] = useState("");
  const [min, setMin] = useState(1);
  const [sec, setSec] = useState(30);
  const dispatch = useDispatch();
  const classes1 = useGlobalStyles();

  const [valueCheck, setValueCheck] = React.useState("berech");

  const handleChangeCheck = (event) => {
    setValueCheck(event.target.value);
  };

  const [openReg, setOpenReg] = React.useState(false);

  const handleClickOpenReg = () => {
    setOpenReg(true);
  };
  const handleCloseReg = () => {
    setOpenReg(false);
  };

  const [openPass, setOpenPass] = React.useState(false);

  const handleClickOpenPass = () => {
    setOpenPass(true);
  };
  const handleClosePass = () => {
    setOpenPass(false);
  };

  let defaultValues = {
    username: "",
    password: "",
  };

  const {
    register,
    watch,
    handleSubmit,
    reset,
    control,
    setValue,
    getValues,
    clearErrors,
    formState: { errors },
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
  });
  const handleEnter = (event) => {};

  const submitLogin = async (data, event) => {
    if (event.key === "Enter" || event.type == "click") {
      const url = configData.SERVICE_URL + "user/userLogin";
      const encryptedUserName = encryptFn(data.username);
      const encryptedPassword = encryptFn(data.password);
      setLoading(true);
      try {
        const res = await fetch(url, {
          method: "POST",
          cache: "no-cache",
          credentials: "same-origin",
          redirect: "follow",
          referrerPolicy: "no-referrer",
          body: JSON.stringify({
            userName: encryptedUserName,
            password: encryptedPassword,
          }),
        });
        
        if (res.status === 403) {
          toast.error("Concurrent Login not allowed");
        } else {
          const value = await res?.json();
          if (value.message === "success") {
            localStorage.setItem("User", JSON.stringify(value));
            localStorage.setItem("AccessToken", JSON.stringify(value?.token));
            dispatch({ type: LOGGED_IN_USER, payload: value });
          } else {
            toast.error(value.message);
          }
        }
      } catch (error) {
        console.log(error)
      }
      setLoading(false);
    }
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleResend = async () => {
    setSec(30);
    setMin(1);
  };
  useEffect(() => {
    const timer = setInterval(() => {
      if (sec > 0) {
        setSec(sec - 1);
      }
      if (sec === 0) {
        if (min === 0) {
          clearInterval(timer);
        } else {
          setMin(min - 1);
          setSec(59);
        }
      }
    }, 1000); // clearing interval
    return () => clearInterval(timer);
  });
  const handleChange = (code) => setCode(code);
  const handleBtnClick = (type) => {
    dispatch({ type: LOGIN_TYPE, payload: type });
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleLogin = (data, event) => {
    if (data.username === "Admin" && data.password === "admin") {
      console.log("ppppp");
      navigate("/Admin");
    } else {
      toast.error("Invalid");
    }
  };
  return (
    <>
      <form>
        <Card className={classes.card}>
          <Typography align="center" className={classes.heading}>
            {t("Login")}
          </Typography>

          <>
            <FormControl component="fieldset">
              <FormLabel component="legend">Select Login Type</FormLabel>
              <RadioGroup row aria-label="position" name="login" value={valueCheck} onChange={handleChangeCheck}>
                <FormControlLabel value="berech" control={<Radio />} label="Admin" />
                <FormControlLabel value="citizen" control={<Radio />} label="Citizen" />
                <FormControlLabel value="doctor" control={<Radio />} label="Doctor" />
                <FormControlLabel value="surgeryadmin" control={<Radio />} label="Surgery Admin"  />
              </RadioGroup>
            </FormControl>
            <div className={classes1.formGroup}>
              <Controller
                name="username"
                control={control}
                render={({ field: { onChange, value } }) => {
                  return (
                    <TextField
                      labelText={t("User Name")}
                      placeholder={t("User Name")}
                      onKeyDown={(e) =>
                        e.keyCode === 13 && handleSubmit(submitLogin)(e)
                      }
                      required
                      disabled={isDirty}
                      className={classes.text}
                      inputProps={{ maxLength: 20 }}
                      onInput={restrict.alphanumeric}
                      autoFocus={true}
                      value={value}
                      onChange={onChange}
                      error={!!errors.username}
                      InputProps={{
                        classes: {
                          input: classes.inputSize,
                        },

                        startAdornment: (
                          <InputAdornment position="start">
                            <Person />
                          </InputAdornment>
                        ),
                      }}
                      helperText={errors.username?.message}
                    />
                  );
                }}
              ></Controller>
            </div>
            <div className={classes1.formGroup}>
              <Controller
                name="password"
                control={control}
                render={({ field: { onChange, value } }) => {
                  return (
                    <TextField
                      labelText={t("Password")}
                      placeholder={t("Password")}
                      onKeyDown={(e) =>
                        e.keyCode === 13 && handleSubmit(submitLogin)(e)
                      }
                      required
                      autoComplete="off"
                      disabled={isDirty}
                      className={classes.text}
                      inputProps={{ maxLength: 20 }}
                      value={value}
                      onChange={onChange}
                      type={showPassword ? "text" : "password"}
                      InputProps={{
                        classes: {
                          input: classes.inputSize,
                        },
                        endAdornment: (
                          <InputAdornment position="start">
                            {showPassword ? (
                              <Visibility
                                style={{ cursor: "pointer" }}
                                onClick={handleClickShowPassword}
                              />
                            ) : (
                              <VisibilityOff
                                style={{ cursor: "pointer" }}
                                onClick={handleClickShowPassword}
                              />
                            )}
                          </InputAdornment>
                        ),
                        startAdornment: (
                          <InputAdornment position="start">
                            <Lock />
                          </InputAdornment>
                        ),
                      }}
                      error={!!errors.password}
                      helperText={errors.password?.message}
                    />
                  );
                }}
              ></Controller>
              <Grid align="right">
                <Typography
                  color="primary"
                  style={{ fontSize: "12px" }}
                  onClick={handleClickOpenPass}
                >
                  Forgot Password ?
                </Typography>
              </Grid>
            </div>
            <Button
              type="submit"
              form="form"
              align="center"
              style={{ marginTop: "15px" }}
              disabled={loading}
              color="primary"
              variant="contained"
              onClick={handleSubmit(submitLogin)}
              // onClick={handleSubmit(submitLogin)}
            >
              {loading && (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                  color="primary"
                />
              )}{" "}
              Submit
            </Button>
            <div className={classes1.formGroup}>
              <Grid align="left" style={{ marginTop: 10 }}>
                {/* <Button onClick={handleBtnClick}> */}
                {valueCheck === "berech" ? (
                  <Typography
                    color="primary"
                    style={{ fontSize: "12px" }}
                    onClick={handleBtnClick}
                  >
                    Not Registered yet? Sign Up
                  </Typography>
                ) : valueCheck === "citizen" ? (
                  <Typography
                    color="primary"
                    style={{ fontSize: "12px" }}
                    onClick={handleClickOpenReg}
                  >
                    Register to Login
                  </Typography>
                ) : null}
                {/* </Button> */}
              </Grid>
            </div>
          </>
        </Card>
      </form>
      {/* otp dialog */}
      <Dialog onClose={handleClose} open={open}>
        <DialogTitle id="customized-dialog-title">
          <Grid container spacing={0} className={classes.diagHeader}>
            <Grid items xs={11} sm={11} md={11}>
              <Typography className={classes.dialogText}>
                <b>Enter the OTP sent to your mobile phone/email </b>
              </Typography>
            </Grid>
            <Grid items xs={1}>
              <IconButton
                aria-label="close"
                className={classes.closeDialog}
                onClick={handleClose}
              >
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>

        <DialogContent dividers style={{ maxWidth: "347px" }}>
          <form onSubmit={handleEnter}>
            <Grid container spacing={0}>
              <Grid items xs={10} sm={10} md={10}>
                <div className={classes.divPadding}>
                  <OtpInput
                    value={code}
                    onChange={handleChange}
                    numInputs={6}
                    separator={<span style={{ width: "15px" }}></span>}
                    isInputNum={true}
                    shouldAutoFocus={true}
                    inputStyle={{
                      border: "1px solid #e0e0e0",
                      borderRadius: "8px",
                      width: "30px",
                      height: "34px",
                      fontSize: "12px",
                      color: "#000",
                      fontWeight: "400",
                      caretColor: "blue",
                    }}
                    focusStyle={{
                      border: "1px solid #0097a7",
                      outline: "none",
                    }}
                  />
                </div>
              </Grid>
              <Grid items xs={4} sm={4} md={3} lg={3} xl={3} align="center">
                <Typography>Resend in</Typography>
              </Grid>
              <Grid items xs={2} sm={2} md={2} lg={2} xl={2} align="left">
                <Typography variant="h8">
                  {min >= 10 ? min : `0${min}`}:{sec >= 10 ? sec : `0${sec}`}
                </Typography>
              </Grid>
              <Grid items xs={7} sm={7} md={3} lg={4} xl={4}>
                <Button
                  disabled={min !== 0 || sec !== 0}
                  onClick={handleResend}
                >
                  <Typography className={classes.button}>
                    {" "}
                    Resend OTP{" "}
                  </Typography>
                </Button>
              </Grid>
              <Grid items xs={5} sm={5} md={3} lg={3} xl={3} align="center">
                <Button color="primary" type="submit" onClick={handleEnter}>
                  <Typography className={classes.button}> Submit</Typography>
                </Button>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>

      {/* register dialog */}

      <Dialog aria-labelledby="customized-dialog-title" open={openReg}>
        <DialogTitle onClose={handleCloseReg}>Registration</DialogTitle>
        <DialogContent dividers>
          <RegistrationCitizen />
        </DialogContent>
      </Dialog>

      {/* forgot password dialog */}
      <Dialog
        onClose={handleClosePass}
        aria-labelledby="customized-dialog-title"
        open={openPass}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClosePass}>
          Reset password
        </DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={2}>
            <Grid items xs={12} className={classes.textfield}>
              <TextField
                labelText="Email Id"
                placeholder="Enter Email Id"
                size="small"
                required
                inputProps={{ maxLength: 50 }}
              />
            </Grid>
            <Grid items xs={12} className={classes.textfield}>
              <TextField
                labelText="New Password"
                placeholder="Enter New Password"
                size="small"
                required
                inputProps={{ maxLength: 50 }}
              />
            </Grid>
            <Grid items xs={12} className={classes.textfield}>
              <TextField
                labelText="Confirm Password"
                placeholder="Enter Confirm Number"
                size="small"
                required
                inputProps={{ maxLength: 50 }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            variant="contained"
            onClick={handleClosePass}
            color="primary"
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
