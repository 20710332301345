import React, { useState, useEffect } from "react";
import { OpenInNewRounded, ScheduleOutlined } from "@material-ui/icons";
import { ClickAwayListener, Grow, IconButton, ListItem, ListItemIcon, ListItemText, MenuItem, MenuList, Paper, Popper, makeStyles } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
//actions
import { addBookingId, addPatientDetails, fetchBookingList, fetchScheduledList, updateOTBooking } from "Redux/Actions/OTActions/OTPatientsAction";
import { addSelectedActionItem } from "../../../Redux/Actions/OTActions/SelectedActionItem";
//pages
import { ScheduleActionItem } from "../Pages/OTSchedule/ScheduleActionItem";
import { NotesActionItem } from "../Pages/OTNotes/NotesActionItem";
import { BillingActionItem } from "../Pages/OTBilling/BillingActionItem";
import { SELECTED_ACTION_ITEM } from "Redux/Types";
import axiosInstance from "pages/Services/AxiosInstance";
import { SubmitDialogBox } from "./SubmitDailogBox";
import { OTBookingStatusUpdate } from "pages/Services/URLs";


const useStyles = makeStyles((theme) => ({
    label: {
        fontSize: "0.875rem",
    }
}));

export default function OTMenuList(props) {

    const classes = useStyles();
    const selectedItem = useSelector((store) => store.selectedItem.item);
    const [openSubmitBox, setOpenSubmitBox] = React.useState(false);
    const bookingId = useSelector((store) => store.OTPatients.bookingId);
    const [open, setOpen] = useState(false);
    const anchorRef = React.useRef(null);
    const prevOpen = React.useRef(open);
    const dispatch = useDispatch();

    const actionItemHandler = (item) => {
        setTimeout(() => {
            dispatch({ type: SELECTED_ACTION_ITEM, payload: null });
            dispatch(addSelectedActionItem(item));
        }, 10);
        dispatch(updateOTBooking('save'));
        dispatch(addPatientDetails(props.item)) //for PatientDetailsBox
        dispatch(addBookingId(props.item?.id)) //for Booking & Schedule Tabs
        props.scroll();
    };

    const rescheduleHandler = (item) => {
        setTimeout(() => {
            dispatch({ type: SELECTED_ACTION_ITEM, payload: null });
            dispatch(addSelectedActionItem(item));
        }, 10);
        dispatch(updateOTBooking('reschedule'));
        dispatch(addPatientDetails(props.item)) //for PatientDetailsBox
        dispatch(addBookingId(props.item?.id)) //for Booking & Schedule Tabs
        props.scroll();
    };

    const handleToggleBox = () => {
        dispatch(addBookingId(props.item?.id));
        dispatch(updateOTBooking('cancelation'));
        setOpenSubmitBox(!openSubmitBox);
    }

    const executeOTCancellation = async () => {
        await axiosInstance
            .post(OTBookingStatusUpdate, {
                bookingId: bookingId,
                otStatus: "Cancelled"
            })
            .then((res) => {
                if (res.status === 200 || res.status === 201) {
                    const strsubmit = "Patient Cancelled Successfully";
                    toast.success(strsubmit);
                    handleToggleBox();
                    dispatch(fetchScheduledList());
                } else if (res.response.status === 409) {
                    toast.warning(res.response.data);
                }
            })
            .catch((error) => {
                toast.error("Something went wrong");
            });
    };

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };
    function handleListKeyDown(event) {
        if (event.key === "Tab") {
            event.preventDefault();
            setOpen(false);
        }
    }
    // return focus to the button when we transitioned from !open -> open
    useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }
        prevOpen.current = open;
    }, [open]);

    return (
        <div>
            <SubmitDialogBox openSubmitBox={openSubmitBox}
                closeSubmitBox={handleToggleBox}
                onClick={executeOTCancellation}
            />
            <IconButton
                ref={anchorRef}
                aria-controls={open ? "menu-list-grow" : undefined}
                aria-haspopup="true"
                onClick={handleToggle}
                size="small"
            >
                <OpenInNewRounded />
            </IconButton>
            <Popper
                style={{ zIndex: 2 }}
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
            >
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin:
                                placement === "bottom" ? "center top" : "center bottom",
                        }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList
                                    className={classes.label}
                                    autoFocusItem={open}
                                    id="menu-list-grow"
                                    onKeyDown={handleListKeyDown}
                                >
                                    {selectedItem?.type?.name === 'OTSchedule' &&
                                        <>
                                            <MenuItem className={classes.label}
                                                onClick={() => actionItemHandler(<ScheduleActionItem />)} >
                                                {/* <ScheduleOutlined fontSize="small" /> */}
                                                OT Schedule
                                            </MenuItem >

                                        </>}
                                    {selectedItem?.type?.name === 'OTNotes' &&
                                        <>
                                            <MenuItem className={classes.label}
                                                onClick={() => actionItemHandler(<NotesActionItem />)}>
                                                OT Notes
                                            </MenuItem>
                                            <MenuItem className={classes.label}
                                                onClick={() => rescheduleHandler(<ScheduleActionItem />)}>
                                                OT Reschedule
                                            </MenuItem>
                                            <MenuItem className={classes.label}
                                                onClick={handleToggleBox}>
                                                OT Cancellation
                                            </MenuItem>
                                        </>}
                                    {selectedItem?.type?.name === 'OTBilling' &&
                                        <>
                                            <MenuItem className={classes.label}
                                                onClick={() => actionItemHandler(<BillingActionItem />)}>
                                                OT Billing
                                            </MenuItem>
                                        </>}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </div >
    );
}