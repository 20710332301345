import React, { useEffect } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useDispatch } from "react-redux";
import { Grid } from "@material-ui/core";

import { StatusBar } from "../../components/StatusBar";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        padding: theme.spacing(10),
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
}));

export const DashBoardItems = (props) => {
    const classes = useStyles();
    const theme = useTheme();
    const dispatch = useDispatch();

    useEffect(() => {
    }, [])

    return (
        <div className={classes.root}>
            <Grid container style={{ maxWidth: "750px" }}>
                <StatusBar />
            </Grid>
        </div>
    );
}