import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from '@material-ui/core/styles';
import clsx from "clsx";
import { AppBar, Box, CssBaseline, Drawer, Grid, IconButton, List, ListItem, ListItemIcon, ListItemText, Toolbar, Typography, } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import { AttachMoneyOutlined, CreditCardOutlined, HomeOutlined, NoteAddOutlined, ScheduleOutlined } from "@material-ui/icons";
//pages
import { DashBoardItems } from "./DashboardItems";
import { OTSchedule } from "../OTSchedule/OTSchedule";
import { OTNotes } from "../OTNotes/OTNotes";
import { OTBilling } from "../OTBilling/OTBilling";
import { BillingService } from "../BillingService/BillingService";
//actions
import { addSelectedItem } from "Redux/Actions/OTActions/SeletedItem";

const drawerWidth = 220;
const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        padding: theme.spacing(0),
        justifyContent: 'center',
        alignItems: 'center'
    },
    title: {
        color: theme.palette.primary.contrastText,
    },
    appBar: {
        backgroundColor: theme.palette.primary.dark,
        zIndex: theme.zIndex.drawer + 1,
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
    drawerOpen: {
        width: drawerWidth,
        backgroundColor: theme.palette.primary.dark,
        transition: "width .7s",
        overflow: 'hidden',
    },
    drawerClose: {
        backgroundColor: theme.palette.primary.dark,
        transition: "width .7s",
        overflowX: 'hidden',
        width: theme.spacing(7) + 1,
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(7) + 1,
        },
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
    // listItemClick: {
    //     "&,&:focus": {
    //         backgroundColor: theme.palette.primary.dark,
    //     },
    //     "&:hover": {
    //         backgroundColor: theme.palette.primary.dark,
    //     },
    // },
    dashboard: {
        "&:hover": {
            backgroundColor: theme.palette.primary.dark,
        },
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
}));
export const OTDashboard = (props) => {
    const classes = useStyles();
    const theme = useTheme();
    const selectedItem = useSelector((store) => store.selectedItem.item);
    const dispatch = useDispatch();
    const [openDrawer, setOpenDrawer] = React.useState(true);

    const AddListItem = (item) => {
        dispatch(addSelectedItem(item));
        //window.scrollTo(0, 0);
    }

    const DrawerList = () => (
        <List >
            <ListItem key={0} divider button
                onClick={() => AddListItem(<DashBoardItems />)}
                className={classes.dashboard}
                style={{
                    marginLeft: -8, marginTop: 70, marginBottom: 10, borderRadius: 10, background: theme.palette.background.light,
                    color: theme.palette.text.primary,
                }} >
                <Box style={{ display: 'flex', flexDirection: 'row', }}>
                    <ListItemIcon style={{ margin: 4, marginLeft: 8, }}><HomeOutlined /></ListItemIcon >
                    <ListItemText primary='DashBoard' />
                </Box>
            </ListItem>
            <ListItem key={1} divider button
                className={classes.title}
                style={{ backgroundColor: selectedItem?.type?.name === 'OTSchedule' && theme.palette.primary.main }}
                onClick={() => AddListItem(<OTSchedule />)}>
                <ListItemIcon className={classes.title}><ScheduleOutlined /></ListItemIcon >
                <ListItemText primary='OT Schedule' />
            </ListItem >
            <ListItem key={2} divider button className={classes.title}
                style={{ backgroundColor: selectedItem?.type?.name === 'OTNotes' && theme.palette.primary.main }}
                onClick={() => AddListItem(<OTNotes />)}>
                <ListItemIcon className={classes.title} ><NoteAddOutlined className={classes.title} /></ListItemIcon >
                <ListItemText primary='OT Notes' />
            </ListItem>
            <ListItem key={3} divider button className={classes.title}
                style={{ backgroundColor: selectedItem?.type?.name === 'OTBilling' && theme.palette.primary.main, }}
                onClick={() => AddListItem(<OTBilling />)}>
                <ListItemIcon className={classes.title} ><AttachMoneyOutlined className={classes.title} /></ListItemIcon >
                <ListItemText primary='OT Billing' />
            </ListItem>
            <ListItem key={4} divider button
                className={classes.title}
                style={{ backgroundColor: selectedItem?.type?.name === 'BillingService' && theme.palette.primary.main, }}
                onClick={() => AddListItem(<BillingService />)}>
                <ListItemIcon className={classes.title} ><CreditCardOutlined /></ListItemIcon>
                <ListItemText primary='Billing Service' />
            </ListItem>
        </List>
    );

    return (
        <div className={classes.root}>
            <CssBaseline />
            <AppBar
                position="fixed"
                className={clsx(classes.appBar)}
            >
                <Toolbar variant="dense">
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={() => setOpenDrawer(!openDrawer)}
                        edge="start"
                    >
                        {openDrawer ? <ChevronLeftIcon /> : <MenuIcon />}
                    </IconButton>
                    <Grid container className={classes.appBarText}>
                        <Grid item>
                            <Typography
                                onClick={() => setOpenDrawer(!openDrawer)}
                                variant="h6"
                                className={classes.title}
                            >
                                BEL RETIRED EMPLOYEES CONTRIBUTORY HEALTH SCHEME (BERECHS)
                            </Typography>
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>
            <Drawer
                variant="permanent"
                className={clsx(classes.drawer, {
                    [classes.drawerOpen]: openDrawer,
                    [classes.drawerClose]: !openDrawer,
                })}
                classes={{
                    paper: clsx({
                        [classes.drawerOpen]: openDrawer,
                        [classes.drawerClose]: !openDrawer,
                    }),
                }}
            >
                {DrawerList()}
            </Drawer>
            <main className={classes.content}>
                {/* <div className={classes.toolbar} /> */}
                {selectedItem ? selectedItem : <DashBoardItems />}
            </main>
        </div>
    );
}
