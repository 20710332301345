import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import * as yup from "yup";
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// material-ui
import { Grid, InputAdornment, Typography, makeStyles } from '@material-ui/core';
// components
import Button from "../../../../components/Button";
import SlotDurationTable from './SlotDurationTable';
import { restrict } from "../../../../restrict";
//url
import { saveSlotDuration, slotDurationgetAll, updateSlotDuration } from 'pages/Services/URLs';
import axiosInstance from 'pages/Services/AxiosInstance';
import TextField from 'components/TextField';

const useStyles = makeStyles((theme) => ({
  center: {
    alignItems: 'center',
    display: 'Grid',
  },
  head: {
    fontSize: "1.3rem",
    fontWeight: 400,
    paddingBottom: "12px",
  },
  padding: {
    [theme.breakpoints.down("sm")]: {
      padding: "4px 0px 8px 8px",
    },
    [theme.breakpoints.down("lg")]: {
      padding: "2px 0px 8px 26px",
    },
    reset: {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.primary.contrastText,
      "&:hover, &:focus": {
        color: theme.palette.primary.contrastText,
        background: theme.palette.primary.main,
      },
    },
    button: {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.secondary.contrastText,
      "&:hover, &:focus": {
        color: theme.palette.secondary.contrastText,
        background: theme.palette.secondary.main,
      },
    },
  },
}));
export default function SlotDuration() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [records, setRecords] = useState([]);
  const [edit, setEdit] = React.useState(false);
  const defaultValues = {
    id: 0,
    departmentId: 1,
    duration: "",
  }
  const schema = yup.object().shape({
    duration: yup
      .number().max(45).min(1)
      .typeError("Consultation Period is required")
      .required("Consultation Period is required")
  });
  const { reset, handleSubmit, control, formState: { errors } } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });
  const submitdata = () => {
    alert("test");
  }

  const fetchSlot = async () => {
    await axiosInstance
      .get(slotDurationgetAll)
      .then((res) => {
        setRecords(res.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };
  useEffect(() => {
    fetchSlot();
  }, []);
  const setEditdata = (edit) => {
    setEdit(edit);
    reset(edit);
  }

 const executeUpdate = async (data) => {
  await axiosInstance
  .post(updateSlotDuration, {...data})
  .then((res)=> {
    if(res.status === 200){
      const strupdate = "Data Successfully Updated.";
      fetchSlot();
      toast.success(strupdate);
      resetForm();
    } else if (res.response.status === 409) {
      toast.warning(res.response.data);
    }
  }).catch((error) => {
    toast.error("Something went wrong");
  });
 };
 const executeSubmit = async (data) => {
    await axiosInstance
      .post(saveSlotDuration, {...data })
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          const strsubmit =  "Data Successfully Submitted.";
          fetchSlot();
          toast.success(strsubmit);
          resetForm();
        } else if (res.response.status === 409) {
          toast.warning(res.response.data);
        }
      })
      .catch((error) => {
        toast.error("Something went wrong");
      });
  };
  const resetForm = () => {
    reset(defaultValues);
    setEdit(false);
  };
    return (
    <>
      <form onSubmit={handleSubmit(executeSubmit)}>

        <Grid container justifyContent='center' spacing={2} >
          <Grid item>
            <Typography> Enter Consultation Period(mins):</Typography>
          </Grid>        
          <Grid item>
            <Controller
              name="duration"
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  placeholder="Consultation Period"
                  size="small"
                  variant="outlined"
                  endAdornment={<InputAdornment position="end">Kg</InputAdornment>}
                  value={value}
                  onChange={onChange}
                  inputProps={{ maxLength: 2 }}
                  error={!!errors.duration}
                  helperText={errors.duration?.message}
                  onInput={(e) => { restrict.digits(e) }}
                />
              )}
            />
          </Grid>          
          <Grid item>
            {edit ? (
              <Button
                align="center"
                type="submit"
                color="primary"
                variant="contained"
                sx={{ mr: 2 }}
                className={classes.reset}
                onClick={handleSubmit(executeUpdate)}
              >
                Update
              </Button>

            ) : (

              <Button
                className={classes.reset}
                align="center"
                type="submit"
                color="primary"
                variant="contained"
                sx={{ m: 6 }}
                onClick={handleSubmit(executeSubmit)}
              >
                Submit
              </Button>
            )}
          </Grid>
          
          <Grid item>
            <Button
              align="center"
              color="primary"
              variant="contained"
              className={classes.reset}
              onClick={() => {
                resetForm();
              }}>
              Reset
            </Button>
          </Grid>         
          <Grid container className={classes.center} >
            <SlotDurationTable data={records} setEditdata={setEditdata} fetch={fetchSlot} />
          </Grid>
        </Grid>
      </form>
    </>
  )
}


