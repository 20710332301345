import { FETCH_SUBMITTED_CLAIM } from "../Types";

import axiosInstance from "../../pages/Services/AxiosInstance";
import { submittedClaims } from "../../pages/Services/URLs";

export const fetchSubmittedClaims = () => async (dispatch) => {
  try {
    const res = await axiosInstance.get(submittedClaims);
    // console.log("res", res.data.length);
    dispatch({
      type: FETCH_SUBMITTED_CLAIM,
      payload: res.data,
    });
  } catch (error) {
    console.log("fetchSubmittedClaims error", error);
  }
};
