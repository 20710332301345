import React, { useEffect } from "react";
import { Grid, Typography, makeStyles } from "@material-ui/core";
import { toast } from "react-toastify";
import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from "react-hook-form";
//components
import TextField from "components/TextField";
import Button from "components/Button";
import axiosInstance from "pages/Services/AxiosInstance";
import { InventoryMstDeleteURL, InventoryMstURL, } from "pages/Services/URLs";
import { DeleteDialogBox } from "pages/OT/components/DeleteDailogBox";
import { restrict } from "restrict";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        padding: theme.spacing(0),
    },
    label: {
        fontSize: "0.875rem",
        color: theme.palette.text.primary,
    },
}));

export const InventoryMstForm = (props) => {
    const classes = useStyles();
    const [edit, setEdit] = React.useState(false);

    const defaultValues = {
        name: "",
    }
    const Schema = yup.object().shape({
        name: yup.string().matches(/^(?!\s)(?!-)(?!.*--)[A-Za-z0-9 -]*$/, 'Only alpha, numeric, "-" are allowed').required("Service Name is required"),
    });

    const {
        watch,
        handleSubmit,
        reset,
        control,
        resetField,
        getValues,
        clearErrors,
        formState: { errors },
    } = useForm({
        defaultValues,
        resolver: yupResolver(Schema),
        mode: "onChange",
    });

    const resetForm = () => {
        reset(defaultValues);
    };

    useEffect(() => {
        if (props.item.length === 0) {
            resetForm();
            setEdit(false);
        } else {
            reset(props.item);
            setEdit(true);
        }
    }, [props.item, props.deleteItem]);

    const executeSubmit = async (data) => {
        await axiosInstance
            .post(InventoryMstURL, { ...data })
            .then((res) => {
                if (res.status === 200 || res.status === 201) {
                    const strsubmit = "Inventory Added Successfully";
                    toast.success(strsubmit);
                    resetForm();
                    props.fetchInventoryMstList();
                } else if (res.status === 409) {
                    toast.warning(res.response.data);
                }
            })
            .catch((error) => {
                if (error.response.status === 409) {
                    toast.warning(error.response.data);
                }
                else {
                    toast.error("Something went wrong");
                }
            });
    }
    const executeUpdate = async (data) => {
        await axiosInstance
            .post(InventoryMstURL, { ...data })
            .then((res) => {
                if (res.status === 200 || res.status === 201) {
                    const strupdate = "Inventory Updated Successfully";
                    toast.success(strupdate);
                    resetForm();
                    props.fetchInventoryMstList();
                } else if (res.status === 409) {
                    toast.warning(res.response.data);
                }
            })
            .catch((error) => {
                if (error.response.status === 409) {
                    toast.warning(error.response.data);
                }
                else {
                    toast.error("Something went wrong");
                }
            });
    }
    const executeDelete = async (data) => {
        await axiosInstance
            .post(InventoryMstDeleteURL, { ...data })
            .then((res) => {
                if (res.status === 200 || res.status === 201) {
                    const strsubmit = "Record Deleted";
                    toast.error(strsubmit);
                    props.closeDeleteBox();
                    props.fetchInventoryMstList();
                } else if (res.response.status === 409) {
                    toast.warning(res.response.data);
                }
            })
            .catch((error) => {
                if (error.response.status === 409) {
                    toast.warning(error.response.data);
                }
                else {
                    toast.error("Something went wrong");
                }
            });
    }

    return (
        <div className={classes.root}>
            <form onSubmit={handleSubmit(executeSubmit)}>
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12} sm={12} md={1} lg={1}>
                        <Typography>Inventory<span style={{ color: 'red' }}>*</span> </Typography>
                    </Grid>
                    <Grid item xs={12} sm={7} md={3} lg={3}>
                        <Controller
                            name="name"
                            control={control}
                            render={({ field: { onChange, value } }) => (
                                <TextField
                                    placeholder="Service Name"
                                    size="small"
                                    variant="outlined"
                                    value={value}
                                    onChange={onChange}
                                    onInput={(e) => { restrict.OTInputs(e) }}
                                    error={!!errors.name}
                                    helperText={errors.name?.message}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} sm={3} md={5} lg={5}>
                        <Grid container spacing={2} >
                            {edit ?
                                <Grid item>
                                    <Button
                                        align="center"
                                        type="submit"
                                        color="primary"
                                        variant="contained"
                                        onClick={handleSubmit(executeUpdate)}
                                    >
                                        Update
                                    </Button>
                                </Grid>
                                :
                                <Grid item>
                                    <Button
                                        align="center"
                                        type="submit"
                                        color="primary"
                                        variant="contained"
                                        onClick={handleSubmit(executeSubmit)}
                                    >
                                        Add
                                    </Button>
                                </Grid>
                            }
                            <Grid item>
                                <Button
                                    onClick={() => {
                                        resetForm();
                                        setEdit(false);
                                    }}
                                    align="center"
                                    type="submit"
                                    color="primary"
                                    variant="outlined"
                                >
                                    Reset
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </form >
            <DeleteDialogBox openDeleteBox={props.openDeleteBox}
                closeDeleteBox={props.closeDeleteBox}
                onClick={() => executeDelete(props.deleteItem)}
            />
        </div >
    )
}