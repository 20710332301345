import { SELECTED_ACTION_ITEM } from "Redux/Types";

export const selectedActionItem = (state = "", action) => {

    switch (action.type) {
        case SELECTED_ACTION_ITEM:
            return {
                ...state,
                item: action.payload,
            };
        default:
            return state;
    }
}
