import React, { useState, useEffect } from "react";
import { useRoutes, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import NotFound from "NotFound";
import HomePage from "pages/HomePage/HomePage";
import ClaimStatus from "pages/BillTables/ClaimStatus/ClaimStatusTable";
import Admin from "./pages/Admin/AdminTabs";
import AdminLogin from "./pages/Admin/AdminLogin";
import EmployeeLayout from "pages/Layout/EmployeeLayout";
import Consultation from "pages/Doctor/Consultation";
import ConsultationCitizen from "pages/Citizen/ConsultationCitizen";
import { OTDashboard } from "pages/OT/Pages/Dashboard/OTDashboard";
import LoginPage from "NewLayout/LoginPage";
import HRDashboard from "NewLayout/HRDashboard";
// const Notifications = lazy(() => import("pages/Notifications"));

export default function Routes() {
  const user =
    useSelector((store) => store.loggedInUser) ||
    JSON.parse(localStorage.getItem("User"));

  window.onbeforeunload = function() {
    localStorage.clear();
  };
  //237
  const allRoutes = [
    {
      path: "/",
      element:
        user?.roleId === 2  || user?.roleId === 3||user?.roleId === 7? (
          <EmployeeLayout />
        
        ) 
        : (
          <LoginPage />
        ),
      // since all types of roleTypes having -- Home (resolve the "/" and "/Home" to the Home Page)
      // children: [
      //   { index: true, element: <Dashboard /> },
      //   // { path: "/Notifications", element: <Notifications /> },
      //   // ...routes,
      // ],
    },
   
    { path: "/", element: <LoginPage /> },
    
    {
      path: "/*",
      element: user ? <NotFound /> : <Navigate replace to={"/"} />,
    },
  ];

  return useRoutes(allRoutes);
}
