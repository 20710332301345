import * as React from "react";
import {
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
  makeStyles,
  IconButton,
  Card,
  CardContent,
  AppBar,
} from "@material-ui/core";
import Button from "components/Button";
import CloseIcon from "@material-ui/icons/Close";
import { useSelector } from "react-redux";
import {
  blockSlotsAppontmentUrl,
  getAllSlotsOfDocWithAppts,
} from "pages/Services/URLs";
import axiosInstance from "pages/Services/AxiosInstance";
import moment from "moment";
import noSlotsImg from "../../assets/Images/noslots.png";
import { toast } from "react-toastify";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  appbar: {
    padding: 8,
  },
  btn: {
    "& > *": {
      margin: theme.spacing(1),
    },
    alignContent: "right",
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(-0.4),
    color: theme.palette.primary.contrastText,
  },
  cardAvailable: {
    width: 210,
    height: 50,
    color: "green",
    margin: "10px",
    border: "1px solid green",
    cursor: "pointer",
    userSelect: "none",
  },
  cardSelected: {
    width: 210,
    height: 50,
    color: "white",
    margin: "10px",
    backgroundColor: "gray",
    cursor: "pointer",
    border: "1px solid gray",
    userSelect: "none",
  },
  cardNotAvailable: {
    width: 210,
    height: 50,
    margin: "10px",
    color: "#8d8d8d",
    cursor: "not-allowed",
    backgroundColor: "#e0e0e0",
    userSelect: "none",
  },
  gridslots: {
    justifyContent: "center",
    justifyItems: "center",
    display: "grid",
    width: "100%",
    gridTemplateRows: "repeat(0, minmax(100px, min-content))",
    gridTemplateColumns: "repeat(auto-fill,220px)",
    gridTemplateColumns: "repeat(auto-fill,220px)",
    gridTemplateColumns: "repeat(auto-fit,minmax(220px, 1fr))",
  },
}));

export default function MySlots(props) {
  const classes = useStyles();
  const logineduser =
    useSelector((store) => store.loggedInUser) ||
    JSON.parse(localStorage.getItem("User"));
  const [slots, setSlots] = React.useState([]);
  const [selectedslot, setSelectedSlot] = React.useState([]);
  const [updateslots, setupdateslots] = React.useState([]);

  React.useEffect(() => {
    setSlots(updateslots);
    setSelectedSlot([]);
    setupdateslots([]);
    setSlots([]);
    getAllSlots();
  }, []);

  const getAllSlots = () => {
    let todaydate = moment(todaydate).format("YYYY-MM-DD");
    let doctorID = parseInt(logineduser?.empId);
    let departmentID = 1;
    axiosInstance
      .get(
        getAllSlotsOfDocWithAppts +
          "/" +
          todaydate +
          "/" +
          doctorID +
          "/" +
          departmentID
      )
      .then((res) => {
        const updateslots = res.data.map((item) => {
          return {
            ...item,
            selected: false,
            doctorId: logineduser?.empId,
            patientId: logineduser?.empId,
            isTeleConsultation: false,
          };
          // }
          // console.log(nowDatetime > selectedSlots);
        });
        const updateslotsfilterdates = updateslots.filter(
          (value) =>
            moment(value?.apptStart).format("YYYY-MM-DDTHH:mm") >
            moment().format("YYYY-MM-DDTHH:mm")
        );
        // setupdateslots(updateslots);
        setSlots(updateslotsfilterdates);
      })
      .catch((e) => console.log(e.message));
  };

  const selectSlot = (id) => {
    const afterseleteddata = slots.map((el) => {
      if (el.selected === false) {
        return el.id === id
          ? { ...el, selected: true, isBlocked: true }
          : { ...el };
      } else {
        return el.id === id
          ? { ...el, selected: false, isBlocked: false }
          : { ...el };
      }
    });

    setSlots(afterseleteddata);
    setSelectedSlot(
      afterseleteddata.filter((value) => {
        return value.selected === true;
      })
    );
  };

  const blockAppontment = () => {
    console.log(selectedslot);

    if (selectedslot.length > 0) {
      axiosInstance
        .post(blockSlotsAppontmentUrl, selectedslot)
        .then((res) => {
          if (res.status === 200 || res.status === 201) {
            const strsubmit = "Data Successfully Submitted.";
            toast.success(strsubmit);
            getAllSlots();
          } else if (res.status === 409) {
            toast.warning(res.status.data);
          } else {
            toast.warning("Something Went Wrong");
            getAllSlots();
          }
        })
        .catch((error) => {
          if (error.response.status == 409) {
            toast.warning(error.response.data);
          } else {
            toast.error("Something Went Wrong");
          }
        });
    } else {
      toast.warning(" NO Slots Seleted ");
    }
  };

  console.log("seletd--------", selectedslot);
  return (
    <div className={classes.root}>
      <Dialog
        open={props.openMySlots}
        onClose={() => props.closeMySlots}
        fullWidth
        maxWidth="lg"
      >
        <AppBar position="static" className={classes.appbar}>
          <Grid container spacing={0}>
            <Grid items xs={11}>
              <Typography>My Slots</Typography>
            </Grid>
            <Grid items xs={1}>
              <IconButton
                aria-label="close"
                className={classes.closeButton}
                onClick={props.closeMySlots}
              >
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </AppBar>

        <DialogContent>
          <Grid container justifyContent="center">
            {slots.length > 0 ? (
              <>
                {" "}
                <Grid container>
                  <div align="center" className={classes.gridslots}>
                    {slots.map((item, index) => (
                      <>
                        {/* <Grid item> */}
                        <Card
                          align="center"
                          onClick={() =>
                            item.available == true
                              ? selectSlot(item.id)
                              : console.log("Not Avelable")
                          }
                          className={
                            item.available == true && item.selected == true
                              ? classes.cardSelected
                              : item.available == false
                              ? classes.cardNotAvailable
                              : classes.cardAvailable
                          }
                        >
                          <CardContent align="center">
                            {" "}
                            {moment(item.apptStart).format("hh:mm A") +
                              " to  " +
                              moment(item.apptEnd).format("hh:mm A")}
                          </CardContent>
                        </Card>
                        {/* </Grid> */}
                      </>
                    ))}
                  </div>
                </Grid>
              </>
            ) : (
              <>
                <Grid container xs={12}>
                  <fieldset style={{ width: "100%" }}>
                    <legend>Slots:</legend>
                    <Grid
                      container
                      spacing={1}
                      style={{ padding: 12 }}
                      align="center"
                    >
                      <Grid item xs={12} direction="row">
                        <img src={noSlotsImg} width={100} />
                        <Typography> No Slots Available</Typography>
                      </Grid>
                    </Grid>
                  </fieldset>
                </Grid>
              </>
            )}
          </Grid>
        </DialogContent>

        <DialogActions>
          <Grid item xs={12} align="right" className={classes.btn}>
            <Button
              color="primary"
              variant="contained"
              onClick={blockAppontment}
            >
              Block
            </Button>
            {/* <Button
              color="primary"
              onClick={props.closeMySlots}
              variant="contained"
            >
              Unblock
            </Button> */}
          </Grid>
        </DialogActions>
      </Dialog>
    </div>
  );
}
