import { Grid, Typography, makeStyles } from "@material-ui/core";
import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from "react-hook-form";
import React, { useEffect } from "react";
import { Autocomplete } from "@material-ui/lab";
import { toast } from "react-toastify";
//components
import TextField from "components/TextField";
import Button from "components/Button";
import axiosInstance from "pages/Services/AxiosInstance";
import { BillingServiceDeleteURL, BillingServiceURL, BillingServiceUpdateURL, ChecklistMstDataURL, DepartmentMstDataURL, EquipmentMstDataURL, InventoryMstDataURL, MedicineMstDataURL, PhysicianMstDataURL, ServiceTypeDataURL } from "pages/Services/URLs";
import { DeleteDialogBox } from "pages/OT/components/DeleteDailogBox";
import { restrict } from "restrict";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        padding: theme.spacing(0),
    },
    label: {
        fontSize: "0.875rem",
        color: theme.palette.text.primary,
    },
}));

export const BillingServicePriceForm = (props) => {
    const classes = useStyles();
    const [serviceTypeList, setServiceTypeList] = React.useState([]);
    const [serviceNameList, setServiceNameList] = React.useState([])
    const [departmentList, setDepartmentList] = React.useState([]);
    const [servicetypeIdValue, setServicetypeIdValue] = React.useState(0);
    const [edit, setEdit] = React.useState(false);

    const defaultValues = {
        servicetypeId: "",
        departmentId: "",
        servicenameId: "",
        price: "",
    }
    const Schema = yup.object().shape({
        servicetypeId: yup.string().required("Service Type is required"),
        departmentId: servicetypeIdValue === 1 || servicetypeIdValue === 2 ?
            yup.string().required("Department is required") : null,
        servicenameId: yup.string().required("Service is required"),
        price: yup.string().required("Price is required")
    });

    const {
        watch,
        handleSubmit,
        reset,
        control,
        resetField,
        getValues,
        clearErrors,
        formState: { errors },
    } = useForm({
        defaultValues,
        resolver: yupResolver(Schema),
        mode: "onChange",
    });

    const resetForm = () => {
        reset(defaultValues);
    };

    useEffect(() => {
        if (props.item.length === 0) {
            resetForm();
            setEdit(false);
        } else {
            reset(props.item);
            setEdit(true);
        }
    }, [props.item, props.deleteItem]);

    useEffect(() => {
        fetchServiceTypeList();
    }, []);

    useEffect(() => {
        fetchServiceNames();
    }, [watch('servicetypeId')]);

    useEffect(() => {
        fetchPhysicianList();
    }, [watch('departmentId')]);

    const fetchServiceTypeList = () => {
        axiosInstance
            .get(ServiceTypeDataURL)
            .then((res) => {
                setServiceTypeList(res.data);
            })
            .catch((e) => console.log("fetchServiceTypeListError:" + e.message));
    }
    const fetchDepartmentList = () => {
        axiosInstance
            .get(DepartmentMstDataURL)
            .then((res) => {
                setDepartmentList(res.data);
            })
            .catch((e) => console.log("fetchDepartmentListError:" + e.message));
    }
    const fetchPhysicianList = () => {
        let departmentId = parseInt(getValues("departmentId"));
        departmentId &&
            axiosInstance
                .get(PhysicianMstDataURL + departmentId)
                .then((res) => {
                    setServiceNameList(res.data);
                })
                .catch((e) => console.log("fetchPhysicianListError:" + e.message));
    }
    const fetchServiceNameList = (URL) => {
        axiosInstance
            .get(URL)
            .then((res) => {
                setServiceNameList(res.data);
            })
            .catch((e) => console.log("fetchServiceNameListError:" + e.message));
    }
    const fetchServiceNames = () => {
        let servicetypeId = parseInt(getValues("servicetypeId"));
        setServicetypeIdValue(servicetypeId);
        if (servicetypeId) {
            try {
                servicetypeId === 1 || servicetypeId === 2 ? fetchDepartmentList() :
                    servicetypeId === 3 ?
                        fetchServiceNameList(ChecklistMstDataURL) :
                        servicetypeId === 4 ?
                            fetchServiceNameList(InventoryMstDataURL) :
                            servicetypeId === 5 ?
                                fetchServiceNameList(EquipmentMstDataURL) :
                                servicetypeId === 6 ?
                                    fetchServiceNameList(MedicineMstDataURL) :
                                    console.log(servicetypeId);
            }
            catch (e) {
                console.log("fetchServiceNamesError:" + e.message);
            }
        }
    }
    const executeSubmit = async (data) => {
        await axiosInstance
            .post(BillingServiceURL, { ...data, })
            .then((res) => {
                if (res.status === 200 || res.status === 201) {
                    const strsubmit = "Service Added Successfully";
                    toast.success(strsubmit);
                    resetForm();
                    servicetypeIdValue === 1 || servicetypeIdValue === 2 ?
                        props.fetchDoctorsPriceList() :
                        props.fetchServicePriceList();
                } else if (res.status === 409) {
                    toast.warning(res.response.data);
                }
            })
            .catch((error) => {
                if (error.response.status === 409) {
                    toast.warning(error.response.data);
                }
                else {
                    toast.error("Something went wrong");
                }
            });
    }
    const executeUpdate = async (data) => {
        await axiosInstance
            .post(BillingServiceUpdateURL, { ...data })
            .then((res) => {
                if (res.status === 200 || res.status === 201) {
                    const strupdate = "Service Updated Successfully";
                    toast.success(strupdate);
                    resetForm();
                    servicetypeIdValue === 1 || servicetypeIdValue === 2 ?
                        props.fetchDoctorsPriceList() :
                        props.fetchServicePriceList();
                } else if (res.status === 409) {
                    toast.warning(res.response.data);
                }
            })
            .catch((error) => {
                if (error.response.status === 409) {
                    toast.warning(error.response.data);
                }
                else {
                    toast.error("Something went wrong");
                }
            });
    }
    const executeDelete = async (data) => {
        await axiosInstance
            .post(BillingServiceDeleteURL, { ...data })
            .then((res) => {
                if (res.status === 200 || res.status === 201) {
                    const strsubmit = "Record Deleted";
                    toast.error(strsubmit);
                    props.closeDeleteBox();
                    data.departmentName ?
                        props.fetchDoctorsPriceList() :
                        props.fetchServicePriceList();
                } else if (res.response.status === 409) {
                    toast.warning(res.response.data);
                }
            })
            .catch((error) => {
                toast.error("Something went wrong");
            });
    }

    return (
        <div className={classes.root}>
            <form onSubmit={handleSubmit(executeSubmit)}>
                <div >
                    <Grid container spacing={2} >
                        <Grid item container xs={12} md={6} lg={4} alignItems="center" >
                            <Grid item xs={4}>
                                <Typography > Service Type<span style={{ color: 'red' }}>*</span> </Typography>
                            </Grid>
                            <Grid item xs={8}  >
                                <Controller
                                    name="servicetypeId"
                                    control={control}
                                    render={({
                                        field: { onChange, value },
                                    }) => (
                                        <Autocomplete
                                            componentsProps={{ popper: { style: { width: 'fit-content' } } }}
                                            size="small"
                                            options={serviceTypeList}
                                            value={serviceTypeList.find((o) => o.id === value) || null
                                            }
                                            getOptionLabel={(option) =>
                                                option.name ? option.name : null
                                            }
                                            onChange={(e, v) => {
                                                onChange((v?.id));
                                                resetField('departmentId');
                                                resetField('servicenameId');
                                                resetField('price');
                                                // =console.log(v)
                                            }}
                                            disabled={edit ? true : false}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    variant="outlined"
                                                    placeholder="Select Service Type"
                                                    error={!!errors.servicetypeId}
                                                    helperText={errors.servicetypeId?.message}
                                                />
                                            )}
                                        />
                                    )}
                                />


                            </Grid>
                        </Grid>
                        {servicetypeIdValue === 1 || servicetypeIdValue === 2 ?
                            <Grid item container xs={12} md={6} lg={4} alignItems="center" >
                                <Grid item xs={4}>
                                    <Typography> Department <span style={{ color: 'red' }}>*</span> </Typography>
                                </Grid>
                                <Grid item xs={8}  >
                                    <Controller
                                        name="departmentId"
                                        control={control}
                                        render={({
                                            field: { onChange, value },
                                        }) => (
                                            <Autocomplete
                                                componentsProps={{ popper: { style: { width: 'fit-content' } } }}
                                                size="small"
                                                options={departmentList}
                                                value={departmentList.find((o) => o.id === value) || null
                                                }
                                                getOptionLabel={(option) =>
                                                    option.departmentName ? option.departmentName : null
                                                }
                                                onChange={(e, v) => {
                                                    onChange((v?.id));
                                                    // console.log(v)
                                                }}
                                                disabled={edit ? true : false}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        variant="outlined"
                                                        placeholder="Select Department"
                                                        error={!!errors.departmentId}
                                                        helperText={errors.departmentId?.message}
                                                    />
                                                )}
                                            />
                                        )}
                                    />
                                </Grid>
                            </Grid>
                            : null
                        }
                        <Grid item container xs={12} md={6} lg={4} alignItems="center" >
                            <Grid item xs={4}>
                                {
                                    servicetypeIdValue === 1 || servicetypeIdValue === 2 ?
                                        <Typography>Doctor Name<span style={{ color: 'red' }}>*</span> </Typography> :
                                        <Typography>Service Name<span style={{ color: 'red' }}>*</span> </Typography>
                                }

                            </Grid>
                            <Grid item xs={8}  >
                                <Controller
                                    name="servicenameId"
                                    control={control}
                                    render={({
                                        field: { onChange, value },
                                    }) => (
                                        <Autocomplete
                                            componentsProps={{ popper: { style: { width: 'fit-content' } } }}
                                            size="small"
                                            options={serviceNameList}
                                            value={serviceNameList.find((o) => o.id === value) || null
                                            }
                                            getOptionLabel={(option) =>
                                                option.name ? option.name : null
                                            }
                                            onChange={(e, v) => {
                                                onChange((v?.id));
                                                // console.log(v)
                                            }}
                                            disabled={edit ? true : false}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    variant="outlined"
                                                    placeholder={servicetypeIdValue === 1 ||
                                                        servicetypeIdValue === 2 ? "Select Doctor Name" :
                                                        "Select Service Name"}
                                                    error={!!errors.servicenameId}
                                                    helperText={errors.servicenameId?.message}
                                                />
                                            )}
                                        />
                                    )}
                                />
                            </Grid>
                        </Grid>
                        <Grid item container xs={12} md={6} lg={4} alignItems="center" >
                            {
                                servicetypeIdValue === 1 || servicetypeIdValue === 2 ?
                                    <Grid item xs={4} md={4} lg={4}>
                                        <Typography > Price <span style={{ color: 'red' }}>*</span> </Typography>
                                    </Grid> :
                                    <Grid item xs={4} md={4} lg={2}>
                                        <Typography > Price <span style={{ color: 'red' }}>*</span> </Typography>
                                    </Grid>
                            }

                            <Grid item xs={8}>
                                <Controller
                                    name="price"
                                    control={control}
                                    render={({ field: { onChange, value } }) => (
                                        <TextField
                                            name="price"
                                            placeholder="Price"
                                            size="small"
                                            variant="outlined"
                                            value={value}
                                            onChange={onChange}
                                            onInput={(e) => { restrict.digits(e) }}
                                            error={!!errors.price}
                                            helperText={errors.price?.message}
                                        />
                                    )}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={12} >
                            <Grid container spacing={2} justifyContent="center"
                                style={{ marginTop: 20 }}>
                                {edit ?
                                    <Grid item>
                                        <Button
                                            align="center"
                                            type="submit"
                                            color="primary"
                                            variant="contained"
                                            onClick={handleSubmit(executeUpdate)}
                                        >
                                            Update
                                        </Button>
                                    </Grid>
                                    :
                                    <Grid item>
                                        <Button
                                            align="center"
                                            type="submit"
                                            color="primary"
                                            variant="contained"
                                            onClick={handleSubmit(executeSubmit)}
                                        >
                                            Add
                                        </Button>
                                    </Grid>
                                }
                                <Grid item>
                                    <Button
                                        onClick={() => {
                                            resetForm();
                                            setEdit(false);
                                        }}
                                        align="center"
                                        type="submit"
                                        color="primary"
                                        variant="outlined"
                                    >
                                        Reset
                                    </Button>
                                </Grid>

                            </Grid>
                        </Grid>
                    </Grid>
                </div>
            </form >
            <DeleteDialogBox openDeleteBox={props.openDeleteBox}
                closeDeleteBox={props.closeDeleteBox}
                onClick={() => executeDelete(props.deleteItem)}
            />
        </div >
    )
}