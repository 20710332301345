import React, { useEffect } from "react";
import Socket from "react-stomp";
import { SERVICE_URL } from "../config.json";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import Button from "components/Button";
import { useDispatch, useSelector } from "react-redux";
import { Box, Grid, makeStyles } from "@material-ui/core";
import { addSelectedAppointmentDetails } from "Redux/Actions/AppointmentAction";
import Swal from "sweetalert2";
import notificationAudio from "../assets/audios/alertaudio.mp3";

const useStyles = makeStyles((theme) => ({
  btn: {
    backgroundColor: theme.palette.primary,
  },
}));

function GlobalSocket({ loginuser }) {
  const primaryColor = useSelector((store) => store.selectedTheme.primary);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const classes = useStyles();

  const HandleJoin = (appdto) => {
    dispatch(addSelectedAppointmentDetails(appdto));
    navigate("/ConsultationCitizen/");
  };
  // const CustomToastWithLink = (msg) => {
  //   return (
  //     <div>
  //       {/* <Button onClick={() => HandleJoin(msg?.patientappointmentDto)}>
  //         Join
  //       </Button>
  //       <Box>{msg.message}</Box> */}
  //       <Grid
  //         container
  //         align="center"
  //         alignContent="center"
  //         justifyContent="center"
  //         spacing={2}
  //       >
  //         <Grid item>
  //           <Box>📢 {msg.message}</Box>
  //         </Grid>
  //         <Grid item>
  //           <Button
  //             variant="contained"
  //             color="primary"
  //             onClick={() => HandleJoin(msg?.patientappointmentDto)}
  //           >
  //             Join Now
  //           </Button>
  //         </Grid>
  //       </Grid>
  //       {/* {msg.patientappointmentDto} */}
  //     </div>
  //   );
  // };
  const NotificationSound = () => {
    const audio = new Audio(notificationAudio);
    audio.play();
  };
  return (
    <div>
      {" "}
      <Socket
        url={`${SERVICE_URL}berech-websocket`}
        topics={[
          "/user/" +
            `${loginuser?.roleId == 4 ? loginuser?.empId : loginuser?.id}` +
            "/private",
        ]}
        onConnect={() => console.log("conneted")}
        onDisconnect={() =>
          console.log("Updating Supply Order List- Disconnected")
        }
        onMessage={(msg) => {
          console.log(" msg-------------layout", msg);
          switch (msg.status) {
            case "APPOINTMENT_JOIN_ALERT":
              // toast.success(CustomToastWithLink(msg));
              Swal.fire({
                title: "Reminder",
                text: "📢" + msg.message,
                type: "info",
                icon: "info",
                // iconHtml: "📢",
                confirmButtonColor: "#" + primaryColor,
                allowOutsideClick: false,
                allowEscapeKey: false,
                confirmButtonText: "Join Now",
                showCancelButton: true,
                cancelButtonColor: "#" + primaryColor,
                cancelButtonText: "No, cancel it!",
              }).then((result) => {
                if (result.isConfirmed) {
                  HandleJoin(msg?.patientappointmentDto);
                }
              });
              break;
            case "JOIN":
              // toast.info(msg?.message);
              NotificationSound();
              Swal.fire({
                title: msg?.message,
                text: "Please Join Now",
                type: "info",
                icon: "info",
                // iconHtml: "📢",
                confirmButtonColor: "#" + primaryColor,
                allowOutsideClick: false,
                allowEscapeKey: false,
              });
              //   toast.success(msg?.senderName + msg?.message);
              break;

            default:
              break;
          }
        }}
        // ref={(ss) => {
        //   clientref.current = ss;
        // }}
      />
    </div>
  );
}

export default GlobalSocket;
