import Button from "components/Button";
import moment from 'moment';
import React, { useEffect } from 'react';
import { makeStyles, Grid, TextField, LinearProgress, Table, TableBody, InputAdornment } from "@material-ui/core";
import { StyledTableRow } from 'Utils';
import useTable, { StyledTableCell } from 'components/UseTable';
import { Search } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
    table: {
      minWidth: "100%",
    },
    tableCell: {
      fontSize: "14px",
      textAlign:"center",
      border: "0.1px solid #ededed",
      padding: "4px",
      [theme.breakpoints.down("md")]: {
        fontSize: "12px",
      },
    },
    image: {
      objectFit: "contain",
      height: 700,
      width: "100%",
    },
    tableColor: {
      color: "white",
    },
    closeDialog: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(0.5),
      color: theme.palette.grey[500],
    },
    color: {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.primary.contrastText,
      "&:hover, &:focus": {
        color: theme.palette.primary.contrastText,
        background: theme.palette.primary.main,
      },
    },  
    item: {
      minWidth: 300,
      maxWidth: 200,
      paddingRight: 20,
    },
    itemLabel: {
      minWidth: 100,
    },
    dateContainer: {
      display: "flex",
      justifyContent: "",
      alignItems: "center",
      flexWrap: "wrap",
      marginTop: "15px",
      marginBottom: "15px",
    },
  }));
export const startDate = moment(); 
export const endDate = moment();
const headCells = [   
    {id:"id" ,label:"Sl no",align:"center"},
    { id: "staffName", label: "Doctor Name", align: "center" },   
    {id:"gender", label: "Gender", align:"center"},
    {id:"phoneNumber", label:"Mobile", align:"center"},
    { id: "emailId", label: "Email", align: "center" }, 
    {id:"dob", label:"Date of Birth", align:"center"},
    {id: "address", label: "Address", align: "center"},    
    {id: "action", label: "Action", align: "center"}
  ];
export default function StaffDetailsTable(props) {
const classes = useStyles();
const [records, setRecords]=React.useState(null);    
const [loading, setLoading]= React.useState();
const [filterFn, setFilterFn] = React.useState({ fn: (items) => items }); 
useEffect(() => {
  setRecords(props.data); 
 }, [props.data]);    
 const requestSearch = (e) => {
  let val = e.target.value;
  setFilterFn({
    fn: (records) => {
      if (!val) return records;
      return records.filter(
        (row) =>       
          row?.id?.toString().toLowerCase().includes(val.toLowerCase())||
          row?.emailId?.toString().toLowerCase().includes(val.toLowerCase()) ||
          row?.address?.toString().toLowerCase().includes(val.toLowerCase()) ||
          row?.username?.toString().toLowerCase().includes(val.toLowerCase()) ||
          row?.staffName?.toString().toLowerCase().includes(val.toLowerCase()) ||
          row?.gender?.toString().toLowerCase().includes(val.toLowerCase()) ||  
          row?.address?.toString().toLowerCase().includes(val.toLowerCase()) ||
          row?.phoneNumber?.toString().toLowerCase().includes(val.toLowerCase())           
      );
    },
  });
};
const { TblContainer, TblHead, TblPagination, recordsAfterPagingAndSorting, getSlNo } = useTable(
  records,
  headCells,
  filterFn
);      
  return (
    <>        
         <div>
          <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "end",
            marginBottom: "8px",
          }}
        >
          <TextField
            placeholder="Search..."
            variant="outlined"
            size="small"           
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
            onChange={requestSearch}
          />
        </div>           
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center" style={{ margin: "auto", overflowX: "auto" }}>
          <Grid container>            
            <TblContainer style={{ maxHeight: "600px" }}>
              {loading && <LinearProgress />}
              <Table
                style={{ position: "relative", border: "1px solid lightgrey" }}
                size="small"
                aria-label="simple table"
                id="data-table-cleanliness-report"
              >
                <TblHead />
                {recordsAfterPagingAndSorting()?.length !== 0 ? (
                  <TableBody>                    
                    {recordsAfterPagingAndSorting()?.map((item, index) => (                      
                     <StyledTableRow className={classes.tableColor} key={index}>                      
                        <StyledTableCell className={classes.tableCell}>{index+1 }</StyledTableCell>
                        <StyledTableCell className={classes.tableCell}>{item?.staffName}</StyledTableCell>                                     
                        <StyledTableCell className={classes.tableCell}>{item?.gender} </StyledTableCell>
                        <StyledTableCell className={classes.tableCell}>{item?.phoneNumber} </StyledTableCell>
                        <StyledTableCell className={classes.tableCell}>{item?.emailId} </StyledTableCell>
                        <StyledTableCell className={classes.tableCell}>{moment(item?.dob != null ? item?.dob :'').format("DD-MM-YYYY")}</StyledTableCell>
                        <StyledTableCell className={classes.tableCell}>{item?.address} </StyledTableCell>                       
                        <StyledTableCell className={classes.tableCell}> <Button 
                                                    onClick={()=>props.editTable(item)}
                                                    align="center"
                                                    style={{ marginTop: "15px" }}
                                                    color="secondary"
                                                    variant="contained">
                                                    Edit
                                                </Button></StyledTableCell>
                                                </StyledTableRow>
                    ))}
                  </TableBody>
                ) : (
                  <TableBody>
                    <StyledTableRow>
                      <StyledTableCell colSpan={8} align="center">
                        No records found
                      </StyledTableCell>
                    </StyledTableRow>
                  </TableBody>
                )}
              </Table>
              {recordsAfterPagingAndSorting()?.length != 0 && recordsAfterPagingAndSorting()?.length != undefined ? (
                <TblPagination />
              ) : (
                ""
              )}
            </TblContainer>
          </Grid>
        </Grid>
      </Grid>
  </div> 
   </>
  )
}

