import { ADD_OT_BOOKING_ID, ADD_OT_PATIENT_DETAILS, FETCH_OT_PENDING_LIST, FETCH_OT_SCHEDULED_LIST, FETCH_OT_BILLING_LIST, UPDATE_OT_BOOKING, FETCH_BILLING_SERVICE_DOCTORS_LIST, FETCH_BILLING_SERVICE_LIST, } from "Redux/Types"
import { OTBookingPendingList, OTBookingScheduledList, OTBookingBillingList, BillingServiceDoctorsTableURL, BillingServiceTableURL } from "pages/Services/URLs";
import axiosInstance from "pages/Services/AxiosInstance";

export const addPatientDetails = (patientDetails) => {
    return {
        type: ADD_OT_PATIENT_DETAILS,
        payload: patientDetails,
    }
};
export const addBookingId = (bookingId) => {
    return {
        type: ADD_OT_BOOKING_ID,
        payload: bookingId,
    }
};
export const updateOTBooking = (boolean) => {
    return {
        type: UPDATE_OT_BOOKING,
        payload: boolean,
    }
};
export const fetchPendingList = () => async (dispatch) => {
    dispatch({
        type: FETCH_OT_PENDING_LIST,
        payload: {
            isLoading: true,
            data: [],
        }
    });
    try {
        const res = await axiosInstance.get(OTBookingPendingList);
        dispatch({
            type: FETCH_OT_PENDING_LIST,
            payload: {
                isLoading: false,
                data: res.data,
            }
        });
    } catch (error) {
        dispatch({
            type: FETCH_OT_PENDING_LIST,
            payload: {
                isLoading: false,
                data: [],
            },
        });
        console.log("fetchPendingListError :", error);
    }
};
export const fetchScheduledList = () => async (dispatch) => {
    dispatch({
        type: FETCH_OT_SCHEDULED_LIST,
        payload: {
            isLoading: true,
            data: [],
        }
    });
    try {
        const res = await axiosInstance.get(OTBookingScheduledList);
        dispatch({
            type: FETCH_OT_SCHEDULED_LIST,
            payload: {
                isLoading: false,
                data: res.data,
            }
        });
    } catch (error) {
        dispatch({
            type: FETCH_OT_SCHEDULED_LIST,
            payload: {
                isLoading: false,
                data: [],
            },
        });
        console.log("fetchScheduledListError:" + error)
    }
};
export const fetchBillingList = () => async (dispatch) => {
    dispatch({
        type: FETCH_OT_BILLING_LIST,
        payload: {
            isLoading: true,
            data: [],
        }
    });
    try {
        const res = await axiosInstance.get(OTBookingBillingList);
        dispatch({
            type: FETCH_OT_BILLING_LIST,
            payload: {
                isLoading: false,
                data: res.data,
            }
        });
    } catch (error) {
        dispatch({
            type: FETCH_OT_BILLING_LIST,
            payload: {
                isLoading: false,
                data: [],
            },
        });
        console.log("fetchBillingListError:" + error)
    }
};
