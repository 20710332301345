import React, { useEffect, useState } from "react";
import { CircularProgress, Dialog, DialogActions, DialogContent, IconButton, makeStyles, Tooltip, Typography } from "@material-ui/core";
import configData from "../config.json";
//
import LoginImg from "../assets/Images/life_insurance_after.jpg";
import belLogo from "assets/Images/bel_logo_bg.png";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { DoubleArrow, Info, Refresh, Visibility, VisibilityOff } from "@material-ui/icons";

import { encryptFn } from "pages/HomePage/UserLogin";
import { LOGGED_IN_USER } from "Redux/Types";
import { useDispatch } from "react-redux";
import axios from "axios";
import { DialogTitleWhite } from "Utils";
import moment from "moment";
import { emailRegex } from "./ProfilePage";
import { passwordRegex } from "pages/Layout/ChangePassword";
import axiosInstance from "pages/Services/AxiosInstance";

export function Required() {
  return <span style={{ color: "red" }}>*</span>;
}

const useStyles = makeStyles((theme) => ({
  wrapper: {
    border: "1px solid lightgrey",
    borderRadius: 4,
    maxWidth: 1200,
    marginInline: "auto",
    display: "flex",
    gap: 16,
    marginBlock: "18px",
  },
  formWrapper: {
    minWidth: 400,
    paddingInline: 32,
    paddingBlock: 16,
    [theme.breakpoints.down("sm")]: {
      minWidth: "unset",
      paddingInline: "15px",
    },
  },

  field: {
    display: "flex",
    flexDirection: "column",
    marginBottom: 16,
  },
  fieldCaptcha: {
    display: "flex",
    marginBottom: 16,
    alignItems: "center",
    gap: 4,
  },
  input: {
    minHeight: 32,
    fontSize: "1rem",
    border: "none",
    outline: "1px solid",
    borderBottom: "2px solid transparent",
    borderRadius: 2,
    transition: "border-bottom 0.3s ease",
    paddingInline: 8,
    "&:focus": {
      borderBottom: "2px solid #0e5688",
    },
    "&::placeholder": {
      color: "#b9d3e6",
    },
  },

  infoText: {
    fontSize: 12,
    fontStyle: "italic",
    fontWeight: 600,
    textDecoration: "underline",
    color: "tomato",
    margin: 0,
    cursor: "pointer",
    "&:hover": {
      color: "red",
    },
  },
  label: {
    color: "#424242",
    fontSize: "0.9rem",
  },
  button: {
    height: 48,
    marginTop: 16,
    backgroundColor: "#ff9e3d",
    color: "#fff",
    border: "none",
    fontSize: 16,
    paddingInline: 32,
    paddingBlock: 8,
    width: "100%",
    borderRadius: 4,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    fontWeight: "bold",
    "&:active": {
      transform: "scale(0.99)",
    },
    "& .arrow": {
      color: "white",
    },
    "&:hover": {
      backgroundColor: "#ff8d1b",
      // color: "#000",
      "& .arrow": {
        // This targets the arrow specifically when the button is hovered
        transform: "translateX(4px)",
        transition: "transform 0.3s ease",
        // color: "black",
      },
    },
    "&:disabled": {
      backgroundColor: "#e0e0e0", // Lighter grey for disabled state
      color: "#a0a0a0", // Dimmed text color
      cursor: "not-allowed", // Shows a 'not-allowed' cursor
      "& .arrow": {
        color: "#a0a0a0", // Arrow color dimmed for disabled state
        transform: "none", // Prevent hover transform in disabled state
      },
    },
  },
  errorText: {
    fontSize: 12,
    margin: 0,
    color: "red",
  },
  newUser: {
    color: "#ff8000",
    fontStyle: "italic",
    fontWeight: 500,
  },
  clickHere: {
    fontWeight: 700,
    cursor: "pointer",
    "&:hover": {
      color: "#d66b00",
      textDecoration: "underline",
    },
  },
  buttonConfirm: {
    backgroundColor: "#4CAF50", // Green confirm button
    color: "white",
    height: 28,
    width: 120,
    fontWeight: 500,
    "&:hover": {
      backgroundColor: "#45a049",
      color: "white",
    },
    "&:disabled": {
      backgroundColor: "#ccc",
      color: "white",
    },
    "&:active": {
      transform: "scale(0.96)",
    },
  },
  buttonClose: {
    backgroundColor: "#f44336", // Red close button
    color: "white",
    height: 28,
    width: 120,
    fontWeight: 500,
    "&:hover": {
      color: "white",
      backgroundColor: "#d32f2f",
    },
    "&:active": {
      transform: "scale(0.96)",
    },
  },
  imgContainer: {
    width: "100%",
    position: "relative",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
}));

const schema = yup.object().shape({
  staffName: yup.string().required("Required"),
  password: yup.string().required("Required"),
  captcha: yup.string().required("Required"),
});

const defaultValues = {
  staffName: "",
  password: "",
  captcha: "",
};

export default function LoginPage() {
  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const [openRegistration, setOpenRegistration] = useState(false);
  const [dialogType, setDialogType] = useState("");
  const [loading, setLoading] = useState(false);
  const [isPassWordVisible, togglePassword] = useState(false);
  const [captcha, setCaptcha] = useState({ captchaId: "", captchaString: "" });
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
  });

  async function onSubmit(formData) {
    console.log(formData, "=== onSubmit ===");
    try {
      const url = configData.SERVICE_URL + "user/userLogin";
      const encryptedUserName = encryptFn(formData.staffName);
      const encryptedPassword = encryptFn(formData.password);
      setLoading(true);
      const req = {
        userName: encryptedUserName,
        password: encryptedPassword,
        captchaId: captcha.captchaId,
        captcha: formData.captcha,
      };

      const { data } = await axios.post(url, req);
      if (data.message === "success") {
        localStorage.setItem("User", JSON.stringify(data));
        localStorage.setItem("AccessToken", JSON.stringify(data.token));
        dispatch({ type: LOGGED_IN_USER, payload: data });
      } else {
        toast.error(data.message);
        getCaptcha();
      }
    } catch (error) {
      console.log(error);
      toast.error("Failed to login, please try again");
    } finally {
      setLoading(false);
    }
  }

  function handleLinkClick(type) {
    setDialogType(type);
    setOpen(true);
  }

  function getCaptcha() {
    const url = configData.SERVICE_URL + "captcha/generateCaptcha";
    setValue("captcha", "");
    axios(url)
      .then(({ data }) => {
        setCaptcha(data);
      })
      .catch(console.log);
  }

  useEffect(() => {
    getCaptcha();
  }, []);

  function handleOpenRegistration() {
    setOpenRegistration(true);
  }

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "column",
          height: "100svh",
        }}
      >
        <header
          style={{
            display: "flex",
            alignItems: "center",
            backgroundColor: "#daecff",
            flexWrap: "wrap",
            justifyContent: "center",
          }}
        >
          <img style={{ height: 80 }} src={belLogo} alt="belLogo" className={classes.image} />

          <Typography
            align="center"
            variant="h2"
            style={{
              marginInline: "auto",
              paddingTop: 8,
              fontWeight: 800,
              color: "#0e5688",
            }}
          >
            <u> BEL RETIRED EMPLOYEES CONTRIBUTORY HEALTH SCHEME (BERECHS)</u>
          </Typography>
        </header>

        <main className={classes.wrapper}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <section className={classes.formWrapper}>
              <div className={classes.field}>
                <Typography align="center" variant="h2" style={{ fontWeight: 600, color: "#0e5688" }}>
                  Login
                </Typography>
                <br />
                <label className={classes.label}>
                  Ex Employee Staff Number &nbsp;
                  <Tooltip title="Enter your  staff number while in service">
                    <Info
                      style={{
                        fontSize: "18px",
                        color: "blue",
                        marginBottom: "-4px",
                      }}
                    />
                  </Tooltip>
                </label>
                <input
                  {...register("staffName")}
                  className={classes.input}
                  placeholder="Enter your staff number while in service"
                  autoComplete="off"
                  maxLength={20}
                />
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <p
                    className={classes.errorText}
                    style={{
                      opacity: errors.staffName ? 1 : 0,
                      transition: "opacity 0.5s ease",
                    }}
                  >
                    {errors.staffName?.message}
                  </p>
                  <p className={classes.infoText} onClick={() => handleLinkClick("staff")}>
                    Forgot Staff No.
                  </p>
                </div>
              </div>

              <div className={classes.field}>
                <label className={classes.label}>Password</label>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    position: "relative",
                  }}
                >
                  <input
                    maxLength={100}
                    autoComplete="off"
                    {...register("password")}
                    className={classes.input}
                    style={{ width: "100%" }}
                    placeholder="Enter Password"
                    type={isPassWordVisible ? "text" : "password"}
                  />
                  {isPassWordVisible ? (
                    <IconButton
                      style={{
                        position: "absolute",
                        right: 10,
                        cursor: "pointer",
                      }}
                      onClick={() => togglePassword((ps) => !ps)}
                      size="small"
                      color={isPassWordVisible ? "primary" : "action"}
                    >
                      <Visibility fontSize="small" />
                    </IconButton>
                  ) : (
                    <IconButton
                      style={{
                        position: "absolute",
                        right: 10,
                        cursor: "pointer",
                      }}
                      onClick={() => togglePassword((ps) => !ps)}
                      size="small"
                      color={isPassWordVisible ? "primary" : "action"}
                    >
                      <VisibilityOff fontSize="small" />
                    </IconButton>
                  )}
                </div>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <p
                    className={classes.errorText}
                    style={{
                      opacity: errors.password ? 1 : 0,
                      transition: "opacity 0.6s ease",
                    }}
                  >
                    {errors.password?.message}
                  </p>
                  <p className={classes.infoText} onClick={() => handleLinkClick("password")}>
                    Forgot Password
                  </p>
                </div>
              </div>
              <div className={classes.fieldCaptcha}>
                <div className={classes.field}>
                  <label className={classes.label}>Captcha</label>
                  <input
                    {...register("captcha")}
                    className={classes.input}
                    style={{ maxWidth: 150 }}
                    placeholder="Enter Captcha here"
                    autoComplete="off"
                    maxLength={10}
                  />
                  <p
                    className={classes.errorText}
                    style={{
                      opacity: errors.captcha ? 1 : 0,
                      transition: "opacity 0.8s ease",
                    }}
                  >
                    {errors.captcha?.message}
                  </p>
                </div>
                <img alt="captcha" height={50} style={{ marginTop: 2 }} src={"data:image/png;base64," + captcha.captchaString} />

                <IconButton tabIndex={-1} onClick={getCaptcha} size="small" color="secondary">
                  <Refresh fontSize="small" />
                </IconButton>
              </div>

              <button disabled={loading} className={classes.button} type="submit" style={{ marginBottom: 24 }}>
                {!loading ? (
                  <>
                    <div>Continue</div>
                    <div className="arrow" style={{ marginTop: 4 }}>
                      <DoubleArrow />
                    </div>
                  </>
                ) : (
                  <CircularProgress size={24} />
                )}
              </button>

              <span className={classes.newUser}>
                Note: New users{" "}
                <span onClick={handleOpenRegistration} className={classes.clickHere}>
                  Click here
                </span>
              </span>
            </section>
          </form>
          <section className={classes.imgContainer}>
            <img style={{ objectFit: "cover" }} alt="login-page-hero" src={LoginImg} width="100%" height="100%" />
          </section>
        </main>

        <footer style={{ backgroundColor: "#15133C", width: "100%" }}>
          <Typography align="center" style={{ color: "white", fontSize: "14px" }}>
            Designed &amp; developed by
            <a style={{ textDecoration: "none" }} target="_blank" href="https://bel-india.in/" rel="noreferrer">
              <span style={{ color: "#ffcb32" }}> Bharat Electronics Limited</span>
            </a>
          </Typography>
        </footer>
      </div>
      {open && <ForgotStaffNo onClose={() => setOpen(false)} type={dialogType} />}
      {openRegistration && <Registration onClose={() => setOpenRegistration(false)} />}
    </>
  );
}

const forgotStaffSchema = yup.object().shape({
  staffNumber: yup
    .string()
    .required("Staff Number is required")
    .matches(/^[a-zA-Z0-9]+$/, "Staff Number must be alphanumeric"),
});

const forgotEmailSchema = yup.object().shape({
  email: yup
    .string()
    .required("Email is required")
    .email("Invalid email format")
    .matches(emailRegex, "Invalid Email"),
});

const defaultValuesForgotStaff = {
  staffNumber: "",
};

const defaultValuesForgotEmail = {
  email: "",
};

function ForgotStaffNo({ onClose, type }) {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: type === "password" ? defaultValuesForgotStaff : defaultValuesForgotEmail,
    resolver: yupResolver(type === "password" ? forgotStaffSchema : forgotEmailSchema),
  });

  let content = (
    <>
      <Typography variant="h6" color="textSecondary" gutterBottom>
        Forgot your Staff Number?
      </Typography>
      <Typography variant="body1"> Please enter your registered email address, and we'll send your Staff Number to your inbox.</Typography>
    </>
  );

  if (type === "password") {
    content = (
      <>
        <Typography variant="h6" color="textSecondary" gutterBottom>
          Forget your Password?
        </Typography>
        <Typography variant="body1">
          A new password will be sent to your registered email address. Please check your inbox (and spam folder).
        </Typography>
      </>
    );
  }

  function onConfirm(formData) {
    console.log("submit");
    if (type === "password") {
      setLoading(true);
      axiosInstance
        .post("user/sendPwdByMail", { recipient: formData.staffNumber })
        .then(({ data }) => {
          toast.success("A new password will be sent to your registered email address, Please check your inbox (and spam folder)");
          onClose();
        })
        .catch((e) => {
          console.log(e);
          toast.error("Failed to send mail, please try again");
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setLoading(true);
      axiosInstance
        .post("user/sendStaffIdByMail", { recipient: formData.email })
        .then(({ data }) => {
          toast.success("Staff Number will be sent to your registered email address, Please check your inbox (and spam folder)");
          onClose();
        })
        .catch((e) => {
          toast.error("Failed to send mail, please try again");
          console.log(e);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }

  return (
    <Dialog open fullWidth maxWidth="sm" onClose={onClose}>
      <DialogTitleWhite onClose={onClose}></DialogTitleWhite>
      <DialogContent>
        <form onSubmit={handleSubmit(onConfirm)} id="myForm">
          {content}
          {type !== "password" ? (
            <div className={classes.field} style={{ marginTop: 20 }}>
              <label>
                Registered Email <Required />
              </label>
              <input {...register("email")} className={classes.input} placeholder="Enter email address" maxLength={100} />
              <p
                className={classes.errorText}
                style={{
                  opacity: errors.email ? 1 : 0,
                  transition: "opacity 0.6s ease",
                }}
              >
                {errors.email?.message}
              </p>
            </div>
          ) : (
            <div className={classes.field} style={{ marginTop: 20 }}>
              <label>
                Staff Number <Required />
              </label>
              <input {...register("staffNumber")} className={classes.input} placeholder="Enter Staff Number" maxLength={10} />
              <p
                className={classes.errorText}
                style={{
                  opacity: errors.staffNumber ? 1 : 0,
                  transition: "opacity 0.6s ease",
                }}
              >
                {errors.staffNumber?.message}
              </p>
            </div>
          )}
        </form>
      </DialogContent>
      <DialogActions>
        <button disabled={loading} form="myForm" type="submit" className={`${classes.button} ${classes.buttonConfirm}`}>
          {loading ? "Loading..." : "Confirm"}
        </button>
        <button onClick={onClose} className={`${classes.button} ${classes.buttonClose}`}>
          Close
        </button>
      </DialogActions>
    </Dialog>
  );
}

const validationSchema = yup.object().shape({
  staffNumber: yup
    .string()
    .required("Staff Number is required")
    .matches(/^[a-zA-Z0-9]+$/, "Staff Number must be alphanumeric"),
  dob: yup
    .date()
    .typeError("Invalid DOB")
    .test("dateOfBirth", "Must be between 18 and 150 years old", (value) => {
      const today = new Date();
      const eighteenYearsAgo = new Date(today.getFullYear() - 18, today.getMonth(), today.getDate());
      const oneHundredFiftyYearsAgo = new Date(today.getFullYear() - 150, today.getMonth(), today.getDate());
      return value >= oneHundredFiftyYearsAgo && value <= eighteenYearsAgo;
    }),
  // email: yup.string().email('Invalid email format').matches(emailRegex, 'Invalid Email').required('Email is required'),
  newPassword: yup
    .string()
    .required("New Password is required")
    .min(8, "Password must be at least 8 characters")
    .max(50, "Password cannot exceed 50 characters")
    .matches(
      passwordRegex,
      "Password must be at least 8 characters long and include an uppercase letter, a lowercase letter, a number, and one special character (@, $, !, %, ?, &, #)."
    ),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("newPassword"), null], "Passwords must match")
    .required("Confirm Password is required"),
});

const defaultValuesRegister = {
  staffNumber: "",
  dateOfBirth: "",
  // email: "",
  newPassword: "",
  confirmPassword: "",
};

function Registration({ onClose }) {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [isPasswordVisible, togglePassword] = useState(false);
  const [isConfirmPasswordVisible, toggleConfirmPassword] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: defaultValuesRegister,
    resolver: yupResolver(validationSchema),
  });

  async function onSubmit(formData) {
    console.log(formData, "=== Register onSubmit ===");

    const encryptedUserName = encryptFn(formData.staffNumber);
    const encryptedPassword = encryptFn(formData.newPassword);
    const encryptedConfirmPassword = encryptFn(formData.confirmPassword);

    try {
      const url = configData.SERVICE_URL + "user/userSignUp";
      setLoading(true);
      const req = {
        username: encryptedUserName,
        password: encryptedPassword,
        confirmPassword: encryptedConfirmPassword,
        // email: formData.email,
        dobStr: moment(formData.dob).format("YYYY-MM-DD"),
      };
      await axios.post(url, req);
      toast.success("Successfully Register, Please login with the register password");
      onClose();
    } catch (error) {
      console.log(error);
      if (error.response?.data) {
        toast.error(error.response.data);
      } else {
        toast.error("Failed to register, please try again");
      }
    } finally {
      setLoading(false);
    }
  }

  return (
    <Dialog open maxWidth="xs" fullWidth>
      <DialogTitleWhite onClose={onClose}> New User Registration Form</DialogTitleWhite>
      <DialogContent dividers>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={classes.field}>
            <label className={classes.label}>
              Ex Employee Staff Number <Required />{" "}
            </label>
            <input {...register("staffNumber")} className={classes.input} placeholder="Enter Staff Number" maxLength={20} />

            <p
              className={classes.errorText}
              style={{
                opacity: errors.staffNumber ? 1 : 0,
                transition: "opacity 0.5s ease",
              }}
            >
              {errors.staffNumber?.message}
            </p>
          </div>

          <div className={classes.field}>
            <label className={classes.label}>
              Date of Birth <Required />
            </label>
            <input {...register("dob")} type="date" className={classes.input} placeholder="Enter DOB" />

            <p
              className={classes.errorText}
              style={{
                opacity: errors.dob ? 1 : 0,
                transition: "opacity 0.5s ease",
              }}
            >
              {errors.dob?.message}
            </p>
          </div>

          {/* <div className={classes.field}>
          <label className={classes.label}>Email <Required /></label>
          <input {...register("email")} className={classes.input} placeholder="Enter Email" maxLength={100} />

          <p className={classes.errorText} style={{ opacity: errors.email ? 1 : 0, transition: "opacity 0.5s ease" }}>
            {errors.email?.message}
          </p>
        </div> */}

          <div className={classes.field}>
            <label className={classes.label}>
              New Password <Required />
            </label>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                position: "relative",
              }}
            >
              <input
                type={isPasswordVisible ? "text" : "password"}
                {...register("newPassword")}
                className={classes.input}
                style={{ paddingRight: 25, flex: 1 }}
                placeholder="Enter Password"
                maxLength={100}
              />
              {isPasswordVisible ? (
                <IconButton
                  style={{ position: "absolute", right: 0, cursor: "pointer" }}
                  onClick={() => togglePassword((ps) => !ps)}
                  size="small"
                  color={isPasswordVisible ? "primary" : "action"}
                >
                  <Visibility fontSize="small" />
                </IconButton>
              ) : (
                <IconButton
                  style={{ position: "absolute", right: 0, cursor: "pointer" }}
                  onClick={() => togglePassword((ps) => !ps)}
                  size="small"
                  color={isPasswordVisible ? "primary" : "action"}
                >
                  <VisibilityOff fontSize="small" />
                </IconButton>
              )}
            </div>
            <p
              className={classes.errorText}
              style={{
                opacity: errors.newPassword ? 1 : 0,
                transition: "opacity 0.5s ease",
              }}
            >
              {errors.newPassword?.message}
            </p>
          </div>

          <div className={classes.field}>
            <label className={classes.label}>
              Confirm Password <Required />
            </label>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                position: "relative",
              }}
            >
              <input
                {...register("confirmPassword")}
                type={isConfirmPasswordVisible ? "text" : "password"}
                className={classes.input}
                style={{ paddingRight: 25, flex: 1 }}
                placeholder="Enter Confirm Password"
                maxLength={100}
              />
              {isConfirmPasswordVisible ? (
                <IconButton
                  style={{ position: "absolute", right: 0, cursor: "pointer" }}
                  onClick={() => toggleConfirmPassword((ps) => !ps)}
                  size="small"
                  color={isConfirmPasswordVisible ? "primary" : "action"}
                >
                  <Visibility fontSize="small" />
                </IconButton>
              ) : (
                <IconButton
                  style={{ position: "absolute", right: 0, cursor: "pointer" }}
                  onClick={() => toggleConfirmPassword((ps) => !ps)}
                  size="small"
                  color={isConfirmPasswordVisible ? "primary" : "action"}
                >
                  <VisibilityOff fontSize="small" />
                </IconButton>
              )}
            </div>
            <p
              className={classes.errorText}
              style={{
                opacity: errors.confirmPassword ? 1 : 0,
                transition: "opacity 0.5s ease",
              }}
            >
              {errors.confirmPassword?.message}
            </p>
          </div>

          <button disabled={loading} className={classes.button} type="submit">
            {!loading ? <div>Register</div> : <CircularProgress size={24} />}
          </button>
        </form>
      </DialogContent>
    </Dialog>
  );
}
