import React from 'react'
import DutyRoster from './DutyRoster/DutyRoster'
import SlotDuration from './SlotDuration/SlotDuration'
import { Container, Grid, Tab, Tabs } from '@material-ui/core'
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { useTheme } from "@material-ui/core/styles";
import DoctorRegistration from './StaffDetails';
import { OTDashboard } from 'pages/OT/Pages/Dashboard/OTDashboard';

function TabPanel(props) {
    const { children, value, index, ...other } = props;  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`horizontal-tabpanel-${index}`}
        aria-labelledby={`horizontal-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box style={{ padding: "0px 24px" }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };  
  function a11yProps(index) {
    return {
      id: `vertical-tab-${index}`,
      "aria-controls": `vertical-tabpanel-${index}`,
    };
  }  
  const useStyles = makeStyles((theme) => ({
    root: {
      backgroundColor: theme.palette.background.primary,
      display: "flex",
      justifyContent: "spacing-between",
    },
    tabs: {
      borderRight: `1px solid ${theme.palette.divider}`,    
    display: "flex",
    justifyContent: "spacing-between",
    },
    tab: {
      textTransform: "capitalize",
      [theme.breakpoints.down("md")]: {
        width: "100%",
        maxWidth: "unset",
      },
    },
  }));
export default function () {
    const [value, setValue] = React.useState(0);
    const theme = useTheme();
    const classes = useStyles();
    const handleChange = (event, newValue) => {
    setValue(newValue);
      };
  return (
    <>
        <Container component={Box} style={{ paddingTop: 16 }} maxWidth="xl" >
        <Grid container spacing={2} >
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Tabs              
              orientation="horizontal"
              variant="scrollable"              
              value={value}
              onChange={handleChange}
              aria-label="Horizontal tabs example"
              className={classes.tabs}
              
            >
              <Tab              
                label="Staff Details"
                {...a11yProps(0)}
                style={{                   
                  background: value === 0 ? `${theme.palette.primary.main}` : `${theme.palette.primary.light}`,
                  color: value === 0 ? theme.palette.primary.contrastText : theme.palette.primary.contrastText,
                  opacity: 1,
                  margin: "5px 10px"
                }}
                className={classes.tab}
              />
              <Tab
                label="Duty Roster"
                {...a11yProps(1)}
                style={{
                  background: value === 1 ? `${theme.palette.primary.main}` : `${theme.palette.primary.light}`,
                  color: value === 1 ? theme.palette.primary.contrastText : theme.palette.primary.contrastText,
                  opacity: 1,
                  margin: "5px 10px",
                }}
                className={classes.tab}
              />
              <Tab
                label="Consultation Period"
                {...a11yProps(2)}
                style={{
                  background: value === 2 ? `${theme.palette.primary.main}` : `${theme.palette.primary.light}`,
                  color: value === 2 ? theme.palette.primary.contrastText : theme.palette.primary.contrastText,
                  opacity: 1,
                  margin: "5px 10px",
                }}
                className={classes.tab}
              />    
              {/* <Tab
                label="OT Management"
                {...a11yProps(3)}
                style={{
                  background: value === 3 ? `${theme.palette.primary.main}` : `${theme.palette.primary.light}`,
                  color: value === 3 ? theme.palette.primary.contrastText : theme.palette.primary.contrastText,
                  opacity: 1,
                  margin: "5px 10px",
                }}
                className={classes.tab}
              />              */}
            </Tabs>      
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <TabPanel value={value} index={0}>
              <DoctorRegistration />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <DutyRoster />
            </TabPanel>
            <TabPanel value={value} index={2}>
              <SlotDuration />
            </TabPanel>  
            <TabPanel value={value} index={3}>
              <OTDashboard />
            </TabPanel>       
          </Grid>
        </Grid>
      </Container>    
    </>
  )
}
