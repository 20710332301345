export const employeeDetails = "/employee/getAllEmployees";
export const changePasswordForEmployee = "/user/changePasswordForEmployee";
export const changePasswordForAdmin  = "/user/changePasswordForAdmin";
export const changePasswordURL = "/user/changePassword";
export const LoginUrl = "user/login";
export const eligibilityURL = "/eligibility/getByEmpIdAndYear/";
export const empDetailsURL = "employee/getById";
export const elgDetailsURL = "eligibility/getAllData";
export const submittedClaims = "claim/getAllNonDraftClaims";
export const expenseTypeURL = "expenseType/getAll";
export const saveClaimDraftURL = "claim/saveDraftClaim";
export const saveClaimURL = "claim/saveClaim";
export const saveClaimHeadUrl = "claim/saveClaimHead";
export const getClaimHeadByEmpId = "claim/getClaimHeadByEmpId/";
export const getAllClaimsByEmpId = "claim/getAllClaimsByEmpId/";
export const getDraftClaimByEmpId = "claim/getDraftClaimByEmpId/";
export const getFile = "file/getFile";
//slotduration
export const slotDurationgetAll = "/slotduration/getAllSlots";
export const saveSlotDuration = "/slotduration/saveSlotTime";
export const slotDurationgetById = "/slotduration/getById";
export const updateSlotDuration = "/slotduration/update";
//Duty Roster
export const saveDutyRosterDetails = "dutyRosterMaster/save";
export const getAllDoctors = "staff/getAllDoctors";
export const getAllDetailsDutyRoster = "dutyRosterMaster/getAll";
export const updateDutyRosterDetails = "dutyRosterMaster/update";
//Registration
export const saveReg = "/staff/registerCitizen";
export const saveDoctor = "/staff/registerDoctor";
export const updateDoctor = "/staff/update";
//Appontment urls
export const getAllDoctorsUrl = "/staff/getAllDoctors";
export const getAllSlotsOfDocWithAppts =
  "dutyRosterMaster/getAllSlotsOfDocWithAppts";
export const saveAppontmentUrl = "patientAppoitment/save";
export const getAppontmentPatientUrl =
  "patientAppoitment/getAppointmentbyPatientid";
export const appontmentUploadFileUrl = "patientAppoitment/saveUploadFiles";
export const appontmentDownloadFileUrl = "patientAppoitment/downloadFiles";
export const cancelAppointmentUrl = "patientAppoitment/delete";
//Doctor
export const getDoctorStatsUrl = "patientAppoitment/getDoctorStats/";
export const getPatientsListUrl = "patientAppoitment/getAppointedSlots";
export const getAllConsultation = "/consultation/getAll";
export const saveConsultation = "/consultation/save";
export const getbyidConsultation = "/consultation/getBy/";
export const blockSlotsAppontmentUrl = "patientAppoitment/blockSlots";
export const getAllPatientsListUrl = "staff/getAllCitizen";
export const getConsultationBypatientIdUrl = "consultation/getbyPatientid";
//Operation Theatre Management
export const OTAdmissionList = "staff/getAllOTPatients";
export const OTDoctorsURL = "staff/getAllDoctors";
export const BookingStatus = "otBooking/bookingStatus";
export const OTBookingList = "otBooking/getAll/";
export const OTSlotsURL = "otBooking/otSlots/getAll";
export const OTBookingPendingList = "otBooking/getAll/pending";
export const OTBookingScheduledList = "otBooking/getAll/scheduled";
export const OTBookingBillingList = "otBooking/getAll/billing";
export const OTBooking = "otBooking/book";
export const OTBookingUpdateURL = "otBooking/update";
export const OTBookingDeleteURL = "otBooking/delete/";
export const OTBookingStatusUpdate = "otBooking/update/bookingStatus";
export const OTNotes = "otNotes/otNotes";
export const OTBillingSurgeryTeam = "otBilling/getSurgeryTeamBill/";
export const OTBillingTechnicalTeam = "otBilling/getTechnicalTeamBill/";
export const OTBillingConsumables = "otBilling/getConsumablesBill/";
export const OTBillingChecklist = "otBilling/getChecklistBill/";
export const OTBillingEquipments = "otBilling/getEquipmentsBill/";
export const OTBillingMedicines = "otBilling/getMedicinesBill/";
// export const BillingServiceURL ="http://172.22.52.10:8080/billingServiceMst/getAll";
export const DepartmentMstDataURL = "departmentMst/getAll";
export const PhysicianTableDataURL = "physician/getAllByBookingId/";
export const PhysicianMstDataURL = "staffdepartment/getByDeptId/";
export const PhysicianURL = "physician/physician";
export const PhysicianUpdateURL = "physician/update";
export const PhysicianDeleteURL = "physician/delete";
export const SupportingStaffTableDataURL = "supportingStaff/getAllByBookingId/";
export const SupportingStaffURL = "supportingStaff/supportingStaff";
export const SupportingStaffUpdateURL = "supportingStaff/update";
export const SupportingStaffDeleteeURL = "supportingStaff/delete";
export const ChecklistTableDataURL = "checklist/getAllByBookingId/";
export const ChecklistMstURL = "checklistMst/saveChecklist";
export const ChecklistMstDeleteURL = "checklistMst/delete";
export const ChecklistMstDataURL = "checklistMst/getAll";
export const ChecklistURL = "checklist/checklist";
export const ChecklistUpdateURL = "checklist/update";
export const ChecklistDeleteURL = "checklist/delete";
export const InventoryTableDataURL = "inventory/getAllByBookingId/";
export const InventoryMstURL = "inventoryMst/saveInventory";
export const InventoryMstDeleteURL = "inventoryMst/delete";
export const InventoryMstDataURL = "inventoryMst/getAll";
export const InventoryURL = "inventory/inventory";
export const InventoryUpdateURL = "inventory/update";
export const InventoryDeleteURL = "inventory/delete";
export const EquipmentTableDataURL = "equipment/getAllByBookingId/";
export const EquipmentMstURL = "equipmentMst/saveEquipment";
export const EquipmentMstDeleteURL = "equipmentMst/delete";
export const EquipmentMstDataURL = "equipmentMst/getAll";
export const EquipmentURL = "equipment/equipments";
export const EquipmentUpdateURL = "equipment/update";
export const EquipmentDeleteURL = "equipment/delete";
export const MedicineTableDataURL = "medicine/getAllByBookingId/";
export const MedicineMstURL = "medicineMst/saveMedicine";
export const MedicineMstDeleteURL = "medicineMst/delete";
export const MedicineMstDataURL = "medicineMst/getAll";
export const MedicineURL = "medicine/medicines";
export const MedicineUpdateURL = "medicine/update";
export const MedicineDeleteURL = "medicine/delete";
export const UserDataURL = "user/getAll";
export const ServiceTypeDataURL = "servicetypeMst/getAll";
export const ServiceURL = "service";
export const OTMstDataURL = "otMst/getByDeptId/";
export const OTMstgetAllURL = "otMst/getAll";
export const OTMstURL = "otMst/saveotMst";
export const OTMstDeleteURL = "otMst/delete";
export const OTBedsMstDataURL = "otBedsMst/getByDeptId/";
export const OTBedsMstgetAllURL = "otBedsMst/getAll";
export const OTBedsMstURL = "otBedsMst/saveotBedsMst";
export const OTBedsUpdateMstURL = "otBedsMst/updateotBedsMst";
export const OTBedsMstDeleteURL = "otBedsMst/delete";
export const SlotGeneratorDataURL = "slotGenerator/getAll";
export const SlotGeneratorURL = "slotGenerator/save";
export const SurgerynamesDataURL = "surgerynamesMst/getByDeptId/";
export const PriorityURL = "priorityMst/getAll";
export const BillingServiceDoctorsTableURL = "billingserviceMst/getAll/Doctors";
export const BillingServiceTableURL = "billingserviceMst/getAll/services";
export const BillingServiceURL = "billingserviceMst/addService";
export const BillingServiceUpdateURL = "billingserviceMst/update";
export const BillingServiceDeleteURL = "billingserviceMst/delete";
//Blood Request
export const BloodRequestSaveURL = "bloodRequest/save";