import React, { useState } from "react";
import { fetchEmpDetails } from "Redux/Actions/EmployeeAction";
//material-ui
import { CircularProgress, Grid, Typography } from "@material-ui/core";
import { default as MuiDialogActions, default as MuiDialogContent } from "@material-ui/core/DialogActions";
import { withStyles } from "@material-ui/core/styles";
import axiosInstance from "pages/Services/AxiosInstance";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import configData from "../../../config.json";

//components
import Button from "../../../components/Button";

export const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

export const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function PedencyLog() {
  const dispatch = useDispatch();
  const user = useSelector((store) => store.loggedInUser) || JSON.parse(localStorage.getItem("User"));
  const [selectedFile, setSelectedFile] = useState(null);
  const fileRef = React.useRef(null);
  const [loading, setLoading] = useState(false);

  const fetchEmp = () => {
    dispatch(fetchEmpDetails());
  };

  const handleSubmit = () => {
    const formData = new FormData();
    formData.append("file", selectedFile);
    formData.append("fileName", selectedFile.name);
    formData.append("lastUpdatedBy", user.empId);

    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };

    const url = configData.SERVICE_URL + "upload/empData";
    setLoading(true);
    axiosInstance
      .post(url, formData, config)
      .then((res) => {
        toast.success("Data Successfully Submitted.");
        fetchEmp();
        fileRef.current.value = "";
        setSelectedFile(null);
        // fetchLatestData();
      })
      .catch((error) => {
        const errorMsg = error.response?.data;
        if (errorMsg) {
          toast.error(errorMsg);
        } else {
          toast.error("Something went wrong");
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Grid container spacing={2} style={{ marginTop: 20 }}>
      <Grid item xs={2} md={3} lg={2}>
        <Typography>Upload Employee Details </Typography>
        <Typography color="secondary" variant="caption">
          (File of type '.xls' allowed){" "}
        </Typography>
      </Grid>
      <Grid item xs="auto">
        <input
          type="file"
          ref={fileRef}
          accept=".xls"
          onChange={(e) => {
            const file = e.target.files[0];
            if (file) {
              if (!/\.(xls)$/g.test(file?.name)) {
                toast.error("Please select only Excel File (.xls) for uploading Employee Details");
              } else {
                setSelectedFile(file);
              }
            }
          }}
        />
      </Grid>
      {selectedFile && (
        <Grid item xs={2}>
          <Button align="center" type="submit" size="small" disabled={loading} color="primary" variant="contained" onClick={handleSubmit}>
            {loading && <CircularProgress size={24} />} Submit
          </Button>
        </Grid>
      )}
    </Grid>
  );
}
