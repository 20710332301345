import {
  Button,
  Chip,
  CircularProgress,
  Dialog,
  Divider,
  InputAdornment,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableRow,
  TextField,
  Typography,
} from "@material-ui/core";
import { useEffect, useRef, useState } from "react";

import { Search } from "@material-ui/icons";
import useTable, { StyledTableCell, StyledTableRow } from "components/UseTable";
import { DialogActions, DialogContent, DialogTitle } from "Utils";
import axiosInstance from "../Services/AxiosInstance";
import { useStylesRenewalForm } from "pages/EmployeeRenewalForm/RenewalForm";
import moment from "moment";
import { toast } from "react-toastify";

const headCells = [
  { id: "S.No.", label: "S.No.", align: "left" },
  { id: "employeeName", label: "Employee Name", align: "left" },
  { id: "employeeId", label: "Employee Id", align: "left" },
  { id: "empMobileNo", label: "Mobile No.", align: "left" },
  { id: "financialYear", label: "Financial Year", align: "left" },
  { id: "status", label: "Status", align: "left" },
  { id: "submittedDate", label: "Submitted Date", align: "left" },
  { id: "action", label: "Action", align: "left", disableSorting: true },
];

const today = new Date();
const getYear = today.getFullYear() + 1;

export default function AdminRenewalForm({ loggedInUser }) {
  const classes = useStylesRenewalForm();
  const [loading, setLoading] = useState(false);
  const [renewalData, setRenewalData] = useState([]);
  const [filterFn, setFilterFn] = useState({ fn: (items) => items });
  const [selectedForm, setSelectedForm] = useState(null);
  const [openDetails, setOpenDetails] = useState(false);
  const [viewMode, setViewMode] = useState(false);

  const requestSearch = (e) => {
    let val = e.target.value;
    setFilterFn({
      fn: (records) => {
        if (!val) return records;
        return records.filter(
          (row) =>
            row.renewalStatus
              .toString()
              .toLowerCase()
              .includes(val.toLowerCase()) ||
            row.renewalYear
              ?.toString()
              .toLowerCase()
              .includes(val.toLowerCase()) ||
            row.employeeName.toLowerCase().includes(val.toLowerCase()) ||
            row.employeeId
              ?.toString()
              .toLowerCase()
              .includes(val.toLowerCase()) ||
            row.empMobileNo
              ?.toString()
              .toLowerCase()
              .includes(val.toLowerCase())
        );
      },
    });
  };

  const { TblContainer, TblHead, TblPagination, recordsAfterPagingAndSorting } = useTable(renewalData, headCells, filterFn, 15);

  function getRenewalData() {
    axiosInstance("renewal/getAll")
      .then(({ data }) => {
        if (Array.isArray(data)) {
          setRenewalData(
            data.map((e) => ({
              ...e,
              renewalStatus:
                e.renewalStatus === "Sent"
                  ? "Submitted"
                  : e.renewalStatus === "Draft"
                  ? "Saved"
                  : e.renewalStatus === "Approved"
                  ? "Approved"
                  : "Rejected",
              submittedDate: e.submittedDate ? moment(e.submittedDate).format("DD-MM-YYYY") : "NA",
            }))
          );
        }
      })
      .catch(console.log)
      .finally(() => {
        setLoading(false);
      });
  }

  useEffect(() => {
    getRenewalData();
  }, [loggedInUser]);

  return (
    <>
      <div style={{ textAlign: "right" }}>
        <TextField
          placeholder="Search..."
          variant="outlined"
          size="small"
          className={classes.searchInput}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
          onChange={requestSearch}
        />
      </div>
      <TblContainer style={{ height: "600px" }}>
        {loading && <LinearProgress />}
        <Table
          style={{
            position: "relative",
            border: "1px solid lightgrey",
          }}
          size="small"
        >
          <TblHead />
          {recordsAfterPagingAndSorting()?.length !== 0 ? (
            <TableBody>
              {recordsAfterPagingAndSorting()?.map((item, index) => (
                <TableRow key={item.id} className={classes.tableColor}>
                  <StyledTableCell>{index + 1} </StyledTableCell>
                  <StyledTableCell>{item.employeeName} </StyledTableCell>
                  <StyledTableCell>{item.employeeId} </StyledTableCell>
                  <StyledTableCell>{item.empMobileNo} </StyledTableCell>
                  <StyledTableCell>{item.submittedDate} </StyledTableCell>
                  <StyledTableCell>{item.renewalYear} </StyledTableCell>
                  <StyledTableCell>
                    <StatusChip status={item.renewalStatus} />
                  </StyledTableCell>
                  <StyledTableCell>
                    {/* {item.renewalStatus === "Approved"  ( */}
                    <Button
                      onClick={() => {
                        setOpenDetails(true);
                        setSelectedForm(item);
                        setViewMode(true);
                      }}
                      style={{ minWidth: 100 }}
                      color="primary"
                      size="small"
                      variant="outlined"
                    >
                      View
                    </Button>
                    {/* ) : (
                      <Button
                        onClick={() => {
                          setOpenDetails(true);
                          setSelectedForm(item);
                          setViewMode(false);
                        }}
                        style={{minWidth:100}}
                        variant="outlined"
                        color="primary"
                        size="small"
                      >
                        Approve
                      </Button>
                    )} */}
                  </StyledTableCell>
                </TableRow>
              ))}
            </TableBody>
          ) : (
            <TableBody>
              <StyledTableRow>
                <StyledTableCell colSpan={8} align="center">
                  No records found
                </StyledTableCell>
              </StyledTableRow>
            </TableBody>
          )}
        </Table>
        {recordsAfterPagingAndSorting()?.length ? <TblPagination /> : null}
      </TblContainer>
      {openDetails && (
        <PreviewForm
          onClose={() => {
            setOpenDetails(false);
            setViewMode(null);
          }}
          reset={getRenewalData}
          employeeDetails={selectedForm}
          viewMode={viewMode}
        />
      )}
    </>
  );
}

function WebcamCapture({ capturedImage, loading }) {
  const classes = useStylesRenewalForm();
  const canvasRef = useRef(null);

  return (
    <div className={classes.root} style={{ width: "300px" }}>
      <Paper elevation={3} className={classes.paper}>
        {capturedImage ? (
          <img src={capturedImage} alt="Captured" height={"100%"} />
        ) : (
          <Typography variant="caption" color="textSecondary">
            {loading ? <CircularProgress /> : "No Image"}
          </Typography>
        )}
      </Paper>
      <canvas ref={canvasRef} style={{ display: "none" }} />
    </div>
  );
}

function PreviewForm({ onClose, employeeDetails, viewMode, reset }) {
  const classes = useStylesRenewalForm();
  const [approving, setApproving] = useState(false);
  const [loadingImg, setLoadingImg] = useState(false);
  const [loadingImgError, setLoadingImgError] = useState("");

  async function handleApprove() {
    if (employeeDetails) {
      setApproving(true);
      try {
        await axiosInstance.post("renewal/approval/" + employeeDetails.id);
        reset();
        toast.success("Successfully approved");
        onClose();
      } catch (error) {
        toast.error("Failed to approve, please try again");
        console.log(error);
      }
      setApproving(false);
    }
  }

  const [capturedImageRetiree, setCapturedImageRetiree] = useState(null);
  const [capturedImageSpouse, setCapturedImageSpouse] = useState(null);

  useEffect(() => {
    if (employeeDetails) {
      setLoadingImg(true);
      const req={id:employeeDetails?.id}
      axiosInstance.post("renewal/getById",req)
        .then(({ data }) => {
          setCapturedImageRetiree(data.empPhotoStr);
          setCapturedImageSpouse(data.spousePhotoStr);
        })
        .catch((e) => {
          console.log(e);
          setLoadingImgError("Failed to Load Image, please reopen the dialog for approval");
        })
        .finally(() => {
          setLoadingImg(false);
        });
    }
  }, [employeeDetails]);

  return (
    <Dialog open maxWidth="lg" fullWidth>
      <DialogTitle onClose={onClose}>Renewal Form</DialogTitle>
      <DialogContent dividers>
        {/* <span>
          Form Status: &nbsp;
          <span
            style={{
              color: employeeDetails.renewalStatus === "Approved" ? "darkgreen" : "darkred",
              backgroundColor: employeeDetails.renewalStatus === "Approved" ? "#d9ffd9" : "#ffdad3",
              borderRadius: 8,
              paddingInline: 16,
              paddingBlock: 6,
            }}
          >
            {employeeDetails.renewalStatus === "Approved" ? "Approved" : "Pending"}
          </span>
        </span> */}
        <div style={{ padding: 16, margin: "auto" }}>
          <Typography variant="h6" align="center" style={{ textDecoration: "underline" }}>
            RENEWAL FORM FOR BERECH SCHEME (as amended)
          </Typography>
          <Typography variant="h6" align="center" style={{ textDecoration: "underline" }}>
            INSTRUCTIONS
          </Typography>
          <br />
          <Typography variant="body1" style={{ textDecoration: "underline" }}>
            All BERECHS Members to take note of the following instructions with regards to RENEWAL of Membership
          </Typography>
          <ol className={classes.orderedList}>
            <li className={classes.listItem}>
              <Typography variant="body2" align="justify">
                BERECHS members are required to send/submit a signed declaration every year as per the formal below:
              </Typography>
            </li>
            <li className={classes.listItem}>
              <Typography variant="body2" align="justify">
                The declaration should be sent/submitted by{" "}
                <b style={{ backgroundColor: "yellow", textDecoration: "underline", textDecorationColor: "red" }}>
                  31 <sup>st</sup> December
                </b>{" "}
                of that year so as to renew and continue the membership with insurance Company.
              </Typography>
            </li>
            <li className={classes.listItem}>
              <Typography variant="body2" align="justify">
                It is mandatory that both the retired employee and his/her spouse should sign the declaration. Further, if the spouse of the
                retiree is employed, it is also mandatory to submit No Objection Certificate (NOC) every year along with this form till the
                retirement of spouse.
              </Typography>
            </li>

            <li className={classes.listItem}>
              <Typography variant="body2" align="justify">
                If the declaration is not received by{" "}
                <b style={{ backgroundColor: "yellow", textDecoration: "underline", textDecorationColor: "red" }}>
                  31 <sup>st</sup> December
                </b>{" "}
                of that year, membership will not be renewed, and the retiree/spouse will not be eligible for any medical facility under
                BERECHS Scheme.
              </Typography>
            </li>
          </ol>

          <Divider />
          <br />
          <Typography variant="h6" align="center" style={{ textDecoration: "underline" }}>
            Declaration
          </Typography>
          {/* <br /> */}

          <Typography align="justify">
            (Declaration should be sent by Post / Courier /E-Mail / Submitted at the Helpdesk and acknowledgement taken on or before 31{" "}
            <sup>st</sup> December every year)
          </Typography>
          <br />

          <Typography variant="body2">
            I/We, Smt/Sri{" "}
            <span style={{ textDecoration: "underline", fontWeight: "bold", paddingInline: 8 }}>{employeeDetails?.eName}</span>
            (Retiree) Ex. Staff No.
            <span style={{ textDecoration: "underline", fontWeight: "bold", paddingInline: 8 }}>{employeeDetails?.empId}</span>
            aged about
            <span style={{ textDecoration: "underline", fontWeight: "bold", paddingInline: 8 }}>{employeeDetails?.age}</span>and Smt/Sri
            <span style={{ textDecoration: "underline", fontWeight: "bold", paddingInline: 8 }}>Ranu Agrawal</span>
            (Spouse) aged about
            <span style={{ textDecoration: "underline", fontWeight: "bold", paddingInline: 8 }}>{employeeDetails?.spouseAge}</span>
            BERECHS Members No.
            <span style={{ textDecoration: "underline", fontWeight: "bold", paddingInline: 8 }}>{employeeDetails?.berechsNo}</span>
            residing at No.{" "}
            <span style={{ textDecoration: "underline", fontWeight: "bold", paddingInline: 8 }}>{employeeDetails?.address}</span>
            are the Members of BERECHS (BEL retired Employees' Contributory Health Scheme)(Revised).
          </Typography>
          <br />
          <Typography>My/our contact details are as follows:</Typography>
          <div style={{ width: "100%" }}>
            <table style={{ border: "1px solid lightgrey", borderCollapse: "collapse", width: "100%", maxWidth: 1000, margin: "auto" }}>
              <thead>
                <td
                  style={{ border: "1px solid lightgrey", fontSize: 13, fontWeight: "bold", padding: 8, backgroundColor: "aliceBlue" }}
                ></td>
                <td
                  style={{
                    border: "1px solid lightgrey",
                    fontSize: 13,
                    fontWeight: "bold",
                    padding: 8,
                    backgroundColor: "aliceBlue",
                    minWidth: 180,
                  }}
                >
                  Mobile No.(Mandatory)
                </td>
                <td
                  style={{
                    border: "1px solid lightgrey",
                    fontSize: 13,
                    fontWeight: "bold",
                    padding: 8,
                    backgroundColor: "aliceBlue",
                    minWidth: 150,
                  }}
                >
                  Landline No.
                </td>
                <td
                  style={{
                    border: "1px solid lightgrey",
                    minWidth: 300,
                    fontSize: 13,
                    fontWeight: "bold",
                    padding: 8,
                    backgroundColor: "aliceBlue",
                  }}
                >
                  E-Mail Id
                </td>
              </thead>
              <tbody>
                <tr>
                  <td style={{ border: "1px solid lightgrey", padding: 8, backgroundColor: "aliceBlue" }}>
                    <Typography style={{ fontSize: 13, fontWeight: "bold" }}>Retiree</Typography>
                  </td>
                  <td style={{ border: "1px solid lightgrey", padding: 8 }}>
                    <span style={{ textDecoration: "underline", fontWeight: "bold", paddingInline: 8 }}>
                      {employeeDetails?.empMobileNo}
                    </span>
                  </td>
                  <td style={{ border: "1px solid lightgrey", padding: 8 }}>
                    <span style={{ textDecoration: "underline", fontWeight: "bold", paddingInline: 8 }}>
                      {employeeDetails?.empLandlineNo}
                    </span>
                  </td>
                  <td style={{ border: "1px solid lightgrey", padding: 8 }}>
                    <span style={{ textDecoration: "underline", fontWeight: "bold", paddingInline: 8 }}>{employeeDetails?.empEmail}</span>
                  </td>
                </tr>
                <tr>
                  <td style={{ border: "1px solid lightgrey", padding: 8, backgroundColor: "aliceBlue" }}>
                    <Typography style={{ fontSize: 13, fontWeight: "bold" }}>Spouse</Typography>
                  </td>
                  <td style={{ border: "1px solid lightgrey", padding: 8 }}>
                    <span style={{ textDecoration: "underline", fontWeight: "bold", paddingInline: 8 }}>
                      {employeeDetails?.spouseMobileNo}
                    </span>
                  </td>
                  <td style={{ border: "1px solid lightgrey", padding: 8 }}>
                    <span style={{ textDecoration: "underline", fontWeight: "bold", paddingInline: 8 }}>
                      {employeeDetails?.spouseLandlineNo}
                    </span>
                  </td>
                  <td style={{ border: "1px solid lightgrey", padding: 8 }}>
                    <span style={{ textDecoration: "underline", fontWeight: "bold", paddingInline: 8 }}>
                      {employeeDetails?.spouseEmail}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <br />
          <Typography variant="body2">
            My Spouse is working in{"  "}
            <span style={{ textDecoration: "underline", fontWeight: "bold", paddingInline: 8 }}>{employeeDetails?.spouseDept}</span>
            {"  "}
            and his/her normal date of retirement is{"  "}
            <span style={{ textDecoration: "underline", fontWeight: "bold", paddingInline: 8 }}>
              {employeeDetails?.spouseRetirementDate}
            </span>
            {"  "}. No Objection Certificate (NOC) from the employer is enclosed.
          </Typography>
          <br />
          <Typography variant="body2" align="justify">
            I/We have utilized the Scheme for the year
            <span style={{ textDecoration: "underline", fontWeight: "bold", paddingInline: 8 }}>{getYear - 1}</span> to
            <span style={{ textDecoration: "underline", fontWeight: "bold", paddingInline: 8 }}>{getYear}</span> (Financial Year) and I/We
            will Continue to utilize the facilities provided by the Scheme for the year
            <span style={{ textDecoration: "underline", fontWeight: "bold", paddingInline: 8 }}>{getYear}</span> to
            <span style={{ textDecoration: "underline", fontWeight: "bold", paddingInline: 8 }}>{getYear + 1}</span>
            (Financial Year). I/We hereby request you to renew our Membership with Insurance Company for the year
            <span style={{ textDecoration: "underline", fontWeight: "bold", paddingInline: 8 }}>{getYear}</span> to
            <span style={{ textDecoration: "underline", fontWeight: "bold", paddingInline: 8 }}>{getYear + 1}</span> (Financial Year).
          </Typography>

          <br />
          <Typography variant="caption">
            <b>
              Note: Both retiree and spouse should Upload their <u>Photo</u> for renewal
            </b>
          </Typography>
          <div style={{ display: "flex", justifyContent: "space-between", marginBottom: 24 }}>
            <div style={{ textAlign: "center", border: "1px solid lightgrey", minWidth: 300 }}>
              <Typography variant="caption" align="center" color="secondary">
                Photo of Retiree
              </Typography>
              <WebcamCapture capturedImage={capturedImageRetiree} loading={loadingImg} />
            </div>
            <div style={{ textAlign: "center", border: "1px solid lightgrey", minWidth: 300 }}>
              <Typography variant="caption" align="center" color="secondary">
                Photo of Spouse
              </Typography>
              <WebcamCapture capturedImage={capturedImageSpouse} loading={loadingImg} />
            </div>
          </div>
        </div>
        <div style={{ textAlign: "center" }}>
          {loadingImgError && <Typography color="error">{loadingImgError}</Typography>}
          {!viewMode && (
            <Button
              disabled={approving || loadingImg || loadingImgError}
              color="secondary"
              size="small"
              onClick={handleApprove}
              variant="contained"
              style={{ minWidth: 300 }}
            >
              {approving && <CircularProgress size={24} />} Approve
            </Button>
          )}
        </div>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" size="small" onClick={onClose} variant="contained">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export function StatusChip({ status }) {
  return (
    <Chip
      title={status}
      label={status}
      variant="default"
      style={{
        color: status === "Submitted" || status === "Pending" ? "blue" : status === "Saved" ? "orange" : status === "Approved" ? "darkgreen" : "darkred",

        // Set background colors for each renewalStatus case
        backgroundColor:
          status === "Submitted" || status === "Pending"
            ? "#cce5ff" // Light blue
            : status === "Saved"
            ? "#fff3cd" // Light yellow
            : status === "Approved"
            ? "#d9ffd9" // Light green
            : "#ffdad3", // Light red
        fontWeight: 500,
      }}
      size="small"
    />
  );
}
