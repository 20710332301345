import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

export const firebaseConfig = {
  apiKey: "AIzaSyBT2-1Qo_C-GwT_4h3erpgycbl2q8LMHJY",
  authDomain: "medical-claim-cc05c.firebaseapp.com",
  projectId: "medical-claim-cc05c",
  storageBucket: "medical-claim-cc05c.appspot.com",
  messagingSenderId: "148643655453",
  appId: "1:148643655453:web:18c9b6b928fb38864e3bf4",
  measurementId: "G-6NYQNJ60V4",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export default app;
