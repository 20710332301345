import React from "react";
import { Grid, makeStyles } from "@material-ui/core";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
//components
import Button from "components/Button";
import { SubmitDialogBox } from "pages/OT/components/SubmitDailogBox";
//forms
import { InventoryForm } from "./ConsumableForms/InventoryForm";
import { EquipmentForm } from "./ConsumableForms/EquipmentForm";
import { MedicineForm } from "./ConsumableForms/MedicinesForm";

import axiosInstance from "pages/Services/AxiosInstance";
import { addSelectedItem } from "Redux/Actions/OTActions/SeletedItem";
import { OTNotes } from "pages/OT/Pages/OTNotes/OTNotes";
import { fetchScheduledList } from "Redux/Actions/OTActions/OTPatientsAction";
import { OTBookingStatusUpdate } from "pages/Services/URLs";
import { SELECTED_ACTION_ITEM } from "Redux/Types";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        padding: theme.spacing(0),
    },
}));

export const ConsumablesTab = (props) => {
    const classes = useStyles();


    return (
        <div className={classes.root}>
            <InventoryForm />
            <EquipmentForm />
            <MedicineForm />
            <Grid container spacing={4} justifyContent='center'
                style={{ marginTop: 10 }}>
                <Grid item>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={props.onClickBack}
                    >
                        Back
                    </Button>
                </Grid>
                <Grid item>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={props.onClickNext}
                    >
                        Next
                    </Button>
                </Grid>
            </Grid>
        </div >
    )
}