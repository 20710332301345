import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { Grid, makeStyles, Typography, Dialog, DialogContent, Divider, DialogActions, IconButton } from "@material-ui/core";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
//components
import Button from "components/Button";
import TextField from "components/TextField";
import SupportingStaffTable from "../SurgeryTeamTables/SupportingStaffTable";
import axiosInstance from "pages/Services/AxiosInstance";
import { SupportingStaffTableDataURL, PhysicianMstDataURL, DepartmentMstDataURL, SupportingStaffURL, SupportingStaffUpdateURL, SupportingStaffDeleteeURL } from "pages/Services/URLs";
import { Autocomplete } from "@material-ui/lab";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { CloseRounded } from "@material-ui/icons";
import { DeleteDialogBox } from "pages/OT/components/DeleteDailogBox";


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        padding: theme.spacing(0),
    },
    grid1: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },
    label: {
        fontsize: '0.875rem',
        margin: 5,
    },
    dialogHead: {
        color: theme.palette.primary.contrastText,
        backgroundColor: theme.palette.primary.main,
        padding: 2,
    },
    buttons: {
        display: 'flex',
        justifyContent: 'flex-end',
        padding: 15,

    }
}));

export const SupportingStaffForm = (props) => {
    const classes = useStyles();
    const bookingId = useSelector((store) => store.OTPatients.bookingId);
    const [open, setOpen] = React.useState(false);
    const [openDeleteBox, setOpenDeleteBox] = React.useState(false);
    const [edit, setEdit] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(true);
    const [item, setItem] = React.useState([]);
    const [keyroleList, setKeyroleList] = React.useState([]);
    const [supportingStaffTable, setSupportingStaffTable] = React.useState([]);
    const [departmentList, setDepartmentList] = React.useState([]);
    const [staffList, setstaffList] = React.useState([]);

    const staffDefaultValues = {
        bookingId: bookingId,
        //keyRole: 1,
        departmentId: "",
        supportingStaffId: "",
    }

    const staffSchema = yup.object().shape({
        // keyRole: yup
        //     .number()
        //     .required("Consultation Period is required"),
        departmentId: yup
            .string()
            .required("Department is required"),
        supportingStaffId: yup
            .string()
            .required("Staff is required"),
    });

    const { reset: staffReset, handleSubmit: staffSubmit, watch, getValues,
        control: staffControl, formState: { errors: staffErrors } } = useForm({
            mode: "onChange",
            staffDefaultValues,
            resolver: yupResolver(staffSchema),

        });

    useEffect(() => {
        fetchSupportingStaffTable();
    }, [])

    useEffect(() => {
        if (open) {
            fetchDepartmentList();
        }
    }, [])

    useEffect(() => {
        fetchStaffList();
    }, [watch('departmentId')])

    const handleClickOpen = () => {
        resetForm();
        setOpen(true);
        fetchDepartmentList();
        fetchStaffList();
    };

    const handleClose = () => {
        setOpen(false);
        setEdit(false);
    };

    const setstaffEditdata = (item) => {
        handleClickOpen();
        setEdit(true);
        staffReset(item);
    }

    const resetForm = () => {
        staffReset(staffDefaultValues);
    };

    const fetchDepartmentList = () => {
        axiosInstance
            .get(DepartmentMstDataURL)
            .then((res) => {
                setDepartmentList(res.data);
            })
            .catch((e) => console.log("fetchDepartmentListError:" + e.message));
    }

    const fetchStaffList = () => {
        let departmentId = parseInt(getValues("departmentId"));
        departmentId &&
            axiosInstance
                .get(PhysicianMstDataURL + departmentId)
                .then((res) => {
                    setstaffList(res.data);
                })
                .catch((e) => console.log("fetchStaffListrror:" + e.message));
    }

    const fetchSupportingStaffTable = () => {
        bookingId ?
            axiosInstance
                .get(SupportingStaffTableDataURL + bookingId)
                .then((res) => {
                    setSupportingStaffTable(res.data);
                    setIsLoading(false);
                })
                .catch((e) => {
                    console.log("fetchSupportingStaffTableError:" + e.message)
                    setIsLoading(false);
                }) : setIsLoading(false);
    }

    const executeSubmit = async (data) => {
            await axiosInstance
                .post(SupportingStaffURL, { ...data })
                .then((res) => {
                    if (res.status === 200 || res.status === 201) {
                        // const strsubmit = "Data Successfully Submitted";
                        // toast.success(strsubmit);
                        handleClose();
                        resetForm();
                        fetchSupportingStaffTable();
                    } else if (res.status === 409) {
                        toast.warning(res.response.data);
                    }
                })
                .catch((error) => {
                    if (error.response.status === 409) {
                        toast.warning(error.response.data);
                    }
                    else {
                        toast.error("Something went wrong");
                    }
                })
    }

    const executeUpdate = async (data) => {
        await axiosInstance
            .post(SupportingStaffUpdateURL, { ...data })
            .then((res) => {
                if (res.status === 200 || res.status === 201) {
                    const strupdate = "Record Updated";
                    toast.success(strupdate);
                    handleClose();
                    resetForm();
                    fetchSupportingStaffTable();
                } else if (res.status === 409) {
                    toast.warning(res.response.data);
                }
            })
            .catch((error) => {
                if (error.response.status === 409) {
                    toast.warning(error.response.data);
                }
                else {
                    toast.error("Something went wrong");
                }
            });
    }

    const executeDelete = async (data) => {
        await axiosInstance
            .post(SupportingStaffDeleteeURL, { ...data })
            .then((res) => {
                if (res.status === 200 || res.status === 201) {
                    const strsubmit = "Record Deleted";
                    toast.error(strsubmit);
                    setOpenDeleteBox(!openDeleteBox);
                    resetForm();
                    fetchSupportingStaffTable();
                } else if (res.response.status === 409) {
                    toast.warning(res.response.data);
                }
            })
            .catch((error) => {
                toast.error("Something went wrong");
            });
    }

    const onClickOpenDeleteBox = (item) => {
        setOpenDeleteBox(!openDeleteBox)
        setItem(item)
    }

    return (
        <div className={classes.root}>
            <Grid container
                className={classes.grid1}
                alignItems="center" spacing={2} justifyContent="center" >
                <Grid item >
                    <Typography> <span style={{ fontWeight: 500 }}>Suppporting Staff ({supportingStaffTable ? supportingStaffTable?.length : 0})</span>
                    </Typography>
                </Grid>
                <Grid item >
                    <Button
                        onClick={handleClickOpen}
                        type="submit"
                        color="primary"
                        variant="contained"
                    >
                        Add Supporting Staff
                    </Button>
                    <form onSubmit={staffSubmit(executeSubmit)}>
                        <Dialog open={open} onClose={handleClose}>
                            <Grid container xs={12} justifyContent="space-between" className={classes.dialogHead}>
                                <Grid item >
                                    <Typography className={classes.label} >Supporting Staff Details</Typography>
                                </Grid>
                                <Grid item  >
                                    <IconButton
                                        size="small"
                                        //style={{ color: "white" }}
                                        onClick={handleClose} >
                                        <CloseRounded />
                                    </IconButton>
                                </Grid>
                            </Grid>
                            <Divider />
                            <DialogContent >
                                <Grid container spacing={2}>
                                    <Grid item xs={12} lg={12}>
                                        <Typography > Department Name<span style={{ color: 'red' }}>*</span> </Typography>
                                        <Controller
                                            name="departmentId"
                                            control={staffControl}
                                            render={({
                                                field: { onChange, value },
                                            }) => (
                                                <Autocomplete
                                                    componentsProps={{ popper: { style: { width: 'fit-content' } } }} l

                                                    options={departmentList}
                                                    value={departmentList.find((o) => o.id === value) || ""
                                                    }
                                                    getOptionLabel={(option) =>
                                                        option.departmentName ? option.departmentName : ""
                                                    }
                                                    onChange={(e, v) => {
                                                        onChange((v?.id));
                                                        //console.log(v)
                                                    }}
                                                    disabled={edit ? true : false}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            variant="outlined"
                                                            size="small"
                                                            placeholder="Select Department"
                                                            error={!!staffErrors.departmentId}
                                                            helperText={staffErrors.departmentId?.message}
                                                        />
                                                    )}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={12} lg={12}>
                                        <Typography > Staff Name<span style={{ color: 'red' }}>*</span> </Typography>
                                        <Controller
                                            name="supportingStaffId"
                                            control={staffControl}
                                            render={({
                                                field: { onChange, value },
                                            }) => (
                                                <Autocomplete
                                                    componentsProps={{ popper: { style: { width: 'fit-content' } } }} l

                                                    options={staffList}
                                                    value={staffList.find((o) => o.id === value) || ""
                                                    }
                                                    getOptionLabel={(option) =>
                                                        option.name ? option.name : ""
                                                    }
                                                    onChange={(e, v) => {
                                                        onChange((v?.id));
                                                        //console.log(v)
                                                    }}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            variant="outlined"
                                                            size="small"
                                                            placeholder="Select SupportingStaff"
                                                            error={!!staffErrors.supportingStaffId}
                                                            helperText={staffErrors.supportingStaffId?.message}
                                                        />
                                                    )}
                                                />
                                            )}
                                        />
                                    </Grid>
                                </Grid>
                            </DialogContent>
                            <DialogActions style={{ justifyContent: "center" }}>
                                {edit ? (
                                    <Button
                                        align="center"
                                        type="submit"
                                        color="primary"
                                        variant="contained"
                                        onClick={staffSubmit(executeUpdate)}
                                    >
                                        Update
                                    </Button>

                                ) : (
                                    <Button

                                        align="center"
                                        type="submit"
                                        color="primary"
                                        variant="contained"
                                        onClick={staffSubmit(executeSubmit)}
                                    >
                                        Submit
                                    </Button>

                                )}

                                <Button
                                    align="center"
                                    color="primary"
                                    variant="contained"
                                    className={classes.reset}
                                    onClick={() => {
                                        resetForm();
                                        setEdit(false);
                                    }}>
                                    Reset
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </form>
                </Grid>
                <Grid item xs={12} >
                    <SupportingStaffTable
                        isLoading={isLoading}
                        data={supportingStaffTable}
                        onClickOpenDeleteBox={onClickOpenDeleteBox}
                        setEditdata={setstaffEditdata} />
                </Grid>
            </Grid>
            <DeleteDialogBox openDeleteBox={openDeleteBox}
                closeDeleteBox={() => setOpenDeleteBox(!openDeleteBox)}
                onClick={() => executeDelete(item)}
            />
        </div >
    )
}