import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  LinearProgress,
  Table,
  TableBody,
  TableContainer,
  TableRow,
  TextField,
  Tooltip,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import useTable, { StyledTableCell, StyledTableRow } from "components/UseTable";
import { Edit, Search, Visibility } from "@material-ui/icons";
import { StatusChip } from "pages/AdminRenewalForm/AdminRenewalForm";
import { useSelector } from "react-redux";
import axiosInstance from "pages/Services/AxiosInstance";
import RenewalForm from "./RenewalForm";
const headCells = [
  { id: "S.No.", label: "S.No.", align: "left" },
  { id: "financialYear", label: "Financial Year", align: "left" },
  { id: "remarks", label: "Hr Remarks", align: "left" },
  { id: "status", label: "Status", align: "left" },
  { id: "action", label: "Action", align: "left", disableSorting: true },
];
export default function SubmittedRenewalList() {
  
  const loggedInUser = useSelector((store) => store.loggedInUser);
  const [filterFn, setFilterFn] = useState({ fn: (items) => items });
  const [openDetails, setOpenDetails] = useState(false);
  const [selectedForm, setSelectedForm] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [isView, setIsView] = useState(false);
  const [renewalData, setRenewalData] = useState([]);
  const [loading, setLoading] = useState(false);
  const requestSearch = (e) => {
    let val = e.target.value;
    setFilterFn({
      fn: (records) => {
        if (!val) return records;

        return records.filter(
          (row) =>
            row.renewalStatus
              .toString()
              .toLowerCase()
              .includes(val.toLowerCase()) ||
            row.renewalYear
              ?.toString()
              .toLowerCase()
              .includes(val.toLowerCase())
        );
      },
    });
  };
  const {  TblHead, TblPagination, recordsAfterPagingAndSorting } = useTable(renewalData, headCells, filterFn, 15);
  function getRenewalData() {
    // handleReset();
    setLoading(true);
    const req={employeeId:loggedInUser?.empId}
    axiosInstance.post("renewal/getByEmpId",req)
      .then(({ data }) => {
        if (Array.isArray(data)) {
          setRenewalData(
            data.map((e) => ({
              ...e,
              statusId:
                e.statusId === 1
                  ? "Submitted"
                  : e.statusId === 2
                  ? "Draft"
                  : e.statusId === 3
                  ? "Approved"
                  : e.statusId === 4 
                  ? "Rejected"
                  : null,
                  remarks: e.remarks ? (e.remarks.includes('+') ? e.remarks.replace(/\+/g, ',') : e.remarks.trim()) : '',
                  // renewalYear: e.renewalYear ? `${e.renewalYear} - ${(parseInt(e.renewalYear, 10) + 1)}` : null,
                  renewalYear: e.renewalYear ? `${e.renewalYear} - ${(parseInt(e.renewalYear, 10) + 1) % 100}` : null,
            }))
          );
        }
      })
      .catch(console.log)
      .finally(() => {
        setLoading(false);
      });
  }
  const refreshData = () => {
    getRenewalData();
  };
  useEffect(() => {
    getRenewalData();
  }, [loggedInUser]);
  return (
    <>
      {openDetails && (
        <PreviewForm
          isEditing={isView}
          selectedForm={selectedForm}
          refreshData={refreshData}
          onlyPreview
          onClose={() => {
            setOpenDetails(false);
            setSelectedForm(null);
            setIsView(false);
          }}
        />
      )}

      <RenewalForm isEditing={isEditing} selectedForm={selectedForm} refreshData={refreshData} />
      <div style={{ textAlign: "right" }}>
        <TextField
          placeholder="Search..."
          variant="outlined"
          size="small"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
          onChange={requestSearch}
        />
      </div>
      <TableContainer style={{ height: "600px" }}>
        {loading && <LinearProgress />}
        <Table
          style={{
            position: "relative",
            border: "1px solid lightgrey",
          }}
          size="small"
        >
          <TblHead />
          {recordsAfterPagingAndSorting()?.length !== 0 ? (
            <TableBody>
              {recordsAfterPagingAndSorting()?.map((item, index) => (
                <TableRow key={item.id}>
                  <StyledTableCell>{index + 1} </StyledTableCell>
                  <StyledTableCell>{item.renewalYear} </StyledTableCell>
                  <StyledTableCell>{item.remarks} </StyledTableCell>
                  <StyledTableCell>
                    <StatusChip status={item.statusId} />{" "}
                  </StyledTableCell>
                  <StyledTableCell>
                    <div style={{ display: "flex", gap: 8 }}>
                      <Tooltip title="View Details">
                        <IconButton
                          onClick={() => {
                            setOpenDetails(true);
                            setSelectedForm(item);
                            setIsView(true);
                          }}
                          color="primary"
                          size="small"
                        >
                          <Visibility />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Edit">
                        <IconButton
                          onClick={() => {
                            setSelectedForm(item);
                            setIsEditing(true);
                          }}
                          disabled={item.statusId === "Submitted" || item.statusId === "Approved"}
                          color="primary"
                          size="small"
                        >
                          <Edit />
                        </IconButton>
                      </Tooltip>
                    </div>
                  </StyledTableCell>
                </TableRow>
              ))}
            </TableBody>
          ) : (
            <TableBody>
              <StyledTableRow>
                <StyledTableCell colSpan={4} align="center">
                  No records found
                </StyledTableCell>
              </StyledTableRow>
            </TableBody>
          )}
        </Table>
        {recordsAfterPagingAndSorting()?.length ? <TblPagination /> : null}
      </TableContainer>
    </>
  );
}

function PreviewForm({ onClose, onlyPreview, isEditing, selectedForm }) {
  return (
    <Dialog open maxWidth="lg" fullWidth>
      {/* <DialogTitle onClose={onClose} className="no-print">Renewal Form</DialogTitle> */}
      <DialogContent>
        <span>
          Form Status: &nbsp;
          <span
            style={{
              color:
                selectedForm.renewalStatus === "Submitted"
                  ? "blue"
                  : selectedForm.renewalStatus === "Saved"
                  ? "orange"
                  : selectedForm.renewalStatus === "Approved"
                  ? "darkgreen"
                  : "darkred",

              // Set background colors for each renewalStatus case
              backgroundColor:
                selectedForm.renewalStatus === "Submitted"
                  ? "#cce5ff" // Light blue
                  : selectedForm.renewalStatus === "Saved"
                  ? "#fff3cd" // Light yellow
                  : selectedForm.renewalStatus === "Approved"
                  ? "#d9ffd9" // Light green
                  : "#ffdad3", // Light red
              borderRadius: 8,
              paddingInline: 16,
              paddingBlock: 6,
            }}
          >
            {selectedForm.renewalStatus}
          </span>
        </span>
        <RenewalForm isEditing={isEditing} selectedForm={selectedForm} onlyPreview={onlyPreview} />
      </DialogContent>
      <DialogActions className="no-print">
    
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => window.print()}
                >
                  Print
                </Button>
             
        <Button color="secondary" size="small" onClick={onClose} variant="contained">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
