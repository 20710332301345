import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography, Card } from "@material-ui/core";



const useStyles = makeStyles((theme) => ({
    root: {
        padding: 5,
        background: props => props.bgcolor,
    },
    value: {
        fontSize: "1.3rem",
        fontWeight: 600,
        textAlign: 'center',
        color: props => props.color
    },
    title: {
        textAlign: 'center',
        color: theme.palette.text.primary
    },
}));

const InfoCard = (props) => {
    const classes = useStyles(props);
    return (
        <Card className={classes.root}>
            <Grid container justifyContent="center"   >
                <Grid item xs={12}>
                    <Grid item >
                        <Typography
                            noWrap
                            className={classes.value}>
                            {props.value}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography noWrap className={classes.title}>
                            {props.title}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>

        </Card >
    );
}
export default InfoCard