import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { toast } from "react-toastify";
import { Container, Grid } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import axiosInstance from "pages/Services/AxiosInstance";
import EmployeeDetails from "../Admin/Employee/EmployeeTable";
import Appbar from "pages/Layout/Appbar";
import Bill from "./ClaimDetails/ClaimDetailsTable";
import ClaimEligibility from "./ClaimEligibility/ClaimEligibilityTable";

import SessionTimeout from "../../SessionTimeOut/SessionTimeout";

import { SERVICE_URL } from "../../config.json";
import { useDispatch, useSelector } from "react-redux";
import { LOGGED_IN_USER } from "Redux/Types";
import DoctorMaster from "../Admin/DoctorMaster/DoctorMaster"
import { OTDashboard } from "pages/OT/Pages/Dashboard/OTDashboard";
import AdminRenewalForm from "pages/AdminRenewalForm/AdminRenewalForm";
import { useNavigate } from "react-router-dom";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box style={{ padding: "0px 24px" }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.primary,
    display: "flex",
    justifyContent: "spacing-between",
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
    overflow: "unset",
  },
  tab: {
    textTransform: "capitalize",
    [theme.breakpoints.down("md")]: {
      width: "100%",
      maxWidth: "unset",
    },
  },
}));

export default function TabpanelView() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const [value, setValue] = React.useState(0);
  const theme = useTheme();
  const [isAuthenticated, setAuth] = React.useState(true);

  const user = useSelector((store) => store.loggedInUser) || JSON.parse(localStorage.getItem("User"));
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleLogout = () => {
    console.log("user in logout", user);
    axiosInstance
      .post(SERVICE_URL + "user/logout", { userName: user.username })
      .then(() => {
        localStorage.clear();
        dispatch({ type: LOGGED_IN_USER, payload: null });
      })
      .catch((e) => console.log(e.message)).finally(()=>{
        navigate("/");
      });
  };

  // useEffect(() => {
  //   axiosInstance(URL_GET_ALL_RECORDS)
  //     .then(({ data }) => {
  //       setAllOrgRecords(data);
  //     })
  //     .catch((e) => {
  //       toast.error("Failed to get all Orgs!");
  //       console.log(e);
  //     });
  // }, []);

  return (
    <>
      <Appbar />
      <Container component={Box} style={{ paddingTop: 16 }} maxWidth="xl">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={2} lg={2}>
            <Tabs
              orientation="vertical"
              variant="scrollable"
              value={value}
              onChange={handleChange}
              aria-label="Vertical tabs example"
              className={classes.tabs}
            >
              <Tab
                label="Employee Details"
                {...a11yProps(0)}
                style={{
                  background: value === 0 ? `${theme.palette.primary.main}` : `${theme.palette.primary.light}`,
                  color: value === 0 ? theme.palette.primary.contrastText : theme.palette.primary.contrastText,
                  opacity: 1,
                  margin: "5px 0px",
                }}
                className={classes.tab}
              />
              <Tab
                label="Medical Claim Eligibility"
                {...a11yProps(1)}
                style={{
                  background: value === 1 ? `${theme.palette.primary.main}` : `${theme.palette.primary.light}`,
                  color: value === 1 ? theme.palette.primary.contrastText : theme.palette.primary.contrastText,
                  opacity: 1,
                  margin: "5px 0px",
                }}
                className={classes.tab}
              />
              <Tab
                label="Claim Details"
                {...a11yProps(2)}
                style={{
                  background: value === 2 ? `${theme.palette.primary.main}` : `${theme.palette.primary.light}`,
                  color: value === 2 ? theme.palette.primary.contrastText : theme.palette.primary.contrastText,
                  opacity: 1,
                  margin: "5px 0px",
                }}
                className={classes.tab}
              />
               <Tab
                label="Doctor Master"
                {...a11yProps(3)}
                style={{
                  background: value === 3 ? `${theme.palette.primary.main}` : `${theme.palette.primary.light}`,
                  color: value === 3 ? theme.palette.primary.contrastText : theme.palette.primary.contrastText,
                  opacity: 1,
                  margin: "5px 0px",
                }}
                className={classes.tab}
              />
               <Tab
                label="Renewal Form"
                {...a11yProps(4)}
                style={{
                  background: value === 4 ? `${theme.palette.primary.main}` : `${theme.palette.primary.light}`,
                  color: value === 4 ? theme.palette.primary.contrastText : theme.palette.primary.contrastText,
                  opacity: 1,
                  margin: "5px 0px",
                }}
                className={classes.tab}
              />
              
            </Tabs>
          </Grid>
          <Grid item xs={12} sm={12} md={10} lg={10}>
            <TabPanel value={value} index={0}>
              <EmployeeDetails />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <ClaimEligibility />
            </TabPanel>
            <TabPanel value={value} index={2}>
              <Bill />
            </TabPanel>
            <TabPanel value={value} index={3}>
             <DoctorMaster/>
            </TabPanel>
            <TabPanel value={value} index={4}>
             <AdminRenewalForm/>
            </TabPanel>
           
           
           
          </Grid>
        </Grid>
      </Container>
      <SessionTimeout isAuthenticated={isAuthenticated} logOut={handleLogout} />
    </>
  );
}
