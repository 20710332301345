import React from "react";
import { useNavigate } from "react-router-dom";
//material-ui
import { makeStyles } from "@material-ui/core/styles";

//component
import Tabs from "pages/Layout/Tabs";
import Appbar from "./Appbar";
import SessionTimeout from "../../SessionTimeOut/SessionTimeout";
import axiosInstance from "pages/Services/AxiosInstance";
import { SERVICE_URL } from "../../config.json";
import { useDispatch, useSelector } from "react-redux";
import { LOGGED_IN_USER } from "Redux/Types";
import CitizenTabs from "pages/Citizen/CitizenTabs";
import Doctor from "pages/Doctor/Doctor";
import { OTDashboard } from "pages/OT/Pages/Dashboard/OTDashboard";

import { toast } from "react-toastify";
import HRDashboard from "NewLayout/HRDashboard";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    color: theme.palette.primary.contrastText,
  },
  appbarRoot: {
    maxHeight: 48,
    minHeight: 48,
  },
  appBar: {
    backgroundColor: theme.palette.primary.dark,
  },
}));

export default function EmployeeLayout() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const navigate = useNavigate()
  const [isAuthenticated, setAuth] = React.useState(true);

  const user = useSelector((store) => store.loggedInUser) || JSON.parse(localStorage.getItem("User"));

  const roleId = useSelector((store) => store.loggedUser?.roleId);
  console.log(roleId, "roleId1");
  // const handleLogOut = () => {
  //   setAuth(!isAuthenticated);
  //   navigate("/");
  // };

  const handleLogout = () => {
    console.log("user in logout", user);
    axiosInstance
      .post(SERVICE_URL + "user/logout", { userName: user?.username })
      .then(() => {
        localStorage.clear();
        dispatch({ type: LOGGED_IN_USER, payload: null });
      })
      .catch((e) => console.log(e.message)).finally(()=>{
        navigate("/");
      });;
  };

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }
    prevOpen.current = open;
  }, [open]);

  return (
    <div className={classes.root}>
      <Appbar />
      {/* <HRDashboard/> */}
      {user?.roleId === 2 ? (
        <Tabs />
      ) : user?.roleId === 3 || user?.roleId === 7 ? (
        <HRDashboard isAdmin ={user?.roleId === 7} />
      ) : null}
      <SessionTimeout isAuthenticated={isAuthenticated} logOut={handleLogout} />
    </div>
  );
}
