import React, { useEffect, useState } from "react";
import { Grid, Typography, makeStyles } from "@material-ui/core";
import { toast } from "react-toastify";
import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from "react-hook-form";
//components
import TextField from "components/TextField";
import Button from "components/Button";
import axiosInstance from "pages/Services/AxiosInstance";
import { DepartmentMstDataURL, OTBedsMstDeleteURL, OTBedsMstURL, OTBedsUpdateMstURL, OTMstDataURL, OTMstDeleteURL, OTMstURL, } from "pages/Services/URLs";
import { DeleteDialogBox } from "pages/OT/components/DeleteDailogBox";
import { Autocomplete } from "@material-ui/lab";
import { restrict } from "restrict";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        padding: theme.spacing(0),
    },
    label: {
        fontSize: "0.875rem",
        color: theme.palette.text.primary,
    },
}));

export const OTBedsMstForm = (props) => {
    const classes = useStyles();
    const [edit, setEdit] = React.useState(false);
    const [departmentList, setDepartmentList] = React.useState([]);
    const [otList, setOtList] = useState([]);

    const defaultValues = {
        departmentId: "",
        otId: "",
        otBeds: "",
    }

    const Schema = yup.object().shape({
        departmentId: yup.string().required("Department is required"),
        otId: yup.string().required("Select  Operation Theatre"),
        otBeds: yup.number().min(1).typeError("Beds are required").required("Beds are required"),
    });

    const {
        watch,
        handleSubmit,
        reset,
        control,
        resetField,
        getValues,
        clearErrors,
        formState: { errors },
    } = useForm({
        defaultValues,
        resolver: yupResolver(Schema),
        mode: "onChange",
    });

    const resetForm = () => {
        reset(defaultValues);
    };

    useEffect(() => {
        fetchDepartmentList();
    }, []);

    useEffect(() => {
        fetchOTMstList();
    }, [watch('departmentId')]);

    useEffect(() => {
        if (props.item.length === 0) {
            resetForm();
            setEdit(false);
        } else {
            reset(props.item);
            setEdit(true);
        }
    }, [props.item, props.deleteItem]);

    const fetchDepartmentList = () => {
        axiosInstance
            .get(DepartmentMstDataURL)
            .then((res) => {
                setDepartmentList(res.data);
            })
            .catch((e) => console.log("fetchDepartmentListError:" + e.message));
    }

    const fetchOTMstList = () => {
        let otDepartmentId = parseInt(getValues("departmentId"));
        otDepartmentId &&
            axiosInstance
                .get(OTMstDataURL + otDepartmentId)
                .then((res) => {
                    setOtList(res.data);
                })
                .catch((e) => console.log("fetchOTMstListError:" + e.message));
    }

    const executeSubmit = async (data) => {
        await axiosInstance
            .post(OTBedsMstURL, { ...data })
            .then((res) => {
                if (res.status === 200 || res.status === 201) {
                    const strsubmit = "OT Beds Added Successfully";
                    toast.success(strsubmit);
                    resetForm();
                    props.fetchOTBedsMstList();
                } else if (res.status === 409) {
                    toast.warning(res.response.data);
                }
            })
            .catch((error) => {
                if (error.response.status === 409) {
                    toast.warning(error.response.data);
                }
                else {
                    toast.error("Something went wrong");
                }
            });
    }
    const executeUpdate = async (data) => {
        await axiosInstance
            .post(OTBedsUpdateMstURL,{ ...data })
            .then((res) => {
                if (res.status === 200 || res.status === 201) {
                    const strupdate = "OT Beds Updated Successfully";
                    toast.success(strupdate);
                    resetForm();
                    props.fetchOTBedsMstList();
                } else if (res.status === 409) {
                    toast.warning(res.response.data);
                }
            })
            .catch((error) => {
                if (error.response.status === 409) {
                    toast.warning(error.response.data);
                }
                else {
                    toast.error("Something went wrong");
                }
            });
    }
    const executeDelete = async (data) => {
        await axiosInstance
            .post(OTBedsMstDeleteURL, { ...data })
            .then((res) => {
                if (res.status === 200 || res.status === 201) {
                    const strsubmit = "Record Deleted";
                    toast.error(strsubmit);
                    props.closeDeleteBox();
                    props.fetchOTBedsMstList();
                } else if (res.response.status === 409) {
                    toast.warning(res.response.data);
                }
            })
            .catch((error) => {
                if (error.response.status === 409) {
                    toast.warning(error.response.data);
                }
                else {
                    toast.error("Something went wrong");
                }
            });
    }

    return (
        <div className={classes.root}>
            <form onSubmit={handleSubmit(executeSubmit)}>
                <Grid container spacing={2} alignItems="center" direction="row" >
                    <Grid item xs={12} sm={12} md={1} lg={1}>
                        <Typography>OT Beds<span style={{ color: "red" }}>*</span></Typography>
                    </Grid>
                    <Grid item xs={12} sm={7} md={3} lg={3}>
                        <Controller
                            name="departmentId"
                            control={control}
                            render={({
                                field: { onChange, value },
                            }) => (
                                <Autocomplete
                                    componentsProps={{
                                        popper: {
                                            style: { width: 'fit-content' }
                                        }
                                    }}
                                    size="small"
                                    options={departmentList}
                                    value={departmentList.find((o) => o.id === value) || null
                                    }
                                    getOptionLabel={(option) =>
                                        option.departmentName ? option.departmentName : null
                                    }
                                    onChange={(e, v) => {
                                        onChange((v?.id));
                                        // console.log(v)
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="outlined"
                                            placeholder="Select Department"
                                            error={!!errors.departmentId}
                                            helperText={errors.departmentId?.message}
                                        />
                                    )}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} sm={7} md={3} lg={3}>
                        <Controller
                            name="otId"
                            control={control}
                            render={({
                                field: { onChange, value },
                            }) => (
                                <Autocomplete
                                    componentsProps={{ popper: { style: { width: 'fit-content' } } }}
                                    size="small"
                                    options={otList}
                                    value={otList.find((o) => o.otId === value) || ""
                                    }
                                    getOptionLabel={(option) =>
                                        option.name ? option.name : ""
                                    }
                                    onChange={(e, v) => {
                                        onChange((v?.otId));
                                        // console.log(v)
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="outlined"
                                            placeholder="Select OT"
                                            error={!!errors.otId}
                                            helperText={errors.otId?.message}
                                        />
                                    )}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} sm={7} md={2} lg={2}>
                        <Controller
                            name="otBeds"
                            control={control}
                            render={({ field: { onChange, value } }) => (
                                <TextField
                                    placeholder="OT Beds"
                                    size="small"
                                    variant="outlined"
                                    value={value}
                                    onChange={onChange}
                                    onInput={(e) => { restrict.digits(e) }}
                                    error={!!errors.otBeds}
                                    helperText={errors.otBeds?.message}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item container spacing={2} xs={12} sm={3} md={3} lg={2}>
                        {edit ?
                            <Grid item >
                                <Button
                                    align="center"
                                    type="submit"
                                    color="primary"
                                    variant="contained"
                                    onClick={handleSubmit(executeUpdate)}
                                >
                                    Update
                                </Button>
                            </Grid>
                            :
                            <Grid item>
                                <Button
                                    align="center"
                                    type="submit"
                                    color="primary"
                                    variant="contained"
                                    onClick={handleSubmit(executeSubmit)}
                                >
                                    Add
                                </Button>
                            </Grid>
                        }
                        <Grid item>
                            <Button
                                onClick={() => {
                                    resetForm();
                                    setEdit(false);
                                }}
                                align="center"
                                type="submit"
                                color="primary"
                                variant="outlined"
                            >
                                Reset
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </form >
            <DeleteDialogBox openDeleteBox={props.openDeleteBox}
                closeDeleteBox={props.closeDeleteBox}
                onClick={() => executeDelete(props.deleteItem)}
            />
        </div >
    )
}