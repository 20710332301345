import React, { useState, useEffect } from "react";
//material-ui
import { makeStyles, Table, TableBody, Grid, InputAdornment, Button } from "@material-ui/core";
import LinearProgress from "@material-ui/core/LinearProgress";
import { Search } from "@material-ui/icons";
//components
import useTable, { StyledTableCell, StyledTableRow } from "components/UseTable"
import TextField from "components/TextField";

const useStyles = makeStyles((theme) => ({
    tableCell: {
        fontSize: "14px",
        textAlign: "center",
        border: "0.1px solid #ededed",
        padding: "6px",
        [theme.breakpoints.down("md")]: {
            fontSize: "12px",
        },
    },
    fontstyle: {
        transform: "lowerCase",
    },
    tableColor: {
        color: "white",
    },
    padding: {
        [theme.breakpoints.down("sm")]: {
            padding: "4px 0px 10px 25px",
        },
        [theme.breakpoints.down("lg")]: {
            padding: "2px 4px 6px 1px",
        },
    },
}));

const headCells = [
    { id: "id", label: "SI.No", align: "center" },
    { id: "departments", label: "Departments", align: "center" },
    { id: "bills", label: "Bills", align: "center" },
    { id: "status", label: "Status", align: "center" },
    { id: "lastupdate", label: "Lastupdate", align: "center" },
];
export default function OTBillingTable(props) {
    const classes = useStyles();
    const [records, setRecords] = useState([]);
    const [filterFn, setFilterFn] = useState({ fn: (items) => items });
    const requestSearch = (e) => {
        let val = e.target.value;
        setFilterFn({
            fn: (records) => {
                if (!val) return records;
                return records.filter(
                    (row) =>
                        row?.id?.toString().includes(val.toLowerCase()) ||
                        row?.departments?.toString().includes(val.toLowerCase()) ||
                        row?.status?.toString().includes(val.toLowerCase()) ||
                        row?.lastupdate?.toString().includes(val.toLowerCase())
                );
            },
        });
    };
    const { TblContainer, TblHead, TblPagination, recordsAfterPagingAndSorting, getSlNo } = useTable(
        records,
        headCells,
        filterFn
    );
    useEffect(() => {
        setRecords(props.data);
    }, [props.data]);
    return (
        <div>
            <Grid container style={{ justifyContent: "end" }} >
                <Grid item >
                    <TextField className={classes.padding}
                        placeholder="Search..."
                        variant="outlined"
                        size="small"
                        // className={classes.searchInput}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Search />
                                </InputAdornment>
                            ),
                        }}
                        onChange={requestSearch}
                    />
                </Grid>
                <Grid item xs={12} >
                    <TblContainer style={{
                        maxHeight: 600,
                        overflowX: "auto"
                    }}>
                        {props.loading && <LinearProgress />}
                        {/* <Table
                            style={{ position: "relative", border: "1px solid lightgrey" }}
                            size="small"
                            aria-label="simple table"
                            id="data-table-cleanliness-report"
                        > */}
                        <Table style={{ width: '100%', }}>
                            <TblHead />
                            {recordsAfterPagingAndSorting()?.length !== 0 ? (
                                <TableBody>
                                    {recordsAfterPagingAndSorting()?.map((item, index) => (
                                        <StyledTableRow className={classes.tableColor} key={index}>
                                            <StyledTableCell className={classes.tableCell} style={{ width: "1%" }}>{index + 1}</StyledTableCell>
                                            <StyledTableCell className={classes.tableCell} style={{ width: "25%" }}>{item?.departments} </StyledTableCell>
                                            <StyledTableCell className={classes.tableCell} style={{ width: "20%" }}>{item?.bills}
                                                <Button
                                                    type="submit"
                                                    align="center"
                                                    onClick={() => props.setEditdata(item)}
                                                    style={{ marginTop: "5px" }}
                                                    color="secondary"
                                                    variant="contained">
                                                    Send to Billing
                                                </Button>
                                            </StyledTableCell>
                                            <StyledTableCell className={classes.tableCell} style={{ width: "10%" }}>{item?.status} </StyledTableCell>
                                            <StyledTableCell className={classes.tableCell} style={{ width: "10%" }}>{item?.lastupdate} </StyledTableCell>
                                        </StyledTableRow>
                                    ))}
                                </TableBody>
                            ) : (
                                <TableBody>
                                    <StyledTableRow>
                                        <StyledTableCell colSpan={8} align="center">
                                            No records found
                                        </StyledTableCell>
                                    </StyledTableRow>
                                </TableBody>
                            )}
                        </Table>
                    </TblContainer>
                    {recordsAfterPagingAndSorting()?.length != 0 && recordsAfterPagingAndSorting()?.length != undefined ? (
                        <TblPagination />
                    ) : (
                        ""
                    )}

                </Grid>

            </Grid>
        </div >
    );
}
