import React from "react";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";

//pages
import Footer from "./Footer";

//component

//assets
// import swachBharat from "assets/Images/Claim.png";
import banner from "assets/Images/newBanner.jpg";
import belLogo from "assets/Images/bel_logo_bg.png";
import UserLogin from "./UserLogin";

import SignUp from "pages/HomePage/SignUp";

const useStyles = makeStyles((theme) => ({
  root: {
    background: "#DFF6FF",
    minHeight: "100vh",
  },
  alignCenter: {
    [theme.breakpoints.down("md")]: {
      textAlign: "center",
    },
  },
  alignCenterImage: {
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },
  image: {
    padding: "20px",
    [theme.breakpoints.up("md")]: {
      height: 75,
    },
    [theme.breakpoints.down("md")]: {
      height: 55,
    },
    [theme.breakpoints.down("sm")]: {
      height: 30,
    },
  },
  typography: {
    padding: "4px",
    borderRadius: "8px",
    color: "black",
    fontSize: 24,
    fontWeight: 400,
    [theme.breakpoints.down("md")]: {
      fontSize: 24,
      fontWeight: 500,
      color: "#103A4D",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
      fontWeight: 500,
      color: "#103A4D",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 16,
      fontWeight: 500,
      color: "#103A4D",
    },
  },
}));

export default function LandingPage() {
  const classes = useStyles();
  const isLogin = useSelector((store) => store.loginOrSignup);

  return (
    <div className={classes.root}>
      <div>
        <Grid
          container
          spacing={0}
          alignItems="center"
          // justifyContent="space-between"
          // style={{ padding: 15 }}
        >
          <Grid
            item
            xs={2}
            sm={1}
            align="left"
            className={classes.alignCenter}
            style={{ paddingLeft: 8 }}
          >
            <img
              style={{ height: "80", width: "240" }}
              src={belLogo}
              alt="belLogo"
              className={classes.image}
            />
          </Grid>

          <Grid
            item
            xs={8}
            sm={10}
            // align="center"
            className={classes.typography}
          >
            <Grid container spacing={0} justifyContent="center">
              <Grid item xs={8} sm={10} align="center">
                <div>
                  <Typography
                    className={classes.typography}
                    style={{ textAlign: "center" }}
                  >
                    <u> BEL RETIRED EMPLOYEES CONTRIBUTORY HEALTH SCHEME (BERECHS)</u>
                  </Typography>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <div>
          <div
            style={{
              // maxWidth: 2800,
              margin: "auto",
              padding: "0px 48px 0px 48px",
              marginTop: "30px",
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                <img
                  style={{
                    height: "600px",
                    maxHeight: "600px",
                    height: "auto",
                    maxWidth: 1300,
                    width: "100%",
                    marginTop: 10,
                  }}
                  src={banner}
                  alt="banner"
                />
              </Grid>
              {isLogin === "login" ? (
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={4}
                  lg={4}
                  xl={4}
                  style={{
                    maxWidth: 1000,
                    margin: "auto",
                    // padding: 24,
                    // marginTop: "80px",
                  }}
                >
                  {/* <SignUp /> */}
                  <UserLogin />
                </Grid>
              ) : (
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={4}
                  lg={4}
                  xl={4}
                  style={{
                    maxWidth: 1000,
                    margin: "auto",
                  }}
                >
                  <SignUp />
                </Grid>
              )}
            </Grid>
            <div
              style={{
                position: "fixed",
                left: "0px",
                right: "0px",
                bottom: "0px",
                marginBotton: "0px",
                width: "100%",
                zIndex: "999",
              }}
            >
              <Footer />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
