import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid, Typography, makeStyles } from "@material-ui/core";
//components
import OTBookingTable from "pages/OT/components/OTTables/OTBookingTable";
import OTMenuList from "../../components/OTMenuList";
import { fetchBillingList } from "Redux/Actions/OTActions/OTPatientsAction";
import { SELECTED_ACTION_ITEM } from "Redux/Types";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        padding: theme.spacing(0),
        // height: '168vh',
    },
}));

export const OTBilling = () => {
    const classes = useStyles();
    const selectedActionItem = useSelector((store) => store.selectedActionItem.item);
    const billingList = useSelector((store) => store.OTPatients.billingList);
    const mainRef = useRef(null);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch({ type: SELECTED_ACTION_ITEM, payload: null });
        dispatch(fetchBillingList())
    }, []);

    const handleScroll = (ref) => {
        setTimeout(() => {
            window.scrollTo(0, document.body.scrollHeight);
        }, 100);
        // if (mainRef.current) {
        //     mainRef.current.scrollTop = mainRef.current.scrollHeight;
        // }
    };

    const MenuList = (item) => (
        <OTMenuList item={item} scroll={() => handleScroll(mainRef.current)} />
    );

    return (
        <div className={classes.root}>
            <Grid container spacing={4} >
                <Grid item xs={12}>
                    <Typography variant="h6" noWrap >
                        Billing ({billingList?.data ? billingList?.data.length : 0})
                    </Typography>
                    <OTBookingTable isLoading={billingList?.isLoading} data={billingList?.data}
                        menuList={(item) => MenuList(item)} />
                </Grid>
                <Grid item xs={12} ref={mainRef} >
                    {selectedActionItem?.type?.name === 'BillingActionItem' && selectedActionItem}
                </Grid>
            </Grid>
        </div>
    )
}






