import React, { useEffect, useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import { Card, CardContent, CircularProgress, Grid, LinearProgress, Typography } from '@material-ui/core';
import { KeyboardDatePicker, KeyboardDateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from "@date-io/date-fns";
import { toast } from 'react-toastify';
import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from "react-hook-form";
import { Autocomplete } from "@material-ui/lab";
import { useDispatch } from "react-redux";
import moment from "moment";
//components
import TextField from "components/TextField";
import Button from "components/Button";
import noSlotsImg from "assets/Images/noslots.png";

//actions
import axiosInstance from "pages/Services/AxiosInstance";
import { DepartmentMstDataURL, OTSlotsURL, OTBooking, OTBookingUpdateURL, OTMstDataURL, PriorityURL, SurgerynamesDataURL } from "pages/Services/URLs";
import { addBookingId, fetchPendingList } from "Redux/Actions/OTActions/OTPatientsAction";

import { HotelRounded } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        padding: theme.spacing(0),
    },
    slotContainer: {
        marginTop: 1,
        justifyContent: 'center',
        border: `1px solid lightgrey`,
        borderRadius: '4px',
        //borderBlockColor: theme.palette.background.default,  
        padding: theme.spacing(1),
        margin: 1,
    },
    slotsColumn: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        border: `1px dashed lightgrey`,
        padding: theme.spacing(1),
    },
    bedsRow: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        padding: theme.spacing(0),
        border: `1px dashed lightgrey`,
    },
    bed: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        margin: theme.spacing(1),
        padding: theme.spacing(1),
        borderRadius: '4px',
        border: `1px solid lightgrey`,
        color: theme.palette.normal.dark,
    },
    bedAvailable: {
        color: theme.palette.normal.dark,
        cursor: "pointer",
        userSelect: "none",
        transition: "background-color 0.3s",
        '&:hover': {
            backgroundColor: theme.palette.infocard.green.light,
            border: "none",
        },
    },
    bedSelected: {
        color: theme.palette.normal.dark,
        transition: "background-color 0.3s",
        background: theme.palette.normal.dark,
        cursor: "pointer",
        userSelect: "none",
    },
    bedUnSelected: {
        cursor: "not-allowed",
        userSelect: "none",
    },
    slotDisabled: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: "#8d8d8d",
        backgroundColor: theme.palette.background.default,
    },
    bedDisabled: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: "#8d8d8d",
        cursor: "not-allowed",
        backgroundColor: theme.palette.background.default,
        userSelect: "none",
        pointerEvents: "none",
    },
}));

export const BookingTab = (props) => {
    const classes = useStyles();
    const theme = useTheme();
    const dispatch = useDispatch();
    const patientsDetails = useSelector((store) => store.OTPatients.patientDetails);
    const updateOTBooking = useSelector((store) => store.OTPatients.updateOTBooking);
    const [isLoading, setIsLoading] = React.useState(true);
    const [otList, setOtList] = useState([]);
    const [otBookingSlots, setOtBookingSlots] = React.useState([]);
    const [departmentList, setDepartmentList] = React.useState([]);
    const [priority, setPriority] = useState([]);
    const [surgeryNameList, setSurgeryNameList] = React.useState([]);
    const [selectedSlots, setSelectedSlots] = useState([]);
    const [updatedSlots, setUpdatedSlots] = useState([]);
    const [unselectedSlots, setUnselectedSlots] = useState([]);

    const defaultValues = {
        id: patientsDetails?.id ? parseInt(patientsDetails?.id) : '',
        regId: patientsDetails?.regId ? parseInt(patientsDetails?.regId) : '',
        otSelectedSlots: [],
        otUnselectedSlots: [],
        wheelIn:
            patientsDetails?.wheelIn ? patientsDetails?.wheelIn : '',
        wheelOut:
            patientsDetails?.wheelOut ? patientsDetails?.wheelOut : '',
        anaesthesiaStartTime:
            patientsDetails?.anaesthesiaStartTime ? patientsDetails?.anaesthesiaStartTime : '',
        anaesthesiaEndTime:
            patientsDetails?.anaesthesiaEndTime ? patientsDetails?.anaesthesiaEndTime : '',

        surgeryDate: patientsDetails.otSelectedSlots && patientsDetails.otSelectedSlots.length > 0
            ? moment(patientsDetails.otSelectedSlots[0].surgeryDate).format("YYYY-MM-DD")
            : '',
        otDepartmentId:
            patientsDetails?.otDepartmentId ? patientsDetails?.otDepartmentId : '',
        otId:
            patientsDetails?.otId ? patientsDetails?.otId : '',
        surgeryId:
            patientsDetails?.surgeryId ? patientsDetails?.surgeryId : '',
        priority:
            patientsDetails?.priority ? patientsDetails?.priority : '',
    }
    const Schema = yup.object().shape({
        wheelIn: yup.date().typeError("Select Date").required("date is Required"),
        wheelOut: yup.date().typeError("Select Date").required("date is Required"),
        anaesthesiaStartTime: yup.date().typeError("Select Date").required("date is Required"),
        anaesthesiaEndTime: yup.date().typeError("Select Date").required("date is Required"),
        surgeryDate: yup.date().typeError("Select Date").required("date is Required"),
        otSelectedSlots: (!selectedSlots.length || (!selectedSlots.length && !unselectedSlots.length))
            && yup.array().min(1, 'Select at least one slot').required('Select at least one slot'),
        otDepartmentId: yup.string().required("Select  Department Name"),
        otId: yup.string().required("Select  Operation Theatre"),
        surgeryId: yup.string().required("Select  Surgery Name"),
        priority: yup.string().required("Select Priority"),
    });

    const {
        handleSubmit,
        reset,
        watch,
        setValue,
        getValues,
        control,
        formState: { errors },
    } = useForm({
        defaultValues,
        resolver: yupResolver(Schema),
        mode: "onChange",
    });

    useEffect(() => {
        fetchDepartmentList();
        fetchOTPriorityList();
    }, []);

    useEffect(() => {
        fetchOTMstList();
        fetchSurgerynameList();
    }, [watch('otDepartmentId')]);

    useEffect(() => {
        setSelectedSlots([]);
        fetchOTSlots();
    }, [watch('surgeryDate')]);

    useEffect(() => {
        setValue("otSelectedSlots", selectedSlots);
    }, [selectedSlots]);

    useEffect(() => {
        patientsDetails.otSelectedSlots &&
            setValue("otSelectedSlots", updatedSlots);
    }, [updatedSlots]);

    useEffect(() => {
        setValue("otUnselectedSlots", unselectedSlots);
    }, [unselectedSlots]);

    const fetchDepartmentList = () => {
        axiosInstance
            .get(DepartmentMstDataURL)
            .then((res) => {
                setDepartmentList(res.data);
            })
            .catch((e) => console.log("fetchDepartmentListError:" + e.message));
    }

    const fetchOTMstList = () => {
        let otDepartmentId = parseInt(getValues("otDepartmentId"));
        otDepartmentId &&
            axiosInstance
                .get(OTMstDataURL + otDepartmentId)
                .then((res) => {
                    setOtList(res.data);
                })
                .catch((e) => console.log("fetchOTMstListError:" + e.message));
    }

    const fetchSurgerynameList = () => {
        let otDepartmentId = parseInt(getValues("otDepartmentId"));
        otDepartmentId &&
            axiosInstance
                .get(SurgerynamesDataURL + otDepartmentId)
                .then((res) => {
                    setSurgeryNameList(res.data);
                })
                .catch((e) => console.log("fetchSurgerynameListError:" + e.message));
    }

    const fetchOTPriorityList = () => {
        axiosInstance
            .get(PriorityURL)
            .then((res) => {
                setPriority(res.data)
            })
            .catch((e) => console.log("fetchOTPriorityListError:" + e.message));
    }

    const fetchOTSlots = () => {

        setIsLoading(true);
        let otDepartmentId = parseInt(getValues("otDepartmentId"));
        let otId = parseInt(getValues("otId"));
        let surgeryDate = moment(getValues("surgeryDate")).format("YYYY-MM-DD");
        const data = {
            otDepartmentId: otDepartmentId,
            otId: otId,
            surgeryDate: surgeryDate,
            bookingId: patientsDetails?.id ? patientsDetails?.id : '',
        }
        otDepartmentId && otId && surgeryDate ?
            axiosInstance
                .post(OTSlotsURL, data)
                .then((res) => {
                    if (res.status === 200 || res.status === 201) {
                        setOtBookingSlots(res.data);
                        setIsLoading(false);
                    } else if (res.status === 409) {
                        toast.warning(res.response.data);
                    }
                })
                .catch((error) => {
                    if (error.response.status === 409) {
                        toast.warning(error.response.data);
                    }
                    else {
                        toast.error("Something went wrong");
                    }
                    console.log("fetchOTSlotsError:" + error.message)
                    setIsLoading(false);
                }) : setIsLoading(false);

        if (patientsDetails?.otSelectedSlots) {
            for (const slot of patientsDetails?.otSelectedSlots) {
                if (slot.available) {
                    setSelectedSlots((prevSelected) => [...prevSelected, slot]);
                    // toggleSlot(slot, 0);
                }
            }
        }
    }

    const executeSubmit = async (data) => {
        await axiosInstance
            .post(OTBooking, { ...data, otStatus: 'Pending' })
            .then((res) => {
                if (res.status === 200 || res.status === 201) {
                    const strsubmit = "OT Slot Created Successfully";
                    toast.success(strsubmit);
                    props.onClickNext();
                    resetForm();
                    dispatch(fetchPendingList());
                    dispatch(addBookingId(res.data.id)); // add bookingId from response
                } else if (res.status === 409) {
                    toast.warning(res.response.data);
                }
            })
            .catch((error) => {
                if (error.response.status === 409) {
                    toast.warning(error.response.data);
                }
                else {
                    toast.error("Something went wrong");
                }
            });
    };

    const executeUpdate = async (data) => {
        await axiosInstance
            .post(OTBookingUpdateURL, { ...data, otStatus: 'Scheduled' })
            .then((res) => {
                if (res.status === 200 || res.status === 201) {
                    const strsubmit = updateOTBooking === 'update' ?
                        "Record Updated Successfully" : "Booking Details Rescheduled"
                    toast.success(strsubmit);
                    props.onClickNext();
                    resetForm();
                    dispatch(fetchPendingList());
                    dispatch(addBookingId(res.data.id)); // add bookingId from response
                } else if (res.status === 409) {
                    toast.warning(res.response.data);
                }
            })
            .catch((error) => {
                if (error.response.status === 409) {
                    toast.warning(error.response.data);
                }
                else {
                    toast.error("Something went wrong");
                }
            });
    };

    const resetForm = () => {
        reset(defaultValues);
    };

    const toggleSlot = (slot, otBedIndex, patientsDetails) => {

        if (slot.available) {
            const selectedOtBed = slot.otBeds[otBedIndex];
            // Create a new object with the selected slot and otBed
            const selectedData = {
                id: slot.id,
                bookingId: slot.bookingId,
                surgeryDate: slot.surgeryDate,
                slotStartTime: slot.slotStartTime,
                slotEndTime: slot.slotEndTime,
                available: slot.available,
                otBeds: [{ id: selectedOtBed.id, available: selectedOtBed.available }],
            };
            // Check if the selected slot is already in selectedSlots with the slot time & same Bed
            const isSameBedExists = selectedSlots.find(
                (s) =>
                    s.slotStartTime === selectedData.slotStartTime &&
                    s.slotEndTime === selectedData.slotEndTime &&
                    s.otBeds[0].id === selectedOtBed.id
            );
            // Check if the selected slot is already in selectedSlots with the slot time & different Bed
            if (isSameBedExists) {

                //Remove
                setSelectedSlots((prevSelected) =>
                    prevSelected.filter(
                        (s) =>
                            s.slotStartTime !== selectedData.slotStartTime &&
                            s.slotEndTime !== selectedData.slotEndTime
                    ));
                //Remove
                patientsDetails?.otSelectedSlots &&
                    setUpdatedSlots((prevUpdated) =>
                        prevUpdated.filter(
                            (s) =>
                                s.slotStartTime !== selectedData.slotStartTime &&
                                s.slotEndTime !== selectedData.slotEndTime
                        ));
                //Add 
                selectedData.id &&
                    setUnselectedSlots((prevUnselected) => [...prevUnselected, selectedData]);
            }
            else {
                // If the slot is not selected, add it to selectedSlots
                setSelectedSlots((prevSelected) => [...prevSelected, selectedData]);
                patientsDetails?.otSelectedSlots &&
                    setUpdatedSlots((prevSelected) => [...prevSelected, selectedData]);

                // Remove when same slot different bed is selected
                selectedData.id &&
                    setUnselectedSlots((prevUnselected) =>
                        prevUnselected.filter(
                            (s) =>
                                s.slotStartTime !== selectedData.slotStartTime &&
                                s.slotEndTime !== selectedData.slotEndTime
                        )
                    );
            }
        }
    };

    return (
        <div className={classes.root}>
            <form onSubmit={handleSubmit(executeSubmit)}>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Typography > <span style={{ fontWeight: 500 }}>Booking Details</span></Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        <Typography>OT Department Name<span style={{ color: "red" }}>*</span></Typography>
                        <Controller
                            name="otDepartmentId"
                            control={control}
                            render={({
                                field: { onChange, value },
                            }) => (
                                <Autocomplete
                                    disabled={updateOTBooking === 'reschedule' ? true : false}
                                    componentsProps={{
                                        popper: {
                                            style: { width: 'fit-content' }
                                        }
                                    }}
                                    size="small"
                                    options={departmentList}
                                    value={departmentList.find((o) => o.id === value) || ""
                                    }
                                    getOptionLabel={(option) =>
                                        option.departmentName ? option.departmentName : ""
                                    }
                                    onChange={(e, v) => {
                                        onChange((v?.id));
                                        // console.log(v)
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="outlined"
                                            placeholder="Select Department"
                                            error={!!errors.otDepartmentId}
                                            helperText={errors.otDepartmentId?.message}
                                        />
                                    )}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        <Typography>Operation Theatre<span style={{ color: "red" }}>*</span></Typography>
                        <Controller
                            name="otId"
                            control={control}
                            render={({
                                field: { onChange, value },
                            }) => (
                                <Autocomplete
                                    componentsProps={{ popper: { style: { width: 'fit-content' } } }}
                                    size="small"
                                    options={otList}
                                    value={otList.find((o) => o.otId === value) || ""
                                    }
                                    getOptionLabel={(option) =>
                                        option.name ? option.name : ""
                                    }
                                    onChange={(e, v) => {
                                        onChange((v?.otId));
                                        // console.log(v)
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="outlined"
                                            placeholder="Select OT"
                                            error={!!errors.otId}
                                            helperText={errors.otId?.message}
                                        />
                                    )}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        <Typography>Surgery Name<span style={{ color: "red" }}>*</span></Typography>
                        <Controller
                            name="surgeryId"
                            control={control}
                            render={({
                                field: { onChange, value },
                            }) => (
                                <Autocomplete
                                    disabled={updateOTBooking === 'reschedule' ? true : false}
                                    componentsProps={{ popper: { style: { width: 'fit-content' } } }}
                                    size="small"
                                    options={surgeryNameList}
                                    value={surgeryNameList.find((o) => o.surgeryId === value) || ""
                                    }
                                    getOptionLabel={(option) =>
                                        option.surgeryName ? option.surgeryName : ""
                                    }
                                    onChange={(e, v) => {
                                        onChange((v?.surgeryId));
                                        // console.log(v)
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="outlined"
                                            placeholder="Select Surgery"
                                            error={!!errors.surgeryId}
                                            helperText={errors.surgeryId?.message}
                                        />
                                    )}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        <Typography>Surgery Date<span style={{ color: "red" }}>*</span></Typography>
                        <Controller
                            name="surgeryDate"
                            control={control}
                            defaultValue={defaultValues.surgeryDate}
                            render={({ field: { onChange, value } }) => {
                                return (
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardDatePicker
                                            autoOk
                                            fullWidth
                                            disablePast
                                            size="small"
                                            format="yyyy-MM-dd"
                                            placeholder="Select Date"
                                            inputVariant="outlined"
                                            value={value}
                                            onChange={onChange}
                                            KeyboardButtonProps={{ "aria-label": "change date" }}
                                            error={!!errors.surgeryDate}
                                            helperText={errors.surgeryDate?.message}
                                        />
                                    </MuiPickersUtilsProvider>
                                );
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography>Select Slot<span style={{ color: "red" }}>*</span></Typography>
                        {otBookingSlots?.length > 0 ? (
                            <>
                                <Grid container spacing={1} className={classes.slotContainer} justifyContent="center"
                                    style={{
                                        border: ((patientsDetails.otSelectedSlots && !selectedSlots.length) ||
                                            (!!errors.otSelectedSlots && !selectedSlots.length)) && `1px solid red`,
                                    }}>
                                    {isLoading ?
                                        <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
                                            <CircularProgress color="primary" />
                                        </Grid> :
                                        <Grid container >
                                            <Controller
                                                name={'otSelectedSlots'}
                                                defaultValue={defaultValues.otSelectedSlots}
                                                control={control}
                                                render={({ field }) => (
                                                    <>
                                                        {otBookingSlots?.map((slot, slotIndex) => (
                                                            <>
                                                                {/* OT Slots */}
                                                                <Grid item xs={3} key={slotIndex} className={slot.available === false ?
                                                                    classes.slotDisabled : classes.slotsColumn}>
                                                                    <Typography>
                                                                        {moment(slot?.slotStartTime, "YYYY-MM-DD HH:mm").format("DD MMM h:mm A") +
                                                                            " - " +
                                                                            moment(slot?.slotEndTime, "YYYY-MM-DD HH:mm").format("DD MMM h:mm A")}
                                                                    </Typography>
                                                                </Grid>
                                                                {/* OT Beds */}
                                                                <Grid item xs={9} className={classes.bedsRow}>
                                                                    {slot.otBeds.map((bed, bedIndex) => (
                                                                        <Grid bed xs={2} key={bedIndex}
                                                                            onClick={() => {

                                                                                if (slot.available) {
                                                                                    selectedSlots.find((s) =>
                                                                                        s.slotStartTime === slot.slotStartTime &&
                                                                                        s.slotEndTime === slot.slotEndTime && s.otBeds[0]?.id !== bed.id) ?
                                                                                        console.log("not allowed")
                                                                                        : toggleSlot(slot, bedIndex, patientsDetails);
                                                                                    field.onChange();
                                                                                }
                                                                            }}
                                                                            className={`${classes.bed} 
                                                                            ${bed.available === true && selectedSlots.find((s) =>
                                                                                s.slotStartTime === slot.slotStartTime &&
                                                                                s.slotEndTime === slot.slotEndTime && s.otBeds[0]?.id === bed.id)
                                                                                    ? classes.bedSelected : bed.available === false
                                                                                        ? classes.bedDisabled
                                                                                        : selectedSlots.find((s) =>
                                                                                            s.slotStartTime === slot.slotStartTime &&
                                                                                            s.slotEndTime === slot.slotEndTime && s.otBeds[0]?.id !== bed.id) ?
                                                                                            classes.bedUnSelected : classes.bedAvailable
                                                                                }`}>
                                                                            <HotelRounded
                                                                                style={{
                                                                                    color: bed.available === true && selectedSlots.find((s) =>
                                                                                        s.slotStartTime === slot.slotStartTime &&
                                                                                        s.slotEndTime === slot.slotEndTime && s.otBeds[0]?.id === bed.id)
                                                                                        ? theme.palette.background.paper : bed.available === false
                                                                                            ? "#8d8d8d"
                                                                                            : theme.palette.normal.dark
                                                                                }}
                                                                            />
                                                                        </Grid>
                                                                    ))}
                                                                </Grid >
                                                            </>
                                                        ))}
                                                    </>
                                                )}
                                            />
                                        </Grid>
                                    }
                                </Grid>
                                {patientsDetails?.otSelectedSlots &&
                                    <Controller
                                        name="otUnselectedSlots"
                                        defaultValue={defaultValues.otUnselectedSlots}
                                        control={control}
                                        render={({ field }) => (
                                            <></>
                                        )}
                                    />}
                                {(patientsDetails.otSelectedSlots && !selectedSlots.length) ? (
                                    <div style={{ marginTop: 10, marginLeft: 10, }}>
                                        <Typography variant="caption" color="error" >
                                            Select at least one slot
                                        </Typography>
                                    </div>
                                ) :
                                    (<>{!!errors.otSelectedSlots && !selectedSlots.length ? (
                                        <div style={{ marginTop: 10, marginLeft: 10, }}>
                                            <Typography variant="caption" color="error" >
                                                {errors.otSelectedSlots?.message}
                                            </Typography>
                                        </div>
                                    ) : null}</>)}
                            </>
                        ) : (
                            <>
                                <Grid container xs={12} spacing={1} className={classes.slotContainer}>
                                    <Grid
                                        container
                                        align="center"
                                        justifyContent="center"
                                    >
                                        {isLoading ? <CircularProgress color="primary" /> :
                                            <Grid item xs={12} direction="row">
                                                <img src={noSlotsImg} width={100} />
                                                <Typography> No Slots Available</Typography>
                                            </Grid>}
                                    </Grid>

                                </Grid>
                            </>
                        )}
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        <Typography>Wheel-in<span style={{ color: "red" }}>*</span></Typography>
                        <Controller
                            name="wheelIn"
                            control={control}
                            render={({ field: { onChange, value } }) => {
                                return (
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardDateTimePicker
                                            autoOk
                                            fullWidth
                                            disablePast
                                            size="small"
                                            format="yyyy-MM-dd h:mm a"
                                            placeholder="Select Date and Time"
                                            inputVariant="outlined"
                                            value={value}
                                            onChange={onChange}
                                            KeyboardButtonProps={{ "aria-label": "change date" }}
                                            error={!!errors.wheelIn}
                                            helperText={errors.wheelIn?.message}
                                        />
                                    </MuiPickersUtilsProvider>
                                );
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        <Typography>Wheel-out<span style={{ color: "red" }}>*</span></Typography>
                        <Controller
                            name="wheelOut"
                            control={control}
                            render={({ field: { onChange, value } }) => {
                                return (
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardDateTimePicker
                                            autoOk
                                            fullWidth
                                            disablePast
                                            size="small"
                                            format="yyyy-MM-dd h:mm a"
                                            placeholder="Select Date and Time"
                                            inputVariant="outlined"
                                            value={value}
                                            onChange={onChange}
                                            KeyboardButtonProps={{ "aria-label": "change date" }}
                                            error={!!errors.wheelOut}
                                            helperText={errors.wheelOut?.message}
                                        />
                                    </MuiPickersUtilsProvider>
                                );
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        <Typography>Anaesthesia Start Date<span style={{ color: "red" }}>*</span></Typography>
                        <Controller
                            name="anaesthesiaStartTime"
                            control={control}
                            render={({ field: { onChange, value } }) => {
                                return (
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardDateTimePicker
                                            autoOk
                                            fullWidth
                                            disablePast
                                            size="small"
                                            format="yyyy-MM-dd h:mm a"
                                            placeholder="Select Date and Time"
                                            inputVariant="outlined"
                                            value={value}
                                            onChange={onChange}
                                            KeyboardButtonProps={{ "aria-label": "change date" }}
                                            error={!!errors.anaesthesiaStartTime}
                                            helperText={errors.anaesthesiaStartTime?.message}
                                        />
                                    </MuiPickersUtilsProvider>
                                );
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        <Typography>Anaesthesia End Date<span style={{ color: "red" }}>*</span></Typography>
                        <Controller
                            name="anaesthesiaEndTime"
                            control={control}
                            render={({ field: { onChange, value } }) => {
                                return (
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardDateTimePicker
                                            autoOk
                                            fullWidth
                                            disablePast
                                            size="small"
                                            format="yyyy-MM-dd h:mm a"
                                            placeholder="Select Date and Time"
                                            inputVariant="outlined"
                                            value={value}
                                            onChange={onChange}
                                            KeyboardButtonProps={{ "aria-label": "change date" }}
                                            error={!!errors.anaesthesiaEndTime}
                                            helperText={errors.anaesthesiaEndTime?.message}
                                        />
                                    </MuiPickersUtilsProvider>
                                );
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        <Typography>Priority<span style={{ color: "red" }}>*</span></Typography>
                        <Controller
                            name="priority"
                            control={control}
                            render={({
                                field: { onChange, value },
                            }) => (
                                <Autocomplete
                                    componentsProps={{ popper: { style: { width: 'fit-content' } } }}
                                    size="small"
                                    options={priority}
                                    value={priority.find((o) => o.priority === value) || ""
                                    }
                                    getOptionLabel={(option) =>
                                        option.priority ? option.priority : ""
                                    }
                                    onChange={(e, v) => {
                                        onChange((v?.priority));
                                        // console.log(v)
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="outlined"
                                            placeholder="Select Priority"
                                            error={!!errors.priority}
                                            helperText={errors.priority?.message}
                                        />
                                    )}
                                />
                            )}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={4} justifyContent='center'
                    style={{ marginTop: 0 }}>
                    <Grid item >
                        {updateOTBooking === 'save' ?
                            <Button
                                className={classes.reset}
                                align="center"
                                type="submit"
                                color="primary"
                                variant="contained"
                                onClick={handleSubmit(executeSubmit)}
                            >
                                Save & Next
                            </Button> :
                            (updateOTBooking === 'update' || updateOTBooking === 'reschedule') ?
                                <Button
                                    className={classes.reset}
                                    align="center"
                                    type="submit"
                                    color="primary"
                                    variant="contained"
                                    onClick={handleSubmit(executeUpdate)}
                                >
                                    Update & Next
                                </Button> : null
                        }
                    </Grid>
                </Grid>
            </form>
        </div >
    );
}