import {
    ADD_OT_PATIENT_DETAILS,
    ADD_OT_BOOKING_ID,
    FETCH_OT_PENDING_LIST,
    FETCH_OT_SCHEDULED_LIST,
    FETCH_OT_BILLING_LIST,
    UPDATE_OT_BOOKING,
    FETCH_BILLING_SERVICE_DOCTORS_LIST,
    FETCH_BILLING_SERVICE_LIST,

} from "Redux/Types";

const initialState = {
    patientDetails: [],
    bookingId: null,
    updateOTBooking: false,
    pendingList: {
        isLoading: true,
        data: [],
    },
    scheduledList: {
        isLoading: false,
        data: [],
    },
    billingList: {
        isLoading: true,
        data: [],
    },
    billingServiceDoctorsList: {
        isLoading: true,
        data: [],
    },
    billingServiceList: {
        isLoading: true,
        data: [],
    },
};

export const OTPatients = (state = initialState, action) => {
    switch (action.type) {
        case ADD_OT_PATIENT_DETAILS:
            return {
                ...state,
                patientDetails: action.payload,
            };
        case ADD_OT_BOOKING_ID:
            return {
                ...state,
                bookingId: action.payload,
            };
        case UPDATE_OT_BOOKING:
            return {
                ...state,
                updateOTBooking: action.payload,
            };
        case FETCH_OT_PENDING_LIST:
            return {
                ...state,
                pendingList: action.payload,
            };
        case FETCH_OT_SCHEDULED_LIST:
            return {
                ...state,
                scheduledList: action.payload,
            };
        case FETCH_OT_BILLING_LIST:
            return {
                ...state,
                billingList: action.payload,
            };

        case FETCH_BILLING_SERVICE_DOCTORS_LIST:
            return {
                ...state,
                billingServiceDoctorsList: action.payload,
            };
        case FETCH_BILLING_SERVICE_LIST:
            return {
                ...state,
                billingServiceList: action.payload,
            };
        default:
            return state;
    }
};

