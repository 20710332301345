import React, { useEffect, useRef, useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { Bar } from "react-chartjs-2";
//pages
import { OTSchedule } from "../Pages/OTSchedule/OTSchedule";
import { OTNotes } from "../Pages/OTNotes/OTNotes";
import { OTBilling } from "../Pages/OTBilling/OTBilling";
//actions
import { SELECTED_ACTION_ITEM } from "Redux/Types";
import { addSelectedItem } from "Redux/Actions/OTActions/SeletedItem";
import { BookingStatus } from "pages/Services/URLs";
import axiosInstance from "pages/Services/AxiosInstance";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        padding: theme.spacing(1),
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    bar: {
        cursor: "pointer",
    },
}));

export const StatusBar = (props) => {
    const classes = useStyles();
    const theme = useTheme();
    const dispatch = useDispatch();
    const [otBookingStatus, setOtBookingStatus] = useState([]);

    const data = {
        labels: [
            'Total Admissions',
            'Pending',
            'Scheduled',
            'Billing'
        ],
        datasets: [{
            label: "OT Patients Status",
            data: [otBookingStatus?.totalAdmissions,
            otBookingStatus?.pending,
            otBookingStatus?.scheduled,
            otBookingStatus?.billing],
            backgroundColor: [
                'rgb(165, 66, 100)',
                'rgb(205, 97, 85)',
                'rgb(82, 190, 128 )',
                'rgb(93, 173, 226 )',
            ],
            hoverOffset: 4,
            borderRadius: 5,
            barPercentage: 0.5,
        }],
    };

    const onElementClick = (e, elements) => {
        if (elements?.length > 0) {
            if (elements[0]?.index === 1) {
                dispatch(addSelectedItem(<OTSchedule />));
                dispatch({ type: SELECTED_ACTION_ITEM, payload: null });
                window.scrollTo(0, 0);
            }
            else if (elements[0]?.index === 2) {
                dispatch(addSelectedItem(<OTNotes />));
                dispatch({ type: SELECTED_ACTION_ITEM, payload: null });
                window.scrollTo(0, 0);
            }
            else if (elements[0]?.index === 3) {
                dispatch(addSelectedItem(<OTBilling />));
                dispatch({ type: SELECTED_ACTION_ITEM, payload: null });
                window.scrollTo(0, 0);
            }
        }
    }

    useEffect(() => {
        fetchBookingSatus();
    }, [])

    const fetchBookingSatus = () => {
        axiosInstance
            .get(BookingStatus)
            .then((res) => {
                setOtBookingStatus(res.data)
            })
    }

    return (
        <div className={classes.root}>
            <Bar 
                className={classes.bar}
                data={data}
                options={{
                    onClick: onElementClick,
                    // plugins: {
                    //     title: {
                    //         display: true,
                    //         text: 'Operation Theatre Status',
                    //         fontSize: 20
                    //     },
                    // },
                    legend: {
                        display: false
                    },
                    responsive: true,
                }}
            />
        </div>
    );
}