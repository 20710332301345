import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
//material-ui
import { Table, TableBody, Grid, InputAdornment, Box, TableContainer, Paper, CircularProgress, IconButton } from "@material-ui/core";
import LinearProgress from "@material-ui/core/LinearProgress";
import { Delete, Search } from "@material-ui/icons";
import EditIcon from '@material-ui/icons/Edit';
import { makeStyles, useTheme } from "@material-ui/core/styles";
import moment from "moment";
//components
import useTable, { StyledTableCell, StyledTableRow } from "components/UseTable";
import TextField from "components/TextField";
import { ScheduleActionItem } from "pages/OT/Pages/OTSchedule/ScheduleActionItem";
import { addSelectedActionItem } from "Redux/Actions/OTActions/SelectedActionItem";
import { addBookingId, addPatientDetails, updateOTBooking } from "Redux/Actions/OTActions/OTPatientsAction";
import { SELECTED_ACTION_ITEM } from "Redux/Types";


const useStyles = makeStyles((theme) => ({
    root: {
        position: "absolute",

    },
    tableCell: {
        background: 'white',
        borderRadius: 1,
        fontSize: "14px",
        textAlign: "center",
        border: "0.1px solid #ededed",
        padding: "4px",
        [theme.breakpoints.down("md")]: {
            fontSize: "12px",
        },
    },
    fontstyle: {
        transform: "lowerCase",
    },
    tableColor: {
        color: "white",
    },
    deleteIcon: { color: theme.palette.deleteIcon.light },
    padding: {
        [theme.breakpoints.down("sm")]: {
            padding: "4px 0px 10px 25px",
        },
        [theme.breakpoints.down("lg")]: {
            padding: "2px 4px 6px 1px",
        },
    },
}));

const headCells = [
    // { id: "SrNo", label: "Sr No", align: "center" },
    { id: "id", label: "Booking No", align: "center" },
    { id: "regId", label: "Reg No", align: "center" },
    { id: "staffName", label: "Name", align: "center" },
    { id: "dob", label: "Dob", align: "center" },
    { id: "gender", label: "Gender", align: "center" },
    { id: "surgery Name", label: "Surgery Name", align: "center" },
    { id: "surgeryDate", label: "Surgery Date", align: "center" },
    { id: "priority", label: "Priority", align: "center" },
    { id: "otStatus", label: "Status", align: "center" },
    { id: "action", label: "Action", align: "center" },
];

export default function OTBookingTable(props) {
    const classes = useStyles();
    const theme = useTheme();
    const [records, setRecords] = useState([]);
    const [filterFn, setFilterFn] = useState({ fn: (items) => items });
    const dispatch = useDispatch();

    const requestSearch = (e) => {
        let val = e.target.value;
        setFilterFn({
            fn: (records) => {
                if (!val) return records;
                return records.filter(
                    (row) =>
                        //row?.id?.toString().includes(val.toLowerCase()) ||
                        row?.regId?.toString().includes(val.toLowerCase()) ||
                        row?.staffName?.toString().includes(val.toLowerCase()) ||
                        row?.dob?.toString().includes(val.toLowerCase()) ||
                        row?.gender?.toString().includes(val.toLowerCase()) ||
                        row?.surgeryName?.toString().includes(val.toLowerCase()) ||
                        row?.surgeryDate?.toString().includes(val.toLowerCase()) ||
                        row?.priority?.toString().includes(val.toLowerCase()) ||
                        row?.otStatus?.toString().includes(val.toLowerCase())
                )
            },
        });
    };

    const { TblContainer, TblHead, TblPagination, recordsAfterPagingAndSorting, getSlNo } = useTable(
        records,
        headCells,
        filterFn
    );

    const updateHandler = (item) => {
        setTimeout(() => {
            dispatch({ type: SELECTED_ACTION_ITEM, payload: null });
            dispatch(addSelectedActionItem(<ScheduleActionItem />));
        }, 10);
        dispatch(updateOTBooking('update'));
        dispatch(addPatientDetails(item)) //for PatientDetailsBox
        dispatch(addBookingId(item?.id)) //for Booking & Schedule Tabs
        props.scroll();
    };

    useEffect(() => {
        setRecords(props.data);
    }, [props.data]);

    return (
        <div >
            <Grid container style={{ justifyContent: "end", }}>
                <Grid item >
                    <TextField className={classes.padding}
                        placeholder="Search..."
                        variant="outlined"
                        size="small"
                        // className={classes.searchInput}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Search />
                                </InputAdornment>
                            ),
                        }}
                        onChange={requestSearch}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Paper>
                        <TblContainer style={{
                            maxHeight: 440,
                            overflowX: "auto"
                        }}>
                            {props.isLoading && <LinearProgress />}
                            {/* <Table
                                stickyHeader
                                style={{ position: "relative", border: "1px solid lightgrey", }}
                                size="small"
                                aria-label="simple table"
                                id="data-table-cleanliness-report"
                            > */}
                            <Table style={{ width: '100%', }}>
                                <TblHead />
                                {recordsAfterPagingAndSorting()?.length !== 0 | props.isLoading ? (
                                    <TableBody >
                                        <>
                                            {recordsAfterPagingAndSorting()?.map((item, index) => (
                                                <StyledTableRow className={classes.tableColor} key={index}>
                                                    {/* <StyledTableCell className={classes.tableCell} style={{ width: "4%" }}>{index + 1}</StyledTableCell> */}
                                                    <StyledTableCell className={classes.tableCell} style={{ width: "4%" }}>{item?.id} </StyledTableCell>
                                                    <StyledTableCell className={classes.tableCell} style={{ width: "4%" }}>{item?.regId} </StyledTableCell>
                                                    <StyledTableCell className={classes.tableCell} style={{ width: "10%" }}>{item?.staffName} </StyledTableCell>
                                                    <StyledTableCell className={classes.tableCell} style={{ width: "5%" }}> {moment(item?.dob).format("DD-MM-YYYY")} </StyledTableCell>
                                                    <StyledTableCell className={classes.tableCell} style={{ width: "1%" }}>{item?.gender} </StyledTableCell>
                                                    <StyledTableCell className={classes.tableCell} style={{ width: "6%" }}>{item?.surgeryName} </StyledTableCell>
                                                    <StyledTableCell className={classes.tableCell} style={{ width: "5%" }}>{moment(item?.otSelectedSlots[0]?.surgeryDate).format("DD-MM-YYYY")} </StyledTableCell>
                                                    <StyledTableCell className={classes.tableCell} style={{ width: "4%", }}>
                                                        <Grid container justifyContent="center">
                                                            <Box style={{
                                                                borderRadius: 12, height: 25, width: 90, display: "flex",
                                                                background: item?.priority === "Normal" ?
                                                                    `linear-gradient(to bottom, ${theme.palette.normal.light}, 
                                                                        ${theme.palette.normal.dark})` :
                                                                    `linear-gradient(to bottom, ${theme.palette.emergency.light}, 
                                                                        ${theme.palette.emergency.dark})`, alignItems: 'center',
                                                                justifyContent: 'center', fontSize: 12, color: "white"
                                                            }}>
                                                                {item?.priority}
                                                            </Box>
                                                        </Grid>
                                                    </StyledTableCell>
                                                    <StyledTableCell className={classes.tableCell} style={{ width: "4%", }}>
                                                        {item?.otStatus}
                                                    </StyledTableCell>
                                                    <StyledTableCell className={classes.tableCell} style={{ width: "1%" }}>
                                                        {item?.otStatus === 'Pending' ?
                                                            <>
                                                                <IconButton
                                                                    style={{ width: 10, height: 10 }}
                                                                    onClick={() => updateHandler(item)} color="primary">
                                                                    <EditIcon />
                                                                </IconButton>
                                                                <IconButton
                                                                    style={{ width: 10, height: 10 }}
                                                                    onClick={() => props.onClickOpenDeleteBox(item)} color="primary">
                                                                    <Delete className={classes.deleteIcon} />
                                                                </IconButton>
                                                            </>
                                                            : props.menuList(item)}
                                                    </StyledTableCell>
                                                </StyledTableRow>
                                            ))}
                                        </>
                                    </TableBody>
                                ) :
                                    (
                                        < TableBody >
                                            <StyledTableRow>
                                                <StyledTableCell colSpan={8} align="center">
                                                    No records found
                                                </StyledTableCell>
                                            </StyledTableRow>
                                        </TableBody>
                                    )}
                            </Table>
                        </TblContainer>

                        {recordsAfterPagingAndSorting()?.length != 0 && recordsAfterPagingAndSorting()?.length != undefined ? (
                            <Box style={{ marginTop: 20 }}>
                                <TblPagination />
                            </Box>
                        ) : (
                            ""
                        )}
                    </Paper>
                </Grid>
            </Grid>
        </div >
    );
}
