import React, { useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from '@material-ui/core/styles';
import { Avatar, Typography, Grid } from "@material-ui/core";
import { Person } from "@material-ui/icons";
import ScheduleTabs from "./ScheduleTabs/ScheduleTabs";
//components
import { PatientDetailBox } from "../../components/PatientDetailBox";

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(0),
    },
    label: {
        fontSize: "0.875rem",
        color: theme.palette.text.primary,
    },
}));
export const ScheduleActionItem = (props) => {
    const classes = useStyles();
    const theme = useTheme();

    return (
        <div className={classes.root}>
            <Grid container spacing={2} direction="column">
                <Grid item>
                    <PatientDetailBox />
                </Grid>
                <Grid item>
                    <ScheduleTabs />
                </Grid>
            </Grid>
        </div>
    );
}