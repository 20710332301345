import {
  Box,
  Grid,
  LinearProgress,
  makeStyles,
  Typography,
} from "@material-ui/core";
import {
  CloseOutlined,
  DoneAllTwoTone,
  PersonRounded,
  RefreshOutlined,
  Widgets,
} from "@material-ui/icons";
import GroupIcon from "@material-ui/icons/Group";
import {
  ArcElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  Title,
  Tooltip,
} from "chart.js";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import useTable from "components/UseTable";
import { useStylesRenewalForm } from "pages/EmployeeRenewalForm/RenewalForm";
import axiosInstance from "pages/Services/AxiosInstance";
import { useEffect, useState } from "react";
import { Doughnut } from "react-chartjs-2";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
// Register Chart.js components
const mobileNumberRegex = /^[6-9]\d{9}$/;
// Register Chart.js components
ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale);

const useStyles = makeStyles((theme) => ({
  card: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(1),
    boxShadow: theme.shadows[2],
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.background.paper,
    width: "auto",
  },
  iconContainer: {
    marginRight: theme.spacing(2),
    color: theme.palette.primary.main,
    fontSize: 48,
  },
  countText: {
    fontWeight: "bold",
  },
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(1),
    boxShadow: theme.shadows[1],
    marginBottom: "18px",
  },
  tabItem: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    padding: theme.spacing(0.5, 1),
    position: "relative",
    "&:hover": {
      opacity: 0.8,
    },
  },
  activeTab: {
    fontWeight: "bold",
    borderBottom: `2px solid ${theme.palette.primary.main}`,
  },
  badge: {
    marginLeft: theme.spacing(0.5),
    padding: theme.spacing(0.5, 1),
    borderRadius: "12px",
    fontSize: "0.75rem",
    color: theme.palette.common.white,
    fontWeight: "bold",
    minWidth: "15px",
    textAlign: "center",
  },
  pendingSubmission: { backgroundColor: "#ee82ee" },
  pending: { backgroundColor: "#fbc02d" },
  completed: { backgroundColor: "#4caf50" },
  rejected: { backgroundColor: "#e57373" },
}));

const headCells = [
  { id: "S.No.", label: "S.No.", align: "left" },
  { id: "employeeName", label: "Employee Name", align: "left" },
  { id: "isEmpAlive", label: "Employee Alive", align: "left" },
  { id: "employeeId", label: "Employee Id", align: "left" },
  { id: "empMobileNo", label: "Mobile No.", align: "left" },
  { id: "spouseName", label: "Spouse Name", align: "left" },
  { id: "isSpouseAlive", label: "Spouse Alive", align: "left" },
  { id: "submittedDate", label: "Submitted Date", align: "left" },
  { id: "financialYear", label: "Financial Year", align: "left" },
  { id: "status", label: "Status", align: "left" },
];

// Data for the Doughnut chart

export default function DashboardNewHR() {
  const loggedInUser = useSelector((store) => store.loggedInUser);
  const gClasses = useStylesRenewalForm();
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [filterFn, setFilterFn] = useState({ fn: (items) => items });
  const [activeTab, setActiveTab] = useState("Approved");
  const [stats, setStats] = useState({});
  //

  const requestSearch = (e) => {
    let val = e.target.value;
    setFilterFn({
      fn: (records) => {
        if (!val) return records;
        return records.filter(
          (row) =>
            row.renewalStatus
              .toString()
              .toLowerCase()
              .includes(val.toLowerCase()) ||
            row.renewalYear
              ?.toString()
              .toLowerCase()
              .includes(val.toLowerCase()) ||
            row.employeeName.toLowerCase().includes(val.toLowerCase()) ||
            row.employeeId
              ?.toString()
              .toLowerCase()
              .includes(val.toLowerCase()) ||
            row.empMobileNo
              ?.toString()
              .toLowerCase()
              .includes(val.toLowerCase())
        );
      },
    });
  };
  const {
    TblContainer,
    TblHead,
    TblPagination,
    recordsAfterPagingAndSorting,
  } = useTable(tableData, headCells, filterFn, 15);

  useEffect(() => {
    setLoading(true);
    axiosInstance("dashboard/stats/unit/" + loggedInUser?.unitCode)
      .then(({ data }) => {
        if (data) {
          setStats(Object.values(data)?.[0]);
        }
      })
      .catch((e) => {
        console.log(e);
        toast.error("Failed to get dashboard stats");
      })
      .finally(() => {
        setLoading(false);
      });
  }, [loggedInUser]);

  const chartData = {
    labels: ["Approved", "Pending", "Rejected"],
    datasets: [
      {
        data: [
          stats.total_approved,
          stats.total_pending_approval,
          stats.total_rejected,
        ],
        data: [
          stats.total_approved,
          stats.total_pending_approval,
          stats.total_rejected,
        ],
        backgroundColor: ["#4CAF50", "#FFEB3B", "#F44336"],
        borderColor: "#fff",
        borderWidth: 2,
      },
    ],
  };

  return (
    <Box style={{ padding: 24, backgroundColor: "#f9f9f9" }}>
      {loading && <LinearProgress />}
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={2}>
          <WidgetCard
            icon={GroupIcon}
            title="Total Employee"
            count={stats.total_employees}
            color="black"
          />
        </Grid>
        <Grid item xs={12} sm={12} md={2}>
          <WidgetCard
            icon={CheckCircleIcon}
            title="Total Submitted"
            count={stats.total_submitted}
            color="#87CEEB"
          />
        </Grid>
        <Grid item xs={12} sm={12} md={2}>
          <WidgetCard
            icon={PersonRounded}
            title="Pending Submission"
            count={stats.total_pending_submission}
            color="#BF72FC"
          />
        </Grid>
        <Grid item xs={12} sm={12} md={2}>
          <WidgetCard
            icon={DoneAllTwoTone}
            title="Approved"
            count={stats.total_approved}
            color="#4CAF50"
          />
        </Grid>
        <Grid item xs={12} sm={12} md={2}>
          <WidgetCard
            icon={RefreshOutlined}
            title="Pending Approval"
            count={stats.total_pending_approval}
            color="#FFEB3B"
          />
        </Grid>
        <Grid item xs={12} sm={12} md={2}>
          <WidgetCard
            icon={CloseOutlined}
            title="Rejected"
            count={stats.total_rejected}
            color="#F44336"
          />
        </Grid>

        <Grid item xs={12}>
          <div style={{ height: 400 }}>
            {/* Set your desired height here */}
            <Doughnut
              data={chartData}
              options={{
                maintainAspectRatio: false, // Allows manual height adjustment
              }}
            />
          </div>
        </Grid>

        {/* <Grid item xs={12}>
          <TextDivider text="Filter Record Based On Status" />
        </Grid>

		<Grid item xs={12}>
          <Box className={classes.container}>
            {statusOptions.map((status) => (
              <Box
                key={status.label}
                onClick={() => setActiveTab(status.label)}
                className={`${classes.tabItem} ${activeTab === status.label ? classes.activeTab : ""}`}
              >
                <Typography variant="body2">{status.label}</Typography>
                <div className={`${classes.badge} ${classes[status.colorClass]}`}>{status.count}</div>
              </Box>
            ))}
          </Box>
          <div style={{ display: "flex", justifyContent: "end", alignItems: "center", gap: "5px" }}>
            <TextField
              placeholder="Search..."
              variant="outlined"
              size="small"
              className={gClasses.searchInput}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
              onChange={requestSearch}
            />
          </div>
          <TblContainer style={{ height: "600px" }}>
            {loading && <LinearProgress />}
            <Table
              style={{
                position: "relative",
                border: "1px solid lightgrey",
              }}
              size="small"
            >
              <TblHead />
              {recordsAfterPagingAndSorting()?.length !== 0 ? (
                <TableBody>
                  {recordsAfterPagingAndSorting()?.map((item, index) => (
                    <TableRow key={item.id} className={gClasses.tableColor}>
                      <StyledTableCell>{index + 1} </StyledTableCell>
                      <StyledTableCell>{item.employeeName} </StyledTableCell>
                      <StyledTableCell>{item.isEmpAlive ? "Yes" : "No"} </StyledTableCell>
                      <StyledTableCell>{item.employeeId} </StyledTableCell>
                      <StyledTableCell>{item.empMobileNo} </StyledTableCell>
                      <StyledTableCell>{item.spouseName} </StyledTableCell>
                      <StyledTableCell>{item.isSpouseAlive ? "Yes" : "No"} </StyledTableCell>

                      <StyledTableCell>{item.submittedDate} </StyledTableCell>
                      <StyledTableCell>{item.renewalYear} </StyledTableCell>
                      <StyledTableCell>
                        <StatusChip status={item.renewalStatus} />
                      </StyledTableCell>
                    </TableRow>
                  ))}
                </TableBody>
              ) : (
                <TableBody>
                  <StyledTableRow>
                    <StyledTableCell colSpan={8} align="center">
                      No records found
                    </StyledTableCell>
                  </StyledTableRow>
                </TableBody>
              )}
            </Table>
            {recordsAfterPagingAndSorting()?.length ? <TblPagination /> : null}
          </TblContainer>
        </Grid> */}
      </Grid>
    </Box>
  );
}

const WidgetCard = ({ icon: IconComponent = Widgets, title, count, color }) => {
  const classes = useStyles();
  return (
    <Box className={classes.card}>
      <Box className={classes.iconContainer}>
        <IconComponent fontSize="inherit" style={{ color }} />
      </Box>
      <Box>
        <Typography color="primary" style={{ fontSize: "14px" }}>
          {title}
        </Typography>
        <Typography className={classes.countText}>{count}</Typography>
      </Box>
    </Box>
  );
};
