import { FormControl, FormControlLabel, FormHelperText, Grid, InputAdornment,  Radio, RadioGroup, Typography, makeStyles } from '@material-ui/core'
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from "@date-io/date-fns";
import moment from 'moment';
import React, { useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import configData from "../../../config.json";
import { restrict } from "restrict";
import { getAllDoctors, saveDoctor, updateDoctor } from "pages/Services/URLs";
import { toast } from "react-toastify";
import axiosInstance from "pages/Services/AxiosInstance";
import CryptoJS from "crypto-js";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import DoctorRegistrationTable from "./StaffDetailsTable";
import { TextField } from "@material-ui/core";
import Button from 'components/Button';
import { emailRegex } from 'NewLayout/ProfilePage';
const useStyles = makeStyles((theme) => ({
  center: {
    alignItems: 'center',
    display: 'Grid',
  },
  head: {
    fontSize: "1.3rem",
    fontWeight: 400,
    paddingBottom: "25px",
  },
  padding: {
    [theme.breakpoints.down("sm")]: {
      padding: "4px 0px 8px 8px",
    },
    [theme.breakpoints.down("lg")]: {
      padding: "4px 0px 8px 8px",
    },
  },
  margin: {
    marginTop: "2.3rem",
    marginBottom: "2.3rem"
  },
  hidden:{
    display:"none"
  }
}));
export const encryptFn = (data) => {
  const encryptionSecret = configData.encryptionSecret;
  var key = CryptoJS.enc.Latin1.parse(encryptionSecret);
  var iv = CryptoJS.enc.Latin1.parse(encryptionSecret);
  var encrypted = CryptoJS.AES.encrypt(data, key, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.ZeroPadding,
  });
  return encrypted.toString();
};
export default function StaffDetails() {
  const [edit, setEdit] = React.useState(false);
  const [doctorlist, setDoctorlist] = React.useState([]);
  const [showPassword, setShowPassword] = React.useState(false);
  const [showPassword1, setShowPassword1] = React.useState(false);
  const classes = useStyles();
  const defaultValues = {
    id: null,
    staffName: "",
    dob: null,
    emailId: "",
    phoneNumber: "",
    address: "",
    gender: "",
    username: "",
    password: "",
    confirmPwd: ""
  }
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleClickShowPassword1 = () => {
    setShowPassword1(!showPassword1);
  };
  const editTable = (edit) => {
    setEdit(edit);
    reset(edit);    
  }
  const Schema = yup.object().shape({
    staffName: yup.string().typeError("Enter Doctor Name").required("Enter Doctor Name"),
    gender: yup
      .string()
      .typeError("  Gender is required")
      .required(" Gender is required"),
    dob: yup.date().typeError("Enter Date of Birth").required(" date is Required"),
    emailId: yup
      .string()
      .trim()
      .email("Email must be valid").matches(emailRegex, 'Invalid Email')
      .required("Email is required"),
    phoneNumber: yup.string()
      .required("Phone Number is required"),
    // .test("2","Please Enter Valid Number 6 to 9",(value)=>/^[6-9][0-9]{9}$/.test(value)),
    address: yup.string().typeError("Enter Address").required(" Enter Address"),
    username: !edit ? (yup.string().typeError("Enter Username").required(" Enter Username")):null,
    password: !edit ? (yup
      .string()
      .required("Password is required")
      .min(8, 'Password must be 8 characters long')
      .matches(/[0-9]/, 'Password requires a number')
      .matches(/[a-z]/, 'Password requires a lowercase letter')
      .matches(/[A-Z]/, 'Password requires an uppercase letter')
      .matches(/[^\w]/, 'Password requires a symbol')):null,
    confirmPwd: !edit ?(yup
      .string()
      .oneOf([yup.ref("password"), null], "Passwords must match").required("Confirm Password is required")):null,
  });
  const {
    register,
    watch,
    handleSubmit,
    reset,
    control,
    setValue,
    getValues,
    // setValues,
    clearErrors,
    formState: { errors },
  } = useForm({
    defaultValues,
    resolver: yupResolver(Schema),
    mode: "onChange",
  });
  const saveDoctorDetails = async (data) => {
    const encryptedUserName = await encryptFn(data.username);
    const encryptedPassword = await encryptFn(data.password);
    const encryptedConfirmPwd = await encryptFn(data.confirmPwd);
    const savedata = {
      id: 0,
      username: encryptedUserName,
      password: encryptedPassword,
      phoneNumber: parseInt(data.phoneNumber),
      staffName: data.staffName,
      emailId: data.emailId,
      confirmPwd: encryptedConfirmPwd,
      dob: moment(data.dob).format("yyyy-MM-DD"),
      address: data.address,
      gender: data.gender
    }
    axiosInstance
      .post(saveDoctor, savedata)
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          const strsubmit = "Doctor Successfully Registered.";
          fetchDoctorList();
          toast.success(strsubmit);
          resetForm();
        } 
      })
      .catch((error) => {
          if (error.response.status !== 200) {
            toast.warning(error.response.data);
        }
        else {
            toast.error("Something went wrong");
        }
      });
  };
const updateDoctorDetails = async (data)=>{
  const encryptedUserName = await encryptFn(data.username);
    const encryptedPassword = await encryptFn(data.password);
    const encryptedConfirmPwd = await encryptFn(data.confirmPwd);
    const updatedata = {
      id: edit.id,
      username: encryptedUserName,
      password: encryptedPassword,
      phoneNumber: parseInt(data.phoneNumber),
      staffName: data.staffName,
      emailId: data.emailId,
      confirmPwd: encryptedConfirmPwd,
      dob: moment(data.dob).format("yyyy-MM-DD"),
      address: data.address,
      gender: data.gender
    }
    axiosInstance
      .post(updateDoctor, updatedata)
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          const strsubmit = "Doctor Details Updated Successfully.";
          fetchDoctorList();
          toast.success(strsubmit);
          resetForm();
        } else if (res.response.status === 409) {
          toast.warning(res.response.data);
        }
      })
      .catch((error) => {
        toast.error("Something went wrong");
      });
  };
  const resetForm = () => {
    reset(defaultValues);
    setEdit(false);
  };
  const fetchDoctorList = () => {
    axiosInstance
      .get(getAllDoctors)
      .then((res) => {
        setDoctorlist(res.data);
      })
  }
  useEffect(() => {
    fetchDoctorList();
  }, [])  
  return (
    <>
      <form >
        {/* <Container component={Box} border="1px solid"  > */}
        <Grid container spacing={2} className={classes.margin}>
          <Grid item xs={12} md={6} lg={4}>
            <Typography> Doctor Name </Typography>
            <Controller
              name="staffName"
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  variant='outlined'
                  size="small"
                  placeholder="Doctor Name"
                  required
                  fullWidth
                  value={value}
                  onChange={onChange}
                  error={!!errors.staffName}
                  helperText={errors.staffName?.message}
                />
              )}
            />
          </Grid>
          
          <Grid item xs={12} md={6} lg={4}>
            <Typography> Mobile  </Typography>
            <Controller
              name="phoneNumber"
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  variant='outlined'
                  size="small"
                  placeholder="Mobile"
                  required
                  disabled={edit? true:false}
                  fullWidth
                  onInput={(e) => { restrict.digits(e) }}
                  inputProps={{ maxLength: 10 }}
                  value={value}
                  onChange={onChange}        
                  error={!!errors.phoneNumber}
                  helperText={errors.phoneNumber?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <Typography> Email </Typography>
            <Controller
              name="emailId"
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  variant='outlined'
                  size="small"
                  placeholder="Email"
                  required
                  
                  disabled={edit? true:false}
                  fullWidth
                  value={value}
                  onChange={onChange}
                  error={!!errors.emailId}
                  helperText={errors.emailId?.message}
                />                
              )}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <Typography> Gender </Typography>
            <FormControl >
              <Controller
                control={control}
                name="gender"
                render={({ field: { onChange, value } }) => (
                  <RadioGroup row value={value} onChange={(e) => onChange((e.target.value))}>
                    <div>
                      <FormControlLabel value="M" control={<Radio />} label="Male" />
                      <FormControlLabel value="F" control={<Radio />} label="Female" />
                    </div>
                  </RadioGroup>
                )}
              />
              <FormHelperText error={!!errors.gender}>{errors.gender?.message}</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <Typography>Date of Birth</Typography>
            <Controller
              name="dob"
              control={control}
              render={({ field: { onChange, value } }) => {
                return (
                  <MuiPickersUtilsProvider utils={DateFnsUtils}  >
                    <KeyboardDatePicker
                      autoOk
                      fullWidth
                      disableFuture
                      size="small"
                      format="dd/MM/yyyy"
                      inputVariant="outlined"
                      value={value}
                      onChange={onChange}
                      KeyboardButtonProps={{ "aria-label": "change date" }}
                      error={!!errors.dob}
                      helperText={errors.dob?.message}
                    />
                  </MuiPickersUtilsProvider>
                );
              }}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <Typography> Address </Typography>
            <Controller
              name="address"
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  multiline
                  variant='outlined'
                  size="small"
                  placeholder="Address"                  
                  required
                  fullWidth
                  value={value}
                  onChange={onChange}
                  error={!!errors.address}
                  helperText={errors.address?.message}                  
                />
              )}
            />
          </Grid>         
         <Grid item xs={12} md={6} lg={4} style={{display: edit ? "none":"block"}} >
            <Typography> Username </Typography>
            <Controller
              name="username"
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  variant='outlined'
                  size="small"
                  placeholder="Username"
                  required                    
                  fullWidth
                  value={value}
                  onChange={onChange}
                  error={!!errors.username}
                  helperText={errors.username?.message}
                />
              )}
            />         
          </Grid>
          <Grid item xs={12} md={6} lg={4}  style={{display: edit ? "none":"block"}} >
            <Typography> Password </Typography>
            <Controller
              name="password"
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  variant='outlined'
                  size="small"
                  placeholder="Password"
                  required                 
                  fullWidth
                  value={value}
                  onChange={onChange}
                  type={showPassword ? "text" : "password"}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">
                        {showPassword ? (
                          <Visibility style={{ cursor: "pointer" }} onClick={handleClickShowPassword} />
                        ) : (
                          <VisibilityOff style={{ cursor: "pointer" }} onClick={handleClickShowPassword} />
                        )}
                      </InputAdornment>
                    ),
                  }}
                  error={!!errors.password}
                  helperText={errors.password?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}  style={{display: edit ? "none":"block"}} >
            <Typography> Confirm Password </Typography>
            <Controller
              name="confirmPwd"
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  variant='outlined'
                  size="small"
                  placeholder="Confirm Password"
                  required                  
                  fullWidth
                  type={showPassword1 ? "text" : "password"}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">
                        {showPassword1 ? (
                          <Visibility style={{ cursor: "pointer" }} onClick={handleClickShowPassword1} />
                        ) : (
                          <VisibilityOff style={{ cursor: "pointer" }} onClick={handleClickShowPassword1} />
                        )}
                      </InputAdornment>
                    ),
                  }}
                  value={value}
                  onChange={onChange}
                  error={!!errors.confirmPwd}
                  helperText={errors.confirmPwd?.message}
                />
              )}
            />
          </Grid>
        
        </Grid>
        <Grid container justifyContent='center' spacing={2} className={classes.margin}>
          <Grid item >
            {edit ? (
              <Button
                variant="contained"
                color="primary"
                type="submit"                
                onClick={handleSubmit(updateDoctorDetails)}
              >                
                Update
              </Button>
            ) : (
              <Button
                variant="contained"
                color="primary"
                type="submit"
                onClick={handleSubmit(saveDoctorDetails)}
              >
                Submit
              </Button>
            )}
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                resetForm();
              }}
            >
              Reset
            </Button>
          </Grid>
        </Grid>
        <Grid container className={classes.center} >
          <DoctorRegistrationTable data={doctorlist} editTable={editTable} />
        </Grid>
      </form>
    </>
  )
}
