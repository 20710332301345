import React, { useState, useEffect } from "react";

//material-ui
import { makeStyles, Table, TableBody, Grid, Dialog } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import MuiDialogActions from "@material-ui/core/DialogActions";
import MuiDialogContent from "@material-ui/core/DialogActions";
import axiosInstance from "pages/Services/AxiosInstance";
import { toast } from "react-toastify";
import configData from "../../../config.json";

//components
import Button from "../../../components/Button";

import DownloadForm from "../DownloadForm";
import { Typography } from "antd";
import { fetchElgDetails } from "Redux/Actions/EmployeeAction";

const useStyles = makeStyles((theme) => ({
  padding: {
    [theme.breakpoints.down("sm")]: {
      padding: "4px 0px 8px 8px",
    },
    [theme.breakpoints.down("lg")]: {
      // padding: "4px 0px 8px 8px",
    },
  },
}));

export const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

export const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function PedencyLog() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);

  const empDetails = useSelector((store) => store.employeeReducer.emp);
  const user = useSelector((store) => store.loggedInUser) || JSON.parse(localStorage.getItem("User"));

  const [selectedFile, setSelectedFile] = useState(null);
  const [fileError, setFileError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [uploadedImg, setUploadedImg] = useState(null);

  const fileRef = React.useRef(null);

  const onChangeImage = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  const fetchElg = async () => {
    await dispatch(fetchElgDetails());
  };
  const handleSubmit = () => {
    const formData = new FormData();
    formData.append("file", selectedFile);
    formData.append("fileName", selectedFile.name);
    formData.append("lastUpdatedBy", user.empId);

    const config = {
      onUploadProgress: (progressEvent) => {
        const totalLength = progressEvent.lengthComputable
          ? progressEvent.total
          : progressEvent.target.getResponseHeader("content-length") ||
            progressEvent.target.getResponseHeader("x-decompressed-content-length");
        console.log("onUploadProgress", totalLength);
      },
      headers: {
        "content-type": "multipart/form-data",
      },
    };

    const url = configData.SERVICE_URL + "upload/eligibilitydata";
    axiosInstance
      .post(url, formData, config)
      .then((res) => {
        setLoading(false);
        setUploadedImg(res?.data?.fileDownloadUri);
        toast.success("Data Successfully Submitted.");
        fetchElg();
        // fetchLatestData();
      })
      .catch((error) => {
        setLoading(false);
        toast.error("Something went wrong");
      });
  };

  return (
    <>
      <Grid container spacing={0}>
        <Grid xs={6} sm={4} md={3} lg={2} xl={2} className={classes.padding}>
          <Typography> Upload Claim Eligibility Details</Typography>
        </Grid>
        <Grid xs={4} sm={3} md={3} lg={2} xl={2} className={classes.padding}>
          <input
            type="file"
            ref={fileRef}
            accept=".xls"
            onChange={(e) => {
              const file = e.target.files[0];
              if (file) {
                if (!/\.(xls)$/g.test(file?.name)) {
                  setFileError(true);
                  toast.error("Please select only Excel File (.xls) for uploading Claim Eligibility Details");
                } else {
                  onChangeImage(e);

                  console.log(e.target.files[0]);
                }
              }
            }}
          />
        </Grid>
        <Grid xs={4} sm={3} md={2} lg={2} xl={2} className={classes.padding}>
          <Button
            align="center"
            type="submit"
            style={{ marginTop: "15px" }}
            color="primary"
            variant="contained"
            onClick={(e) => {
              handleSubmit();
            }}
          >
            Submit
          </Button>
        </Grid>
      </Grid>
      <Dialog open={open} maxWidth="xl" fullWidth>
        <DialogContent dividers style={{ padding: 16, position: "relative" }}>
          <DownloadForm />
        </DialogContent>

        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            // onClick={handleSave}
            type="submit"
          >
            update
          </Button>

          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              setOpen(false);
            }}
          >
            close
          </Button>
        </DialogActions>
      </Dialog>{" "}
    </>
  );
}
