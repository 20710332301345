import { ADD_PATIENT_DETAILS } from "Redux/Types";

export const PatientDetails = (state = [], action) => {

    switch (action.type) {
        case ADD_PATIENT_DETAILS:
            return {
                ...state,
                patientDetails: action.payload,
            };
        default:
            return state;
    }
}
