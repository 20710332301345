import React, { useState, useEffect } from "react";
//material-ui
import { makeStyles, Table, TableBody, Grid, InputAdornment, Button } from "@material-ui/core";
import LinearProgress from "@material-ui/core/LinearProgress";
import { Search } from "@material-ui/icons";
//components
import useTable, { StyledTableCell, StyledTableRow } from "../../../../components/UseTable";
import TextField from "components/TextField";
const useStyles = makeStyles((theme) => ({
      tableCell: {
        fontSize: "14px",
        textAlign: "center",
        border: "0.1px solid #ededed",
        padding: "4px",
        [theme.breakpoints.down("md")]: {
            fontSize: "12px",
        },
        
    },
    fontstyle: {
        transform: "lowerCase",
    },
    tableColor: {
        color: "white",
    },
    padding: {
        [theme.breakpoints.down("sm")]: {
          padding: "4px 0px 10px 25px",    
        },
        [theme.breakpoints.down("lg")]: {
          padding: "2px 4px 6px 1px",    
        },
      },
}));

const headCells = [
    { id: "id", label: "SI.No", align: "center"},   
    { id: "duration", label: "Duration (mins)", align: "center" },
    { id: "action", label: "Action", align: "center" },
];
export default function SlotDurationTable(props) {
    const classes = useStyles();
    const [loading, setLoading] = React.useState(false);
    const [records, setRecords] = useState([]);
    const [filterFn, setFilterFn] = useState({ fn: (items) => items });
    console.log(records);
    const requestSearch = (e) => {
        let val = e.target.value;
        setFilterFn({
          fn: (records) => {
            if (!val) return records;
                return records.filter(
              (row) =>
                row?.id?.toString().includes(val.toLowerCase()) ||
                row?.departmentId?.toString().includes(val.toLowerCase()) ||
                row?.duration?.toString().includes(val.toLowerCase()) 
            );
          },
        });
      };
    const { TblContainer, TblHead, TblPagination, recordsAfterPagingAndSorting, getSlNo } = useTable(
        records,
        headCells,
        filterFn
    );
    useEffect(() => {
        setRecords(props.data);
    }, [props.data]);
    return (
        <div>
            <Grid container style={{justifyContent:"end"}} >
                <Grid item >
                    <TextField className={classes.padding} 
                        placeholder="Search..."
                        variant="outlined"
                        size="small"
                        // className={classes.searchInput}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Search />
                                </InputAdornment>
                            ),
                        }}
                        onChange={requestSearch}
                    />
                </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center" style={{ margin: "auto", overflowX: "auto" }}>
                    <Grid container>
                        <TblContainer style={{ maxHeight: "600px" }}>
                            {loading && <LinearProgress />}
                            <Table
                                style={{ position: "relative", border: "1px solid lightgrey"  }}
                                size="small"
                                aria-label="simple table"
                                id="data-table-cleanliness-report"
                            >
                                <TblHead />
                                {recordsAfterPagingAndSorting()?.length !== 0 ? (
                                    <TableBody>
                                        {recordsAfterPagingAndSorting()?.map((item, index) => (
                                            <StyledTableRow className={classes.tableColor} key={index}>
                                                <StyledTableCell className={classes.tableCell} style={{width: "10%"}}>{index+1}</StyledTableCell>
                                                {/* <StyledTableCell className={classes.tableCell}>{item?.departmentId} </StyledTableCell> */}
                                                <StyledTableCell className={classes.tableCell}style={{width: "50%"}}>{item?.duration} </StyledTableCell>
                                                <StyledTableCell className={classes.tableCell} style={{width: "50%"}}>{item?.action} 
                                                  <Button
                                                    type="submit"
                                                    align="center"
                                                    onClick={()=>props.setEditdata(item)}
                                                    style={{ marginTop: "5px" }}
                                                    color="secondary"
                                                    variant="contained">
                                                    Edit
                                                 </Button>
                                                </StyledTableCell>
                                            </StyledTableRow>
                                        ))}
                                    </TableBody>
                                ) : (
                                    <TableBody>
                                        <StyledTableRow>
                                            <StyledTableCell colSpan={8} align="center">
                                                 No records found
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    </TableBody>
                                )}
                            </Table>
                            {recordsAfterPagingAndSorting()?.length != 0 && recordsAfterPagingAndSorting()?.length != undefined ? (
                                <TblPagination />
                            ) : (
                                ""
                            )}
                        </TblContainer>
                    </Grid>
                </Grid>
            </Grid>
        </div>        
    );
}
