import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import moment from "moment";
//material-ui
import { makeStyles, Table, TableBody, Grid, InputAdornment, Box, TableContainer, Paper } from "@material-ui/core";
import LinearProgress from "@material-ui/core/LinearProgress";
import { Search } from "@material-ui/icons";
//components
import useTable, { StyledTableCell, StyledTableRow } from "components/UseTable";
import TextField from "components/TextField";

const useStyles = makeStyles((theme) => ({
    tableCell: {
        background: 'white',
        borderRadius: 1,
        fontSize: "14px",
        textAlign: "center",
        border: "0.1px solid #ededed",
        padding: "4px",
        [theme.breakpoints.down("md")]: {
            fontSize: "12px",
        },

    },
    fontstyle: {
        transform: "lowerCase",
    },
    tableColor: {
        color: "white",
    },
    padding: {
        [theme.breakpoints.down("sm")]: {
            padding: "4px 0px 10px 25px",
        },
        [theme.breakpoints.down("lg")]: {
            padding: "2px 4px 6px 1px",
        },
    },
}));

const headCells = [
    // { id: "SrNo", label: "Sr No", align: "center" },
    { id: "regId", label: "Reg No", align: "center" },
    { id: "regDate", label: "Reg Date", align: "center" },
    { id: "staffName", label: "Name", align: "center" },
    { id: "gender", label: "Gender", align: "center" },
    { id: "dob", label: "dob", align: "center" },
    { id: "action", label: "Action", align: "center" },
];

export default function PatientsListTable(props) {
    const classes = useStyles();
    const [records, setRecords] = useState([]);
    const [filterFn, setFilterFn] = useState({ fn: (items) => items });

    const requestSearch = (e) => {
        let val = e.target.value;
        setFilterFn({
            fn: (records) => {
                if (!val) return records;
                return records.filter(
                    (row) =>
                        // row?.regId?.toString().includes(val.toLowerCase()) ||
                        row?.regDate?.toString().includes(val.toLowerCase()) ||
                        row?.staffName?.toString().includes(val.toLowerCase()) ||
                        row?.gender?.toString().includes(val.toLowerCase()) ||
                        row?.dob?.toString().includes(val.toLowerCase())
                )
            },
        });
    };
    const { TblContainer, TblHead, TblPagination, recordsAfterPagingAndSorting, getSlNo } = useTable(
        records,
        headCells,
        filterFn
    );
    useEffect(() => {
        setRecords(props.data);
    }, [props.data]);

    return (
        <div>
            <Grid container style={{ justifyContent: "end" }}>
                <Grid item >
                    <TextField className={classes.padding}
                        placeholder="Search..."
                        variant="outlined"
                        size="small"
                        // className={classes.searchInput}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Search />
                                </InputAdornment>
                            ),
                        }}
                        onChange={requestSearch}
                    />
                </Grid>
                <Grid item xs={12} >
                    <Paper>
                        <TblContainer style={{
                            maxHeight: 440,
                            overflowX: "auto"
                        }}>
                            {props.isLoading && <LinearProgress />}
                            {/* <Table
                                stickyHeader
                                style={{ position: "relative", border: "1px solid lightgrey", }}
                                size="small"
                                aria-label="simple table"
                                id="data-table-cleanliness-report"
                            > */}
                            <Table style={{ width: '100%', }}>
                                <TblHead />
                                {recordsAfterPagingAndSorting()?.length !== 0 | props.isLoading ? (
                                    <TableBody >
                                        {recordsAfterPagingAndSorting()?.map((item, index) => (
                                            <StyledTableRow className={classes.tableColor} key={index}>
                                                {/* <StyledTableCell className={classes.tableCell} style={{ width: "4%" }}>{index + 1}</StyledTableCell> */}
                                                <StyledTableCell className={classes.tableCell} style={{ width: "2%" }}>{item?.regId}</StyledTableCell>
                                                <StyledTableCell className={classes.tableCell} style={{ width: "5%" }}>
                                                    {moment(item?.regDate).format("DD-MM-YYYY")}</StyledTableCell>
                                                <StyledTableCell className={classes.tableCell} style={{ width: "10%" }}>{item?.staffName}</StyledTableCell>
                                                <StyledTableCell className={classes.tableCell} style={{ width: "1%" }}>{item?.gender}</StyledTableCell>
                                                <StyledTableCell className={classes.tableCell} style={{ width: "5%" }}>{moment(item?.dob).format("DD-MM-YYYY")}</StyledTableCell>
                                                <StyledTableCell className={classes.tableCell} style={{ width: "1%" }}>
                                                    {props.menuList(item)}

                                                </StyledTableCell>
                                            </StyledTableRow>
                                        ))}
                                    </TableBody>
                                ) : (
                                    <TableBody>
                                        <StyledTableRow>
                                            <StyledTableCell colSpan={8} align="center">
                                                No records found
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    </TableBody>
                                )}
                            </Table>
                        </TblContainer>
                        {recordsAfterPagingAndSorting()?.length != 0 && recordsAfterPagingAndSorting()?.length != undefined ? (
                            <Box style={{ marginTop: 20 }}>
                                <TblPagination />
                            </Box>
                        ) : (
                            ""
                        )}
                    </Paper>
                </Grid>
            </Grid>
        </div>
    );
}
