import {
  FETCH_CLAIM_ID,
  FETCH_EMPLOYEE_DETAILS,
  FETCH_DRAFT_CLAIM_DETAILS,
  FETCH_EMP_DETAILS_BY_ID,
  FETCH_ELG_DETAILS,
  FETCH_ALL_CLAIM_BY_EMP_ID,
  FETCH_FILE,
  DELETE_BILL,
} from "../Types";

const initialState = {
  emp: [],
  empById: [],
  elg: [],
  claimId: "",
  claimDetails: [],
  allClaimByEmpId: [],
  getFile: [],
  delBill: "",
};

const EmployeeReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_EMPLOYEE_DETAILS:
      return {
        ...state,
        emp: action.payload,
      };
    case FETCH_EMP_DETAILS_BY_ID:
      return {
        ...state,
        empById: action.payload,
      };
    case FETCH_ELG_DETAILS:
      return {
        ...state,
        elg: action.payload,
      };
    case FETCH_CLAIM_ID:
      return {
        ...state,
        claimId: action.payload,
      };

    case FETCH_DRAFT_CLAIM_DETAILS:
      return {
        ...state,
        claimDetails: action.payload,
      };

    case FETCH_ALL_CLAIM_BY_EMP_ID:
      return {
        ...state,
        allClaimByEmpId: action.payload,
      };
    case FETCH_FILE:
      return {
        ...state,
        getFile: action.payload,
      };
    case DELETE_BILL:
      return {
        ...state,
        delBill: action.payload,
      };
    default:
      return state;
  }
};

export default EmployeeReducer;
