import { Divider, FormControlLabel, Grid, Switch, Typography, makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import BillingServiceTable from "./BillingServiceTables/BillingServiceTable";
import BillingServiceDoctorsTable from "./BillingServiceTables/BillingServiceDoctorsTable";
import MasterTable from "./BillingServiceTables/MasterTable";
import { BillingServicePriceForm } from "./BillingServiceForms/BillingServicePriceForm";
import { ChecklistMstForm } from "./BillingServiceForms/ChecklistMstForm";
import { InventoryMstForm } from "./BillingServiceForms/InventoryMstForm";
import { EquipmentMstForm } from "./BillingServiceForms/EquipmentMstForm";
import { MedicineMstForm } from "./BillingServiceForms/MedicineMstForm";
import axiosInstance from "pages/Services/AxiosInstance";
import {
    ChecklistMstDataURL, InventoryMstDataURL, EquipmentMstDataURL, MedicineMstDataURL, BillingServiceDoctorsTableURL, BillingServiceTableURL, OTMstgetAllURL, OTBedsMstgetAllURL
} from "pages/Services/URLs";
import { OTMstForm } from "./BillingServiceForms/OTMstForm";
import OTMasterTable from "./BillingServiceTables/OTMasterTable";
import { SlotGeneratorForm } from "./BillingServiceForms/SlotGeneratorForm";
import { OTBedsMstForm } from "./BillingServiceForms/OTBedsMstForm";
import OTBedsMasterTable from "./BillingServiceTables/OTBedsMasterTable";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        padding: theme.spacing(0),
    },
    container: {
        background: theme.palette.background.light,
        borderRadius: 6,
        padding: 15,
    },
    label: {
        fontSize: "0.875rem",
        color: theme.palette.text.primary,
    },
    formAlign: {
        padding: 15,
    },
}));

export const BillingService = (props) => {
    const classes = useStyles();
    const [checklistMstLoading, setChecklistMstLoading] = React.useState(true);
    const [inventoryMstLoading, setInventoryMstLoading] = React.useState(true);
    const [equipmentMstLoading, setEquipmentMstLoading] = React.useState(true);
    const [medicineMstLoading, setMedicineMstLoading] = React.useState(true);
    const [otMstLoading, setOTMstLoading] = React.useState(true);
    const [otBedsMstLoading, setOTBedsMstLoading] = React.useState(true);
    const [doctorsPriceLoading, setDoctorsPriceLoading] = React.useState(true);
    const [servicePriceLoading, setServicePriceLoading] = React.useState(true);
    const [checklistMstList, setChecklistMstList] = useState([]);
    const [inventoryMstList, setInventoryMstList] = useState([]);
    const [equipmentMstList, setEquipmentMstList] = useState([]);
    const [medicineMstList, setMedicineMstList] = useState([]);
    const [otMstList, setOTMstList] = useState([]);
    const [otBedsMstList, setOTBedsMstList] = useState([]);
    const [doctorsPriceList, setDoctorsPriceList] = React.useState([]);
    const [servicePriceList, setServicePriceList] = React.useState([]);
    const [priceTableItem, setPriceTableItem] = React.useState([]);
    const [checklistMstItem, setChecklistMstItem] = React.useState([]);
    const [inventoryMstItem, setInventoryMstItem] = React.useState([]);
    const [equipmentMstItem, setEquipmentMstItem] = React.useState([]);
    const [medicineMstItem, setMedicineMstItem] = React.useState([]);
    const [otMstItem, setOTMstItem] = React.useState([]);
    const [otBedsMstItem, setOTBedsMstItem] = React.useState([]);
    const [priceTableDeleteItem, setPriceTableDeleteItem] = React.useState([]);
    const [checklistMstDeleteItem, setChecklistMstDeleteItem] = React.useState([]);
    const [inventoryMstDeleteItem, setInventoryMstDeleteItem] = React.useState([]);
    const [equipmentMstDeleteItem, setEquipmentMstDeleteItem] = React.useState([]);
    const [medicineMstDeleteItem, setMedicineMstDeleteItem] = React.useState([]);
    const [otMstDeleteItem, setOTMstDeleteItem] = React.useState([]);
    const [otBedsMstDeleteItem, setOTBedsMstDeleteItem] = React.useState([]);
    const [openChecklistDeleteBox, setOpenChecklistDeleteBox] = React.useState(false);
    const [openInventoryDeleteBox, setOpenInventoryDeleteBox] = React.useState(false);
    const [openEquipmentDeleteBox, setOpenEquipmentDeleteBox] = React.useState(false);
    const [openMedicineDeleteBox, setOpenMedicineDeleteBox] = React.useState(false);
    const [openOTDeleteBox, setOpenOTDeleteBox] = React.useState(false);
    const [openOTBedsDeleteBox, setOpenOTBedsDeleteBox] = React.useState(false);
    const [openPricesDeleteBox, setOpenPricesDeleteBox] = React.useState(false);
    const [masterTableToggle, setMasterTableToggle] = React.useState(false);
    const [priceTableToggle, setPriceTableToggle] = React.useState(false);
    const [fetchMasterTables, setFetchMasterTables] = React.useState(false);
    const [fetchPriceTables, setFetchPriceTables] = React.useState(false);

    useEffect(() => {
        if (fetchMasterTables) {
            fetchChecklistMstList();
            fetchInventoryMstList();
            fetchEquipmentMstList();
            fetchMedicineMstList();
            fetchOTMstList();
            fetchOTBedsMstList();
        }
        if (fetchPriceTables) {
            fetchDoctorsPriceList();
            fetchServicePriceList();
        }

    }, [fetchMasterTables, fetchPriceTables]);

    const fetchDoctorsPriceList = () => {
        axiosInstance
            .get(BillingServiceDoctorsTableURL)
            .then((res) => {
                setDoctorsPriceList(res.data)
                setDoctorsPriceLoading(false);
            })
            .catch((error) => {
                console.log("fetchDoctorsPriceListError:" + error.message)
                setServicePriceLoading(false);
            });
    }
    const fetchServicePriceList = () => {
        axiosInstance
            .get(BillingServiceTableURL)
            .then((res) => {
                setServicePriceList(res.data)
                setServicePriceLoading(false);
            })
            .catch((error) => {
                console.log("fetchServicePriceListError:" + error.message)
                setServicePriceLoading(false);
            });
    }
    const fetchChecklistMstList = () => {
        axiosInstance
            .get(ChecklistMstDataURL)
            .then((res) => {
                setChecklistMstList(res.data)
                setChecklistMstLoading(false);
            })
            .catch((e) => {
                console.log("fetchChecklistMstListError:" + e.message)
                setChecklistMstLoading(false);
            });
    }
    const fetchInventoryMstList = () => {
        axiosInstance
            .get(InventoryMstDataURL)
            .then((res) => {
                setInventoryMstList(res.data)
                setInventoryMstLoading(false);
            })
            .catch((e) => {
                console.log("fetchInventoryMstListError:" + e.message)
                setInventoryMstLoading(false);
            });
    }
    const fetchEquipmentMstList = () => {
        axiosInstance
            .get(EquipmentMstDataURL)
            .then((res) => {
                setEquipmentMstList(res.data)
                setEquipmentMstLoading(false);
            })
            .catch((e) => {
                console.log("fetchEquipmentMstListError:" + e.message)
                setEquipmentMstLoading(false);
            });
    }
    const fetchMedicineMstList = () => {
        axiosInstance
            .get(MedicineMstDataURL)
            .then((res) => {
                setMedicineMstList(res.data)
                setMedicineMstLoading(false);
            })
            .catch((e) => {
                console.log("fetchMedicineMstListError:" + e.message)
                setMedicineMstLoading(false);
            });
    }
    const fetchOTMstList = () => {
        axiosInstance
            .get(OTMstgetAllURL)
            .then((res) => {
                setOTMstList(res.data)
                setOTMstLoading(false);
            })
            .catch((e) => {
                console.log("fetchOTMstListError:" + e.message)
                setOTMstLoading(false);
            });
    }
    const fetchOTBedsMstList = () => {
        axiosInstance
            .get(OTBedsMstgetAllURL)
            .then((res) => {
                setOTBedsMstList(res.data)
                setOTBedsMstLoading(false);
            })
            .catch((e) => {
                console.log("fetchOTBedsMstListError:" + e.message)
                setOTBedsMstLoading(false);
            });
    }
    const setEditPriceTable = (item) => {
        setChecklistMstItem([]);
        setInventoryMstItem([]);
        setEquipmentMstItem([]);
        setMedicineMstItem([]);
        setOTMstItem([]);
        setOTBedsMstItem([]);
        setPriceTableItem(item);
        window.scrollTo(0, 0);
    }
    const setEditChecklistMstTable = (item) => {
        setInventoryMstItem([]);
        setEquipmentMstItem([]);
        setMedicineMstItem([]);
        setOTMstItem([]);
        setOTBedsMstItem([]);
        setPriceTableItem([]);
        setChecklistMstItem(item);
        window.scrollTo(0, 0);
    }
    const setEditInventoryMstTable = (item) => {
        setChecklistMstItem([]);
        setEquipmentMstItem([]);
        setMedicineMstItem([]);
        setOTMstItem([]);
        setOTBedsMstItem([]);
        setPriceTableItem([]);
        setInventoryMstItem(item);
        window.scrollTo(0, 0);
    }
    const setEditEquipmentMstTable = (item) => {
        setChecklistMstItem([]);
        setInventoryMstItem([]);
        setMedicineMstItem([]);
        setOTMstItem([]);
        setOTBedsMstItem([]);
        setPriceTableItem([]);
        setEquipmentMstItem(item);
        window.scrollTo(0, 0);
    }
    const setEditMedicineMstTable = (item) => {
        setChecklistMstItem([]);
        setInventoryMstItem([]);
        setEquipmentMstItem([]);
        setOTMstItem([]);
        setOTBedsMstItem([]);
        setPriceTableItem([]);
        setMedicineMstItem(item);
        window.scrollTo(0, 0);
    }
    const setEditOTMstTable = (item) => {
        setChecklistMstItem([]);
        setInventoryMstItem([]);
        setEquipmentMstItem([]);
        setMedicineMstItem([]);
        setPriceTableItem([]);
        setOTBedsMstItem([]);
        setOTMstItem(item);
        window.scrollTo(0, 0);
    }
    const setEditOTBedsMstTable = (item) => {
        setChecklistMstItem([]);
        setInventoryMstItem([]);
        setEquipmentMstItem([]);
        setMedicineMstItem([]);
        setPriceTableItem([]);
        setOTMstItem([]);
        setOTBedsMstItem(item);
        window.scrollTo(0, 0);
    }
    const openChecklistMstDeleteBox = (item) => {
        setChecklistMstItem([]);
        setOpenChecklistDeleteBox(!openChecklistDeleteBox)
        setChecklistMstDeleteItem(item)
    }
    const openInventoryMstDeleteBox = (item) => {
        setInventoryMstItem([]);
        setOpenInventoryDeleteBox(!openInventoryDeleteBox)
        setInventoryMstDeleteItem(item)
    }
    const openEquipmentMstDeleteBox = (item) => {
        setEquipmentMstItem([]);
        setOpenEquipmentDeleteBox(!openEquipmentDeleteBox)
        setEquipmentMstDeleteItem(item)
    }
    const openMedicineMstDeleteBox = (item) => {
        setMedicineMstItem([]);
        setOpenMedicineDeleteBox(!openMedicineDeleteBox)
        setMedicineMstDeleteItem(item)
    }
    const openOTMstDeleteBox = (item) => {
        setOTMstItem([]);
        setOpenOTDeleteBox(!openOTDeleteBox)
        setOTMstDeleteItem(item)
    }
    const openOTBedsMstDeleteBox = (item) => {
        setOTBedsMstItem([]);
        setOpenOTBedsDeleteBox(!openOTBedsDeleteBox)
        setOTBedsMstDeleteItem(item)
    }
    const openPriceDeleteBox = (item) => {
        setPriceTableItem([]);
        setOpenPricesDeleteBox(!openPricesDeleteBox)
        setPriceTableDeleteItem(item)
    }

    return (
        <div className={classes.root}>
            <Grid container spacing={2} >
                <Grid item xs={12}>
                    <div className={classes.container}>
                        <Grid container justifyContent="space-between" alignItems="center">
                            <Grid item  >
                                <Typography noWrap >
                                    <span style={{ fontWeight: 500 }}>Add Service</span>
                                </Typography>
                            </Grid>
                            <Grid item >
                                <FormControlLabel control={<Switch color="Primary" checked={masterTableToggle}
                                    onClick={() => { setMasterTableToggle(!masterTableToggle); setFetchMasterTables(true) }} />} label="Master table" />
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Divider />
                        </Grid>
                        <div className={classes.formAlign}>
                            <Grid container spacing={2} alignItems="center" >
                                <Grid item xs={12}  >
                                    <ChecklistMstForm item={checklistMstItem} deleteItem={checklistMstDeleteItem}
                                        fetchChecklistMstList={fetchChecklistMstList}
                                        openDeleteBox={openChecklistDeleteBox}
                                        closeDeleteBox={() => setOpenChecklistDeleteBox(!openChecklistDeleteBox)} />
                                </Grid>
                                <Grid item xs={12}  >
                                    <InventoryMstForm item={inventoryMstItem} deleteItem={inventoryMstDeleteItem}
                                        fetchInventoryMstList={fetchInventoryMstList}
                                        openDeleteBox={openInventoryDeleteBox}
                                        closeDeleteBox={() => setOpenInventoryDeleteBox(!openInventoryDeleteBox)} />

                                </Grid>
                                <Grid item xs={12}   >
                                    <EquipmentMstForm item={equipmentMstItem} deleteItem={equipmentMstDeleteItem}
                                        fetchEquipmentMstList={fetchEquipmentMstList}
                                        openDeleteBox={openEquipmentDeleteBox}
                                        closeDeleteBox={() => setOpenEquipmentDeleteBox(!openEquipmentDeleteBox)} />
                                </Grid>
                                <Grid item xs={12}  >
                                    <MedicineMstForm item={medicineMstItem} deleteItem={medicineMstDeleteItem}
                                        fetchMedicineMstList={fetchMedicineMstList}
                                        openDeleteBox={openMedicineDeleteBox}
                                        closeDeleteBox={() => setOpenMedicineDeleteBox(!openMedicineDeleteBox)} />
                                </Grid>
                                <Grid item xs={12} >
                                    <OTMstForm item={otMstItem} deleteItem={otMstDeleteItem}
                                        fetchOTMstList={fetchOTMstList}
                                        openDeleteBox={openOTDeleteBox}
                                        closeDeleteBox={() => setOpenOTDeleteBox(!openOTDeleteBox)} />
                                </Grid>
                                <Grid item xs={12} >
                                    <OTBedsMstForm item={otBedsMstItem} deleteItem={otBedsMstDeleteItem}
                                        fetchOTBedsMstList={fetchOTBedsMstList}
                                        openDeleteBox={openOTBedsDeleteBox}
                                        closeDeleteBox={() => setOpenOTBedsDeleteBox(!openOTBedsDeleteBox)} />
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                </Grid>

                <Grid item xs={12}>
                    <div className={classes.container}>
                        <Grid container justifyContent="space-between" alignItems="center">
                            <Grid item  >
                                <Typography noWrap >
                                    <span style={{ fontWeight: 500 }}>Add Price</span>
                                </Typography>
                            </Grid>
                            <Grid item >
                                <FormControlLabel control={<Switch color="Primary" checked={priceTableToggle}
                                    onClick={() => { setPriceTableToggle(!priceTableToggle); setFetchPriceTables(true) }} />} label="Price table" />
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Divider />
                        </Grid>
                        <div item className={classes.formAlign} >
                            <BillingServicePriceForm item={priceTableItem} deleteItem={priceTableDeleteItem}
                                fetchDoctorsPriceList={fetchDoctorsPriceList} fetchServicePriceList={fetchServicePriceList}
                                openDeleteBox={openPricesDeleteBox} closeDeleteBox={() => setOpenPricesDeleteBox(!openPricesDeleteBox)} />
                        </div >
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <div className={classes.container}>
                        <Grid container style={{ marginBottom: 6 }}>
                            <Grid item  >
                                <Typography noWrap >
                                    <span style={{ fontWeight: 500 }}>Slot Generator</span>
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Divider />
                        </Grid>
                        <div item className={classes.formAlign} >
                            <SlotGeneratorForm />
                        </div>

                    </div>
                </Grid>
                {masterTableToggle &&
                    <>
                        <Grid item xs={6}>
                            <Typography variant="h6" noWrap >
                                <span style={{ fontWeight: 500 }}>Checklist Master Table
                                    ({checklistMstList ? checklistMstList?.length : 0})</span>
                            </Typography>
                            <MasterTable isLoading={checklistMstLoading} data={checklistMstList}
                                setEditdata={setEditChecklistMstTable} onClickOpenDeleteBox={openChecklistMstDeleteBox} />
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant="h6" noWrap >
                                <span style={{ fontWeight: 500 }}>Inventory Master Table
                                    ({inventoryMstList ? inventoryMstList?.length : 0})</span>
                            </Typography>
                            <MasterTable isLoading={inventoryMstLoading} data={inventoryMstList}
                                setEditdata={setEditInventoryMstTable} onClickOpenDeleteBox={openInventoryMstDeleteBox} />
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant="h6" noWrap >
                                <span style={{ fontWeight: 500 }}>Equipment Master Table
                                    ({equipmentMstList ? equipmentMstList?.length : 0})</span>
                            </Typography>
                            <MasterTable isLoading={equipmentMstLoading} data={equipmentMstList}
                                setEditdata={setEditEquipmentMstTable} onClickOpenDeleteBox={openEquipmentMstDeleteBox} />
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant="h6" noWrap >
                                <span style={{ fontWeight: 500 }}>Medicine Master Table
                                    ({medicineMstList ? medicineMstList?.length : 0})</span>
                            </Typography>
                            <MasterTable isLoading={medicineMstLoading} data={medicineMstList}
                                setEditdata={setEditMedicineMstTable} onClickOpenDeleteBox={openMedicineMstDeleteBox} />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="h6" noWrap >
                                <span style={{ fontWeight: 500 }}>OT Master Table
                                    ({otMstList ? otMstList?.length : 0})</span>
                            </Typography>
                            <OTMasterTable isLoading={otMstLoading} data={otMstList}
                                setEditdata={setEditOTMstTable} onClickOpenDeleteBox={openOTMstDeleteBox} />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="h6" noWrap >
                                <span style={{ fontWeight: 500 }}>OT Beds Master Table
                                    ({otBedsMstList ? otBedsMstList?.length : 0})</span>
                            </Typography>
                            <OTBedsMasterTable isLoading={otBedsMstLoading} data={otBedsMstList}
                                setEditdata={setEditOTBedsMstTable} onClickOpenDeleteBox={openOTBedsMstDeleteBox} />
                        </Grid>
                    </>
                }

                {priceTableToggle &&
                    <>
                        <Grid item xs={12}>
                            <Typography variant="h6" noWrap >
                                <span style={{ fontWeight: 500 }}>Doctors Price
                                    ({doctorsPriceList ? doctorsPriceList?.length : 0})</span>
                            </Typography>
                            <BillingServiceDoctorsTable isLoading={doctorsPriceLoading} data={doctorsPriceList}
                                setEditdata={setEditPriceTable} onClickOpenDeleteBox={openPriceDeleteBox} />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="h6" noWrap >
                                <span style={{ fontWeight: 500 }}>Services Price
                                    ({servicePriceList ? servicePriceList?.length : 0})</span>
                            </Typography>
                            <BillingServiceTable isLoading={servicePriceLoading} data={servicePriceList}
                                setEditdata={setEditPriceTable} onClickOpenDeleteBox={openPriceDeleteBox} />
                        </Grid>
                    </>
                }
            </Grid>
        </div >
    )
}