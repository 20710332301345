import React, { useEffect, useRef, useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
//components
import PatientsListTable from "../../components/OTTables/PatientsListTable";
import OTBookingTable from "../../components/OTTables/OTBookingTable";
import OTMenuList from "../../components/OTMenuList";
//actions
import { fetchPendingList } from "Redux/Actions/OTActions/OTPatientsAction";
import { OTAdmissionList, OTBookingDeleteURL } from "pages/Services/URLs";
import axiosInstance from "pages/Services/AxiosInstance";
import { SELECTED_ACTION_ITEM } from "Redux/Types";
import { DeleteDialogBox } from "pages/OT/components/DeleteDailogBox";
import { toast } from "react-toastify";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        padding: theme.spacing(0),
        // height: '250vh',
    },
}));

export const OTSchedule = (props) => {
    const classes = useStyles();
    const theme = useTheme();
    const dispatch = useDispatch();
    const [admissionListLoading, setadmissionListLoading] = React.useState(true);
    const [admissionList, setadmissionList] = useState([]);
    const [openDeleteBox, setOpenDeleteBox] = React.useState(false);
    const [item, setItem] = React.useState([]);
    const selectedActionItem = useSelector((store) => store.selectedActionItem.item);
    const pendingList = useSelector((store) => store.OTPatients.pendingList);
    const mainRef = useRef(null);

    useEffect(() => {
        dispatch({ type: SELECTED_ACTION_ITEM, payload: null });
        fetchAdmissionList();
        dispatch(fetchPendingList());
    }, []);

    const handleScroll = (ref) => {
        setTimeout(() => {
            window.scrollTo(0, document.body.scrollHeight);
        }, 20);
        // window.scrollTo({
        //     top: ref.offsetTop - 65,
        //     left: 0,
        //     behavior: "smooth",
        // });
        // if (mainRef.current) {
        //     mainRef.current.scrollTop = mainRef.current.scrollHeight;
        // }
    }

    const fetchAdmissionList = () => {
        axiosInstance
            .get(OTAdmissionList)
            .then((res) => {
                setadmissionList(res.data)
                setadmissionListLoading(false);
            })
            .catch((e) => {
                console.log("fetchAdmissionListError:" + e.message)
                setadmissionListLoading(false);
            });
    }

    const executeDeletePending = async (item) => {
        await axiosInstance
            .post(OTBookingDeleteURL + item.id)
            .then((res) => {
                if (res.status === 200 || res.status === 201) {
                    const strsubmit = "Record Deleted";
                    toast.error(strsubmit);
                    setOpenDeleteBox(!openDeleteBox);
                    dispatch(fetchPendingList());
                } else if (res.response.status === 409) {
                    toast.warning(res.response.data);
                }
            })
            .catch((error) => {
                toast.error("Something went wrong");
            });
    }

    const onClickOpenDeleteBox = (item) => {
        setOpenDeleteBox(!openDeleteBox)
        setItem(item)
    }

    const MenuList = (item) => (
        <OTMenuList item={item} scroll={() => handleScroll(mainRef.current)} />
    );

    return (
        <div className={classes.root}>
            <Grid container spacing={4} >
                <Grid item xs={12}>
                    <Typography noWrap variant="h6" >
                        Admission Details ({admissionList ? admissionList?.length : 0})
                    </Typography>
                    <PatientsListTable isLoading={admissionListLoading} data={admissionList}
                        menuList={(item) => MenuList(item)} />
                </Grid>
                <Grid item xs={12}>
                    <Typography noWrap variant="h6" >
                        Pending Details ({pendingList?.data ? pendingList?.data.length : 0})
                    </Typography>
                    <OTBookingTable isLoading={pendingList?.isLoading} data={pendingList?.data}
                        menuList={(item) => MenuList(item)}
                        scroll={handleScroll}
                        onClickOpenDeleteBox={onClickOpenDeleteBox} />
                </Grid>
                <Grid item xs={12} ref={mainRef} >
                    {selectedActionItem?.type?.name === 'ScheduleActionItem' && selectedActionItem}
                </Grid>
            </Grid>
            <DeleteDialogBox openDeleteBox={openDeleteBox}
                closeDeleteBox={() => setOpenDeleteBox(!openDeleteBox)}
                onClick={() => executeDeletePending(item)}
            />
        </div>
    );
}
