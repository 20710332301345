import React from "react";
import { Grid, makeStyles, Typography, Dialog, DialogContent, DialogActions, IconButton } from "@material-ui/core";
import { CloseRounded, ErrorOutlineRounded } from "@material-ui/icons";

//components
import Button from "components/Button";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        padding: theme.spacing(0),
    },
    label: {
        fontsize: '0.875rem',
        margin: 5,
    },
    buttons: {
        display: 'flex',
        justifyContent: 'flex-end',
        padding: 15,

    }
}));

export const DeleteDialogBox = (props) => {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <Dialog open={props.openDeleteBox} onClose={props.closeDeleteBox}>
                <DialogContent style={{ justifyContent: "center", width: 400 }}>
                    <Grid container justifyContent="flex-end" >
                        <IconButton
                            size="small"
                            onClick={props.closeDeleteBox} >
                            <CloseRounded />
                        </IconButton>
                    </Grid>
                    <Grid container spacing={2} direction="column" justifyContent="center" alignItems="center">
                        <Grid item>
                            <ErrorOutlineRounded style={{ color: "#E74C3C", fontSize: 80 }} />
                        </Grid>
                        <Grid item>
                            <Typography ><strong>Are you sure ?</strong> </Typography>
                        </Grid>
                        <Grid item>
                            <Typography>This will delete the record in database.</Typography>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions style={{ justifyContent: "center" }}>
                    <Button
                        align="center"
                        color="primary"
                        variant="contained"
                        onClick={props.closeDeleteBox}
                    >
                        Cancel
                    </Button>
                    <Button
                        align="center"
                        type="submit"
                        color="primary"
                        variant="contained"
                        onClick={() => props.onClick()}
                    >
                        Delete
                    </Button>

                </DialogActions>
            </Dialog>
        </div >
    )
}