import {
  FETCH_EMPLOYEE_DETAILS,
  FETCH_EMP_DETAILS_BY_ID,
  FETCH_DRAFT_CLAIM_DETAILS,
  FETCH_ELG_DETAILS,
  FETCH_ALL_CLAIM_BY_EMP_ID,
  FETCH_CLAIM_ID,
  FETCH_FILE,
  DELETE_BILL,
} from "../Types";
import { useSelector } from "react-redux";
import axiosInstance from "pages/Services/AxiosInstance";
import {
  employeeDetails,
  empDetailsURL,
  elgDetailsURL,
  saveClaimHeadUrl,
  getClaimHeadByEmpId,
  getAllClaimsByEmpId,
  getClaimDetailsByEmpId,
} from "../../pages/Services/URLs";

import configData from "../../config.json";

export const fetchEmpDetails = (unitCodeValue) => async (dispatch) => {
  try {
    const res = await axiosInstance.get(`employee/getAllEmployees/${unitCodeValue}`);
    console.log("res", res.data.length);
    dispatch({
      type: FETCH_EMPLOYEE_DETAILS,
      payload: res.data,
    });
  } catch (error) {
    console.log("fetchEmpDetails error", error);
  }
};

export const fetchEmpDetailsById = (staffId) => async (dispatch) => {
  try {
    const req={id:staffId}
    const res = await axiosInstance.post(empDetailsURL,req);
    // console.log("res", res.data.length);
    dispatch({
      type: FETCH_EMP_DETAILS_BY_ID,
      payload: res.data,
    });
  } catch (error) {
    console.log("fetchEmpDetailsById error", error);
  }
};

export const fetchElgDetails = () => async (dispatch) => {
  try {
    const res = await axiosInstance.get(elgDetailsURL);
    // console.log("res", res.data.length);
    dispatch({
      type: FETCH_ELG_DETAILS,
      payload: res.data,
    });
  } catch (error) {
    console.log("fetchElgDetailsById error", error);
  }
};

export const fetchClaimId = (empId) => async (dispatch) => {
  try {
    const res = await axiosInstance.get(getClaimHeadByEmpId + `${empId}`);
    // console.log("res", res.data.length);
    dispatch({
      type: FETCH_CLAIM_ID,
      payload: res.data,
    });
  } catch (error) {
    console.log("fetchClaimId error", error);
  }
};

export const saveClaimHead = (ClaimHeadDetails) => async (dispatch) => {
  try {
    const response = await axiosInstance.post(saveClaimHeadUrl, ClaimHeadDetails);

    const res = response.data;

    // if (response.status === 200) {
    //   if (ClaimHeadDetails.id == 0) {
    //     toast.success("Claim Details added successfully");
    //   } else {
    //     toast.success("ClaimHead details updated successfully");
    //   }
    // } else if (res == "failure") {
    //   toast.error("Request failed. Plz try again.");
    // } else {
    //   toast.error(res);
    // }
    return res;
  } catch (error) {
    console.log(error);
    return "failure";
  }
};

export const fetchGetAllClaimsByEmpId = (empId) => async (dispatch) => {
  try {
    const res = await axiosInstance.get(getAllClaimsByEmpId + `${empId}`);
    // console.log("res", res.data.length);
    dispatch({
      type: FETCH_ALL_CLAIM_BY_EMP_ID,
      payload: res.data,
    });
  } catch (error) {
    console.log("fetchEmpId error", error);
  }
};
export const fetchDraftClaimDetails = (empId) => async (dispatch) => {
  const url = configData.SERVICE_URL + "claim/getDraftClaimByEmpId/";
  try {
    const res = await axiosInstance.get(url + `${empId}`);
    // console.log("res", res.data.length);
    dispatch({
      type: FETCH_DRAFT_CLAIM_DETAILS,
      payload: res.data,
    });
    return res.data;
  } catch (error) {
    console.log("fetchClaimDetails error", error);
  }
};

export const fetchGetFile = (empId) => async (dispatch) => {
  const url = configData.SERVICE_URL + "file/getFile/";
  try {
    const res = await axiosInstance.get(url + `${empId}`);
    // console.log("res", res.data.length);
    dispatch({
      type: FETCH_FILE,
      payload: res.data,
    });
  } catch (error) {
    console.log("fetchGetFile error", error);
  }
};

export const deleteBill = (billId) => async (dispatch) => {
  const url = configData.SERVICE_URL + "claim/delete/";
  try {
    const res = await axiosInstance.get(url + `${billId}`);
    // console.log("res", res.data.length);
    dispatch({
      type: DELETE_BILL,
      payload: res.data,
    });
    return res.data;
  } catch (error) {
    console.log("deleteBill error", error);
  }
};
