import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid, Typography, makeStyles } from "@material-ui/core";
//components
import OTBookingTable from "../../components/OTTables/OTBookingTable";
import OTMenuList from "../../components/OTMenuList";
import { fetchScheduledList } from "Redux/Actions/OTActions/OTPatientsAction";
import { SELECTED_ACTION_ITEM } from "Redux/Types";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        padding: theme.spacing(0),
        // height: '175vh',
    },
}));

export const OTNotes = () => {
    const classes = useStyles();
    const selectedActionItem = useSelector((store) => store.selectedActionItem.item);
    const scheduledList = useSelector((store) => store.OTPatients.scheduledList);
    const mainRef = useRef(null);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch({ type: SELECTED_ACTION_ITEM, payload: null });
        dispatch(fetchScheduledList())
    }, []);

    const handleScroll = (ref) => {
        setTimeout(() => {
            window.scrollTo(0, document.body.scrollHeight);
        }, 20);
        // if (mainRef.current) {
        //     mainRef.current.scrollTop = mainRef.current.scrollHeight;
        // }
    }

    const MenuList = (item) => (
        <OTMenuList item={item} scroll={() => handleScroll(mainRef.current)} />
    );

    return (
        <div className={classes.root}>
            <Grid container spacing={4} >
                <Grid item xs={12}>
                    <Typography noWrap variant="h6"  >
                        Notes ({scheduledList?.data ? scheduledList?.data.length : 0})
                    </Typography>
                    <OTBookingTable isLoading={scheduledList?.isLoading} data={scheduledList?.data}
                        menuList={(item) => MenuList(item)} />
                </Grid>
                <Grid item xs={12} ref={mainRef} >
                    {selectedActionItem?.type?.name === 'NotesActionItem' && selectedActionItem || 'ScheduleActionItem' && selectedActionItem}
                </Grid>
            </Grid>
        </div>
    );
}