import React, { useState, useEffect } from "react";
import moment from "moment";
//material-ui
import { makeStyles, Table, TableBody, Grid, TextField, InputAdornment, Paper } from "@material-ui/core";

import { useDispatch, useSelector } from "react-redux";

import LinearProgress from "@material-ui/core/LinearProgress";
import { Search } from "@material-ui/icons";
//components
import useTable, { StyledTableCell, StyledTableRow } from "../../../components/UseTable";
// import TextField from "../../../components/TextField"
import { fetchElgDetails } from "../../../Redux/Actions/EmployeeAction";
import EmployeeButtons from "./ClaimEligibility";
import { Typography } from "antd";

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: "100%",
  },

  tableCell: {
    fontSize: "14px",
    border: "0.1px solid #ededed",
    padding: "4px",
    // color: "",
    [theme.breakpoints.down("md")]: {
      fontSize: "12px",
    },
  },
  fontstyle: {
    transform: "lowerCase",
  },
  tableColor: {
    color: "white",
  },
}));

const headCells = [
  //    { id: "id", label: "S.No.", align: "left" },

  { id: "staffNo", label: "Staff No.", align: "left" },
  { id: "curYear", label: "Current Finacial Year", align: "left" },
  { id: "renewed", label: "Renewed", align: "left" },
  { id: "covType", label: "Coverage Type", align: "left" },
  { id: "medElg", label: "Med Eligibity", align: "left" },
  { id: "medPaid", label: "Med Paid", align: "left" },
  { id: "medBal", label: "Med Balance", align: "left" },
  { id: "nonMedElg", label: "Non Med Eligibity", align: "left" },
  { id: "nonMedPaid", label: "Non Med Paid", align: "left" },
  { id: "nonMedBal", label: "Non Med Balance", align: "left" },
  //   { id: "spouseStatus", label: "Spouse Status", align: "left" },
  //   { id: "accountStaus", label: "Account Status", align: "left" },
  // { id: "actions", label: "Actions", disableSorting: true },
];

export default function PedencyLog() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);

  const [records, setRecords] = useState([]);

  const user = useSelector((store) => store.loggedInUser) || JSON.parse(localStorage.getItem("User"));
  const [filterFn, setFilterFn] = useState({ fn: (items) => items });
  const empDetails = useSelector((store) => store.employeeReducer.elg);
  const requestSearch = (e) => {
    let val = e.target.value;

    setFilterFn({
      fn: (records) => {
        if (!val) return records;

        return records.filter(
          (row) =>
            row?.staffNo?.toString().includes(val.toLowerCase()) ||
            // row?.curYear?.toString().includes(val.toLowerCase()) ||
            moment(row?.curYear)
              .format("YYYY-YY")
              .includes(val.toLowerCase()) ||
            row?.renewed?.toLowerCase().includes(val.toLowerCase()) ||
            row?.medElg?.toString().includes(val) ||
            row?.medPaid?.toString().includes(val) ||
            row?.medBal?.toString().includes(val) ||
            row?.nonMedElg?.toString().includes(val) ||
            row?.nonMedPaid?.toString().includes(val) ||
            row?.nonMedBal?.toString().includes(val)
        );
      },
    });
  };
  const fetch = async () => {
    await dispatch(fetchElgDetails());
  };
  const capitalize = (s) => (s && s[0]?.toUpperCase() + s.slice(1)) || "";
  const capitalizeFirstLetter = (string) => {
    const newString = string.charAt(0) + string.slice(1, string.maxLength)?.toLowerCase();
    return newString;
  };
  useEffect(() => {
    fetch();
  }, []);
  useEffect(() => {
    console.log("klkll", empDetails);
    if (empDetails.length !== 0) {
      setRecords(empDetails);
    }
  }, [empDetails]);
  const { TblContainer, TblHead, TblPagination, recordsAfterPagingAndSorting, getSlNo } = useTable(
    records,
    headCells,
    filterFn
  );
  return (
    // <Paper>
    <div>
      <Grid>
        <Grid container>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <EmployeeButtons />
          </Grid>
        </Grid>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "end",
            marginBottom: "8px",
          }}
        >
          <TextField
            placeholder="Search..."
            variant="outlined"
            size="small"
            // className={classes.searchInput}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
            onChange={requestSearch}
          />
        </div>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center" style={{ margin: "auto", overflowX: "auto" }}>
          <Grid container>
            <TblContainer style={{ maxHeight: "600px" }}>
              {loading && <LinearProgress />}
              <Table
                style={{ position: "relative", border: "1px solid lightgrey" }}
                size="small"
                aria-label="simple table"
                id="data-table-cleanliness-report"
              >
                <TblHead />
                {recordsAfterPagingAndSorting()?.length !== 0 ? (
                  <TableBody>
                    {recordsAfterPagingAndSorting()?.map((item, index) => (
                      <StyledTableRow className={classes.tableColor} key={index}>
                        <StyledTableCell className={classes.tableCell}>{item?.employeeId}</StyledTableCell>
                        <StyledTableCell className={classes.tableCell}>{item?.currentYear}</StyledTableCell>
                        <StyledTableCell className={classes.tableCell}>{item?.renewed}</StyledTableCell>
                        <StyledTableCell className={classes.tableCell}>
                          {item?.coverageType ? item?.coverageType : "-"}
                        </StyledTableCell>
                        <StyledTableCell className={classes.tableCell}>{item?.medicineEligibility}</StyledTableCell>
                        <StyledTableCell className={classes.tableCell}>{item?.medicinePaid}</StyledTableCell>
                        <StyledTableCell className={classes.tableCell}>{item?.medicineBal}</StyledTableCell>
                        <StyledTableCell className={classes.tableCell}>{item?.nonMedicineEligibility}</StyledTableCell>
                        <StyledTableCell className={classes.tableCell}>{item?.nonMedicinePaid}</StyledTableCell>
                        <StyledTableCell className={classes.tableCell}>{item?.nonMedicineBal}</StyledTableCell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                ) : (
                  <TableBody>
                    <StyledTableRow>
                      <StyledTableCell colSpan={8} align="center">
                        No records found
                      </StyledTableCell>
                    </StyledTableRow>
                  </TableBody>
                )}
              </Table>
              {recordsAfterPagingAndSorting()?.length != 0 && recordsAfterPagingAndSorting()?.length != undefined ? (
                <TblPagination />
              ) : (
                ""
              )}
            </TblContainer>
          </Grid>
        </Grid>
      </Grid>
    </div>
    // </Paper>
  );
}
