import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Typography,
} from "@material-ui/core";
import { DataGrid, GridToolbar } from "@material-ui/data-grid";
import moment from "moment";
import axiosInstance from "pages/Services/AxiosInstance";
import React, { useEffect, useRef, useState } from "react";
import Button from "components/Button";
import useTable, {
  StyledTableCell,
  StyledTableRow,
} from "../../components/UseTable";
import { useDispatch, useSelector } from "react-redux";
import {
  Grid,
  InputAdornment,
  makeStyles,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
} from "@material-ui/core";
import { CloseOutlined, Search } from "@material-ui/icons";
import LinearProgress from "@material-ui/core/LinearProgress";

import {
  getAllPatientsListUrl,
  getByPatientDetailsUrl,
} from "pages/Services/URLs";
import ConsultationHistory from "pages/Citizen/ConsultationHistory";

// export const startDate = moment(); // Same Date In future they can change the from/Start Date
// export const endDate = moment();
// const VISIBLE_FIELDS = ["name", "rating", "country", "dateCreated", "isAdmin"];

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: "100%",
  },
  tableCell: {
    fontSize: "14px",
    border: "0.1px solid #ededed",
    padding: "4px",
    [theme.breakpoints.down("md")]: {
      fontSize: "12px",
    },
  },
  image: {
    objectFit: "contain",
    height: 700,
    width: "100%",
  },
  tableColor: {
    color: "white",
  },
  closeDialog: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(0.5),
    color: theme.palette.grey[500],
  },
  color: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.contrastText,
    "&:hover, &:focus": {
      color: theme.palette.primary.contrastText,
      background: theme.palette.primary.main,
    },
  },
  heading: {
    textDecoration: "underline",
    fontWeight: 600,
    display: "flex",
    justifyContent: "center",
  },
  head: {
    backgroundColor: theme.palette.primary.main,
  },
  title: {
    fontSize: "1.3rem",
    color: theme.palette.primary.contrastText,
  },
  item: {
    minWidth: 300,
    maxWidth: 200,
    paddingRight: 20,
  },
  itemLabel: {
    minWidth: 100,
  },
  weight: {
    fontWeight: "bold",
  },
  dateContainer: {
    display: "flex",
    justifyContent: "",
    alignItems: "center",
    flexWrap: "wrap",
    marginTop: "15px",
    marginBottom: "15px",
  },
}));

const headCells = [
  { id: "id", label: "S.No.", align: "left" },
  { id: "staffName", label: "Patient Name", align: "left" },
  { id: "phoneNumber", label: "Phone Number", align: "left" },
];

export default function AllPatients() {
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false);
  const [records, setRecords] = useState([]);
  const [filterFn, setFilterFn] = useState({ fn: (items) => items });

  // const [item, setItem] = useState(flase);
  const [selectedpatient, setSelectedPatient] = useState(null);
  const user =
    useSelector((store) => store.loggedInUser) ||
    JSON.parse(localStorage.getItem("User"));
  const patientDetails = useSelector(
    (store) => store.PatientDetails.patientDetails
  );
  const [opendilog, setOpendilog] = useState(false);
  const [showConsultations, setShowConsultations] = useState(null);
  const {
    TblContainer,
    TblHead,
    TblPagination,
    recordsAfterPagingAndSorting,
    getSlNo,
  } = useTable(records, headCells, filterFn, 15);

  const requestSearch = (e) => {
    let val = e.target.value;
    setFilterFn({
      fn: (records) => {
        if (!val) return records;
        return records.filter(
          (row) =>
            row?.id
              ?.toString()
              .toLowerCase()
              .includes(val.toLowerCase()) ||
            row?.staffName
              ?.toString()
              .toLowerCase()
              .includes(val.toLowerCase()) ||
            row?.phoneNumber
              ?.toString()
              .toLowerCase()
              .includes(val.toLowerCase())
        );
      },
    });
  };

  //   const openViewDetailsModal = (data, status) => {
  //     setModalData(data);
  //   };
  const handleRowClick = (data) => {
    setOpendilog(true);
    setShowConsultations(data);
    console.log("item", data);
  };
  const getConsultationData = () => {
    setLoading(true);
    axiosInstance.get(getAllPatientsListUrl).then((res) => {
      setRecords(res.data);
      setLoading(false);
    });
  };
  React.useEffect(() => {
    getConsultationData();
  }, []);

  //   const handleClose = () => {
  //     setOpen(false);
  //   };

  return (
    <>
      {/* <>
                <Dialog open={open}
                    onClose={handleClose}
                >
                  
                </Dialog></> */}
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "end",
          marginBottom: "8px",
        }}
      >
        <TextField
          placeholder="Search..."
          variant="outlined"
          size="small"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
          onChange={requestSearch}
        />
      </div>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          align="center"
          style={{ margin: "auto", overflowX: "auto" }}
        >
          <Grid container>
            <TblContainer style={{ maxHeight: "600px" }}>
              {loading && <LinearProgress />}
              <Table
                style={{ position: "relative", border: "1px solid lightgrey" }}
                size="small"
                aria-label="simple table"
                id="data-table-cleanliness-report"
              >
                <TblHead />
                {recordsAfterPagingAndSorting()?.length !== 0 ? (
                  <>
                    <TableBody>
                      {recordsAfterPagingAndSorting()?.map((item, index) => (
                        <TableRow
                          key={index}
                          className={classes.tableColor}
                          style={{ cursor: "pointer" }}
                          onClick={() => handleRowClick(item)}
                        >
                          <StyledTableCell className={classes.tableCell}>
                            {index + 1}
                          </StyledTableCell>
                          <StyledTableCell className={classes.tableCell}>
                            <Typography> {item?.staffName} </Typography>{" "}
                          </StyledTableCell>
                          <StyledTableCell className={classes.tableCell}>
                            {item?.phoneNumber}
                          </StyledTableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </>
                ) : (
                  <TableBody>
                    <StyledTableRow>
                      <StyledTableCell colSpan={8} align="center">
                        No records found
                      </StyledTableCell>
                    </StyledTableRow>
                  </TableBody>
                )}
              </Table>
              {recordsAfterPagingAndSorting()?.length != 0 &&
              recordsAfterPagingAndSorting()?.length != undefined ? (
                <TblPagination />
              ) : (
                ""
              )}
            </TblContainer>
          </Grid>
        </Grid>
      </Grid>
      {/* {showConsultations != null ? (
        <>
          <ConsultationHistory pdata={showConsultations} />{" "}
        </>
      ) : (
        ""
      )} */}
      <Dialog
        open={opendilog}
        onClose={() => setOpendilog(false)}
        fullWidth={"lg"}
        maxWidth={"lg"}
      >
        <DialogTitle align="right">
          <Grid container justifyContent="space-between">
            <Grid item>
              <Typography align="left" variant="h4">
                Consultation History
              </Typography>
            </Grid>
            <Grid item>
              <IconButton
                edge="start"
                color="inherit"
                onClick={() => setOpendilog(false)}
                aria-label="close"
              >
                <CloseOutlined />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <ConsultationHistory patientId={showConsultations?.id} />
        </DialogContent>
      </Dialog>
    </>
  );
}
