import React, { useState } from 'react';
import { PersonRounded, Timer, VideoCallRounded } from '@material-ui/icons';
import { Box, Typography, Avatar, Grid, Paper, makeStyles, Card, Divider, CardContent, styled, CardActions, CardHeader } from '@material-ui/core';
import Button from 'components/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import Consultation from './Consultation';
import ConsultationTabs from './ConsultationTabs';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.primary.main,
    },
    heading: {
        display: "inline",
        fontSize: "0.875rem",
        color: theme.palette.primary.contrastText,
    },
    headColor: {
        backgroundColor: theme.palette.primary.main,
    },
    background: {
        backgroundColor: theme.palette.background.light,
    },
    label: {
        display: "inline",
        fontSize: "0.875rem",
        fontWeight: 500,
    },
    scheduleIcon: {
        color: theme.palette.primary.main,
        margin:2,
    },
    resposive: {

        [theme.breakpoints.down("xs")]: {
            display: 'flex',
            justifyContent: 'flex-center',
        },
    }
}));

export default function PatientDetailsCard(props) {

    const classes = useStyles();

    return (
        <div>
            <Card className={classes.root}>
                <Grid
                    container
                    spacing={0}
                    alignItems="center"
                    justifyContent="center"
                    className={classes.background}
                >
                    <Grid container
                        spacing={0}
                        style={{ position: "relative", padding: 8 }}
                        className={classes.headColor}
                    >
                        <Grid item xs={12} sm={12} md={4} lg={4}>
                            <Typography align="left" variant="h8" className={classes.heading}>
                                Patient Details
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item container xs={12} sm={6} md={6} lg={6} spacing={1} direction='row' style={{ position: "relative", padding: 8 }}>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <Typography className={classes.label}>
                                    Patient Name <span style={{ marginLeft: '0.2rem' }} ></span>
                                    : {props.patientDetails?.userDto?.ename}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <Typography className={classes.label}>
                                    Gender <span style={{ marginLeft: '2.9rem' }} ></span>
                                    : {props.patientDetails?.userDto?.genderName}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <Typography className={classes.label}>
                                    Age<span style={{ marginLeft: '4.6rem' }} ></span>
                                    : {props.patientDetails?.userDto?.age}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid item container xs={12} sm={6} md={6} lg={6} spacing={0} style={{
                            position: "relative",
                            padding: 8, display: 'flex', justifyContent: 'flex-end'
                        }}>
                            <Grid>
                                <Grid container spacing={1} className={classes.resposive}>
                                    <Grid item>
                                        <Timer fontSize='small' className={classes.scheduleIcon} ></Timer>
                                    </Grid>
                                    <Grid item>
                                        <Typography className={classes.label}>
                                            Scheduled Time{" : "}
                                            {props.patientDetails?.apptStart &&
                                                moment(props.patientDetails?.apptStart).format("hh:mm A")}
                                            {" - "}
                                            {props.patientDetails?.apptEnd &&
                                                moment(props.patientDetails?.apptEnd).format("hh:mm A")} {" "}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Card>

        </div >
    );
}
