import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import OtpInput from "react-otp-input";

//component
import Button from "components/Button";

//material-ui
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { Typography, Card, Grid } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({}));

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(0.1),
    color: theme.palette.grey[500],
  },
});
export default function Otp(props) {
  const classes = useStyles();
  const navigate = useNavigate();

  const [code, setCode] = useState("");
  const [min, setMin] = useState(1);
  const [sec, setSec] = useState(30);
  const dispatch = useDispatch();
  const isClear = props.isClear;
  const handleEnter = (event) => {
    // event.preventDefault();
    // props.setSubmit(true);
    props.otpSubmit(event, code);
  };

  const handleResend = async () => {
    setSec(30);
    setMin(1);
  };

  useEffect(() => {
    if (isClear) {
      setCode("");
    }
  }, [isClear]);

  useEffect(() => {
    const timer = setInterval(() => {
      if (sec > 0) {
        setSec(sec - 1);
      }
      if (sec === 0) {
        if (min === 0) {
          clearInterval(timer);
        } else {
          setMin(min - 1);
          setSec(59);
        }
      }
    }, 1000); // clearing interval
    return () => clearInterval(timer);
  });
  const handleChange = (code) => setCode(code);

  return (
    <>
      <form onSubmit={handleEnter}>
        <Grid container spacing={0}>
          <Grid items xs={10} sm={10} md={10}>
            <div className={classes.divPadding}>
              <OtpInput
                value={code}
                onChange={handleChange}
                numInputs={6}
                separator={<span style={{ width: "15px" }}></span>}
                isInputNum={true}
                shouldAutoFocus={true}
                inputStyle={{
                  border: "1px solid #e0e0e0",
                  borderRadius: "8px",
                  width: "30px",
                  height: "34px",
                  fontSize: "12px",
                  color: "#000",
                  fontWeight: "400",
                  caretColor: "blue",
                }}
                focusStyle={{
                  border: "1px solid #0097a7",
                  outline: "none",
                }}
              />
            </div>
          </Grid>
          <Grid items xs={4} sm={4} md={3} lg={3} xl={3} align="center">
            <Typography>Resend in</Typography>
          </Grid>
          <Grid items xs={2} sm={2} md={2} lg={2} xl={2} align="left">
            <Typography variant="h8">
              {min >= 10 ? min : `0${min}`}:{sec >= 10 ? sec : `0${sec}`}
            </Typography>
          </Grid>
          <Grid items xs={7} sm={7} md={3} lg={4} xl={4}>
            <Button disabled={min !== 0 || sec !== 0} onClick={handleResend}>
              <Typography className={classes.button}> Resend OTP </Typography>
            </Button>
          </Grid>
          <Grid items xs={5} sm={5} md={3} lg={3} xl={3} align="center">
            <Button color="primary" type="submit" onClick={handleEnter}>
              <Typography className={classes.button}> Submit</Typography>
            </Button>
          </Grid>
        </Grid>
      </form>
    </>
  );
}
