import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { InputLabel } from "@material-ui/core";
import MuiTextField from "@material-ui/core/TextField";

const useStyles = makeStyles((theme) => ({
  label: {
    fontSize: "1rem",
    marginBottom: 5,
    marginLeft: 1,
    color: theme.palette.text.primary,
  },
  inputSize: {
    fontSize: "0.875rem",
    color: "black",
  },
  root: {
    borderRadius: 4,
    height: "35px",
  },

  helperText: {
    marginLeft: 4,
  },
}));

export default function TextField(props) {
  const classes = useStyles();
  return (
    <>
      {props.labelText && (
        <InputLabel disabled={props.disabled} className={classes.label}>
          {props.labelText} {props.required ? <span style={{ color: "red" }}>*</span> : null}
        </InputLabel>
      )}
      <MuiTextField
        {...props}
        variant="outlined"
        size="small"
        fullWidth
        inputProps={{ ...props.inputProps }}
        InputProps={{
          classes: {
            input: classes.inputSize,
            root: classes.root,
          },
          ...props.InputProps,
        }}
        FormHelperTextProps={{
          classes: {
            root: classes.helperText,
          },
        }}
      />
    </>
  );
}
