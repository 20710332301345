import {
  Box,
  Grid,
  Paper,
  Typography,
  makeStyles,
  withStyles,
  TextField,
  Accordion,
  AccordionDetails,
  DialogContent,
  Dialog,
  DialogActions,
  Divider,
  DialogTitle,
  IconButton,
} from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import moment from "moment";
import ReactToPrint from "react-to-print";
import { Controller, useForm } from "react-hook-form";
import { restrict } from "restrict";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";

//pages
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import Button from "components/Button";
import belLogo from "assets/Images/bel_logo_bg.png";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import axiosInstance from "pages/Services/AxiosInstance";
import { saveConsultation } from "pages/Services/URLs";
import { ADD_PATIENT_DETAILS } from "Redux/Types";
import { Close } from "@material-ui/icons";


const AccordionSummary = withStyles((theme) => ({
  root: {
    color: "white",
    minHeight: "20px",
    "&$expanded": {
      minHeight: "20px",
    },
  },
  table: {
    minWidth: 700,
  },
  style: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  },
  content: {
    "&$expanded": {
      margin: "12px 0",
    },
  },
  expanded: {},
}))(MuiAccordionSummary);

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  tab: {
    fontSize: "1.3rem",
    fontWeight: 400,
    fontFamily: ["Poppins", "sans-serif"]

  },
  heading: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.background.light,
    margin: 0,
  },
  paper: {
    padding: 20,
    height: '68vh',
    overflow: 'auto',
    borderBottomRightRadius: 0,
    borderBottomLeftRadius: 0
  },
  paperHead: {
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    paddingTop: 10,
    paddingBottom: 10,
    borderTopRightRadius: 0,
    borderTopLeftRadius: 0
  }
}));

export default function ConsultationTabs() {
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const user = useSelector((store) => store.loggedInUser) || JSON.parse(localStorage.getItem("User"));
  const classes = useStyles();
  const [isEdit, setIsEdit] = useState(false);
  const patientDetails = useSelector((store) => store.PatientDetails.patientDetails);
  let componentRef = useRef();
  const current = new Date();
  const day = `${current.getDate()}-${current.getMonth() + 1}-${current.getFullYear()}`;
  const [date, setDate] = useState(new Date());

  useEffect(() => {
    var timer = setInterval(() => setDate(new Date()), 1000)
    return function cleanup() {
      clearInterval(timer)
    }

  });



  const defaultValues = {
    staffId: patientDetails?.doctorId,
    appointmentId: patientDetails?.id,
    vitals: null,
    prescription: null,
    labInvestigations: null,
    remarks: null,
    advice: null,
    diagnosis: null,
    consultationStatus: true,
  }
  const [previewdata, setPreviewdata] = useState(null);
  const previewDialog = (data) => {
    console.log(data);
    setPreviewdata(data);
    setOpen(true);
  }


  const executeSubmit = async (data) => {
    console.log("Submitted..");
    await axiosInstance
      .post(saveConsultation, { ...data })
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          const strsubmit = "Data Successfully Submitted.";
          // fetchSlot();
          toast.success(strsubmit);
          dispatch({ type: ADD_PATIENT_DETAILS, payload: null });
          resetForm();
        }
      })
      .catch((error) => {
        toast.error("Something went wrong");
      });
  };
  const resetForm = () => {
    reset(defaultValues);
  };

  const { reset, handleSubmit, control, formState: { errors } } = useForm({
    mode: "onChange",
    defaultValues,
    // resolver: yupResolver(schema),
  });
  const handleClose = () => {
    setIsEdit(false);
    setOpen(false);
  };

  return (
    <>
      <div className={classes.root}>
        <form>
          {/* id="Print" ref={(el) => (componentRef = el)} */}
          <Paper className={classes.paper}>
            <Accordion defaultExpanded >
              <AccordionSummary

                expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                className={classes.heading}
                style={{ height: "1010" }}>
                <Typography >Vitals</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Controller
                  name="vitals"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      placeholder="Enter Vitals.."
                      size="small"
                      variant="outlined"
                      value={value}
                      rows={4}
                      multiline
                      fullWidth
                      onChange={onChange}
                      inputProps={{ maxLength: 50 }}
                      // error={!!errors.vitals}
                      // helperText={errors.vitals?.message}
                      onInput={(e) => { restrict.inputName(e) }}
                    />
                  )}
                />
              </AccordionDetails>
            </Accordion>

            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
                aria-controls="panel2a-content"
                id="panel2a-header"
                className={classes.heading}
              >
                <Typography>Prescription</Typography>
              </AccordionSummary>
              <AccordionDetails aria-expanded={true}>
                <Controller
                  name="prescription"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      placeholder="Enter Prescription.."
                      size="small"
                      variant="outlined"
                      value={value}
                      rows={4}
                      multiline
                      fullWidth
                      onChange={onChange}
                      inputProps={{ maxLength: 50 }}
                      // error={!!errors.prescription}
                      // helperText={errors.prescription?.message}
                      onInput={(e) => { restrict.inputName(e) }}
                    />
                  )}
                />
              </AccordionDetails>
            </Accordion>

            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
                aria-controls="panel2a-content"
                id="panel2a-header"
                className={classes.heading}
              >
                <Typography >Lab Investigations</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Controller
                  name="labInvestigations"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      placeholder="Enter Lab Investigations.."
                      size="small"
                      variant="outlined"
                      value={value}
                      rows={4}
                      multiline
                      fullWidth
                      onChange={onChange}
                      inputProps={{ maxLength: 50 }}
                      // error={!!errors.labInvestigations}
                      // helperText={errors.labInvestigations?.message}
                      onInput={(e) => { restrict.inputName(e) }}
                    />
                  )}
                />
              </AccordionDetails>
            </Accordion>

            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
                aria-controls="panel2a-content"
                id="panel2a-header"
                className={classes.heading}
              >
                <Typography>Advices</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Controller
                  name="advice"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      placeholder="Enter Advices.."
                      size="small"
                      variant="outlined"
                      value={value}
                      rows={4}
                      multiline
                      fullWidth
                      onChange={onChange}
                      inputProps={{ maxLength: 50 }}
                      // error={!!errors.advice}
                      // helperText={errors.advice?.message}
                      onInput={(e) => { restrict.inputName(e) }}
                    />
                  )}
                />
              </AccordionDetails>
            </Accordion>

            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
                aria-controls="panel2a-content"
                id="panel2a-header"
                className={classes.heading}
              >
                <Typography >Diagnosis</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Controller
                  name="diagnosis"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      placeholder="Enter Diagnosis.."
                      size="small"
                      variant="outlined"
                      value={value}
                      rows={4}
                      multiline
                      fullWidth
                      onChange={onChange}
                      inputProps={{ maxLength: 50 }}
                      // error={!!errors.diagnosis}
                      // helperText={errors.diagnosis?.message}
                      onInput={(e) => { restrict.inputName(e) }}
                    />
                  )}
                />
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
                aria-controls="panel2a-content"
                id="panel2a-header"
                className={classes.heading}
              >
                <Typography >Remarks</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Controller
                  name="remarks"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      placeholder="Enter Remarks.."
                      size="small"
                      variant="outlined"
                      value={value}
                      rows={4}
                      multiline
                      fullWidth
                      onChange={onChange}
                      inputProps={{ maxLength: 50 }}
                      // error={!!errors.remarks}
                      // helperText={errors.remarks?.message}
                      onInput={(e) => { restrict.inputName(e) }}
                    />
                  )}
                />
              </AccordionDetails>
            </Accordion>
          </Paper>
        </form>
      </div>
      <div className={classes.root}>
        <Box className={classes.paperHead} >
          <Paper >
            <Button variant="contained" color="secondary" onClick={handleSubmit(previewDialog)} >Preview</Button>
            <Dialog
              open={open}
              fullWidth
              maxWidth="lg"
            >
              <DialogTitle id="customized-dialog-title">
                <Grid container spacing={0} className={classes.diagHeader}>
                  <Grid items xs={11} >
                    <Typography ><strong>Prescription Details</strong></Typography>
                  </Grid>
                  <Grid items xs={1} align="right">
                    <IconButton aria-label="close" onClick={handleClose}>
                      <Close />
                    </IconButton>
                  </Grid>
                </Grid>
              </DialogTitle>
              <DialogContent id="Print" ref={(el) => (componentRef = el)}>
                <Grid container alignItems="center" spacing={4} justifyContent="center">
                  <Grid item xs={3} align="center" >
                    <img
                      style={{ height: 60, width: 160 }}
                      src={belLogo}
                    />
                  </Grid>
                  <Grid item container xs={6} align="center">
                    <Grid item xs={12}  >
                      <Typography><strong>BHARAT ELECTRONICS HOSPITAL</strong></Typography>
                    </Grid>
                    <Grid item xs={12} >
                      <Typography>Medical Department</Typography>
                    </Grid>
                  </Grid>
                  <Grid item xs={3} align="center">
                    <Typography>{day}</Typography>
                    <Typography>{date.toLocaleTimeString()}</Typography>
                  </Grid>
                  <Grid item container xs={12} justifyContent="center">
                    <Grid item xs={6}>
                      <Typography>Patient ID
                        <span style={{ marginLeft: '3.9rem' }} ></span>
                        : {patientDetails?.patientId}
                      </Typography>
                      <Typography>Patient Name
                        <span style={{ marginLeft: '1.8rem' }} ></span>
                        : {patientDetails?.userDto?.ename}
                      </Typography>
                      <Typography>appointment ID
                        <span style={{ marginLeft: '0.8rem' }} ></span>
                        :  {patientDetails?.id}
                      </Typography>
                      <Typography>Mode
                        <span style={{ marginLeft: '5.8rem' }} ></span>
                        : {patientDetails?.isTeleConsultation ? "Online" : "Offline"}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography>Scheduled Date
                        <span style={{ marginLeft: '0.8rem' }} ></span>
                        : {moment(patientDetails?.apptStart).format("DD-MM-YYYY")}{" "}
                      </Typography>
                      <Typography>Start Time
                        <span style={{ marginLeft: '3.7rem' }} ></span>
                        : {moment(patientDetails?.apptStart).format("hh:mm A")}
                      </Typography>
                      <Typography>End Time
                        <span style={{ marginLeft: '4.3rem' }} ></span>
                        : {moment(patientDetails?.apptEnd).format("hh:mm A")}{" "}
                      </Typography>
                      <Typography>Consulted to
                        <span style={{ marginLeft: '2.45rem' }} ></span>
                        : {user?.eName}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Divider />
                <br />
                <div>
                  {
                    previewdata?.vitals && (<>
                      <Typography ><strong>Vitals :</strong></Typography>
                      {isEdit ? (<>
                        <Controller
                          name="vitals"
                          control={control}
                          render={({ field: { onChange, value } }) => (
                            <TextField
                              placeholder="Enter Vitals.."
                              size="small"
                              variant="outlined"
                              value={value}
                              rows={4}
                              multiline
                              fullWidth
                              onChange={onChange}
                              inputProps={{ maxLength: 50 }}
                            // error={!!errors.vitals}
                            // helperText={errors.vitals?.message}
                            onInput={(e) => { restrict.inputName(e) }}
                            />
                          )}
                        /> </>) : previewdata?.vitals}

                    </>)
                  }
                </div>
                <div>
                  {
                    previewdata?.prescription && (<>
                      <Typography><strong>Prescription :</strong></Typography>
                      {isEdit ? (<>
                        <Controller
                          name="prescription"
                          control={control}
                          render={({ field: { onChange, value } }) => (
                            <TextField
                              placeholder="Enter Prescription.."
                              size="small"
                              variant="outlined"
                              value={value}
                              rows={4}
                              multiline
                              fullWidth
                              onChange={onChange}
                              inputProps={{ maxLength: 50 }}
                              // error={!!errors.prescription}
                              // helperText={errors.prescription?.message}
                              onInput={(e) => { restrict.inputName(e) }}
                            />
                          )}
                        /> </>) : previewdata?.prescription}
                    </>)}
                </div>
                <div>
                  {
                    previewdata?.labInvestigations && (<>
                      <Typography ><strong>Lab Investigations :</strong></Typography>
                      {isEdit ? (<>
                        <Controller
                          name="labInvestigations"
                          control={control}
                          render={({ field: { onChange, value } }) => (
                            <TextField
                              placeholder="Enter Lab Investigations.."
                              size="small"
                              variant="outlined"
                              value={value}
                              rows={4}
                              multiline
                              fullWidth
                              onChange={onChange}
                              inputProps={{ maxLength: 50 }}
                              // error={!!errors.labInvestigations}
                              // helperText={errors.labInvestigations?.message}
                              onInput={(e) => { restrict.inputName(e) }}
                            />
                          )}
                        /> </>) : previewdata?.labInvestigations}
                    </>)}
                </div>
                <div>
                  {
                    previewdata?.advice && (<>
                      <Typography><strong>Advices :</strong></Typography>
                      {isEdit ? (<>
                        <Controller
                          name="advice"
                          control={control}
                          render={({ field: { onChange, value } }) => (
                            <TextField
                              placeholder="Enter Advices.."
                              size="small"
                              variant="outlined"
                              value={value}
                              rows={4}
                              multiline
                              fullWidth
                              onChange={onChange}
                              inputProps={{ maxLength: 50 }}
                              // error={!!errors.advice}
                              // helperText={errors.advice?.message}
                              onInput={(e) => { restrict.inputName(e) }}
                            />
                          )}
                        /> </>) : previewdata?.advice}
                    </>)}
                </div>
                <div>
                  {
                    previewdata?.diagnosis && (<>
                      <Typography ><strong>Diagnosis :</strong></Typography>
                      {isEdit ? (<>
                        <Controller
                          name="diagnosis"
                          control={control}
                          render={({ field: { onChange, value } }) => (
                            <TextField
                              placeholder="Enter Diagnosis.."
                              size="small"
                              variant="outlined"
                              value={value}
                              rows={4}
                              multiline
                              fullWidth
                              onChange={onChange}
                              inputProps={{ maxLength: 50 }}
                              // error={!!errors.diagnosis}
                              // helperText={errors.diagnosis?.message}
                              onInput={(e) => { restrict.inputName(e) }}
                            />
                          )}
                        /> </>) : previewdata?.diagnosis}
                    </>)}
                </div>
                <div >
                  {
                    previewdata?.remarks && (<>
                      <Typography ><strong>Remarks :</strong></Typography>
                      {isEdit ? (<>
                        <Controller
                          name="remarks"
                          control={control}
                          render={({ field: { onChange, value } }) => (
                            <TextField
                              placeholder="Enter Remarks.."
                              size="small"
                              variant="outlined"
                              value={value}
                              rows={4}
                              multiline
                              fullWidth
                              onChange={onChange}
                              inputProps={{ maxLength: 50 }}
                              // error={!!errors.remarks}
                              // helperText={errors.remarks?.message}
                              onInput={(e) => { restrict.inputName(e) }}
                            />
                          )}
                        /> </>) : previewdata?.remarks}
                    </>)}
                </div>
              </DialogContent>
              <DialogActions>
                <ReactToPrint
                  trigger={() => (
                    <Button variant="contained" color="secondary">
                      Print
                    </Button>
                  )}
                  content={() => componentRef}
                />
                <Button variant='contained' type="submit" onClick={handleSubmit(executeSubmit)}
                  color="primary"> Submit </Button>
                {!isEdit && <Button variant="contained" color="secondary" onClick={() => setIsEdit(true)} >Edit</Button>}
              </DialogActions>
            </Dialog>
          </Paper>
        </Box>
      </div>
    </>
  );
}
