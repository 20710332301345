import * as React from "react";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import {
  Drawer,
  Box,
  Typography,
  CircularProgress,
  Divider,
  InputAdornment,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  TextField,
  makeStyles,
  Grid,
  IconButton,
} from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircle,
  faCircleNotch,
  faDesktop,
  faLaptop,
  faSearch,
  faUser,
  faVideo,
  faVideoSlash,
} from "@fortawesome/free-solid-svg-icons";

//api
import { getPatientsListUrl } from "pages/Services/URLs";
import axiosInstance from "pages/Services/AxiosInstance";

//actions
import { addPatientDetails } from "Redux/Actions/DoctorActions/PatientDetailsAction";
import { addSelectedAppointmentDetails } from "Redux/Actions/AppointmentAction";
import Button from "components/Button";
import { RefreshSharp } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  sliderContainer: {
    height: "100%",
    background: theme.palette.primary.dark,
  },
  label: {
    color: theme.palette.primary.contrastText,
  },
  dividerColor: {
    backgroundColor: theme.palette.background.default,
  },
  searchBox: {
    margin: 10,
    padding: 2,
    borderRadius: 5,
    backgroundColor: theme.palette.background.default,
  },
  searchField: {
    marginRight: 17,
  },
  titleBox: {
    height: 50,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: 10,
  },
  searchIcon: {
    color: theme.palette.text.primary,
  },
  emptyIcon: {
    margin: 10,
    color: theme.palette.text.hint,
  },
  emptyBox: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    marginTop: 20,
  },
  circular: {
    marginTop: 40,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  circularColor: {
    color: theme.palette.background.default,
  },
}));

export default function Slider(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const user =
    useSelector((store) => store.loggedInUser) ||
    JSON.parse(localStorage.getItem("User"));
  const [patientsList, setPatientsList] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [filteredPatients, setFilteredPatients] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const todayDate = moment(new Date()).format("YYYY-MM-DD");

  useEffect(() => {
    if (user) {
      fetchPatientsList();
    } else setIsLoading(false);
  }, []);

  const fetchPatientsList = async () => {
    setIsLoading(true);

    await axiosInstance
      .get(getPatientsListUrl + `/${user.empId}/${todayDate}`)
      .then((response) => {
        setPatientsList(response.data);
        setFilteredPatients(response.data);
        setIsLoading(false);
      })
      .catch((e) => {
        console.log("error :" + e);
        setIsLoading(false);
      });
  };

  const filterBySearch = (e) => {
    const keyword = e.target.value;
    if (keyword !== "") {
      const filteredData = patientsList.filter((item) => {
        return Object.values(item?.userDto?.ename)
          .join("")
          .toLowerCase()
          .trim()
          .includes(keyword.toLowerCase());
      });

      setFilteredPatients(filteredData);
    } else {
      setFilteredPatients(patientsList);
    }
    setSearchInput(keyword);
  };

  const handlePatient = (obj) => {
    dispatch(addPatientDetails(obj));
    dispatch(addSelectedAppointmentDetails(obj));
  };
  const sliderList = () => (
    <Box className={classes.sliderContainer}>
      <Box className={classes.titleBox}>
        <Grid
          container
          row
          className={classes.label}
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
        >
          <Grid item>
            <Typography>Today's Patients</Typography>
          </Grid>
          <Grid item>
            <IconButton
              aria-label="refresh"
              onClick={fetchPatientsList}
              className={classes.label}
            >
              <RefreshSharp />
            </IconButton>
          </Grid>
        </Grid>
      </Box>
      <Divider className={classes.dividerColor} />
      <Box className={classes.searchBox}>
        <TextField
          fullWidth
          placeholder="Search..."
          variant="outlined"
          size="small"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <FontAwesomeIcon
                  size="sm"
                  icon={faSearch}
                  className={classes.searchIcon}
                />
              </InputAdornment>
            ),
          }}
          value={searchInput}
          onChange={filterBySearch}
        />
      </Box>
      <Divider className={classes.dividerColor} />
      {isLoading ? (
        <Box className={classes.circular}>
          <CircularProgress className={classes.circularColor} size={30} />
        </Box>
      ) : (
        <List>
          {filteredPatients && filteredPatients.length > 0 ? (
            filteredPatients.map((item, index) => (
              <>
                {item?.consultationDto == null ? (
                  <>
                    <ListItem
                      className={classes.label}
                      divider
                      button
                      key={index}
                      onClick={props.closeSlider}
                    >
                      <ListItemIcon className={classes.label}>
                        <FontAwesomeIcon icon={faUser} size="lg" />
                      </ListItemIcon>
                      <ListItemText
                        onClick={() => handlePatient(item)}
                        primary={item?.userDto?.ename}
                        primaryTypographyProps={{
                          style: {
                            whiteSpace: "break-spaces",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          },
                        }}
                      />
                      {item?.isTeleConsultation ? (
                        <FontAwesomeIcon
                          size="xs"
                          icon={faVideo}
                          className={classes.label}
                        />
                      ) : (
                        <FontAwesomeIcon
                          size="xs"
                          icon={faLaptop}
                          className={classes.label}
                        />
                      )}
                    </ListItem>
                  </>
                ) : (
                  <>
                    {" "}
                    <ListItem
                      style={{ backgroundColor: "#500000" }}
                      className={classes.label}
                      divider
                      button
                      key={index}
                      onClick={props.closeSlider}
                    >
                      <ListItemIcon className={classes.label}>
                        <FontAwesomeIcon icon={faUser} size="lg" />
                      </ListItemIcon>
                      <ListItemText
                        onClick={() => handlePatient(item)}
                        primary={item?.userDto?.ename}
                        primaryTypographyProps={{
                          style: {
                            whiteSpace: "break-spaces",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          },
                        }}
                      />
                      {item?.isTeleConsultation ? (
                        <FontAwesomeIcon
                          size="xs"
                          icon={faVideo}
                          className={classes.label}
                        />
                      ) : (
                        <FontAwesomeIcon
                          size="xs"
                          icon={faLaptop}
                          className={classes.label}
                        />
                      )}
                    </ListItem>
                  </>
                )}
              </>
            ))
          ) : (
            <Box className={classes.emptyBox}>
              <FontAwesomeIcon
                size="2x"
                icon={faSearch}
                className={classes.emptyIcon}
              />
              <Typography className={classes.label}>
                No Results Found!
              </Typography>
            </Box>
          )}
        </List>
      )}
    </Box>
  );
  return (
    <Drawer anchor="left" open={props.openSlider} onClose={props.closeSlider}>
      {sliderList()}
    </Drawer>
  );
}
