export function inputRestrict(e, removeListRegex) {
  const input = e.target;
  const start = input.selectionStart,
    end = input.selectionEnd;
  const initialLen = input.value.length;
  input.value = input.value.replace(removeListRegex, "");
  const lengthDifference = input.value.length - initialLen;
  if (lengthDifference) {
    const newStart = start + lengthDifference;
    const newEnd = end + lengthDifference;
    input.setSelectionRange(newStart, newEnd);
  }
}

export function inputToUpper(e) {
  const input = e.target,
    start = input.selectionStart,
    end = input.selectionEnd;
  input.value = input.value.toLocaleUpperCase();
  input.value = input.value.endsWith(" ") ? input.value.trim() + " " : input.value.trim();
  input.setSelectionRange(start, end);
}
export function inputTrim(e) {
  const input = e.target,
    start = input.selectionStart,
    end = input.selectionEnd;
  input.value = input.value.endsWith(" ") ? input.value.trim() + " " : input.value.trim();
  input.setSelectionRange(start, end);
}

export function inputPhoneNumber(num) {
  const first = num % 10;
  while (num) {
    if (num % 10 !== first) return false;
    num = Math.floor(num / 10);
  }
  return true;
}

export const restrict = {
  email(e) {
    inputRestrict(e, /[^@a-zA-Z-_.\d]/g);
  },
  name(e) {
    inputRestrict(e, /[^a-zA-Z0-9-.,():'& ]/g);
  },
  OTInputs(e) {
    inputRestrict(e, /[^a-zA-Z0-9 -]/g);
  },
  remarks(e) {
    inputRestrict(e, /[^-a-zA-Z(),&. \d]/g);
  },
  onlyCharacter(e) {
    inputRestrict(e, /[^a-zA-Z ]/g);
  },
  dot(e) {
    inputRestrict(e, /[^\w.a-zA-Z\d]/g);
  },
  bloodRequest(e) {
    inputRestrict(e, /[^0-9.0-9]/g);
  },
  eventname(e) {
    inputRestrict(e, /[^\w.a-z A-Z\d]/g);
  },
  address(e) {
    inputRestrict(e, /[^-/a-zA-Z.#/,()& \d,]/g);
  },

  digits(e) {
    inputRestrict(e, /\D/g);
  },
  dose(e) {
    inputRestrict(e, /[^\d.]/g);
  },
  number(e) {
    inputRestrict(e, /[^\d.-]/g);
  },

  positiveInteger(e) {
    inputRestrict(e, /[^\d]/g);
  },
  notFirstZero(e) {
    inputRestrict(e, /[^(?!(0))[0-9]]/g);
  },
  restrictedInput(e) {
    inputRestrict(e, /[^-_a-zA-Z0-9 +.@# ]/g);
  },
  ministryName(e) {
    inputRestrict(e, /[^-_a-zA-Z0-9 .,()& ]/g);
  },
  officeName(e) {
    inputRestrict(e, /[^-_a-zA-Z0-9 .,()& ]/g);
  },
  venueName(e) {
    inputRestrict(e, /[^-_a-zA-Z0-9 .,()& ]/g);
  },
  designationName(e) {
    inputRestrict(e, /[^a-zA-Z0-9+-.,/()'&\[\] ]/g);
  },
  eventName(e) {
    inputRestrict(e, /[^-a-zA-Z0-9 .,()& ]/g);
  },
  eventDescription(e) {
    inputRestrict(e, /[^-_a-zA-Z0-9 +@#.,:()&' ]/g);
  },
  alphaNumericWithNoSpace(e) {
    inputRestrict(e, /[^a-zA-Z0-9]/g);
  },

  billNo(e) {
    inputRestrict(e, /[^-_a-zA-Z0-9]/g);
  },
  venueRemarks(e) {
    inputRestrict(e, /[^-_a-zA-Z0-9 +@#:,().&' ]/g);
  },
  encRemarks(e) {
    inputRestrict(e, /[^-_a-zA-Z0-9 +@#:,().&' ]/g);
  },
  relationshipName(e) {
    inputRestrict(e, /[^-a-zA-Z ,. ]/g);
  },
  inputName(e) {
    inputRestrict(e,/[^a-zA-Z0-9 ,-_/']/g);
  }
};
