import React, { useState, useEffect } from "react";
import Excel from "exceljs";
import FileSaver from "file-saver";
import moment from "moment";
//material-ui
import { makeStyles, Table, TableBody, Grid, Dialog } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import MuiDialogActions from "@material-ui/core/DialogActions";
import MuiDialogContent from "@material-ui/core/DialogActions";
import axiosInstance from "pages/Services/AxiosInstance";
import { toast } from "react-toastify";

import configData from "../../../config.json";
import { Typography } from "antd";
//components
import Button from "../../../components/Button";

import DownloadForm from "../DownloadForm";
import { fetchSubmittedClaims } from "Redux/Actions/ClaimAction";

const useStyles = makeStyles((theme) => ({
  padding: {
    [theme.breakpoints.down("sm")]: {
      padding: "4px 0px 8px 8px",
    },
    [theme.breakpoints.down("lg")]: {
      // padding: "4px 0px 8px 8px",
    },
  },
}));

export const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

export const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function PedencyLog() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [selectedFile, setSelectedFile] = useState(null);
  const fileRef = React.useRef(null);
  const [uploadedImg, setUploadedImg] = useState(null);
  const [fileError, setFileError] = useState(false);

  const user = useSelector((store) => store.loggedInUser) || JSON.parse(localStorage.getItem("User"));
  const [loading, setLoading] = useState(false);
  const upload = React.useCallback(async () => {
    fileRef.current.click();
  }, []);
  const [format, setFormat] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const submittedClaims = useSelector((store) => store.claimReducer.submittedClaims);
  
  const printExcel = () => {
    const wb = new Excel.Workbook();
    const now = moment(new Date()).format("DD-MM-YYYY");
    const time = new Date().toLocaleTimeString();

    const data = submittedClaims
      .map((d) => [
        ...d?.medicalBillDtos.map((dl) => [
          d?.claimId ? d?.claimId : "--",
          d?.claimDate ? moment(d.claimDate)?.format("DD.MM.YYYY") : "--",
          d?.employeeId,
          d?.claimYear,
          dl?.id,
          dl?.expenseTypeId,
          dl?.billDate ? moment(dl?.billDate)?.format("DD.MM.YYYY") : "--",
          dl?.prescriptionDate ? moment(dl?.prescriptionDate)?.format("DD.MM.YYYY") : "--",
          dl?.amountClaimed,
          dl?.isSelf && dl?.isSelf === "t" ? "Self" : "Spouse",
          d?.claimStatus,
        ]),
      ])
      .flat();
    console.log(data, "pp");

    const sheet = wb.addWorksheet("Claim Data report");

    const tableData = data.map((r, i) => [i + 1, ...r]);

    const wsData = [
      [
        "Claim Ref No.",
        "Claim date",
        "Staff No.",
        "Claim Year",
        "Bill Serial No",
        "Type of Expenses",
        "Bill Date",
        "Prescription Date",
        "Claim Amount",
        "Self/ Spouse",
        "claimStatus",
      ],
      ...data,
    ];

    sheet.columns = [{ width: 6 }, { width: 14 }, { width: 24 }, ...Array(5).fill({ width: 18 })];

    sheet.addRows(wsData);

    const range = (start, end) => Array.from({ length: end }, (_, i) => i + start);

    range(1, 18).forEach(
      (c) =>
        (sheet.getColumn(c).alignment = {
          vertical: "middle",
          horizontal: "center",
        })
    );
    ["A1", "B1", "C1", "D1", "E1", "F1", "G1", "H1", "I1", "J1", "K1"].forEach(
      (e) => (sheet.getCell(e).font = { bold: true })
    );

    sheet.eachRow(function(row, rowNumber) {
      console.log("row no", rowNumber);
      row.eachCell((cell, colNumber) => {
        if (rowNumber) {
          cell.border = {
            top: { style: "thin" },
            left: { style: "thin" },
            bottom: { style: "thin" },
            right: { style: "thin" },
          };
        }
      });
    });

    wb.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      FileSaver.saveAs(blob, `Claim Data Report ${now}, ${time}.xlsx`);
    });
  };

  const onChangeImage = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  const fetchClaim = async () => {
    await dispatch(fetchSubmittedClaims());
  };

  const handleSubmit = () => {
    const formData = new FormData();
    formData.append("file", selectedFile);
    formData.append("fileName", selectedFile.name);
    // formData.append("dto", "abc");
    formData.append("lastUpdatedBy", user.empId);

    // formData.append("fileType", "employee");
    // formData.append("dto", { fileName: selectedFile.name, lastUpdatedBy: new Date() });

    const config = {
      onUploadProgress: (progressEvent) => {
        const totalLength = progressEvent.lengthComputable
          ? progressEvent.total
          : progressEvent.target.getResponseHeader("content-length") ||
            progressEvent.target.getResponseHeader("x-decompressed-content-length");
        console.log("onUploadProgress", totalLength);
      },
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    const url = configData.SERVICE_URL + "upload/claimdata";

    axiosInstance
      .post(url, formData, config)
      .then((res) => {
        setLoading(false);
        setUploadedImg(res?.data?.fileDownloadUri);
        toast.success("Data Successfully Submitted.");
        fetchClaim();
        // fetchLatestData();
      })
      .catch((error) => {
        setLoading(false);
        toast.error("Something went wrong");
      });
  };

  return (
    <>
      <Grid container spacing={0}>
        <Grid xs={6} sm={4} md={3} lg={2} xl={2} className={classes.padding}>
          <Typography> Upload Claim Details</Typography>
        </Grid>
        <Grid xs={4} sm={4} md={3} lg={2} xl={2} className={classes.padding}>
          <input
            type="file"
            ref={fileRef}
            accept=".xls"
            onChange={(e) => {
              const file = e.target.files[0];
              if (file) {
                if (!/\.(xls)$/g.test(file?.name)) {
                  setFileError(true);
                  toast.error("Please select only Excel File (.xls) for uploading Claim Details");
                } else {
                  onChangeImage(e);

                  console.log(e.target.files[0]);
                }
              }
            }}
          />
        </Grid>
        <Grid xs={4} sm={4} md={3} lg={2} xl={2} className={classes.padding}>
          <Button
            align="center"
            type="submit"
            sie
            style={{ marginTop: "15px" }}
            color="primary"
            variant="contained"
            onClick={(e) => {
              handleSubmit();
            }}
          >
            Submit
          </Button>

          {/* {selectedFile ? selectedFile?.name : null} */}
        </Grid>
        <Grid xs={8} sm={6} md={4} lg={2} xl={1} className={classes.padding}>
          <Button
            type="submit"
            align="center"
            style={{ marginTop: "15px" }}
            color="primary"
            variant="contained"
            onClick={() => format === printExcel()}
            // onClick={handleSubmit(submitLogin)}
          >
            Download Claim Details
          </Button>
        </Grid>
      </Grid>
      <Dialog open={open} maxWidth="xl" fullWidth>
        <DialogContent dividers style={{ padding: 16, position: "relative" }}>
          <DownloadForm />
        </DialogContent>

        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            // onClick={handleSave}
            type="submit"
          >
            update
          </Button>

          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              setOpen(false);
            }}
          >
            close
          </Button>
        </DialogActions>
      </Dialog>{" "}
    </>
  );
}
