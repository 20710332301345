import React from "react";
import { Grid, makeStyles, Typography, Dialog, DialogContent, DialogActions, IconButton } from "@material-ui/core";
import { CloseRounded, ErrorOutlineRounded } from "@material-ui/icons";
import { useSelector } from "react-redux";
//components
import Button from "components/Button";


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        padding: theme.spacing(0),
    },
    label: {
        fontsize: '0.875rem',
        margin: 5,
    },
    buttons: {
        display: 'flex',
        justifyContent: 'flex-end',
        padding: 15,

    }
}));

export const SubmitDialogBox = (props) => {
    const classes = useStyles();
    const updateOTBooking = useSelector((store) => store.OTPatients.updateOTBooking);

    return (
        <div className={classes.root}>
            <Dialog open={props.openSubmitBox} onClose={props.closeSubmitBox}>
                <DialogContent style={{ justifyContent: "center", width: 400 }}>
                    <Grid container justifyContent="flex-end" >
                        <IconButton
                            size="small"
                            onClick={props.closeSubmitBox} >
                            <CloseRounded />
                        </IconButton>
                    </Grid>
                    <Grid container spacing={2} direction="column" justifyContent="center" alignItems="center">
                        <Grid item>
                            <ErrorOutlineRounded style={{ color: "#E74C3C", fontSize: 80 }} />
                        </Grid>
                        <Grid item>
                            <Typography><strong>Are you sure ?</strong></Typography>
                        </Grid>
                        <Grid item>
                            {updateOTBooking === 'reschedule' ?
                                <Typography>This will make OT Reschedule.</Typography> :
                                updateOTBooking === 'cancelation' ?
                                    <Typography>This will make OT cancellation.</Typography> :
                                    <Typography>This will make OT Schedule.</Typography>
                            }
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions style={{ justifyContent: "center" }}>
                    <Button
                        align="center"
                        color="primary"
                        variant="contained"
                        onClick={props.closeSubmitBox}
                    >
                        Cancel
                    </Button>
                    <Button
                        align="center"
                        type="submit"
                        color="primary"
                        variant="contained"
                        onClick={() => props.onClick()}
                    >
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>
        </div >
    )
}