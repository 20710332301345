import { combineReducers } from "redux";
import { selectedTreeItem } from "./Reducer/SelectedTreeItem";
import { loginOrSignup } from "./Reducer/LoginOrSingup";
import { selectedTheme } from "./Reducer/SelectedTheme";
import { prepareFinalObject } from "./Reducer/MdmsReducer";
import { selectedHomeEvent } from "./Reducer/selectedHomeEvent";
import { selectedMenu } from "./Reducer/SelectedMenu";
import { loggedInUser } from "./Reducer/User";
import EmployeeDetails from "./Reducer/EmployeeReducer";
import ClaimDetails from "./Reducer/ClaimReducer";
import { PatientDetails } from "./Reducer/DoctorReducers/PatientDetailsReducer";
import { selectedItem } from "./Reducer/OTReducers/SelectedItemReducer";
import { selectedActionItem } from "./Reducer/OTReducers/SelectedActionItemReducer";
import { OTPatients } from "./Reducer/OTReducers/OTPatientsReducer";
export default combineReducers({
  selectedTreeItem,
  loginOrSignup,
  selectedTheme,
  prepareFinalObject,
  selectedHomeEvent,
  selectedMenu,
  loggedInUser,
  employeeReducer: EmployeeDetails,
  claimReducer: ClaimDetails,
  PatientDetails,
  selectedItem,
  selectedActionItem,
  OTPatients,
});