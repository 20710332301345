import { UPDATE_OBJECT } from "../Types";
import { updateFinalObject } from "./utils";

export const initialState = {
    updatedFinalObject : {}
};

export const prepareFinalObject = (state = initialState, action) => {
    switch (action.type) {
        case UPDATE_OBJECT:
            const updatedFinalObject = updateFinalObject(
                state.updatedFinalObject,
                action.path,
                action.value
            );
            return {
                ...state,
                updatedFinalObject: updatedFinalObject,
            };

        default:
            return state;
    }
};
