import DateFnsUtils from "@date-io/date-fns";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Card,
  CardContent,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";
import { Autocomplete } from "@material-ui/lab";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import moment from "moment";
import axiosInstance from "pages/Services/AxiosInstance";
import {
  getAllDoctorsUrl,
  getAllSlotsOfDocWithAppts,
  getAppontmentPatientUrl,
  saveAppontmentUrl,
} from "pages/Services/URLs";
import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import AppointmentTable from "./AppointmentTable";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import noSlotsImg from "../../assets/Images/noslots.png";
import { Check, Save } from "@material-ui/icons";
import Button from "components/Button";
const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  card: {
    width: 210,
    height: 50,
    color: "green",
    margin: "10px",
    border: "1px solid green",
  },
  cardAvailable: {
    width: 210,
    height: 50,
    color: "green",
    margin: "10px",
    border: "1px solid green",
    cursor: "pointer",
    userSelect: "none",
  },
  cardSelected: {
    width: 210,
    height: 50,
    color: "white",
    margin: "10px",
    backgroundColor: "green",
    cursor: "pointer",
    border: "1px solid green",
    userSelect: "none",
  },
  cardNotAvailable: {
    width: 210,
    height: 50,
    margin: "10px",
    color: "#8d8d8d",
    cursor: "not-allowed",
    backgroundColor: "#e0e0e0",
    userSelect: "none",
  },

  text: {
    fontWeight: 500,
  },
  dateContainer: {
    display: "flex",
    justifyContent: "",
    alignItems: "center",
    flexWrap: "wrap",
    marginTop: "15px",
    marginBottom: "15px",
  },
  item: {
    minWidth: 300,
    maxWidth: 200,
    paddingRight: 20,
  },
  rowcenter: {
    display: "flex",
    justifyContent: "center",
  },
  mt_2: {
    marginTop: 20,
  },
  gridslots: {
    justifyContent: "center",
    justifyItems: "center",
    display: "grid",
    width: "100%",
    gridTemplateRows: "repeat(0, minmax(100px, min-content))",
    gridTemplateColumns: "repeat(auto-fill,220px)",
    gridTemplateColumns: "repeat(auto-fill,220px)",
    gridTemplateColumns: "repeat(auto-fit,minmax(220px, 1fr))",
  },
}));

export default function Appointment() {
  const defaultValues = {
    id: 0,
    deptId: 1,
    date: moment().format(),
    doctorId: null,
    is_tele_consultation: "true",
  };
  const schema = yup.object().shape({
    date: yup.date(),
    doctorId: yup
      .number()
      .typeError("Doctor is required")
      .required("Doctor is required"),
    deptId: yup.number(),
    is_tele_consultation: yup
      .boolean()
      .typeError("Tele Consultation is required")
      .required("Tele Consultation is required"),
  });

  const {
    reset,
    handleSubmit,
    control,
    getValues,
    watch,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });
  const [endDateError, setEndDateError] = React.useState("");
  const classes = useStyles();
  const [doctorlist, setDoctorList] = React.useState([]);
  const logineduser =
    useSelector((store) => store.loggedInUser) ||
    JSON.parse(localStorage.getItem("User"));
  const [slots, setSlots] = React.useState([]);
  const [selectedslot, setSelectedSlot] = React.useState(null);
  const [updateslots, setupdateslots] = React.useState([]);
  const [patientlist, setPatientList] = React.useState([]);
  // console.log(watch());

  const getDoctorsList = () => {
    axiosInstance
      .get(getAllDoctorsUrl)
      .then((res) => {
        setDoctorList(res.data);
      })
      .catch((e) => console.log(e.message));
  };

  useEffect(() => {
    getDoctorsList();
    setSlots(updateslots);
    getAppointedlist();
  }, []);
  const getAppointedlist = () => {
    axiosInstance
      .get(getAppontmentPatientUrl + "/" + logineduser?.id)
      .then((res) => {
        setPatientList(res.data);
      })
      .catch((e) => console.log(e.message));
  };

  useEffect(() => {
    setSelectedSlot(null);
    setupdateslots([]);
    setSlots([]);
    getAllSlots();
  }, [watch("doctorId"), watch("date")]);

  const getAllSlots = () => {
    let doctorID = parseInt(getValues("doctorId"));
    let departmentID = parseInt(getValues("deptId"));
    axiosInstance
      .get(
        getAllSlotsOfDocWithAppts +
          "/" +
          moment(getValues("date")).format("YYYY-MM-DD") +
          "/" +
          doctorID +
          "/" +
          departmentID
      )
      .then((res) => {
        const updateslots = res.data.map((item) => {
          // console.log(item.apptEnd);
          // let selectedSlots = moment(item?.apptStart).format(
          //   "YYYY-MM-DDTHH:mm"
          // );
          // let nowDatetime = moment().format("YYYY-MM-DDTHH:mm");
          // if (nowDatetime < selectedSlots) {
          //   return {
          //     ...item,
          //     selected: false,
          //   };
          // } else {
          //   return {};
          // }
          // else {
          //   return {};
          // }
          //else {
          return {
            ...item,
            selected: false,
          };
          // }
          // console.log(nowDatetime > selectedSlots);
        });
        const updateslotsfilterdates = updateslots.filter(
          (value) =>
            moment(value?.apptStart).format("YYYY-MM-DDTHH:mm") >
            moment().format("YYYY-MM-DDTHH:mm")
        );
        // setupdateslots(updateslots);
        setSlots(updateslotsfilterdates);
      })
      .catch((e) => console.log(e.message));
  };
  const selectSlot = (id) => {
    const afterseleteddata = slots.map((el) =>
      el.id === id ? { ...el, selected: true } : { ...el, selected: false }
    );
    setSelectedSlot(slots.find((value) => value.id == id));
    setSlots(afterseleteddata);
  };
  const saveAppontment = (data) => {
    console.log(selectedslot);

    if (selectedslot != null) {
      let selectedSlots = moment(selectedslot?.apptStart).format(
        "YYYY-MM-DDTHH:mm"
      );
      let nowDatetime = moment().format("YYYY-MM-DDTHH:mm");
      if (nowDatetime > selectedSlots) {
        toast.warning("Slot Time Over");
        getAllSlots();
      } else {
        let savedata = {
          slot: null,
          doctorId: data?.doctorId,
          patientId: logineduser?.id,
          apptStart: moment(selectedslot?.apptStart).format("YYYY-MM-DDTHH:mm"),
          apptEnd: moment(selectedslot?.apptEnd).format("YYYY-MM-DDTHH:mm"),
          isTeleConsultation: data?.is_tele_consultation,
        };
        axiosInstance
          .post(saveAppontmentUrl, savedata)
          .then((res) => {
            if (res.status === 200 || res.status === 201) {
              const strsubmit = "Data Successfully Submitted.";
              toast.success(strsubmit);
              getAppointedlist();
              getAllSlots();
            } else if (res.status === 409) {
              toast.warning(res.status.data);
            } else {
              toast.warning("Something Went Wrong");
              getAllSlots();
            }
          })
          .catch((error) => {
            if (error.response.status == 409) {
              toast.warning(error.response.data);
            } else {
              toast.error("Something Went Wrong");
            }
          });
      }
    } else {
      toast.warning(" NO Slots Seleted ");
      getAllSlots();
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(saveAppontment)}>
        <Grid container xs={12}>
          <Grid container xs={12} spacing={3}>
            <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
              {" "}
              <Typography>
                Select Date <span style={{ color: "red" }}>*</span>
              </Typography>
              <Controller
                name="date"
                control={control}
                render={({ field: { onChange, value } }) => {
                  return (
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        autoOk
                        fullWidth
                        disableToolbar
                        disablePast
                        size="small"
                        variant="inline"
                        format="dd/MM/yyyy"
                        id="date-picker-inline"
                        inputVariant="outlined"
                        value={value}
                        onChange={onChange}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                        error={!!errors.date}
                        helperText={errors.date?.message}
                      />
                    </MuiPickersUtilsProvider>
                  );
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
              <Typography>
                Select Doctor <span style={{ color: "red" }}>*</span>
              </Typography>
              <Controller
                name="doctorId"
                control={control}
                render={({ field: { onChange, value } }) => {
                  return (
                    <>
                      <Autocomplete
                        size="small"
                        options={doctorlist}
                        // value={doctorlist.find((o) => o.id === value) || ""}
                        value={
                          typeof value === "number"
                            ? doctorlist.find((o) => o.id === value)
                            : ""
                        }
                        fullWidth
                        getOptionLabel={(option) => option.staffName}
                        onChange={(e, v) => {
                          onChange(v?.id);
                          getAllSlots();
                        }}
                        renderInput={(params) => (
                          <TextField
                            fullWidth
                            multiline
                            {...params}
                            variant="outlined"
                            placeholder="Select/Search Doctor"
                            error={!!errors.doctorId}
                            helperText={errors.doctorId?.message}
                          />
                        )}
                      />
                    </>
                  );
                }}
              />
            </Grid>
          </Grid>

          <Grid container justifyContent="center">
            {slots.length > 0 ? (
              <>
                {" "}
                <Grid container>
                  <fieldset style={{ width: "100%" }}>
                    <legend>Select Slot:</legend>
                    <div
                      container
                      spacing={1}
                      // style={{ padding: 12 }}
                      align="center"
                      className={classes.gridslots}
                    >
                      {slots.map((item, index) => (
                        <>
                          {/* <Grid item> */}
                          <Card
                            align="center"
                            onClick={() =>
                              item.available == true
                                ? selectSlot(item.id)
                                : console.log("Not Avelable")
                            }
                            className={
                              item.available == true && item.selected == true
                                ? classes.cardSelected
                                : item.available == false
                                ? classes.cardNotAvailable
                                : classes.cardAvailable
                            }
                          >
                            <CardContent align="center">
                              {" "}
                              {moment(item.apptStart).format("hh:mm A") +
                                " to  " +
                                moment(item.apptEnd).format("hh:mm A")}
                            </CardContent>
                          </Card>
                          {/* </Grid> */}
                        </>
                      ))}
                    </div>
                  </fieldset>
                </Grid>
              </>
            ) : (
              <>
                <Grid container xs={12}>
                  <fieldset style={{ width: "100%" }}>
                    <legend>Slots:</legend>
                    <Grid
                      container
                      spacing={1}
                      style={{ padding: 12 }}
                      align="center"
                    >
                      <Grid item xs={12} direction="row">
                        <img src={noSlotsImg} width={100} />
                        <Typography> No Slots Available</Typography>
                      </Grid>
                    </Grid>
                  </fieldset>
                </Grid>
              </>
            )}
          </Grid>

          <Grid container spacing={2} className={classes.mt_2}>
            <Grid item xs={6}>
              <Controller
                name="is_tele_consultation"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <FormControl>
                    <FormLabel component="legend">
                      Tele-consultation <span style={{ color: "red" }}>*</span>
                    </FormLabel>
                    <RadioGroup row value={value} onChange={onChange}>
                      <FormControlLabel
                        value="true"
                        control={<Radio />}
                        label="Yes"
                        labelPlacement="start"
                      />
                      <FormControlLabel
                        value="false"
                        control={<Radio />}
                        label="No"
                        labelPlacement="start"
                      />
                    </RadioGroup>
                    <FormHelperText error={!!errors.is_tele_consultation}>
                      {errors.is_tele_consultation?.message}
                    </FormHelperText>
                  </FormControl>
                )}
              ></Controller>
            </Grid>
            <Grid xs={6} align="left" style={{ paddingTop: 20 }}>
              <Button variant="contained" color="primary" type="submit">
                Submit
              </Button>
            </Grid>
          </Grid>

          {/* <Grid container justifyContent="center">
            <Button variant="contained" color="primary" type="submit">
              Submit
            </Button>
          </Grid> */}

          <Grid container justifyContent="center">
            <AppointmentTable
              data={patientlist}
              fetchData={getAppointedlist}
              fetchslots={getAllSlots}
            />
          </Grid>
        </Grid>
      </form>
    </>
  );
}
