import {
  Box,
  Container,
  Grid,
  makeStyles,
  Tab,
  Tabs,
  Typography
} from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import EmployeeDetails from "pages/Admin/Employee/EmployeeTable";
import PropTypes from "prop-types";
import { useState } from "react";
import RenewalList from "./RenewalList";
import DashboardNew from "./DashboardNew";
import DashboardNewHR from "./DashboardNewHR";


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`vertical-tabpanel-${index}`} aria-labelledby={`vertical-tab-${index}`} {...other}>
      {value === index && (
        <Box style={{ padding: "0px 24px" }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};
function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.primary,
    display: "flex",
    justifyContent: "spacing-between",
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
    overflow: "unset",
  },
  tab: {
    textTransform: "capitalize",
    [theme.breakpoints.down("md")]: {
      width: "100%",
      maxWidth: "unset",
    },
  },
}));
export default function HRDashboard({isAdmin}) {
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Container maxWidth="xl" style={{ paddingTop: 24 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={2} lg={2}>
          <Tabs
            orientation="vertical"
            variant="scrollable"
            value={value}
            onChange={handleChange}
            aria-label="Vertical tabs example"
            className={classes.tabs}
          >
            <Tab
              label="Employee Details"
              {...a11yProps(0)}
              style={{
                background: value === 0 ? theme.palette.primary.main : theme.palette.primary.light,
                color: value === 0 ? theme.palette.primary.contrastText : theme.palette.primary.contrastText,
                opacity: 1,
                margin: "5px 0px",
              }}
              className={classes.tab}
            />
            <Tab
              label="Employee Renewal"
              {...a11yProps(1)}
              style={{
                background: value === 1 ? theme.palette.primary.main : theme.palette.primary.light,
                color: value === 1 ? theme.palette.primary.contrastText : theme.palette.primary.contrastText,
                opacity: 1,
                margin: "5px 0px",
              }}
              className={classes.tab}
            />
            <Tab
              label="Dashboard"
              {...a11yProps(2)}
              style={{
                background: value === 2 ? theme.palette.primary.main : theme.palette.primary.light,
                color: value === 2 ? theme.palette.primary.contrastText : theme.palette.primary.contrastText,
                opacity: 1,
                margin: "5px 0px",
              }}
              className={classes.tab}
            />
          </Tabs>
        </Grid>
        <Grid item xs={12} sm={12} md={10} lg={10}>
          <TabPanel value={value} index={0}>
            <EmployeeDetails />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <RenewalList />
          </TabPanel>
          <TabPanel value={value} index={2}>
           {isAdmin ? <DashboardNew /> : <DashboardNewHR /> }
          </TabPanel>
        </Grid>
      </Grid>
    </Container>
  );
}

