import React from "react";

//material-ui
import { makeStyles } from "@material-ui/core/styles";
import {
  AppBar,
  Grid,
  DialogActions,
  DialogTitle,
  Toolbar,
  Typography,
  DialogContent,
  Dialog,
  IconButton,
  Divider,
  Box,
} from "@material-ui/core";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import clsx from "clsx";

//icons
import DeleteIcon from "@material-ui/icons/Delete";
import CloseIcon from "@material-ui/icons/Close";
import { Person } from "@material-ui/icons";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";

import ThemeSetting from "pages/ThemeSetting";
import { useNavigate } from "react-router-dom";
import { ADD_PATIENT_DETAILS, LOGGED_IN_USER } from "Redux/Types";
import { useDispatch, useSelector } from "react-redux";
import ChangePassword from "./ChangePassword";
import axiosInstance from "pages/Services/AxiosInstance";
import { SERVICE_URL } from "../../config.json";
import { Greet } from "components/Greeting";
import MySlots from "pages/Doctor/MySlots";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    color: theme.palette.primary.contrastText,
  },
  appbarRoot: {
    maxHeight: 48,
    minHeight: 48,
  },
  appBar: {
    backgroundColor: theme.palette.primary.dark,
  },
  OTappBar: {
    backgroundColor: theme.palette.primary.dark,
    zIndex: theme.zIndex.drawer + 1,
  },
  appBarText: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      justifyContent: "space-between",
      alignItems: "center",
    },
  },
}));

export default function Appbar() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const [openTheme, setOpenTheme] = React.useState(false);
  const [openMySlots, setOpenMySlots] = React.useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const user =
    useSelector((store) => store.loggedInUser) ||
    JSON.parse(localStorage.getItem("User"));
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const [changeOpen, setChangeOpen] = React.useState(false);
  const handleChangeOpen = () => {
    setChangeOpen(true);
  };

  const handleChangeClose = () => {
    setChangeOpen(false);
  };
  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const handleLogout = () => {
    axiosInstance
      .post(SERVICE_URL + "user/logout", { userName: user.username })
      .catch((e) => console.log(e.message))
      .finally(() => {
        localStorage.clear();
        dispatch({ type: LOGGED_IN_USER, payload: null });
        dispatch({ type: ADD_PATIENT_DETAILS, payload: null });
        navigate("/");
      });
  };

  return (
    <div className={classes.root}>
      {openTheme && (
        <Dialog maxWidth="md" fullWidth open={true}>
          <ThemeSetting onClose={() => setOpenTheme(false)} />
        </Dialog>
      )}
      <AppBar
        position="static"
        className={user?.roleId === 6 ? clsx(classes.OTappBar) : classes.appBar}
        // classes={{ root: classes.appbarRoot }}
      >
        <Toolbar variant="dense">
          <Grid container className={classes.appBarText}>
            <Grid item>
              <Typography
                variant="h6"
                className={classes.title}
                startIcon={<DeleteIcon />}
              >
                BEL RETIRED EMPLOYEES CONTRIBUTORY HEALTH SCHEME (BERECHS)
              </Typography>
            </Grid>
            <Grid item>
              {user?.eName}&nbsp;&nbsp;({user?.roleName})&nbsp;&nbsp;
            </Grid>
          </Grid>

          <IconButton
            ref={anchorRef}
            aria-controls={open ? "menu-list-grow" : undefined}
            aria-haspopup="true"
            onClick={handleToggle}
            size="small"
            style={{ background: "white" }}
          >
            <Person />
          </IconButton>
          <Popper
            style={{ zIndex: 1 }}
            open={open}
            anchorEl={anchorRef.current}
            role={undefined}
            transition
            disablePortal
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin:
                    placement === "bottom" ? "center top" : "center bottom",
                }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList
                      autoFocusItem={open}
                      id="menu-list-grow"
                      onKeyDown={handleListKeyDown}
                    >
                      <MenuItem>
                      <a target="_blank" download 
                      href={
         user?.roleId === 2
      ? "https://drive.google.com/file/d/1QmE8qTZ08gyc0Dai9qMMjoVXdy_kgvi6/view?usp=sharing"
      : user?.roleId === 7
      ? "https://drive.google.com/file/d/1uzBd48jUEKRx_PJAfkBw0evRPli4Gg-I/view?usp=sharing"
      : user?.roleId === 1
      ? "https://drive.google.com/file/d/1opeRV2wLdjRcZmmBFWFFNXyHsnr7W6bY/view?usp=sharing"
      : null // Fallback to null if none of the conditions match
       }
      rel="noopener noreferrer"
       >
                       
                          <Typography style={{ color: "blue" }}>
                             User Manual
                          </Typography>
                        </a>
                      </MenuItem>
                      <MenuItem>{user?.roleName}</MenuItem>
                      {user?.roleId === 4 && (
                        <div>
                          <MenuItem onClick={() => setOpenMySlots(true)}>
                            My Slots
                          </MenuItem>
                          <MySlots
                            openMySlots={openMySlots}
                            closeMySlots={() => setOpenMySlots(!openMySlots)}
                          />
                        </div>
                      )}
                      <MenuItem onClick={() => setOpenTheme(true)}>
                        Theme Settings
                      </MenuItem>
                      <MenuItem onClick={handleChangeOpen}>
                        Change Password
                      </MenuItem>
                      <MenuItem>
                      <a
                          target="_blank"
                          download
                          href={"https://drive.google.com/file/d/1rjWaohNO1HciQVlv4_nRICFuxWgq35AI/view?usp=sharing"}
                          rel="noopener noreferrer"
                        >
                        <Typography style={{ color: "blue" }}>Contact HR</Typography>
                        </a>
                      </MenuItem>
                      <MenuItem onClick={handleLogout}>
                        <Typography style={{ color: "red" }}>Logout</Typography>
                      </MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </Toolbar>
      </AppBar>

      <Dialog
        open={changeOpen}
        onClose={handleChangeClose}
        aria-labelledby="form-dialog-title"
        maxWidth="xs"
      >
        <Grid container spacing={0}>
          <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
            <DialogTitle>
              <Typography style={{ color: "black" }}>
                Change password
              </Typography>
            </DialogTitle>
          </Grid>
          <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
            <IconButton
              onClick={handleChangeClose}
              edge="start"
              color="primary"
              arialabel="menu"
            >
              <CloseIcon style={{ color: "black" }} />
            </IconButton>
          </Grid>
        </Grid>

        <DialogContent>
          <ChangePassword
            user={user}
            onClose={handleChangeClose}
            logout={handleLogout}
          />
          {/* <Typography>gffg</Typography> */}
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
    </div>
  );
}
