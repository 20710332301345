import {
  Box,
  Button,
  Card,
  CardContent,
  FormControl,
  Grid,
  InputAdornment,
  LinearProgress,
  makeStyles,
  Select,
  Table,
  TableBody,
  TableRow,
  TextField,
  Typography,
  Badge,
} from "@material-ui/core";
import { CloseOutlined, DoneAllTwoTone, PersonRounded, RefreshOutlined, Search, Widgets } from "@material-ui/icons";
import { ArcElement, CategoryScale, Chart as ChartJS, Legend, Title, Tooltip } from "chart.js";
import useTable, { StyledTableCell, StyledTableRow } from "components/UseTable";
import { StatusChip } from "pages/AdminRenewalForm/AdminRenewalForm";
import { useStylesRenewalForm } from "pages/EmployeeRenewalForm/RenewalForm";
import axiosInstance from "pages/Services/AxiosInstance";
import { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import { toast } from "react-toastify";
import { TextDivider } from "./ProfilePage";
// Register Chart.js components
const mobileNumberRegex = /^[6-9]\d{9}$/;
ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale);

const unitsData = [
  { id: "-1", value: "All" },
  { id: "1", value: "GD" },
  { id: "2", value: "PK" },
  { id: "3", value: "BG" },
  { id: "4", value: "HD" },
  { id: "5", value: "KT" },
  { id: "6", value: "NM" },
  { id: "7", value: "PN" },
  { id: "8", value: "MC" },
];

const useStyles = makeStyles((theme) => ({
  card: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(2),
    boxShadow: theme.shadows[2],
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.background.paper,
    width: "auto",
  },
  iconContainer: {
    marginRight: theme.spacing(2),
    color: theme.palette.primary.main,
    fontSize: 48,
  },
  countText: {
    fontWeight: "bold",
  },
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(1),
    boxShadow: theme.shadows[1],
    marginBottom: "18px",
  },
  tabItem: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    padding: theme.spacing(0.5, 1),
    position: "relative",
    "&:hover": {
      opacity: 0.8,
    },
  },
  activeTab: {
    fontWeight: "bold",
    borderBottom: `2px solid ${theme.palette.primary.main}`,
  },
  badge: {
    marginLeft: theme.spacing(0.5),
    padding: theme.spacing(0.5, 1),
    borderRadius: "12px",
    fontSize: "0.75rem",
    color: theme.palette.common.white,
    fontWeight: "bold",
    minWidth: "15px",
    textAlign: "center",
  },
  pendingSubmission: { backgroundColor: "#ee82ee" },
  pending: { backgroundColor: "#fbc02d" },
  completed: { backgroundColor: "#4caf50" },
  rejected: { backgroundColor: "#e57373" },
}));

export const statusOptions = [
  { label: "Approved", count: 800, colorClass: "completed" },
  { label: "Rejected", count: 50, colorClass: "rejected" },
  { label: "Pending Approval", count: 50, colorClass: "pending" },
  { label: "Pending Submission", count: 100, colorClass: "pendingSubmission" },
];

const headCells = [
  { id: "S.No.", label: "S.No.", align: "left" },
  { id: "employeeName", label: "Employee Name", align: "left" },
  { id: "isEmpAlive", label: "Employee Alive", align: "left" },
  { id: "employeeId", label: "Employee Id", align: "left" },
  { id: "empMobileNo", label: "Mobile No.", align: "left" },
  { id: "spouseName", label: "Spouse Name", align: "left" },
  { id: "isSpouseAlive", label: "Spouse Alive", align: "left" },
  { id: "submittedDate", label: "Submitted Date", align: "left" },
  { id: "financialYear", label: "Financial Year", align: "left" },
  { id: "status", label: "Status", align: "left" },
];

export default function DashboardNew() {
  const gClasses = useStylesRenewalForm();
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [selectedUnit, setSelectedUnit] = useState("-1");
  const [tableData, setTableData] = useState([]);
  const [units] = useState(unitsData);
  const [filterFn, setFilterFn] = useState({ fn: (items) => items });

  const [activeTab, setActiveTab] = useState("Approved");
  const [stats, setStats] = useState({});
  const [overallStats, setOverallStats] = useState({})
  //


  useEffect(() => {
    if (selectedUnit !== "-1") {
      if (!selectedUnit) {
        toast.error("Please select unit");
        return
      }
      setLoading(true)
      axiosInstance('dashboard/stats/unit/' + selectedUnit).then(({ data }) => {
        setStats(data);
        const unitName = unitsData.find(u => u.id === selectedUnit)?.value
        setOverallStats(data[unitName])
      }).catch((e) => {
        console.log(e);
        toast.error("Failed to get dashboard stats")
      }).finally(()=>{
        setLoading(false)
      })
    } else {
      setLoading(true)
      axiosInstance('dashboard/stats').then(({ data }) => {
        setStats(data);
        setOverallStats(data.overall);
      }).catch((e) => {
        console.log(e);
        toast.error("Failed to get dashboard stats")
      }).finally(()=>{
        setLoading(false)
      })
    }
  }, [selectedUnit])


  const requestSearch = (e) => {
    let val = e.target.value;
    setFilterFn({
      fn: (records) => {
        if (!val) return records;
        return records.filter(
          (row) =>
            row.renewalStatus
              .toString()
              .toLowerCase()
              .includes(val.toLowerCase()) ||
            row.renewalYear
              ?.toString()
              .toLowerCase()
              .includes(val.toLowerCase()) ||
            row.employeeName.toLowerCase().includes(val.toLowerCase()) ||
            row.employeeId
              ?.toString()
              .toLowerCase()
              .includes(val.toLowerCase()) ||
            row.empMobileNo
              ?.toString()
              .toLowerCase()
              .includes(val.toLowerCase())
        );
      },
    });
  };

  const { TblContainer, TblHead, TblPagination, recordsAfterPagingAndSorting } = useTable(tableData, headCells, filterFn, 15);
  const statusOptions = [
    { label: "Approved", count: overallStats.total_approved, colorClass: "completed" },
    { label: "Rejected", count: overallStats.total_rejected, colorClass: "rejected" },
    { label: "Pending Approval", count: overallStats.total_pending_approval, colorClass: "pending" },
    { label: "Pending Submission", count: overallStats.total_pending_submission, colorClass: "pendingSubmission" },
  ];

  return (
    <Box style={{ padding: 24, backgroundColor: "#f9f9f9" }}>
      <FormControl variant="outlined" size="small" style={{ marginBottom: 20, minWidth: 300 }}>
        <Typography variant="body2" color="textSecondary">
          Please Select Unit
        </Typography>
        <Select native variant="outlined" required value={selectedUnit} onChange={(e) => setSelectedUnit(e.target.value)}>
          <option value="">Select unit</option>
          {units.map((unit) => (
            <option key={unit.id} value={unit.id}>
              {unit.value}
            </option>
          ))}
        </Select>
      </FormControl>
      {loading &&<LinearProgress/>}

      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={4}>
          <WidgetCard title="Total Employee" count={overallStats.total_employees} color="black" />
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <WidgetCard title="Total Submitted" count={overallStats.total_submitted} color="grey" />
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <WidgetCard icon={PersonRounded} title="Pending Submission" count={overallStats.total_pending_submission} color="violet" />
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <WidgetCard icon={DoneAllTwoTone} title="Approved" count={overallStats.total_approved} color="darkgreen" />
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <WidgetCard icon={RefreshOutlined} title="Pending Approval" count={overallStats.total_pending_approval} color="orange" />
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <WidgetCard icon={CloseOutlined} title="Rejected" count={overallStats.total_rejected} color="tomato" />
        </Grid>

        <Grid item xs={12}>
          <BarChart data={stats} />
        </Grid>
        {/* <Grid item xs={12}>
          <TextDivider text="Filter Record Based On Status" />
        </Grid> */}

        {/* <Grid item xs={12}>
          <Box className={classes.container}>
            {statusOptions.map((status) => (
              <Box
                key={status.label}
                onClick={() => setActiveTab(status.label)}
                className={`${classes.tabItem} ${activeTab === status.label ? classes.activeTab : ""}`}
              >
                <Typography variant="body2">{status.label}</Typography>
                <div className={`${classes.badge} ${classes[status.colorClass]}`}>{status.count}</div>
              </Box>
            ))}
          </Box>
          <div style={{ display: "flex", justifyContent: "end", alignItems: "center", gap: "5px" }}>
            <TextField
              placeholder="Search..."
              variant="outlined"
              size="small"
              className={gClasses.searchInput}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
              onChange={requestSearch}
            />
          </div>
          <TblContainer style={{ height: "600px" }}>
            {loading && <LinearProgress />}
            <Table
              style={{
                position: "relative",
                border: "1px solid lightgrey",
              }}
              size="small"
            >
              <TblHead />
              {recordsAfterPagingAndSorting()?.length !== 0 ? (
                <TableBody>
                  {recordsAfterPagingAndSorting()?.map((item, index) => (
                    <TableRow key={item.id} className={gClasses.tableColor}>
                      <StyledTableCell>{index + 1} </StyledTableCell>
                      <StyledTableCell>{item.employeeName} </StyledTableCell>
                      <StyledTableCell>{item.isEmpAlive ? "Yes" : "No"} </StyledTableCell>
                      <StyledTableCell>{item.employeeId} </StyledTableCell>
                      <StyledTableCell>{item.empMobileNo} </StyledTableCell>
                      <StyledTableCell>{item.spouseName} </StyledTableCell>
                      <StyledTableCell>{item.isSpouseAlive ? "Yes" : "No"} </StyledTableCell>

                      <StyledTableCell>{item.submittedDate} </StyledTableCell>
                      <StyledTableCell>{item.renewalYear} </StyledTableCell>
                      <StyledTableCell>
                        <StatusChip status={item.renewalStatus} />
                      </StyledTableCell>
                    </TableRow>
                  ))}
                </TableBody>
              ) : (
                <TableBody>
                  <StyledTableRow>
                    <StyledTableCell colSpan={8} align="center">
                      No records found
                    </StyledTableCell>
                  </StyledTableRow>
                </TableBody>
              )}
            </Table>
            {recordsAfterPagingAndSorting()?.length ? <TblPagination /> : null}
          </TblContainer>
        </Grid> */}
      </Grid>
    </Box>
  );
}

const transformDataForChart = (data) => {
  // Extract region names as labels
  const labels = Object.keys(data).filter((e) => e !== 'overall');

  // Create aligned datasets
  const datasets = [
    {
      label: "Approved",
      data: labels.map((region) => data[region]?.total_approved || 0),
      backgroundColor: "rgba(75, 192, 50, 0.6)",
    },
    {
      label: "Pending Approval",
      data: labels.map((region) => data[region]?.total_pending_approval || 0),
      backgroundColor: "rgba(255, 206, 86, 0.6)",
    },
    {
      label: "Rejected",
      data: labels.map((region) => data[region]?.total_rejected || 0),
      backgroundColor: "rgba(255, 99, 99, 0.6)",
    },
  ];

  return { labels, datasets };
};

function BarChart({ data }) {
  // Data and options for the Chart.js Bar Chart


  const options = {
    responsive: true,
    scales: {
      x: {
        stacked: false, // Each unit will have 3 separate bars
      },
      y: {
        beginAtZero: true,
      },
    },
  };
  const ChatData = transformDataForChart(data)

  return (
    <Card style={{ maxWidth: "100%", margin: "auto" }}>
      <CardContent>
        {/* <Typography variant="h6" align="center">
          Status by Unit Wise 
        </Typography> */}
        <Bar data={ChatData} options={options} />
      </CardContent>
    </Card>
  );
}

const WidgetCard = ({ icon: IconComponent = Widgets, title, count, color }) => {
  const classes = useStyles();
  return (
    <Box className={classes.card}>
      <Box className={classes.iconContainer}>
        <IconComponent fontSize="inherit" style={{ color }} />
      </Box>
      <Box>
        <Typography variant="h6" color="primary">
          {title}
        </Typography>
        <Typography variant="h5" className={classes.countText}>
          {count}
        </Typography>
      </Box>
    </Box>
  );
};
